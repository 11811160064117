import ReportHeader from "../../../components/reportHeader/ReportHeader";
import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";

const typeOptions = [
  { label: "Pediatric Vaccine (PDV)", value: "Pediatric Vaccine" },
  { label: "Vaccine (VAC)", value: "Vaccine" },
  { label: "Procedure (PROC)", value: "Procedure" },
  { label: "Injection (INJEC)", value: "Injection" },
  { label: "IT Equipment 1 (ITEQUIP)", value: "IT Equipment 1 (ITEQUIP)" },
  { label: "Covid 19 Test (COVID 19)", value: "Covid 19 Test (COVID 19)" },
];

const overrideStrings = {
  search: "Search",
  selectAll: "All",
  selectSomeItems: "All",
};
const MedicationsList = () => {
  const [selectedStock, setSelectedStock] = useState([]);
  return (
    <div className="report-content-wrapper">
      <ReportHeader
        reportTitle="SRX Report - List of Medications"
        reportDate="04 April 2024"
      />
      <div className="report-filter-wrapper">
        <div className="report-filter-item-wrapper">
          <div className="filter-item-box type-filter-item">
            <label className="form-label">Type</label>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={typeOptions}
              value={selectedStock}
              onChange={setSelectedStock}
              labelledBy="Select"
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">Show</label>
            <select className="form-select">
              <option selected>All</option>
              <option value="1">Active</option>
              <option value="2">Active &amp; Recall</option>
              <option value="3">Active &amp; Expired</option>
              <option value="3">Inactive</option>
            </select>
          </div>
          <div className="filter-item-box clear-all-box">
            <button type="button" className="clear-filter-btn">
              Clear All
            </button>
          </div>
        </div>
        <div className="report-export-box">
          <button type="button" className="report-export-btn">
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
            Export
          </button>
          <button type="button" className="report-export-btn">
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} /> UOM
            Export
          </button>
        </div>
      </div>
      <div className="filter-detail-wrapper">
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-title">
            <h4></h4>
          </div>
          <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>MANUFACTURER</th>
                  <th>NDC</th>
                  <th>LOT NO</th>
                  <th>EXP. DATE</th>
                  <th>Group</th>
                  <th>CVX</th>
                  <th>RXID</th>
                  <th>UOM</th>
                  <th>Type</th>
                  <th>Vac</th>
                  <th>Rcal</th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>01A VARIVAX</td>
                  <td>Merck Sharp</td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>05/06/2023</td>
                  <td>varicella</td>
                  <td>21</td>
                  <td>RX2020092211464026</td>
                  <td>Tablet(s)</td>
                  <td>VAC</td>
                  <td>X</td>
                  <td>X</td>
                  <td>X</td>
                </tr>
                <tr>
                  <td>01A VARIVAX</td>
                  <td>Merck Sharp</td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>05/06/2023</td>
                  <td>varicella</td>
                  <td>21</td>
                  <td>RX2020092211464026</td>
                  <td>Tablet(s)</td>
                  <td>VAC</td>
                  <td>X</td>
                  <td>X</td>
                  <td>X</td>
                </tr>
                <tr>
                  <td>01A VARIVAX</td>
                  <td>Merck Sharp</td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>05/06/2023</td>
                  <td>varicella</td>
                  <td>21</td>
                  <td>RX2020092211464026</td>
                  <td>Tablet(s)</td>
                  <td>VAC</td>
                  <td>X</td>
                  <td>X</td>
                  <td>X</td>
                </tr>
                <tr>
                  <td>01A VARIVAX</td>
                  <td>Merck Sharp</td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>05/06/2023</td>
                  <td>varicella</td>
                  <td>21</td>
                  <td>RX2020092211464026</td>
                  <td>Tablet(s)</td>
                  <td>VAC</td>
                  <td>X</td>
                  <td>X</td>
                  <td>X</td>
                </tr>
                <tr>
                  <td>01A VARIVAX</td>
                  <td>Merck Sharp</td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>05/06/2023</td>
                  <td>varicella</td>
                  <td>21</td>
                  <td>RX2020092211464026</td>
                  <td>Tablet(s)</td>
                  <td>VAC</td>
                  <td>X</td>
                  <td>X</td>
                  <td>X</td>
                </tr>
                <tr>
                  <td>01A VARIVAX</td>
                  <td>Merck Sharp</td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>05/06/2023</td>
                  <td>varicella</td>
                  <td>21</td>
                  <td>RX2020092211464026</td>
                  <td>Tablet(s)</td>
                  <td>VAC</td>
                  <td>X</td>
                  <td>X</td>
                  <td>X</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicationsList;
