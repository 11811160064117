import { Button, Dropdown } from "react-bootstrap";
import "./athenaprofileheader.scss";
import threedotvertical from "../../assets/images/three-dots-vertical.svg";
import deleteicon from "../../assets/images/delete-icon.svg";
import discardicon from "../../assets/images/times-icon.svg";
import circlecheckicon from "../../assets/images/unprocessed-icon.svg";
import { ReactSVG } from "react-svg";
import { DeleteConfModel } from "../DeleteConfModel/deleteConfModel";
const AthenaProfileHeader = ({
  title,
  id,
  srxMessage,
  isEdit,
  markAthenaOrderunProcessedAPI,
  discardAthenaOrderAPI,
  deleteAthenaOrderAPI,
  recordName,
  toggleModal,
  showModal,
  srxStatus,
}: any) => {
  return (
    <div className="athena-profile-header-wrapper">
      <div className="profile-title-status-box">
        <h2>{title}</h2>
      </div>
      <div className="profile-actions-box">
        <span
          className={`${srxStatus === "S" ? "issue-status-tag-success" : "issue-status-tag"}`}
        >
          {srxMessage}
        </span>
        <div
          className="edit-btn-box"
          style={{ display: isEdit ? "none" : "block" }}
        >
          <Button
            variant="primary"
            href={`/edit-transaction/${id}`}
            disabled={srxStatus === "D" || srxStatus === "S"}
          >
            <i className="bi bi-pencil-square"></i> Edit
          </Button>
        </div>
        <Dropdown>
          <Dropdown.Toggle variant="link" id="dropdown-basic">
            <ReactSVG
              className="svg-box"
              wrapper="span"
              src={threedotvertical}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={markAthenaOrderunProcessedAPI}
              disabled={srxStatus === "D" || srxStatus === "S"}
            >
              <ReactSVG
                style={{ marginLeft: "-2px" }}
                className="svg-box"
                wrapper="span"
                src={circlecheckicon}
              />
              Mark Unprocessed
            </Dropdown.Item>
            <Dropdown.Item
              onClick={toggleModal}
              disabled={srxStatus === "D" || srxStatus === "S"}
            >
              <ReactSVG className="svg-box" wrapper="span" src={deleteicon} />
              Delete
            </Dropdown.Item>
            <Dropdown.Item
              onClick={discardAthenaOrderAPI}
              disabled={srxStatus === "D" || srxStatus === "S"}
            >
              <ReactSVG className="svg-box" wrapper="span" src={discardicon} />
              Discard
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteAthenaOrderAPI}
        modelName={`record`}
        recordName={recordName}
      />
    </div>
  );
};

export default AthenaProfileHeader;
