export const APIResMessages = {
  S_W_R: "something went wrong",
};
export const CharacterLength = {
  sevenFive: 75,
  twoFiveSix: 256,
  fifty: 50,
  fiveHundred: 500,
  oneTwoFive: 125,
  hundred: 100,
};
export const PERMISSION_MODULES = {
  INVENTORY: "Rx",
};

export const PERMISSION_IDENTIFIERS = {
  MANAGE_USE_UNITS: "ManageUseUnits",
  DUPLICATE_INVENTORY_ITEM: "DuplicateRx",
  EDIT_INVENTORY_ITEM: "EditRx",
  DELETE_INVENTORY_ITEM: "DeleteRx",
  ADD_INVENTORY_ITEM: "AddRx",
};
