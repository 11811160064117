import ReportHeader from "../../../components/reportHeader/ReportHeader";

import { useState } from "react";

import TelerikReporting from "../../../components/TelerikReporting/TelerikReporting";

const UsersList = () => {
  const [isActiveUser, setIsActiveUser] = useState(true);
  const [reportKey, setReportKey] = useState(0);

  const onSelectUser = (e: any) => {
    setIsActiveUser(e.target.value);
    setReportKey((prevKey) => prevKey + 1);
  };

  return (
    <>
      <div className="report-filter-wrapper" style={{ marginBottom: "20px" }}>
        <div className="report-filter-item-wrapper">
          <div
            className="filter-item-box"
            style={{ width: "120px", marginTop: "10px" }}
          >
            <select className="form-select" onChange={onSelectUser}>
              <option value="true">Active</option>
              <option value="false">In Active</option>
            </select>
          </div>
        </div>
      </div>
      <TelerikReporting
        key={reportKey}
        params={{ IsUserActive: isActiveUser }}
        reportName="TrinetUserReport.trdp"
        reportEndPoint="reports/listofusers/get"
      />
    </>
  );
};

export default UsersList;
