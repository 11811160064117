import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Unit {
  unitCode: string;
  unitName: string;
  inventoryUnit: boolean;
  emrUnit: boolean;
  orderingUnit: boolean;
  isActiveInventoryUnit: boolean;
  isActiveEMRUnit: boolean;
  isActiveOrderingUnit: boolean;
  sortOrder: number;
  deletedBy?: string;
  deleted: boolean;
  deletedOnUtc?: string;
  id: string;
  isActive: boolean;
  createdOnUtc?: string;
  updatedOnUtc?: string;
  createdBy: string;
  updatedBy?: string;
}
export interface UnitData {
  selectedUnit: Unit;
}
const storedUnit = localStorage.getItem("selectedUnit");
const initialState: UnitData = {
  selectedUnit: storedUnit ? JSON.parse(storedUnit) : null,
};

const unitSlice = createSlice({
  name: "unit",
  initialState,
  reducers: {
    setSelectedUnit: (state, action: PayloadAction<Unit>) => {
      state.selectedUnit = action.payload;
      localStorage.setItem("selectedUnit", JSON.stringify(action.payload));
    },
  },
});
export const { setSelectedUnit } = unitSlice.actions;
export default unitSlice.reducer;
export const selectedUnit = (state: RootState) => state.unit.selectedUnit;
