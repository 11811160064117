import { toast } from "react-toastify";
import { getErrorMessage } from "../utils/commonUtils";
import {
  useGetAllItemsMutation,
  useGetBarcodeDetailsMutation,
  useGetItemCreateDropdownValMutation,
  GetBarcode,
  useCreateInventortItemMutation,
  useGetInventoryCountListMutation,
  useInventoryTransactionsReceiveMutation,
  useInventoryTransactionsAdjustMutation,
  useInventoryTransactionsTransferMutation,
  useInventoryOrderLevelEditMutation,
  useInventoryTransactionMutation,
  useEditInventoryItemMutation,
  useDeleteInventoryItemMutation,
  useGetOrderingProductsMutation,
  useCreateOrderingProductsMutation,
  useGetNewBarcodeMutation,
  useDuplicateItemMutation,
  useGetOrderUnitItemsMutation,
  useCreateOrderUnitItemsMutation,
  useEditOrderUnitItemsMutation,
  useDeleteOrderUnitItemsMutation,
  useInventoryTransactionsCountMutation,
  useInvOrderManagementMutation,
  useInvOrdManGetQtyOnHandMutation,
  useInventoryOrderLevelDeleteMutation,
  useInventoryTransactionsRepackMutation,
  useGetBarcodesMutation,
  useBarcodeCreateMutation,
  useBarcodeEditMutation,
  useBarcodeDefaultMutation,
  useBarcodePrintMutation,
} from "./../redux/inventory/inventoryApiSlice";

export default function useTenant() {
  const [getAllInventoryApi, { isLoading: inventoryItemListLoading }] =
    useGetAllItemsMutation();
  const [
    getItemCreateDropdownVal,
    { isLoading: createItemDropdownvalLoading },
  ] = useGetItemCreateDropdownValMutation();
  const [getBarcodeDetails, { isLoading: barcodeDetailsLoading }] =
    useGetBarcodeDetailsMutation();
  const [createInventoryItem, { isLoading: createInventoryItemLoading }] =
    useCreateInventortItemMutation();
  const [getInventoryCountList, { isLoading: getInventoryCountListLoading }] =
    useGetInventoryCountListMutation();
  const [editInventoryItem, { isLoading: editInventoryItemLoading }] =
    useEditInventoryItemMutation();
  const [deleteInventoryItem, { isLoading: deleteInventoryItemLoading }] =
    useDeleteInventoryItemMutation();
  const [barcodePrint, { isLoading: barcodePrintLoading }] =
    useBarcodePrintMutation();

  const [
    inventoryTransactionsReceive,
    { isLoading: inventoryTransactionsReceiveLoading },
  ] = useInventoryTransactionsReceiveMutation();
  const [
    inventoryTransactionsCount,
    { isLoading: inventoryTransactionsCountLoading },
  ] = useInventoryTransactionsCountMutation();
  const [
    inventoryTransactionsAdjust,
    { isLoading: inventoryTransactionsAdjustLoading },
  ] = useInventoryTransactionsAdjustMutation();
  const [
    inventoryTransactionsTransfer,
    { isLoading: inventoryTransactionsTransferLoading },
  ] = useInventoryTransactionsTransferMutation();
  const [
    inventoryOrderLevelEdit,
    { isLoading: inventoryOrderLevelLoadingEdit },
  ] = useInventoryOrderLevelEditMutation();
  const [
    inventoryOrderLevelDelete,
    { isLoading: inventoryOrderLevelLoadingDelete },
  ] = useInventoryOrderLevelDeleteMutation();

  const [
    inventoryOrderManagement,
    { isLoading: inventoryOrderManagementLoading },
  ] = useInvOrderManagementMutation();

  const [invOrdManGetQtyOnHand, { isLoading: invOrdManGetQtyOnHandLoading }] =
    useInvOrdManGetQtyOnHandMutation();

  const [inventoryTransaction, { isLoading: getInventoryTransactionsLoading }] =
    useInventoryTransactionMutation();
  const [getOrderingProducts, { isLoading: getOrderingProductsLoading }] =
    useGetOrderingProductsMutation();
  const [createOrderingProducts, { isLoading: createOrderingProductsLoading }] =
    useCreateOrderingProductsMutation();
  const [getNewBarcode, { isLoading: getNewBarcodeLoading }] =
    useGetNewBarcodeMutation();
  const [duplicateItem, { isLoading: duplicateItemLoading }] =
    useDuplicateItemMutation();
  const [getOrderUnitItems, { isLoading: getOrderUnitItemsLoading }] =
    useGetOrderUnitItemsMutation();
  const [createOrderUnitItems, { isLoading: createOrderUnitItemsLoading }] =
    useCreateOrderUnitItemsMutation();
  const [editOrderUnitItems, { isLoading: editOrderUnitItemsLoading }] =
    useEditOrderUnitItemsMutation();
  const [deleteOrderUnitItems, { isLoading: deleteOrderUnitItemsLoading }] =
    useDeleteOrderUnitItemsMutation();
  const [
    inventoryTransactionsRepack,
    { isLoading: inventoryTransactionsRepackLoading },
  ] = useInventoryTransactionsRepackMutation();
  const [getBarcodes, { isLoading: getBarcodesLoading }] =
    useGetBarcodesMutation();
  const [barcodeCreate, { isLoading: barcodeCreateLoading }] =
    useBarcodeCreateMutation();
  const [barcodeEdit, { isLoading: barcodeEditLoading }] =
    useBarcodeEditMutation();
  const [barcodeDefault, { isLoading: barcodeDefaultLoading }] =
    useBarcodeDefaultMutation();
  const barcodePrintAPI = async (data: any) => {
    try {
      const response = await barcodePrint(data).unwrap();
      toast.success(`${response.result.rxSRXId} has been set to default`);
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const barcodeDefaultAPI = async (data: any) => {
    try {
      const response = await barcodeDefault(data).unwrap();
      toast.success(`${response.result.rxSRXId} has been set to default`);
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const barcodeEditAPI = async (data: any) => {
    try {
      const response = await barcodeEdit(data).unwrap();
      toast.success(`Barcode Status has been updated`);
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const barcodeCreateAPI = async (data: any) => {
    try {
      const response = await barcodeCreate(data).unwrap();
      toast.success(`Barcode ${response.result.rxSRXId} added successfully`);
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getBarcodesAPI = async (itemId: any) => {
    try {
      const response = await getBarcodes(itemId).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getOrderUnitItemsAPI = async (data: any) => {
    try {
      const response = await getOrderUnitItems(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const createOrderUnitItemsAPI = async (data: any) => {
    try {
      const response = await createOrderUnitItems(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const editOrderUnitItemsAPI = async (data: any) => {
    try {
      const response = await editOrderUnitItems(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deleteOrderUnitItemsAPI = async (data: any) => {
    try {
      const response = await deleteOrderUnitItems(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getInventoryItemsList = async (data: any) => {
    try {
      const response = await getAllInventoryApi(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getItemDropdownVal = async () => {
    try {
      const response = await getItemCreateDropdownVal({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getBarcodeDecode = async (data: GetBarcode) => {
    try {
      const response = await getBarcodeDetails(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const createInventoryItemAPI = async (data: any) => {
    try {
      const response = await createInventoryItem(data).unwrap();
      toast.success("Item has been added successfully");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getInventoryCountListAPI = async (data: any) => {
    try {
      const response = await getInventoryCountList(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryTransRepack = async (data: any) => {
    try {
      const response = await inventoryTransactionsRepack(data).unwrap();
      toast.success("Item repackage completed successfully");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryTransReceive = async (data: any) => {
    try {
      const response = await inventoryTransactionsReceive(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryTransCount = async (data: any) => {
    try {
      const response = await inventoryTransactionsCount(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryTransAdjust = async (data: any) => {
    try {
      const response = await inventoryTransactionsAdjust(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryTransTransfer = async (data: any) => {
    try {
      const response = await inventoryTransactionsTransfer(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryOrderLevelEditApi = async (itemId: any) => {
    try {
      const response = await inventoryOrderLevelEdit(itemId).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryOrderLevelDeleteApi = async (id: string) => {
    try {
      const response = await inventoryOrderLevelDelete(id).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const inventoryOrderManagementGet = async (itemId: any) => {
    try {
      const response = await inventoryOrderManagement(itemId).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const invOrdManGetQtyOnHandGet = async (data: any) => {
    try {
      const response = await invOrdManGetQtyOnHand(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getInventoryTransactions = async () => {
    try {
      const response = await inventoryTransaction({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const editInventoryItemAPI = async (data: any) => {
    try {
      const response = await editInventoryItem(data).unwrap();
      toast.success("Success! Item has been Updated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deleteInventoryItemAPI = async (data: any) => {
    try {
      const response = await deleteInventoryItem(data).unwrap();
      toast.success(
        `${response.result.rxName} Item has been deleted successfully`,
      );
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getOrderingProductsAPI = async (data: any) => {
    try {
      const response = await getOrderingProducts(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const createOrderingProductsAPI = async (data: any) => {
    try {
      const response = await createOrderingProducts(data).unwrap();
      toast.success("Ordering Product has been created successfully");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getNewBarcodeAPI = async () => {
    try {
      const response = await getNewBarcode({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const duplicateItemAPI = async (data: any) => {
    try {
      const response = await duplicateItem(data).unwrap();
      toast.success("Success! Inventory has been Duplicated with New Barcode");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    barcodePrint,
    barcodePrintLoading,
    getInventoryItemsList,
    inventoryItemListLoading,
    getItemDropdownVal,
    createItemDropdownvalLoading,
    getBarcodeDecode,
    barcodeDetailsLoading,
    createInventoryItemAPI,
    createInventoryItemLoading,
    getInventoryCountListAPI,
    getInventoryCountListLoading,
    inventoryTransReceive,
    inventoryTransactionsReceiveLoading,
    inventoryTransAdjust,
    inventoryTransactionsAdjustLoading,
    inventoryTransTransfer,
    inventoryTransactionsTransferLoading,
    inventoryOrderLevelEditApi,
    inventoryOrderLevelLoadingEdit,
    inventoryOrderLevelDeleteApi,
    inventoryOrderLevelLoadingDelete,
    getInventoryTransactions,
    getInventoryTransactionsLoading,
    editInventoryItemAPI,
    editInventoryItemLoading,
    deleteInventoryItemAPI,
    deleteInventoryItemLoading,
    getOrderingProductsAPI,
    getOrderingProductsLoading,
    createOrderingProductsAPI,
    createOrderingProductsLoading,
    getNewBarcodeAPI,
    getNewBarcodeLoading,
    duplicateItemAPI,
    duplicateItemLoading,
    getOrderUnitItemsAPI,
    createOrderUnitItemsAPI,
    deleteOrderUnitItemsAPI,
    editOrderUnitItemsAPI,
    getOrderUnitItemsLoading,
    createOrderUnitItemsLoading,
    editOrderUnitItemsLoading,
    deleteOrderUnitItemsLoading,
    inventoryTransCount,
    inventoryTransactionsCountLoading,
    inventoryOrderManagementGet,
    inventoryOrderManagementLoading,
    invOrdManGetQtyOnHandGet,
    invOrdManGetQtyOnHandLoading,
    inventoryTransRepack,
    inventoryTransactionsRepackLoading,
    getBarcodesAPI,
    getBarcodesLoading,
    barcodeCreateAPI,
    barcodeCreateLoading,
    barcodeEditAPI,
    barcodeEditLoading,
    barcodeDefaultAPI,
    barcodeDefaultLoading,
  };
}
