import "./resetPassword.scss";
import loginlogo from "../../assets/images/login-logo.svg";
import loginimage from "../../assets/images/login-image.jpg";
import passwordResetModalImage from "../../assets/images/pass-reset-succes-image.svg";
import Form from "react-bootstrap/Form";
import { Button, Modal, Image, Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import useAuth from "../../Hooks/useAuth";
import Loading from "../../components/LoadingPage/Loading";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);
  const [isVerified, setIsVerified] = useState(true);
  const location = useLocation();
  const {
    verifyResetPassApi,
    verifyResetPassLoading,
    resetPasswordApi,
    resetPasswordLoading,
  } = useAuth();
  // Create a URLSearchParams object to parse the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Extract parameters
  const clientId = queryParams.get("clientId") ?? "";
  const userEmailId = queryParams.get("userEmailId") ?? "";
  const uniqueKey = queryParams.get("uniqueKey") ?? "";

  const payload = {
    uniqueId: uniqueKey,
    userEmailId: userEmailId,
    clientId: clientId,
  };

  useEffect(() => {
    verifyResetPassApi(payload)
      .then()
      .catch((errpr) => {
        setIsVerified(false);
      });
  }, []);

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      password: "",
      userEmailId: userEmailId ?? "",
      clientId: clientId ?? "",
      confirmPassword: "",
      uniqueId: uniqueKey ?? "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(
          /[\W_]/,
          "Password must contain at least one special character",
        )
        .required("Password is Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Confirm Password is Required"),
    }),

    onSubmit: async (values: any) => {
      resetPasswordApi(values).then((res) => {
        handleShow();
      });
    },
  });
  return (
    <div className="reset-password-wrapper">
      {verifyResetPassLoading || resetPasswordLoading ? <Loading /> : null}
      <Container fluid>
        <Row style={{ minHeight: "100vh" }}>
          <Col xs={12} md={6}>
            {isVerified ? (
              <div className="login-content-wrapper forgot-pass-wrapper">
                <div className="login-content">
                  <img src={loginlogo} alt="logo" />
                  <h4>Reset your Password</h4>
                  <p>Enter a new password to reset your current password</p>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-4 password-field-box">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter New Password"
                        id="password"
                        name="password"
                        onChange={(e) =>
                          handleChange("password")(e.target.value ?? "")
                        }
                        value={values.password}
                        isInvalid={touched.password && !!errors.password}
                      />
                      {touched.password && errors.password ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.password as string}
                        </Form.Control.Feedback>
                      ) : null}

                      {showPassword ? (
                        <i
                          className="bi bi-eye-fill"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      ) : (
                        <i
                          className="bi bi-eye-slash-fill"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      )}
                    </Form.Group>
                    <Form.Group className="mb-4 password-field-box">
                      <Form.Label>Confirm Your Password</Form.Label>
                      <Form.Control
                        type={showVerifyPassword ? "text" : "password"}
                        placeholder="Confirm Your Password"
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={(e) =>
                          handleChange("confirmPassword")(e.target.value ?? "")
                        }
                        value={values.confirmPassword}
                        isInvalid={
                          touched.confirmPassword && !!errors.confirmPassword
                        }
                      />
                      {touched.confirmPassword && errors.confirmPassword ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmPassword as string}
                        </Form.Control.Feedback>
                      ) : null}

                      {showVerifyPassword ? (
                        <i
                          className="bi bi-eye-fill"
                          onClick={() => {
                            setShowVerifyPassword(!showVerifyPassword);
                          }}
                        />
                      ) : (
                        <i
                          className="bi bi-eye-slash-fill"
                          onClick={() => {
                            setShowVerifyPassword(!showVerifyPassword);
                          }}
                        />
                      )}
                    </Form.Group>
                    <Form.Group className="mb-4 d-grid">
                      <Button type="submit" variant="primary">
                        Change Password
                      </Button>
                    </Form.Group>
                    <Form.Group className="text-center">
                      <p>
                        <Link to="/login">
                          <i className="bi bi-arrow-left"></i> Return to the
                          Sign in Screen
                        </Link>
                      </p>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            ) : (
              <div className="display-center">
                <h4>link expired</h4>
              </div>
            )}
          </Col>

          <Col
            style={{
              backgroundImage: `url(${loginimage})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
            item
            xs={12}
            md={6}
          >
            <div className="login-image-wrapper">
              <h4>Company</h4>
              <h1>Overview</h1>
              <p>
                TriNet Medical provides Healthcare IT solutions with
                quantifiable results in the areas of Data Management services,
                Technology Services and Barcode Integration with EMR.
              </p>
              <p>
                We deliver highly valuable and innovative solutions that bridge
                our client's execution gaps through a clear understanding of
                their business needs and a culture of shared commitments.
              </p>
              <div className="url-link-box">
                <Link
                  className="text-primary-700"
                  to="https://www.trinetmedical.com/"
                  target="_blank"
                >
                  www.trinetmedical.com
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal id="password-link-success-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Image src={passwordResetModalImage} alt="img" />
          <h5>Password Reset</h5>
          <p>
            Your Password has been successfully reset, <br />
            Click below to continue your access.
          </p>
          <Button
            type="button"
            variant="primary"
            onClick={() => navigate("/login")}
          >
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ResetPassword;
