import "./productmasterprofile.scss";
import ProductMasterProfileHeader from "../../../components/ProductMasterProfileHeader/ProductMasterProfileHeader";
import { useState } from "react";
import { toggleClass } from "../../../utils/commonUtils";
import {
  Product,
  selectedProduct,
  setSelectedProduct,
} from "../../../redux/product/productSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Loading from "../../../components/LoadingPage/Loading";
import { useNavigate } from "react-router-dom";
import useProducts from "../../../Hooks/useProduct";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { DuplicateModel } from "../ProductMasterListing/popup/duplicateModel";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
const ProductMasterProfile = () => {
  const selectedProductData = useAppSelector(selectedProduct);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeProductAPI, setProductStatusActiveLoading } = useProducts();
  const { deactiveProductAPI, setProductStatusDeActiveLoading } = useProducts();
  const { deleteProductAPI, deleteProductLoading } = useProducts();
  const [showModal, setShowModal] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const { duplicateProductAPI, duplicateProductLoading } = useProducts();
  const updateStatus = () => {
    if (selectedProductData.isActive) {
      deactiveProductAPI({
        id: selectedProductData.id,
        updatedBy: currentUserId ?? "",
      })
        .then((res: Product) => {
          if (res) {
            dispatch(setSelectedProduct(res));
          }
        })
        .catch();
    } else {
      activeProductAPI({
        id: selectedProductData.id,
        updatedBy: currentUserId ?? "",
      })
        .then((res: Product) => {
          if (res) {
            dispatch(setSelectedProduct(res));
          }
        })
        .catch();
    }
  };
  const deleteProduct = async () => {
    await deleteProductAPI({
      id: selectedProductData.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    navigate("/product-master");
  };

  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const toggleDuplicateModal = () => {
    toggleClass();
    setShowDuplicateModal(!showDuplicateModal);
  };
  const duplicateProduct = async (barcode: string) => {
    await duplicateProductAPI({
      barcode,
      id: selectedProductData.id,
      updatedBy: currentUserId ?? "",
    });
    toggleDuplicateModal();
    navigate("/product-master");
  };
  return (
    <div className="product-master-profile-page-wrapper">
      {(setProductStatusActiveLoading ||
        setProductStatusDeActiveLoading ||
        deleteProductLoading ||
        duplicateProductLoading) && <Loading />}
      <ProductMasterProfileHeader
        selectedProductData={selectedProductData}
        updateStatus={updateStatus}
        toggleModal={toggleModal}
        toggleDuplicateModal={toggleDuplicateModal}
      />
      <div className="profile-detail-box-wrapper">
        <div className="profile-detail-box">
          <div className="profile-info-box">
            <label>Product Code / NDC</label>
            <p>{selectedProductData.productCode}</p>
          </div>
          <div className="profile-info-box">
            <label>Barcode</label>
            <p>{selectedProductData.barcode}</p>
          </div>
          <div className="profile-info-box">
            <label>Product Name</label>
            <p>{selectedProductData.productName}</p>
          </div>
          <div className="profile-info-box">
            <label>Category / RXGroup</label>
            <p>{selectedProductData.category}</p>
          </div>
          <div className="profile-info-box">
            <label>Manufacturer</label>
            <p>{selectedProductData.manufacturer}</p>
          </div>
          <div className="profile-info-box">
            <label>Model / CVX</label>
            <p>{selectedProductData.model}</p>
          </div>
          <div className="profile-info-box">
            <label>IsMed</label>
            <p> {selectedProductData.isMedicine ? "Yes" : "No"}</p>
          </div>
        </div>
      </div>

      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteProduct}
        recordName={selectedProductData?.productName || ""}
        modelName={`product`}
      />
      <DuplicateModel
        showModal={showDuplicateModal}
        toggleDuplicateModal={toggleDuplicateModal}
        duplicateProduct={duplicateProduct}
        currentProduct={selectedProductData}
      />
    </div>
  );
};

export default ProductMasterProfile;
