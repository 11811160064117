import Form from "react-bootstrap/Form";
import "./athenasettings.scss";
import { Button } from "react-bootstrap";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import { useEffect, useState } from "react";
import Select from "react-select";
import useAthena from "../../../Hooks/useAthena";
import Loading from "../../../components/LoadingPage/Loading";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import {
  AthenaEnvironment,
  AthenaSettingsModel,
  AthenaTrnsSetIniVal,
  OrderingMode,
  OrderStatuse,
  OrderType,
} from "../../../redux/athena/athenaApiSlice";
import { DropDownValues } from "../../../utils/types";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";

const overrideStrings = {
  search: "Search",
  selectAll: "All",
  selectSomeItems: "All",
};

const AthenaSettings = () => {
  const currentUserId = useAppSelector(selectCurrentUserId);

  const { athenaSettingsAPI, athenaSettingsLoading } = useAthena();
  const { athenaSettingsEditAPI, athenaSettingsEditLoading } = useAthena();
  const [selectedOptions, setSelectedOptions] = useState<DropDownValues[]>([]);
  const [selectedFromLocOptions, setSelectedFromLocOptions] = useState<
    DropDownValues[]
  >([]);
  const [previewValue, setPreviewValue] = useState<string>();
  const [orderTypes, setOrderTypes] = useState<OrderType[]>([]);
  const [orderingModes, setOrderingModes] = useState<OrderingMode[]>([]);
  const [orderStatuses, setOrderStatuses] = useState<OrderStatuse[]>([]);

  const [deptOptions, setDeptOptions] = useState<DropDownValues[]>([]);
  const [selectedToLoc, setSelectedToLoc] = useState<DropDownValues | null>(
    null,
  );

  const [processUsingOptions, setProcessUsingOptions] = useState<
    DropDownValues[]
  >([]);
  const [athenaEnvironments, setAthenaEnvironments] = useState<any>([]);
  const [isShowPreviewError, setIsShowPreviewError] = useState(false);
  const [isShowPreviewMessage, setIsShowPreviewMessage] = useState(false);
  const [isShowFromLocationErrorMsg, setIsShowFromLocationErrorMsg] =
    useState(false);
  const [isShowToLocationErrorMsg, setIsShowToLocationErrorMsg] =
    useState(false);

  const initialVal: AthenaTrnsSetIniVal = {
    environment: "",
    athenaURL: "",
    athenaTokenURL: "",
    practiceId: 0,
    pageSize: 0,
    departmentIds: [],
    showLoadInRxMaster: true,
    administerStatuses: [],
    validOrderTypes: [
      {
        type: "",
        validOrderingModes: [],
        requestType: "",
        statuses: [],
      },
    ],
    obfuscateInformation: true,
    obfuscateRulesObject: {
      defaultDate: "2024-08-15T17:09:34.332Z",
      keepFirstNChars: 0,
    },
    validateLot: true,
    processUsing: "",
    locationMapSettings: [],
    leaveUnprocessed: true,
    postToLabResults: true,
    noOfAttempts: 0,
    deltaMinutes: 0,
  };

  const validationSchema = Yup.object().shape({
    pageSize: Yup.number()
      .required("Page size is required")
      .positive("Enter a positive number")
      .integer("Enter a positive number"),
    practiceId: Yup.number()
      .required("Practice ID is required")
      .positive("Enter a positive number")
      .integer("Enter a positive number"),
    deltaMinutes: Yup.number()
      .required("Time difference is required")
      .positive("Enter a positive number")
      .integer("Enter a positive number")
      .min(0, "Time difference cannot be negative"),
    noOfAttempts: Yup.number()
      .required("No. of attempts is required")
      .positive("Enter a positive number")
      .integer("Enter a positive number"),
  });
  const [dynamicForm, setDynamicForm] = useState(initialVal);
  useEffect(() => {
    athenaSettingsAPI().then((res: AthenaSettingsModel) => {
      if (res) {
        setAthenaEnvironments(res.athenaEnvironments ?? []);
        setProcessUsingOptions(
          res.processUsingOptions?.map((option: any) => {
            return {
              value: option.key,
              label: option.value,
            };
          }),
        );
        setDeptOptions(
          res.locations?.map((option: any) => {
            return {
              value: option.key,
              label: option.value,
            };
          }),
        );
        setOrderTypes(res.orderTypes);
        setOrderingModes(res.orderingModes);
        setOrderStatuses(res.orderStatuses);
        setDynamicForm(res.athenaHealthSetting);
      }
    });
  }, []);

  const {
    handleChange,
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    validateForm,
    setTouched,
  } = useFormik({
    initialValues: dynamicForm,
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        if (previewValue) {
          athenaSettingsEditAPI({ ...values, currentUserId });
          setIsShowPreviewError(false);
          setIsShowPreviewMessage(false);
        }
      } catch (err) {}
      setSubmitting(false);
    },
    validationSchema,
    enableReinitialize: true,
  });
  const setEnv = (Env: string) => {
    const envValues: AthenaEnvironment = athenaEnvironments.find(
      (x: AthenaEnvironment) => x.environment === Env,
    );
    setFieldValue("environment", Env);
    setFieldValue("athenaURL", envValues.athenaURL);
    setFieldValue("athenaTokenURL", envValues.athenaTokenURL);
  };
  const handleSelectAll = (items: any) => {
    if (values.departmentIds.length === deptOptions.length) {
      setSelectedOptions([]);
      setFieldValue("departmentIds", []);
    } else {
      setSelectedOptions(items);
      setFieldValue(
        "departmentIds",
        items.map((item: DropDownValues) => item.value),
      );
    }
  };
  const addLocationMapping = () => {
    if (!selectedFromLocOptions.length || !selectedToLoc?.value) {
      return;
    }
    const updatedLocationMapSettings = [
      ...values.locationMapSettings,
      {
        fromLocations: selectedFromLocOptions.map((x) => x.value),
        toLocationId: selectedToLoc?.value ?? "",
      },
    ];
    setFieldValue("locationMapSettings", updatedLocationMapSettings);
    setSelectedFromLocOptions([]);
    setSelectedToLoc(null);
  };
  const administerStatuses = (val: boolean, type: string) => {
    const administerStatuses = [...values.administerStatuses, type];
    if (val) {
      setFieldValue("administerStatuses", administerStatuses);
    } else {
      setFieldValue(
        "administerStatuses",
        administerStatuses.filter((x) => x !== type),
      );
    }
  };

  const setValidOrderTypeChecked = (checked: boolean, type: string) => {
    const updatedOrderTypes = !values.validOrderTypes.some(
      (orderType) => orderType.type === type,
    )
      ? checked
        ? [
            ...values.validOrderTypes,
            {
              type,
              validOrderingModes: [],
              requestType: type,
              statuses: [],
            },
          ]
        : values.validOrderTypes
      : checked
        ? values.validOrderTypes
        : values.validOrderTypes.filter((x) => x.type !== type);

    setFieldValue("validOrderTypes", updatedOrderTypes);
  };

  const setValidOrderTypeStatusChecked = (
    checked: boolean,
    type: string,
    status: string,
  ) => {
    // Create a new array with updated values
    const updatedOrderTypes = values.validOrderTypes.map((orderType) =>
      orderType.type === type
        ? {
            ...orderType,
            statuses: checked
              ? orderType.statuses.includes(status)
                ? orderType.statuses // Status already included, no change needed
                : [...orderType.statuses, status] // Add status
              : orderType.statuses.includes(status)
                ? orderType.statuses.filter((x) => x !== status) // Remove status
                : orderType.statuses, // Status not included, no change needed
          }
        : orderType,
    );

    // If no matching type was found, add a new one
    if (!values.validOrderTypes.some((orderType) => orderType.type === type)) {
      updatedOrderTypes.push({
        type,
        validOrderingModes: [], // Provide defaults or derive from elsewhere
        requestType: type,
        statuses: checked ? [status] : [], // Only add status if checked
      });
    }

    // Update the form values
    setFieldValue("validOrderTypes", updatedOrderTypes);
  };
  const setValidOrderTypeModesChecked = (
    checked: boolean,
    type: string,
    status: string,
  ) => {
    // Create a new array with updated values
    const updatedOrderTypes = values.validOrderTypes.map((orderType) =>
      orderType.type === type
        ? {
            ...orderType,
            validOrderingModes: checked
              ? orderType.validOrderingModes.includes(status)
                ? orderType.validOrderingModes // Status already included, no change needed
                : [...orderType.validOrderingModes, status] // Add status
              : orderType.validOrderingModes.includes(status)
                ? orderType.validOrderingModes.filter((x) => x !== status) // Remove status
                : orderType.validOrderingModes, // Status not included, no change needed
          }
        : orderType,
    );

    // If no matching type was found, add a new one
    if (!values.validOrderTypes.some((orderType) => orderType.type === type)) {
      updatedOrderTypes.push({
        type,
        validOrderingModes: checked ? [status] : [],
        requestType: type,
        statuses: [],
      });
    }

    // Update the form values
    setFieldValue("validOrderTypes", updatedOrderTypes);
  };
  const handleLocationsSelectAll = (items: any) => {
    if (selectedFromLocOptions.length === deptOptions.length) {
      setSelectedFromLocOptions([]);
    } else {
      setSelectedFromLocOptions(items);
    }
  };

  return (
    <div className="athena-setting-wrapper">
      {athenaSettingsLoading || athenaSettingsEditLoading ? <Loading /> : null}
      <div className="page-title">
        <h2>Athena Health Settings</h2>
      </div>
      <div className="athena-setting-box-container">
        <Form onSubmit={handleSubmit}>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>Environment</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Check
                inline
                label="Preview"
                name="env-setting"
                type="radio"
                checked={values.environment === "Preview"}
                onChange={() => setEnv("Preview")}
              />
              <Form.Check
                inline
                label="Prod"
                name="env-setting"
                type="radio"
                checked={values.environment === "Prod"}
                onChange={() => setEnv("Prod")}
              />
            </div>
          </div>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>Athena URL</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Control
                type="text"
                value={values.athenaURL}
                placeholder="Enter Athena URL"
                id="athenaURL"
                disabled={true}
              />
            </div>
          </div>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>Athena Token URL</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Control
                type="text"
                id="athenaTokenURL"
                value={values.athenaTokenURL}
                placeholder="Enter Athena Token URL"
                disabled={true}
              />
            </div>
          </div>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>Page Size</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Control
                type="number"
                value={values.pageSize}
                id="pageSize"
                placeholder="Enter Page Size"
                onChange={handleChange}
                isInvalid={touched.pageSize && !!errors.pageSize}
              />
              {touched.pageSize && errors.pageSize ? (
                <Form.Control.Feedback type="invalid">
                  {errors.pageSize}
                </Form.Control.Feedback>
              ) : null}
            </div>
          </div>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>Practice ID</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Control
                type="number"
                value={values.practiceId}
                id="practiceId"
                onChange={handleChange}
                placeholder="Enter Practice ID"
                isInvalid={touched.practiceId && !!errors.practiceId}
              />
              {touched.practiceId && errors.practiceId ? (
                <Form.Control.Feedback type="invalid">
                  {errors.practiceId}
                </Form.Control.Feedback>
              ) : null}
            </div>
          </div>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>Time Difference (Min)</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Control
                type="number"
                value={values.deltaMinutes}
                onChange={handleChange}
                id="deltaMinutes"
                placeholder="Enter Time Difference (Min)"
                isInvalid={touched.deltaMinutes && !!errors.deltaMinutes}
              />
              {touched.deltaMinutes && errors.deltaMinutes ? (
                <Form.Control.Feedback type="invalid">
                  {errors.deltaMinutes}
                </Form.Control.Feedback>
              ) : null}
            </div>
          </div>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>Validate Lot?</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Check
                inline
                name="validateLot"
                type="checkbox"
                checked={values.validateLot}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>Leave Unprocessed</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Check
                inline
                name="leaveUnprocessed"
                type="checkbox"
                checked={values.leaveUnprocessed}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>No. of Attempts</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Control
                type="number"
                value={values.noOfAttempts}
                id="noOfAttempts"
                onChange={handleChange}
                placeholder="Enter No. of Attempts"
                isInvalid={touched.noOfAttempts && !!errors.noOfAttempts}
              />
              {touched.noOfAttempts && errors.noOfAttempts ? (
                <Form.Control.Feedback type="invalid">
                  {errors.noOfAttempts}
                </Form.Control.Feedback>
              ) : null}
            </div>
          </div>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>Post to Lab Results</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Check
                inline
                name="postToLabResults"
                type="checkbox"
                checked={values.postToLabResults}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>Obfuscate Info?</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Check
                inline
                name="obfuscateInformation"
                type="checkbox"
                checked={values.obfuscateInformation}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="athena-setting-box has-sub-settings">
            <div className="athena-setting-title">
              <Form.Label>Departments</Form.Label>
            </div>
            <div className="athena-setting-content">
              <MultiSelect
                overrideStrings={overrideStrings}
                options={deptOptions}
                onChange={(option: any) => {
                  handleSelectAll(option);
                }}
                value={selectedOptions}
                labelledBy="Select"
                classNamePrefix="react-select"
                className="react-select-multiselect react-select-container"
                key="departmentIds"
                isClearable={false}
                isSelectAll={true}
                menuPlacement={"bottom"}
              />
              <div
                className="sub-setting-box-container"
                style={{ marginTop: "20px" }}
              >
                <div className="sub-setting-box">
                  <Form.Label>From Location</Form.Label>
                  <MultiSelect
                    overrideStrings={overrideStrings}
                    options={deptOptions}
                    onChange={(option: any) => {
                      setIsShowFromLocationErrorMsg(false);
                      handleLocationsSelectAll(option);
                    }}
                    value={selectedFromLocOptions}
                    labelledBy="Select"
                    classNamePrefix="react-select"
                    className={`react-select-multiselect react-select-container ${isShowFromLocationErrorMsg ? "red-border" : ""}`}
                    isClearable={false}
                    isSelectAll={true}
                    menuPlacement={"bottom"}
                  />
                  {isShowFromLocationErrorMsg && (
                    <span className="invalid-feedback">
                      From location field is required
                    </span>
                  )}
                </div>
                <div className="sub-setting-box">
                  <Form.Label>To Location</Form.Label>
                  <Select
                    classNamePrefix="react-select"
                    options={deptOptions}
                    value={selectedToLoc}
                    onChange={(e) => {
                      setIsShowToLocationErrorMsg(false);
                      setSelectedToLoc(e);
                    }}
                    className={`react-select-container ${isShowToLocationErrorMsg ? "red-border" : ""}`}
                  />
                  {isShowToLocationErrorMsg && (
                    <span className="invalid-feedback">
                      To location field is required
                    </span>
                  )}
                </div>
                <div style={{ minWidth: "100%" }}>
                  <Button
                    type="button"
                    onClick={() => {
                      if (!selectedFromLocOptions.length) {
                        setIsShowFromLocationErrorMsg(true);
                      }
                      if (!selectedToLoc?.value) {
                        setIsShowToLocationErrorMsg(true);
                      }
                      addLocationMapping();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>Location Mapping</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Control
                disabled={true}
                as="textarea"
                value={JSON.stringify(values.locationMapSettings)}
                rows={3}
              />
            </div>
          </div>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>Show Load in RX Master?</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Check
                inline
                name="showLoadInRxMaster"
                type="checkbox"
                checked={values.showLoadInRxMaster}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>Administer Statuses</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Check
                label="True"
                inline
                name="admin-status-true"
                type="checkbox"
                checked={values.administerStatuses.includes("true")}
                onChange={(e) => administerStatuses(e.target.checked, "true")}
              />
              <Form.Check
                label="False"
                inline
                name="admin-status-false"
                type="checkbox"
                checked={values.administerStatuses.includes("false")}
                onChange={(e) => administerStatuses(e.target.checked, "false")}
              />
              <Form.Check
                label="Blank"
                inline
                name="admin-status-blank"
                type="checkbox"
                checked={values.administerStatuses.includes("")}
                onChange={(e) => administerStatuses(e.target.checked, "")}
              />
            </div>
          </div>
          <div className="athena-setting-box has-sub-settings">
            <div className="athena-setting-title">
              <Form.Label>Process Using</Form.Label>
            </div>
            <div className="athena-setting-content">
              <div className="main-setting-box">
                <Select
                  classNamePrefix="react-select"
                  options={processUsingOptions}
                  id="processUsing"
                  name="processUsing"
                  value={processUsingOptions?.find(
                    (option: DropDownValues) =>
                      option.value === values.processUsing,
                  )}
                  onChange={(option: any) => {
                    handleChange("processUsing")(option.value);
                  }}
                  className="react-select-container"
                />
              </div>
            </div>
          </div>
          <div className="athena-setting-box has-sub-settings">
            <div className="athena-setting-title">
              <Form.Label>Order Types</Form.Label>
            </div>
            <div className="athena-setting-content">
              {orderTypes.map((type) => (
                <>
                  <div className="sub-setting-box-container">
                    <div className="main-setting-box">
                      <Form.Check
                        label={type.value}
                        inline
                        name={type.value}
                        type="checkbox"
                        checked={
                          !!values.validOrderTypes.find(
                            (x) => x.type === type.key,
                          )
                        }
                        onChange={(e) =>
                          setValidOrderTypeChecked(e.target.checked, type.key)
                        }
                      />
                    </div>
                    <div className="sub-setting-box">
                      <Form.Label>Statuses</Form.Label>
                      <div>
                        {orderingModes.map((mode) => (
                          <Form.Check
                            label={mode.value}
                            inline
                            name={mode.value}
                            type="checkbox"
                            checked={values.validOrderTypes
                              .find((x) => x.type === type.key)
                              ?.statuses.includes(mode.key)}
                            onChange={(e) =>
                              setValidOrderTypeStatusChecked(
                                e.target.checked,
                                type.key,
                                mode.key,
                              )
                            }
                          />
                        ))}
                      </div>
                    </div>
                    <div className="sub-setting-box">
                      <Form.Label>Ordering Modes</Form.Label>
                      <div>
                        {orderStatuses.map((status) => (
                          <Form.Check
                            label={status.value}
                            inline
                            name={status.value}
                            type="checkbox"
                            checked={values.validOrderTypes
                              .find((x) => x.type === type.key)
                              ?.validOrderingModes.includes(status.key)}
                            onChange={(e) =>
                              setValidOrderTypeModesChecked(
                                e.target.checked,
                                type.key,
                                status.key,
                              )
                            }
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="athena-setting-box">
            <div className="athena-setting-title">
              <Form.Label>Preview</Form.Label>
            </div>
            <div className="athena-setting-content">
              <Form.Control
                as="textarea"
                rows={6}
                disabled
                value={previewValue}
                isInvalid={!previewValue && isShowPreviewError}
              />
            </div>

            <div></div>
            {isShowPreviewMessage && previewValue && (
              <div className="info-msg">
                <span>
                  Data ready for preview. Click on Save to persist the data.{" "}
                </span>
              </div>
            )}

            {isShowPreviewError && (
              <div className="error-msg">Preview is required</div>
            )}
          </div>
          <div className="mb-2 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={async () => {
                const errors = await validateForm();
                setTouched({
                  pageSize: true,
                  practiceId: true,
                  deltaMinutes: true,
                  noOfAttempts: true,
                });
                if (!Object.keys(errors).length)
                  setPreviewValue(JSON.stringify(values));
                setIsShowPreviewError(false);
                setIsShowPreviewMessage(true);
              }}
            >
              Preview
            </Button>
            <Button
              type="submit"
              onClick={(e) => {
                setIsShowPreviewError(true);
                e.preventDefault();
                handleSubmit();
              }}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AthenaSettings;
