import { getErrorMessage } from "../utils/commonUtils";
import {
  useCreateProductMutation,
  useDeleteProductMutation,
  useDuplicateProductMutation,
  useEditProductMutation,
  useExportProductMasterListMutation,
  useGetProductsMutation,
  useImportProductMasterExcelFileMutation,
  useSetProductStatusActiveMutation,
  useSetProductStatusDeActiveMutation,
} from "../redux/product/productApiSlice";
import { toast } from "react-toastify";

export default function useProduct() {
  const [getProduct, { isLoading: getProductLoading }] =
    useGetProductsMutation();
  const [createProduct, { isLoading: createProductLoading }] =
    useCreateProductMutation();
  const [editProduct, { isLoading: editProductLoading }] =
    useEditProductMutation();
  const [deleteProduct, { isLoading: deleteProductLoading }] =
    useDeleteProductMutation();
  const [setProductStatusActive, { isLoading: setProductStatusActiveLoading }] =
    useSetProductStatusActiveMutation();
  const [
    setProductStatusDeActive,
    { isLoading: setProductStatusDeActiveLoading },
  ] = useSetProductStatusDeActiveMutation();
  const [duplicateProduct, { isLoading: duplicateProductLoading }] =
    useDuplicateProductMutation();

  const [exportProductMasterList] = useExportProductMasterListMutation();
  const [importProductMasterExcelFile] =
    useImportProductMasterExcelFileMutation();

  const getProductAPI = async (data: any) => {
    try {
      const response: any = await getProduct(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const createProductAPI = async (data: any) => {
    try {
      const response: any = await createProduct(data).unwrap();
      toast.success("Success! Product has been Created.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const editProductAPI = async (data: any) => {
    try {
      const response: any = await editProduct(data).unwrap();
      toast.success("Success! Product has been Updated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const activeProductAPI = async (data: any) => {
    try {
      const response: any = await setProductStatusActive(data).unwrap();
      toast.success("Success! Product has been Activated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deactiveProductAPI = async (data: any) => {
    try {
      const response: any = await setProductStatusDeActive(data).unwrap();
      toast.success("Success! Product has been Deactivated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deleteProductAPI = async (data: any) => {
    try {
      const response: any = await deleteProduct(data).unwrap();
      toast.success("Success! Product has been Deleted.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const duplicateProductAPI = async (data: any) => {
    try {
      const response: any = await duplicateProduct(data).unwrap();
      toast.success("Success! Product has been Duplicated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const onExportProductMasterList = async () => {
    try {
      const response: any = await exportProductMasterList({});
      toast.success("Success!. File downloaded successfully");
      return response;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const onImportProductMasterFile = async (file: any) => {
    try {
      const response: any = await importProductMasterExcelFile(file);
      if (!response.error) {
        toast.success("Success!. File imported successfully");
        return response;
      }
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    getProductAPI,
    getProductLoading,
    createProductLoading,
    createProductAPI,
    editProductAPI,
    editProductLoading,
    deleteProductAPI,
    deleteProductLoading,
    activeProductAPI,
    setProductStatusActiveLoading,
    deactiveProductAPI,
    setProductStatusDeActiveLoading,
    duplicateProductLoading,
    duplicateProductAPI,
    onExportProductMasterList,
    onImportProductMasterFile,
  };
}
