import { getErrorMessage } from "../utils/commonUtils";
import {
  useCreateOPMutation,
  useDeleteOPMutation,
  useDuplicateOPMutation,
  useEditOPMutation,
  useGetOPsMutation,
  useSetOPStatusActiveMutation,
  useSetOPStatusDeActiveMutation,
} from "../redux/orderingProduct/OPApiSlice";
import { toast } from "react-toastify";

export default function useOP() {
  const [getOP, { isLoading: getOPLoading }] = useGetOPsMutation();
  const [createOP, { isLoading: createOPLoading }] = useCreateOPMutation();
  const [editOP, { isLoading: editOPLoading }] = useEditOPMutation();
  const [deleteOP, { isLoading: deleteOPLoading }] = useDeleteOPMutation();
  const [setOPStatusActive, { isLoading: setOPStatusActiveLoading }] =
    useSetOPStatusActiveMutation();
  const [setOPStatusDeActive, { isLoading: setOPStatusDeActiveLoading }] =
    useSetOPStatusDeActiveMutation();
  const [duplicateOP, { isLoading: duplicateOPLoading }] =
    useDuplicateOPMutation();
  const getOPAPI = async (data: any) => {
    try {
      const response: any = await getOP(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const createOPAPI = async (data: any) => {
    try {
      const response: any = await createOP(data).unwrap();
      toast.success("Success! Ordering Product has been Created.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const editOPAPI = async (data: any) => {
    try {
      const response: any = await editOP(data).unwrap();
      toast.success("Success! Ordering Product has been Updated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const activeOPAPI = async (data: any) => {
    try {
      const response: any = await setOPStatusActive(data).unwrap();
      toast.success("Success! Ordering Product has been Activated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deactiveOPAPI = async (data: any) => {
    try {
      const response: any = await setOPStatusDeActive(data).unwrap();
      toast.success("Success! Ordering Product has been Deactivated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deleteOPAPI = async (data: any) => {
    try {
      const response: any = await deleteOP(data).unwrap();
      toast.success("Success! Ordering Product has been Deleted.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const duplicateOPAPI = async (data: any) => {
    try {
      const response: any = await duplicateOP(data).unwrap();
      toast.success("Success! Ordering Product has been Duplicated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    getOPAPI,
    getOPLoading,
    createOPLoading,
    createOPAPI,
    editOPAPI,
    editOPLoading,
    deleteOPAPI,
    deleteOPLoading,
    activeOPAPI,
    setOPStatusActiveLoading,
    deactiveOPAPI,
    setOPStatusDeActiveLoading,
    duplicateOPLoading,
    duplicateOPAPI,
  };
}
