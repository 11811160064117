import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface LocationData {
  selectedLocation: any;
}
const storedLocation = localStorage.getItem("selectedLocation");
const initialState: LocationData = {
  selectedLocation: storedLocation ? JSON.parse(storedLocation) : null,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setSelectedLocation: (state, action: PayloadAction<any>) => {
      state.selectedLocation = action.payload;
      localStorage.setItem("selectedLocation", JSON.stringify(action.payload));
    },
  },
});
export const { setSelectedLocation } = locationSlice.actions;
export default locationSlice.reducer;
export const selectedLocation = (state: RootState) =>
  state.location.selectedLocation;
