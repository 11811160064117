import "./athenatransactionprofile.scss";
import AthenaProfileHeader from "../../../components/AthenaProfileHeader/AthenaProfileHeader";
import { useEffect, useState } from "react";
import { toggleClass } from "../../../utils/commonUtils";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import useAthena from "../../../Hooks/useAthena";
import Loading from "../../../components/LoadingPage/Loading";
import { AthenaList } from "../../../redux/athena/athenaApiSlice";
import useLocations from "../../../Hooks/useLocations";
import { LocationList } from "../../../redux/location/locationApiSlice";

const AthenaTransactionProfile = () => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [athenaDetails, setAthenaDetails] = useState<AthenaList>();
  const { getAthenaByIdAPI, getAthenaByIdLoading } = useAthena();
  const [locationOptions, setLocationOptions] = useState<LocationList[]>([]);
  const { getLocationsAPI, getLocationsLoading } = useLocations();
  const { markAthenaOrderunProcessedAPI, markAthenaOrderunProcessedLoading } =
    useAthena();
  const { discardAthenaOrderAPI, discardAthenaOrderLoading } = useAthena();
  const { deleteAthenaOrderAPI, deleteAthenaOrderLoading } = useAthena();
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  useEffect(() => {
    getLocationsAPI({ searchText: "", includeDeleted: false })
      .then((res: any) => setLocationOptions(res))
      .catch();

    getAthenaByIdAPI(id ?? "").then((res: any) => {
      if (res) {
        setAthenaDetails(res);
      }
    });
  }, []);
  const MAOP = () => {
    markAthenaOrderunProcessedAPI(athenaDetails?.id || "").then();
  };
  const disAthOrd = () => {
    discardAthenaOrderAPI(athenaDetails?.id || "").then();
  };
  const delAth = () => {
    deleteAthenaOrderAPI(athenaDetails?.id || "").then((res) => toggleModal());
  };
  return (
    <div className="usage-trans-page-wrapper">
      {getAthenaByIdLoading ||
      discardAthenaOrderLoading ||
      deleteAthenaOrderLoading ||
      markAthenaOrderunProcessedLoading ||
      getLocationsLoading ? (
        <Loading />
      ) : null}
      <AthenaProfileHeader
        title={`
          ${athenaDetails?.athenaPatient?.fName || ""} ${athenaDetails?.athenaPatient?.lName || ""}`}
        id={id}
        srxMessage={athenaDetails?.srxMessage}
        markAthenaOrderunProcessedAPI={MAOP}
        discardAthenaOrderAPI={disAthOrd}
        deleteAthenaOrderAPI={delAth}
        isEdit={false}
        recordName={athenaDetails?.athenaId || ""}
        toggleModal={toggleModal}
        showModal={showModal}
        srxStatus={athenaDetails?.srxStatus}
      />
      <div className="profile-detail-box-wrapper">
        <Form>
          <div className="profile-detail-box">
            <h5 className="profile-info-box profile-section-title full-width">
              Overview
            </h5>
            <div className="profile-info-box">
              <label>Manufacturer</label>
              <p className="disabled-record">
                {athenaDetails?.manufacturer || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>NDC</label>
              <p className="disabled-record">{athenaDetails?.ndc || "-"}</p>
            </div>
            <div className="profile-info-box">
              <label>Lot Number</label>
              <p className="disabled-record">
                {athenaDetails?.lotNumber || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Expiry Date</label>
              <p className="disabled-record">{athenaDetails?.expDate || "-"}</p>
            </div>
            <div className="profile-info-box">
              <label>Internal Note / Barcode</label>
              <p className="disabled-record">
                {athenaDetails?.internalNote || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Department/Location</label>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <p style={{ width: "100px" }} className="disabled-record">
                  {athenaDetails?.departmentId || ""}
                </p>
                <span style={{ fontSize: "14px", paddingTop: "8px" }}>
                  {locationOptions?.find(
                    (location: LocationList) =>
                      athenaDetails?.departmentId === location.locationCode,
                  )?.locationName ?? ""}
                </span>
              </div>
            </div>
            <div className="profile-info-box">
              <label>Quantity</label>
              <p className="disabled-record">
                {athenaDetails?.quantity || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>UOM</label>
              <p className="disabled-record">{athenaDetails?.uom || "-"}</p>
            </div>
            <h5 className="profile-info-box profile-section-title full-width">
              Athena Order Details
            </h5>
            <div className="profile-info-box">
              <label>Athena ID</label>
              <p className="disabled-record">
                {athenaDetails?.athenaId || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Encounter ID</label>
              <p className="disabled-record">
                {athenaDetails?.encounterId || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Patient ID</label>
              <p className="disabled-record">
                {athenaDetails?.athenaPatient?.patientID || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Patient Name</label>
              <p className="disabled-record">
                {athenaDetails?.athenaPatient?.fName +
                  " " +
                  athenaDetails?.athenaPatient?.lName || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Provider ID</label>
              <p className="disabled-record">
                {athenaDetails?.providerId || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Status</label>
              <p className="disabled-record">{athenaDetails?.status || "-"}</p>
            </div>
            <div className="profile-info-box">
              <label>Order Type</label>
              <p className="disabled-record">
                {athenaDetails?.orderType || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Document Class</label>
              <p className="disabled-record">
                {athenaDetails?.documentClass || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Version #</label>
              <p className="disabled-record">
                {athenaDetails?.versionNo || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Refills Allowed</label>
              <p className="disabled-record">
                {athenaDetails?.refillsAllowed || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Dosage Duration</label>
              <p className="disabled-record">
                {athenaDetails?.dosageDuration || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Action Note</label>
              <p className="disabled-record">
                {athenaDetails?.actionNote || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Dosage Frequency Unit</label>
              <p className="disabled-record">
                {athenaDetails?.dosageFrequencyUnit || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Dosage Duration Unit</label>
              <p className="disabled-record">
                {athenaDetails?.dosageDurationUnit || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Dosage Quantity</label>
              <p className="disabled-record">
                {athenaDetails?.dosageQuantityValue || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Signature</label>
              <p className="disabled-record">{athenaDetails?.sig || "-"}</p>
            </div>
            <div className="profile-info-box">
              <label>SRX Status</label>
              <p className="disabled-record">
                {athenaDetails?.srxStatus || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Ordering Mode</label>
              <p className="disabled-record">
                {athenaDetails?.orderingMode || "-"}
              </p>
            </div>
            <div className="profile-info-box">
              <label>Administered By (Username)</label>
              <p className="disabled-record">
                {athenaDetails?.administeredBy || "-"}
              </p>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AthenaTransactionProfile;
