import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./itemtypelisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import deactivateicon from "../../../assets/images/deactivate-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import duplicateicon from "../../../assets/images/duplicate-icon.svg";
import ListingTitle from "../../../components/listingTitle/ListingTitle";
import { ReactSVG } from "react-svg";
import useItemTypes from "../../../Hooks/useItemTypes";
import Loading from "../../../components/LoadingPage/Loading";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { setSelectedItemType } from "../../../redux/itemType/itemTypeSlice";
import { toggleClass } from "../../../utils/commonUtils";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import ItemListingTitle from "../../../components/itemListingTitle/itemListingTitle";
const ItemTypeListing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { getItemTypesAPI, getItemTypesLoading } = useItemTypes();
  const { activeItemTypeAPI, setItemStatusActiveLoading } = useItemTypes();
  const { deActiveItemTypeAPI, setItemStatusDeActiveLoading } = useItemTypes();

  const { deleteTypeAPI, deleteTypeLoading } = useItemTypes();
  const { duplicateTypeAPI, duplicateTypeLoading } = useItemTypes();
  const [typesList, setTypesList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentType, setCurrentType] = useState<any>();
  const defaultFilter = { searchText: "", active: true };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const deleteType = async () => {
    await deleteTypeAPI({
      id: currentType.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    getTypes();
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const getTypes = () => {
    getItemTypesAPI(searchFieldVal).then((res: any) => setTypesList(res));
  };
  const editType = (type: any) => {
    dispatch(setSelectedItemType(type));
    navigate("/edit-item-master");
  };
  const duplicateType = async (type: any) => {
    await duplicateTypeAPI({
      id: type.id,
      updatedBy: currentUserId ?? "",
    });
    getTypes();
  };
  const navigateToView = (type: any) => {
    dispatch(setSelectedItemType(type));
    navigate("/view-item-master");
  };
  const updateStatus = async (type: any) => {
    if (type.isActive) {
      await deActiveItemTypeAPI({
        id: type.id,
        updatedBy: currentUserId ?? "",
      });
    } else {
      await activeItemTypeAPI({ id: type.id, updatedBy: currentUserId ?? "" });
    }
    getTypes();
  };
  useEffect(() => {
    getTypes();
  }, [searchFieldVal]);

  return (
    <div className="item-type-listing-wrapper">
      {getItemTypesLoading ||
      setItemStatusActiveLoading ||
      setItemStatusDeActiveLoading ||
      deleteTypeLoading ||
      duplicateTypeLoading ? (
        <Loading />
      ) : null}
      <ItemListingTitle
        title="Item Type Master"
        buttonTitle="Add New"
        href="/add-item-master"
        disRecords={typesList.length || 0}
        totalRecords={typesList.length || 0}
        searchVal={searchFieldVal}
        setSearchVal={setSearchFieldVal}
        defaultFilter={defaultFilter}
        isAddPermitted={true}
      />
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>RX Type Id</th>
              <th>RX Type Name</th>
              <th>Vaccine</th>
              <th>Sort Order</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {typesList?.map((x: any) => (
              <tr
                key={x.id}
                onDoubleClick={() => {
                  navigateToView(x);
                }}
              >
                <td>{x.rxTypeCode}</td>
                <td>{x.rxTypeName}</td>
                <td>{x.isVaccine ? "Yes" : "No"}</td>
                <td>{x.sortOrder}</td>
                <td>
                  <span
                    className={`status-circle ${x.isActive ? "active" : "inactive"}`}
                  >
                    {x.isActive ? "Active" : "InActive"}
                  </span>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigateToView(x)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={viewicon}
                        />
                        View
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          updateStatus(x);
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deactivateicon}
                        />
                        {x.isActive ? "Deactivate" : "Activate"}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => editType(x)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentType(x);
                          toggleModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => duplicateType(x)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={duplicateicon}
                        />
                        Duplicate
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteType}
        recordName={currentType?.rxTypeName || ""}
        modelName={"type"}
      />
    </div>
  );
};

export default ItemTypeListing;
