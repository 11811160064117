import "./userprofile.scss";
import UserProfileHeader from "../../../components/userprofileheader/UserProfileHeader";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectedUser, setSelectedUser } from "../../../redux/user/userSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import useUser from "../../../Hooks/useUser";
import Loading from "../../../components/LoadingPage/Loading";
const UserProfile = () => {
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { setUserActive, setUserStatusActiveLoading } = useUser();
  const { setUserInActive, setUserStatusDeActiveLoading } = useUser();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedUserData = useAppSelector(selectedUser);
  useEffect(() => {
    if (!selectedUserData) {
      navigate("/users");
    }
    setLoading(false);
  }, [loading, selectedUserData]);

  const updateStatus = async () => {
    try {
      if (selectedUserData.isActive) {
        await setUserInActive({
          id: selectedUserData.id,
          updatedBy: currentUserId || "",
        });
      } else {
        await setUserActive({
          id: selectedUserData.id,
          updatedBy: currentUserId || "",
        });
      }
      dispatch(
        setSelectedUser({
          ...selectedUserData,
          isActive: !selectedUserData.isActive,
        }),
      );
    } catch (err) {}
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="profile-page-wrapper">
          {setUserStatusActiveLoading ||
            (setUserStatusDeActiveLoading && <Loading />)}
          <UserProfileHeader
            selectedUserData={selectedUserData}
            updateStatus={updateStatus}
            currentUserId={currentUserId}
          />
          <div className="profile-detail-box-wrapper">
            <div className="profile-detail-box">
              <div className="profile-info-box">
                <label>SRX ID</label>
                <p>{selectedUserData?.id || "-"}</p>
              </div>
              <div className="profile-info-box">
                <label>User Name</label>
                <p>{selectedUserData.userName || "-"}</p>
              </div>
              <div className="profile-info-box">
                <label>First Name</label>
                <p>{selectedUserData.fName || "-"}</p>
              </div>
              <div className="profile-info-box">
                <label>Middle Initials</label>
                <p>{selectedUserData.mi || "-"}</p>
              </div>
              <div className="profile-info-box">
                <label>Last Name</label>
                <p>{selectedUserData.lName || "-"}</p>
              </div>
              <div className="profile-info-box">
                <label>Credentials</label>
                <p>{selectedUserData.title || "-"}</p>
              </div>
              <div className="profile-info-box">
                <label>Clinical Role</label>
                <p>{selectedUserData.clinicalRole.roleName || "-"}</p>
              </div>
              <div className="profile-info-box">
                <label>Access Type</label>
                <p>{selectedUserData.role.roleName || "-"}</p>
              </div>

              <div className="profile-info-box">
                <label>Email</label>
                <p>{selectedUserData.email || "-"}</p>
              </div>

              <div className="profile-info-box">
                <label>NPI</label>
                <p>{selectedUserData.deaNumber || "-"}</p>
              </div>
              <div className="profile-info-box">
                <label>Provider Activity</label>
                <p>NA</p>
              </div>
              <div className="profile-info-box">
                <label>User Activity</label>
                <p>NA</p>
              </div>
              <div className="profile-info-box full-width">
                <label>Notes</label>
                <p>{selectedUserData.notes || "-"}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
