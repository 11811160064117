import { getErrorMessage } from "../utils/commonUtils";
import {
  useGetRecordUsageBarcodeDetailsMutation,
  useGetDDvaluesMutation,
  useGetLogListMutation,
  useLogDDCalMutation,
} from "../redux/recordUsage/recordUsageApiSlice";
import { toast } from "react-toastify";

export default function useRecordUsage() {
  const [getDDvalues, { isLoading: getDDvaluesLoading }] =
    useGetDDvaluesMutation();
  const [getBarcodeDetails, { isLoading: getBarcodeDetailsLoading }] =
    useGetRecordUsageBarcodeDetailsMutation();
  const [logDDCal, { isLoading: logDDCalLoading }] = useLogDDCalMutation();

  const [getLogList, { isLoading: getLogListLoading }] =
    useGetLogListMutation();
  const getDDvaluesAPI = async (data: any) => {
    try {
      const response: any = await getDDvalues(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getBarcodeDetailsAPI = async (data: any) => {
    try {
      const response: any = await getBarcodeDetails(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getLogListAPI = async (data: any) => {
    try {
      const response: any = await getLogList(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const logDDCalAPI = async () => {
    try {
      const response: any = await logDDCal({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    getDDvaluesAPI,
    getDDvaluesLoading,
    getBarcodeDetailsAPI,
    getBarcodeDetailsLoading,
    logDDCalAPI,
    logDDCalLoading,
    getLogListAPI,
    getLogListLoading,
  };
}
