import "./vendorprofile.scss";
import VendorProfileHeader from "../../../components/vendorprofileheader/VendorProfileHeader";
import {
  Vendor,
  selectedVendor,
  setSelectedVendor,
} from "../../../redux/vendor/vendorSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Loading from "../../../components/LoadingPage/Loading";
import { useNavigate } from "react-router-dom";
import useVendors from "../../../Hooks/useVendors";
import { useState } from "react";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { toggleClass } from "../../../utils/commonUtils";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
const VendorProfile = () => {
  const selectedVendorData = useAppSelector(selectedVendor);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeVendorAPI, setVendorStatusActiveLoading } = useVendors();
  const { deactiveVendorAPI, setVendorStatusDeActiveLoading } = useVendors();
  const { deleteVendorAPI, deleteVendorLoading } = useVendors();
  const [showModal, setShowModal] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const { duplicateVendorAPI, duplicateVendorLoading } = useVendors();
  const updateStatus = () => {
    if (selectedVendorData.isActive) {
      deactiveVendorAPI({
        id: selectedVendorData.id,
        updatedBy: currentUserId ?? "",
      })
        .then((res: Vendor) => {
          if (res) {
            dispatch(setSelectedVendor(res));
          }
        })
        .catch();
    } else {
      activeVendorAPI({
        id: selectedVendorData.id,
        updatedBy: currentUserId ?? "",
      })
        .then((res: Vendor) => {
          if (res) {
            dispatch(setSelectedVendor(res));
          }
        })
        .catch();
    }
  };
  const deleteVendor = async () => {
    await deleteVendorAPI({
      id: selectedVendorData.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    navigate("/vendor-master");
  };

  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const toggleDuplicateModal = () => {
    toggleClass();
    setShowDuplicateModal(!showDuplicateModal);
  };
  const duplicateVendor = async () => {
    await duplicateVendorAPI({
      id: selectedVendorData.id,
      updatedBy: currentUserId ?? "",
    });
    toggleDuplicateModal();
    navigate("/vendor-master");
  };
  return (
    <div className="profile-page-wrapper">
      {(setVendorStatusActiveLoading ||
        setVendorStatusDeActiveLoading ||
        deleteVendorLoading ||
        duplicateVendorLoading) && <Loading />}
      <VendorProfileHeader
        selectedVendorData={selectedVendorData}
        updateStatus={updateStatus}
        toggleModal={toggleModal}
        duplicateVendor={duplicateVendor}
      />
      <div className="profile-detail-box-wrapper">
        <div className="profile-detail-box">
          <div className="profile-info-box">
            <label>Name</label>
            <p>{selectedVendorData.name}</p>
          </div>
          <div className="profile-info-box">
            <label>Address 1</label>
            <p>{selectedVendorData.address || "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Address 2</label>
            <p>{selectedVendorData.address2 || "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>City</label>
            <p>{selectedVendorData.city || "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>State</label>
            <p>{selectedVendorData.state || "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Zip</label>
            <p>{selectedVendorData.zipCode || "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Email ID</label>
            <p>{selectedVendorData.email || "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Phone</label>
            <p>{selectedVendorData.phone || "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Contact Person</label>
            <p>{selectedVendorData.contactPerson || "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Status</label>
            <p> {selectedVendorData.isActive ? "Active" : "InActive"}</p>
          </div>
        </div>
      </div>

      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteVendor}
        recordName={selectedVendorData?.name || ""}
        modelName={`vendor`}
      />
    </div>
  );
};

export default VendorProfile;
