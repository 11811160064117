import { Button } from "react-bootstrap";
import "./usagetransactionprofile.scss";
import { useEffect, useState } from "react";
import { toggleClass } from "../../../utils/commonUtils";
import { useNavigate, useParams } from "react-router-dom";
import useUsageTransactions from "../../../Hooks/useUsageTransactions";
import { UsageTransList } from "../../../redux/usageTransactions/usageTransactionsApiSlice";
import moment from "moment";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import Loading from "../../../components/LoadingPage/Loading";
import { DeleteConfModel } from "../popUp/deleteConfModel";
const UsageTransactionProfile = () => {
  const {
    getUsageTransByIdAPI,
    getUsageTransByIdLoading,
    usageTransDeleteAPI,
    usageTransDeleteLoading,
  } = useUsageTransactions();
  const { id } = useParams();
  const navigate = useNavigate();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [showModal, setShowModal] = useState(false);
  const [details, setDetails] = useState<UsageTransList>();
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  useEffect(() => {
    getUsageTransByIdAPI(id ?? "").then((res: UsageTransList) => {
      if (res) {
        setDetails(res);
      }
    });
  }, []);
  const deleteRecord = () => {
    usageTransDeleteAPI({ id: details?.id, deletedBy: currentUserId }).then(
      (res) => {
        toggleModal();
        navigate("/usage-transactions");
      },
    );
  };
  return (
    <div className="usage-trans-page-wrapper">
      {getUsageTransByIdLoading || usageTransDeleteLoading ? <Loading /> : null}
      <div className="usage-trans-profile-header">
        <h2>
          {details?.patient.firstName} {details?.patient.lastName}
        </h2>
        <Button
          variant="primary"
          onClick={toggleModal}
          disabled={details?.deleted}
        >
          Delete
        </Button>
      </div>
      <div className="profile-detail-box-wrapper">
        <div className="profile-detail-box">
          <div className="profile-info-box">
            <label>Shot Date</label>
            <p>{moment(details?.shotDate).format("MM/DD/YYYY HH:mm:ss")}</p>
          </div>
          <div className="profile-info-box">
            <label>Patient ID</label>
            <p>{details?.patientId}</p>
          </div>
          <div className="profile-info-box">
            <label>Patient Name</label>
            <p>
              {details?.patient.firstName} {details?.patient.lastName}
            </p>
          </div>
          <div className="profile-info-box">
            <label>Gender</label>
            <p>{details?.patient.gender ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>DOB</label>
            <p>
              {details?.patient?.dob
                ? moment(details.patient.dob).format("L")
                : "-"}
            </p>
          </div>
          <div className="profile-info-box">
            <label>HCP Name</label>
            <p>NA</p>
          </div>
          <div className="profile-info-box">
            <label>User Name</label>
            <p>{details?.provider.userName}</p>
          </div>
          <div className="profile-info-box">
            <label>Location</label>
            <p>{details?.location.locationName}</p>
          </div>
          <div className="profile-info-box">
            <label>Quantity</label>
            <p>{details?.dose} Tablet(s)</p>
          </div>
          <div className="profile-info-box">
            <label>EMR Quantity</label>
            <p>1|Tablet(s) 1|Tablet(s)</p>
          </div>
          <div className="profile-info-box">
            <label>Barcode</label>
            <p>RX2020092211472871</p>
          </div>
          <div className="profile-info-box">
            <label>Item Name</label>
            <p>{details?.item.rxName}</p>
          </div>
          <div className="profile-info-box">
            <label>Item Group</label>
            <p>{details?.item.drugName}</p>
          </div>
          <div className="profile-info-box">
            <label>Manufacturer</label>
            <p>{details?.item.manufacturer}</p>
          </div>
          <div className="profile-info-box">
            <label>NDC</label>
            <p>{details?.item.ndcCode}</p>
          </div>
          <div className="profile-info-box">
            <label>CVX</label>
            <p>{details?.item.cvx}</p>
          </div>
          <div className="profile-info-box">
            <label>LOT #</label>
            <p>{details?.item.lotNo}</p>
          </div>
          <div className="profile-info-box">
            <label>Expiry Date</label>
            <p>
              {details?.item.expiryDate
                ? moment(details?.item.expiryDate).format("L")
                : "-"}
            </p>
          </div>
          <div className="profile-info-box">
            <label>Deleted</label>
            <p>{details?.deleted ? "Yes" : "No"}</p>
          </div>
        </div>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteRecord}
        recordName={
          details?.patient.firstName + " " + details?.patient.lastName
        }
        modelName={`record`}
      />
    </div>
  );
};

export default UsageTransactionProfile;
