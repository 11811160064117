import RightNavbar from "../../components/navbarRight/NavbarRight";
import logo from "../../assets/images/logo.svg";
import sidebarlefttoggleicon from "../../assets/images/chevrons-left.svg";
import sidebarrighttoggleicon from "../../assets/images/chevrons-right.svg";
import logotext from "../../assets/images/logo-text2.svg";
import "./topbar.scss";
import Searchbar from "../../components/searchbar/Searchbar";
import { ReactSVG } from "react-svg";
const Topbar = ({ isCollapsed, toggleCollapsed }: any) => {
  return (
    <header
      id="header"
      className="header d-flex align-items-center justify-content-between"
    >
      <div className="sidebar-header icon-box">
        <div className="logo-box">
          <img src={logo} alt="home" style={{ width: "40px" }} />
          {/* <span className="logo-text">SRX</span> */}
          <span className="logo-text">
            <ReactSVG className="svg-box" wrapper="span" src={logotext} />
          </span>
        </div>
        <span className="sidebar-toggle-button" onClick={toggleCollapsed}>
          {isCollapsed ? (
            <img src={sidebarrighttoggleicon} alt="sidebar right toggle icon" />
          ) : (
            <img src={sidebarlefttoggleicon} alt="sidebar left toggle icon" />
          )}
        </span>
      </div>
      {/* <Searchbar /> */}
      <RightNavbar />
    </header>
  );
};

export default Topbar;
