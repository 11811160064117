import { toast } from "react-toastify";
import {
  AthenaTrnsSetIniVal,
  useAthenaSettingsEditMutation,
  useAthenaSettingsMutation,
  useAthenaTransDDMutation,
  useAthenaTransGetMutation,
  useAthenaTransListMutation,
  useDeleteAthenaOrderMutation,
  useDiscardAthenaOrderMutation,
  useLoadAthenaLocationsMutation,
  useLoadAthenaOrdersMutation,
  useLoadAthenaProvidersMutation,
  useMarkAthenaOrderunProcessedMutation,
  useProcessAthenaOrderMutation,
} from "../redux/athena/athenaApiSlice";
import { getErrorMessage } from "../utils/commonUtils";

export default function useAthena() {
  const [athenaTransDD, { isLoading: athenaTransDDLoading }] =
    useAthenaTransDDMutation();
  const [athenaTransList, { isLoading: athenaTransListLoading }] =
    useAthenaTransListMutation();
  const [getAthenaById, { isLoading: getAthenaByIdLoading }] =
    useAthenaTransGetMutation();
  const [athenaProviders, { isLoading: athenaProvidersLoading }] =
    useLoadAthenaProvidersMutation();
  const [athenaLocations, { isLoading: athenaLocationsLoading }] =
    useLoadAthenaLocationsMutation();
  const [athenaOrders, { isLoading: athenaOrdersLoading }] =
    useLoadAthenaOrdersMutation();
  const [deleteAthenaOrder, { isLoading: deleteAthenaOrderLoading }] =
    useDeleteAthenaOrderMutation();
  const [
    markAthenaOrderunProcessed,
    { isLoading: markAthenaOrderunProcessedLoading },
  ] = useMarkAthenaOrderunProcessedMutation();
  const [discardAthenaOrder, { isLoading: discardAthenaOrderLoading }] =
    useDiscardAthenaOrderMutation();
  const [processAthenaOrder, { isLoading: processAthenaOrderLoading }] =
    useProcessAthenaOrderMutation();
  const [athenaSettings, { isLoading: athenaSettingsLoading }] =
    useAthenaSettingsMutation();
  const [athenaSettingsEdit, { isLoading: athenaSettingsEditLoading }] =
    useAthenaSettingsEditMutation();
  const athenaSettingsAPI = async () => {
    try {
      const response: any = await athenaSettings({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const athenaSettingsEditAPI = async (data: AthenaTrnsSetIniVal) => {
    try {
      const response: any = await athenaSettingsEdit(data).unwrap();
      toast.success("Data saved successfully");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const processAthenaOrderAPI = async (id: string) => {
    try {
      const response: any = await processAthenaOrder(id).unwrap();
      toast.success("Athena order processed successfully.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const discardAthenaOrderAPI = async (id: string) => {
    try {
      const response: any = await discardAthenaOrder(id).unwrap();
      toast.success("Athena order discarded successfully.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const markAthenaOrderunProcessedAPI = async (id: string) => {
    try {
      const response: any = await markAthenaOrderunProcessed(id).unwrap();
      toast.success("Athena order marked as unprocessed successfully.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deleteAthenaOrderAPI = async (id: string) => {
    try {
      const response: any = await deleteAthenaOrder(id).unwrap();
      toast.success("Athena order deleted successfully.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const athenaOrdersAPI = async (id: string) => {
    try {
      const response: any = await athenaOrders(id).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const athenaLocationsAPI = async (id: string) => {
    try {
      const response: any = await athenaLocations(id).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const athenaProvidersAPI = async (id: string) => {
    try {
      const response: any = await athenaProviders(id).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getathenaTransDDAPI = async (data: any) => {
    try {
      const response: any = await athenaTransDD(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getAthenaTransListAPI = async (data: any) => {
    try {
      const response: any = await athenaTransList(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getAthenaByIdAPI = async (id: string) => {
    try {
      const response: any = await getAthenaById(id).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    athenaProvidersAPI,
    athenaLocationsAPI,
    athenaOrdersAPI,
    deleteAthenaOrderAPI,
    markAthenaOrderunProcessedAPI,
    discardAthenaOrderAPI,
    getathenaTransDDAPI,
    athenaTransDDLoading,
    getAthenaTransListAPI,
    athenaTransListLoading,
    getAthenaByIdAPI,
    getAthenaByIdLoading,
    processAthenaOrderAPI,
    athenaProvidersLoading,
    athenaLocationsLoading,
    deleteAthenaOrderLoading,
    athenaOrdersLoading,
    markAthenaOrderunProcessedLoading,
    processAthenaOrderLoading,
    discardAthenaOrderLoading,
    athenaSettingsAPI,
    athenaSettingsLoading,
    athenaSettingsEditAPI,
    athenaSettingsEditLoading,
  };
}
