import { createAsyncThunk } from "@reduxjs/toolkit";

export interface Configuration {
  authApiBaseUrl: string;
  appApiBaseUrl: string;
  userGuideHeader: string;
}

export const fetchConfigData = createAsyncThunk<
  Configuration,
  void,
  { rejectValue: string }
>("config/fetchConfigData", async (_, { rejectWithValue }) => {
  try {
    const response = await fetch("/config.json");
    return (await response.json()) as Configuration;
  } catch (error) {
    return rejectWithValue((error as Error).message);
  }
});
