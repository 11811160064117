import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./labellisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import duplicateicon from "../../../assets/images/duplicate-icon.svg";
import { ReactSVG } from "react-svg";
import { toggleClass } from "../../../utils/commonUtils";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import useLabelSetup from "../../../Hooks/useLabelSetup";
import Loading from "../../../components/LoadingPage/Loading";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import { LabelSetup } from "../../../redux/labelSetup/labelSetupSlice";
import { DuplicateItemModel } from "./popUpModels/duplicateItemModel";
import CommonSearchOnly from "../../../components/commonSearchOnly/commonSearchOnly";

const LabelListing = () => {
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const navigate = useNavigate();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const {
    getLabelSetupAPI,
    getLabelSetupLoading,
    duplicateLabelSetupAPI,
    duplicateLabelSetupLoading,
  } = useLabelSetup();

  const { deleteLabelSetupAPI, deleteLabelSetupLoading } = useLabelSetup();
  const [showModal, setShowModal] = useState(false);
  const [LabelSetupList, setLabelSetupList] = useState<LabelSetup[]>([]);
  const [currentLabelSetup, setCurrentLabelSetup] = useState<LabelSetup>();
  const [currentItem, setCurrentItem] = useState<any>();
  const defaultFilter = { searchText: "" };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const deleteLabelSetup = async () => {
    await deleteLabelSetupAPI({
      id: currentLabelSetup?.id,
      deletedBy: currentUserId ?? "",
    });
    toggleModal();
    getLabelSetup();
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };

  const getLabelSetup = () => {
    getLabelSetupAPI(searchFieldVal)
      .then((res: any) => setLabelSetupList(res))
      .catch();
  };
  useEffect(() => {
    getLabelSetup();
  }, [searchFieldVal]);
  const handleDuplicate = async () => {
    await duplicateLabelSetupAPI({
      id: currentItem.id,
      createdBy: currentUserId ?? "",
    });
    toggleDuplicateModal();
  };
  const toggleDuplicateModal = () => {
    toggleClass();
    setShowDuplicateModal(!showDuplicateModal);
    getLabelSetup();
  };
  return (
    <div className="label-listing-wrapper">
      {getLabelSetupLoading ||
      deleteLabelSetupLoading ||
      duplicateLabelSetupLoading ? (
        <Loading />
      ) : null}
      <CommonSearchOnly
        title="Label Setup"
        buttonTitle="Add New"
        href="/add-label"
        disRecords={LabelSetupList?.length ?? 0}
        totalRecords={LabelSetupList?.length ?? 0}
        searchVal={searchFieldVal}
        setSearchVal={setSearchFieldVal}
        defaultFilter={defaultFilter}
      />
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>Label Name</th>
              <th>Sort Order</th>
              <th>Label Type</th>
              <th>Active</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {LabelSetupList?.map((x: LabelSetup) => (
              <tr
                key={x.id}
                onDoubleClick={() => navigate(`/label-details/${x.id}`)}
              >
                <td>{x.labelName}</td>
                <td>{x.sortOrder}</td>
                <td>{x.labelTypeName?.labelTypeName}</td>
                <td>
                  <span
                    className={`status-circle ${x.isActive ? "active" : "inactive"}`}
                  >
                    {x.isActive ? "Yes" : "No"}
                  </span>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href={`/label-details/${x.id}`}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={viewicon}
                        />
                        View
                      </Dropdown.Item>
                      <Dropdown.Item href={`/edit-label/${x.id}`}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentLabelSetup(x);
                          toggleModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentItem(x);
                          toggleDuplicateModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={duplicateicon}
                        />
                        Duplicate
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteLabelSetup}
        recordName={currentLabelSetup?.labelName || ""}
        modelName={`item`}
      />
      <DuplicateItemModel
        selectedItem={currentItem}
        toggleDuplicateModal={toggleDuplicateModal}
        showModal={showDuplicateModal}
        handleDuplicate={handleDuplicate}
      />
    </div>
  );
};

export default LabelListing;
