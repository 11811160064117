import { getErrorMessage } from "../utils/commonUtils";
import { toast } from "react-toastify";
import {
  useGetUsageTransListMutation,
  useGetUsageTransByIdMutation,
  useUsageTransDeleteMutation,
} from "../redux/usageTransactions/usageTransactionsApiSlice";

export default function useUsageTransactions() {
  const [getUsageTransList, { isLoading: getUsageTransListLoading }] =
    useGetUsageTransListMutation();
  const [getUsageTransById, { isLoading: getUsageTransByIdLoading }] =
    useGetUsageTransByIdMutation();
  const [usageTransDelete, { isLoading: usageTransDeleteLoading }] =
    useUsageTransDeleteMutation();
  const getUsageTransListAPI = async (data: any) => {
    try {
      const response: any = await getUsageTransList(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getUsageTransByIdAPI = async (data: string) => {
    try {
      const response: any = await getUsageTransById(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const usageTransDeleteAPI = async (data: any) => {
    try {
      const response: any = await usageTransDelete(data).unwrap();
      toast.success("Success! Shot record has been Deleted.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    getUsageTransListAPI,
    getUsageTransListLoading,
    getUsageTransByIdAPI,
    getUsageTransByIdLoading,
    usageTransDeleteAPI,
    usageTransDeleteLoading,
  };
}
