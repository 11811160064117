import Form from "react-bootstrap/Form";
import "./adduserprofile.scss";
import { Button, Image } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import InputGroup from "react-bootstrap/InputGroup";
import profilepic from "../../../assets/images/profile-pic.png";
import editicon from "../../../assets/images/edit-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import deleteitemmodalimge from "../../../assets/images/delete-item-modal-image.svg";
import uploadfileicon from "../../../assets/images/upload-file-icon.svg";
import { useEffect, useState } from "react";
import CustomModal from "../../../components/customModal/CustomModal";
import { toggleClass } from "../../../utils/commonUtils";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useUser from "../../../Hooks/useUser";
import Loading from "../../../components/LoadingPage/Loading";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const AddUserProfile = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadImgModal, setShowUploadImgModal] = useState(false);
  const [userData, setUserData] = useState<any>();
  const navigate = useNavigate();
  const { updateUsers, editUserLoading, getUserByIdApi, getUserByIdLoading } =
    useUser();

  const [dynamicUserVal, setDynamicUserVal] = useState({
    fName: "",
    lName: "",
    userName: "",
    mi: "",
    email: "",
    clinicalRoleId: "",
    roleId: "",
    phone: "",
    deaNumber: "",
    profilePicture: "",
    isActive: true,
  });
  useEffect(() => {
    getUserByIdApi({ id: localStorage.getItem("id") }).then((res) => {
      setUserData(res);
      setDynamicUserVal((prevState) => {
        return {
          ...prevState,
          userName: res.userName,
          fName: res.fName,
          lName: res.lName,
          mi: res.mi,
          email: res.email,
          clinicalRoleId: res.clinicalRoleId,
          roleId: res.roleId,
          phone: res.phone,
          deaNumber: res.deaNumber,
          profilePicture: res.profilePicture,
          isActive: res.isActive,
        };
      });
    });
  }, []);
  const toggleDeleteModal = () => {
    toggleClass();
    setShowDeleteModal(!showDeleteModal);
  };
  const toggleUploadImgModal = () => {
    toggleClass();
    setShowUploadImgModal(!showUploadImgModal);
  };
  const {
    handleChange,
    values,
    handleSubmit,
    touched,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...dynamicUserVal,
    },
    validationSchema: Yup.object({
      fName: Yup.string()
        .max(40, "First Name can not be more than 40 characters")
        .required("First Name is Required"),
      lName: Yup.string()
        .max(40, "Last Name can not be more than 40 characters")
        .required("Last Name is Required"),
      mi: Yup.string()
        .max(1, "Middle Initials can not be more than 1 character.")
        .notRequired(),
      phone: Yup.string()
        .max(30, "Phone Number should not be more than 30 characters")
        .notRequired(),
    }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      const valid = _.isEqual(values, dynamicUserVal);
      if (!valid) {
        try {
          updateUsers({ ...values, id: localStorage.getItem("id") }).then(
            (res) => {
              localStorage.setItem("firstName", res.fName);
              localStorage.setItem("lastName", res.lName);
              navigate("/user-profile");
            },
          );
        } catch (err) {}
      }

      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  const [fileData, setFileData] = useState<Uint8Array | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [fileSize, setFileSize] = useState<number>(0);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        if (result instanceof ArrayBuffer) {
          const byteArray = new Uint8Array(result);
          setFileData(byteArray);
        }
        if (typeof result === "string") {
          setImageUrl(result);
        } else if (result instanceof ArrayBuffer) {
          const blob = new Blob([result]);
          const url = URL.createObjectURL(blob);
          setImageUrl(url);
        }
      };
      reader.readAsArrayBuffer(file);
      setFileSize(+(file.size > 0 ? file.size / 1024 / 1024 : 0).toFixed(2));
    }
  };
  const clearImg = () => {
    setFileData(null);
    setImageUrl(null);
    setFileSize(0);
  };
  const handleUpload = () => {
    if (fileData) {
      uploadFile(fileData);
    }
  };
  const uploadFile = async (byteArray: Uint8Array) => {
    setFieldValue("profilePicture", new Uint8Array(byteArray));
    if (fileSize < 5) {
      handleSubmit();
    }
  };
  return (
    <div className="add-user-profile-wrapper">
      {getUserByIdLoading || editUserLoading ? <Loading /> : null}
      <div className="login-user-profile-header-wrapper">
        <h2>Edit Profile</h2>
        <div className="login-user-header-box">
          <div className="login-user-box">
            <div className="login-user-img dropdown" data-bs-toggle="dropdown">
              <Image src={profilepic} roundedCircle />
              <div className="edit-img-overlay">
                <span className="img-edit-icon">
                  <ReactSVG className="svg-box" wrapper="span" src={editicon} />
                </span>
              </div>
              <ul className="dropdown-menu">
                <li onClick={toggleUploadImgModal}>
                  <ReactSVG className="svg-box" wrapper="span" src={editicon} />{" "}
                  Change Profile Photo
                </li>
                <li onClick={toggleDeleteModal}>
                  <ReactSVG
                    className="svg-box"
                    wrapper="span"
                    src={deleteicon}
                  />
                  Delete
                </li>
              </ul>
            </div>
            <div className="login-user-title">
              <h4>{localStorage.getItem("profileClientId")?.toUpperCase()}</h4>
              <span>{userData?.role?.roleName ?? "-"}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="add-user-box">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3 full-width">
            <h5>General Information</h5>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>User Name</Form.Label>
            <Form.Control
              value={userData?.userName ?? "-"}
              type="text"
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              id="fName"
              name="fName"
              value={values.fName}
              onChange={(e) => setFieldValue("fName", e.target.value)}
              onBlur={(e) => setFieldValue("fName", e.target.value)}
              isInvalid={touched.fName && !!errors.fName}
            />
            {touched.fName && errors.fName ? (
              <Form.Control.Feedback type="invalid">
                {errors.fName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Middle Initials</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Middle Initials"
              id="mi"
              name="mi"
              onChange={(e) => handleChange("mi")(e.target.value)}
              value={values.mi}
              isInvalid={touched.mi && !!errors.mi}
            />
            {touched.mi && errors.mi ? (
              <Form.Control.Feedback type="invalid">
                {errors.mi}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Last Name"
              id="lName"
              name="lName"
              onChange={(e) => setFieldValue("lName", e.target.value)}
              value={values.lName}
              isInvalid={touched.lName && !!errors.lName}
            />
            {touched.lName && errors.lName ? (
              <Form.Control.Feedback type="invalid">
                {errors.lName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email Address"
              value={userData?.email ?? "-"}
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Phone Number</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter Phone Number"
                id="phone"
                name="phone"
                onChange={(e) => handleChange("phone")(e.target.value)}
                value={values.phone}
                isInvalid={touched.phone && !!errors.phone}
              />
              {touched.phone && errors.phone ? (
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              ) : null}
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-4">
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="Active"
                name="active"
                type="checkbox"
                checked={!!userData?.isActive}
                disabled={true}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3 full-width">
            <h5>Other Information</h5>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Clinical Role</Form.Label>
            <Form.Control
              value={userData?.role?.roleName ?? "-"}
              type="text"
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Access Type</Form.Label>
            <Form.Control
              disabled={true}
              value={userData?.clinicalRole?.roleName ?? "-"}
              type="text"
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Location"
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-4 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => resetForm}
            >
              Cancel
            </Button>
            <Button type="submit">Update</Button>
          </Form.Group>
        </Form>
      </div>

      {showDeleteModal && (
        <CustomModal
          title=""
          content={
            <>
              <div className="modal--content--wrapper">
                <img src={deleteitemmodalimge} alt="" />
                <h5>
                  Are you sure you want to <br />
                  delete your avatar?
                </h5>
              </div>
              <div className="modal--action--btn--wrapper">
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={toggleDeleteModal}
                >
                  Cancel
                </Button>
                <Button type="button" variant="primary">
                  Delete
                </Button>
              </div>
            </>
          }
          onClose={toggleDeleteModal}
        />
      )}
      {showUploadImgModal && (
        <CustomModal
          id="upload-avatar-modal"
          title="Change Profile Photo"
          content={
            <>
              <div className="modal--content--wrapper">
                <div className="img-upload-box">
                  <Form.Control
                    type="file"
                    id="avatar-upload-input"
                    accept=".jpeg, .png"
                    onChange={handleFileChange}
                  />
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="Preview"
                      className="img-preview"
                      style={{ width: "200px", height: "200px" }}
                    />
                  ) : (
                    <>
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={uploadfileicon}
                      />
                      <p>Drag and Drop your image here or Click to Upload</p>
                    </>
                  )}
                </div>
                <div className="file-type-hint">
                  <span>File Type: JPEG, PNG</span>
                  <span
                    style={fileSize && fileSize > 5 ? { color: "red" } : {}}
                  >
                    Maximum Size: 5 MB
                  </span>
                </div>
              </div>
              <div className="modal--action--btn--wrapper">
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={() => {
                    clearImg();
                    toggleUploadImgModal();
                  }}
                >
                  Cancel
                </Button>
                <Button type="button" variant="primary" onClick={handleUpload}>
                  Upload
                </Button>
              </div>
            </>
          }
          onClose={toggleUploadImgModal}
        />
      )}
    </div>
  );
};

export default AddUserProfile;
