import { Button, Dropdown } from "react-bootstrap";
import "./labelmasterprofileheader.scss";
import threedotvertical from "../../assets/images/three-dots-vertical.svg";
import duplicateicon from "../../assets/images/duplicate-icon.svg";
import deleteicon from "../../assets/images/delete-icon.svg";
import { ReactSVG } from "react-svg";

const LabelMasterProfileHeader = ({
  title,
  id,
  toggleModal,
  duplicateType,
}: any) => {
  return (
    <div className="patients-master-profile-header-wrapper">
      <div className="profile-title-status-box">
        <h2>{title}</h2>
      </div>
      <div className="profile-actions-box">
        <div className="edit-btn-box">
          <Button variant="primary" href={`/edit-label/${id}`}>
            <i className="bi bi-pencil-square"></i> Edit
          </Button>
        </div>
        <Dropdown>
          <Dropdown.Toggle variant="link" id="dropdown-basic">
            <ReactSVG
              className="svg-box"
              wrapper="span"
              src={threedotvertical}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={duplicateType}>
              <ReactSVG
                className="svg-box"
                wrapper="span"
                src={duplicateicon}
              />
              Duplicate
            </Dropdown.Item>
            <Dropdown.Item onClick={toggleModal}>
              <ReactSVG className="svg-box" wrapper="span" src={deleteicon} />
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default LabelMasterProfileHeader;
