import CustomModal from "../../../../components/customModal/CustomModal";
import duplicateitemmodalimge from "../../../../assets/images/duplicate-item-modal-image.svg";
import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import useInventory from "../../../../Hooks/useInventory";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import Loading from "../../../../components/LoadingPage/Loading";
import { selectCurrentUserId } from "../../../../redux/auth/authSlice";
import { useAppSelector } from "../../../../redux/hooks";
import React from "react";
export const DuplicateItemModel = ({
  selectedItem,
  showModal,
  toggleDuplicateModal,
  reloadListing,
}: any) => {
  const { getNewBarcodeAPI, getNewBarcodeLoading } = useInventory();
  const { duplicateItemAPI, duplicateItemLoading } = useInventory();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [dynamicVal, setDynamicVal] = useState({
    createdBy: currentUserId,
    id: "",
    barcode: "",
  });
  useEffect(() => {
    if (showModal && selectedItem?.id) {
      getNewBarcodeAPI().then((res: any) =>
        setDynamicVal({ ...dynamicVal, barcode: res, id: selectedItem.id }),
      );
    }
  }, [showModal, selectedItem]);

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: dynamicVal,
    validationSchema: Yup.object({
      barcode: Yup.string().required("New Barcode is required"),
    }),
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        await duplicateItemAPI(values);
        toggleDuplicateModal();
        reloadListing();
      } catch (err) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  return (
    <>
      {getNewBarcodeLoading || duplicateItemLoading ? <Loading /> : null}
      {showModal && (
        <CustomModal
          title=""
          content={
            <>
              <div className="modal--content--wrapper">
                <img src={duplicateitemmodalimge} alt="" />
                <h5>
                  Are you sure you want to <br />
                  duplicate this item '{selectedItem?.rxName}'?
                </h5>
                <div className="modal--inputbox">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-4">
                      <Form.Label>New Barcode</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter New Barcode"
                        id="barcode"
                        name="barcode"
                        onChange={handleChange}
                        value={values.barcode}
                        isInvalid={touched.barcode && !!errors.barcode}
                      />
                      {touched.barcode && errors.barcode ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.barcode}
                        </Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="full-width text-center">
                      <Button
                        onClick={toggleDuplicateModal}
                        style={{ marginRight: "10px" }}
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>
                      <Button type="submit" variant="primary">
                        Duplicate
                      </Button>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </>
          }
          onClose={toggleDuplicateModal}
        />
      )}
    </>
  );
};
