import Modal from "react-bootstrap/Modal";
import { ReactSVG } from "react-svg";
import { NavDropdown, Table } from "react-bootstrap";
import srxlogowithtext from "../../../../../assets/images/SRX-logo-with-text.svg";
import sflogo from "../../../../../assets/images/SourceFuse-logo.png";
import downloadicon from "../../../../../assets/images/download-icon.svg";
import exporticon from "../../../../../assets/images/export-icon.svg";
import excelicon from "../../../../../assets/images/excel-icon.svg";
import pdficon from "../../../../../assets/images/pdf-icon.svg";
import arrowdown from "../../../../../assets/images/angle-down.svg";
export const InventoryTransactionReportsModel = ({
  showReportModal,
  onCloseReportModal,
  selectedItem,
}: any) => {
  return (
    <Modal
      size="xl"
      id="trans-report-modal"
      show={showReportModal}
      onHide={onCloseReportModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>View Report</h4>
          <div className="trans-report-action-wrapper">
            <div className="trans-report-action-box download-report-box">
              <ReactSVG className="svg-box" wrapper="span" src={downloadicon} />
            </div>
            <div className="trans-report-action-box export-report-opt-box">
              <ReactSVG className="svg-box" wrapper="span" src={exporticon} />
              <NavDropdown title="Export Report">
                <NavDropdown.Item>
                  <img src={excelicon} alt="" /> Excel
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <img src={pdficon} alt="" /> PDF
                </NavDropdown.Item>
              </NavDropdown>
              <ReactSVG className="svg-box" wrapper="span" src={arrowdown} />
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="trans-report-content-wrapper">
          <div className="trans-report-content-header">
            <div className="header-content">
              <h5>Transaction Report</h5>
              <p>04 April 2024</p>
            </div>
            <div className="header-logo-wrapper">
              <div className="header-logo-box srx-logo-box">
                <img src={srxlogowithtext} alt="srx" />
              </div>
              <div className="header-logo-box sf-logo-box">
                <img src={sflogo} alt="sourcefuse" />
              </div>
            </div>
          </div>
          <div className="trans-report-detail-box-wrapper">
            <h5>01A VARIVAX</h5>
            <div className="trans-report-detail-box-container">
              <div className="trans-report-detail-box">
                <label>SRX Barcode</label>
                <p>RX20230202121822262</p>
              </div>
              <div className="trans-report-detail-box">
                <label>Manufacturer</label>
                <p>Merck Sharp</p>
              </div>
              <div className="trans-report-detail-box">
                <label>NDC</label>
                <p>0006-4827-01</p>
              </div>
              <div className="trans-report-detail-box">
                <label>LOT Number</label>
                <p>K003420</p>
              </div>
              <div className="trans-report-detail-box">
                <label>Expiration Date</label>
                <p>01/04/2024</p>
              </div>
            </div>
            <div className="trans-report-table-wrapper">
              <Table bordered={false} hover={true}>
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>TRANS.Type</th>
                    <th>Ref. No</th>
                    <th>user</th>
                    <th>location</th>
                    <th>Qty.</th>
                    <th>Current qty.</th>
                    <th>price</th>
                    <th>trans.value</th>
                    <th>current value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01/04/2024</td>
                    <td>Add Adjustment</td>
                    <td>Count 5.00</td>
                    <td>System Admin</td>
                    <td>1234 Maple...</td>
                    <td>5.00</td>
                    <td>5.00</td>
                    <td>$40</td>
                    <td>$200</td>
                    <td>$200</td>
                  </tr>
                  <tr>
                    <td>01/04/2024</td>
                    <td>Add Adjustment</td>
                    <td>Count 5.00</td>
                    <td>System Admin</td>
                    <td>1234 Maple...</td>
                    <td>5.00</td>
                    <td>5.00</td>
                    <td>$40</td>
                    <td>$200</td>
                    <td>$200</td>
                  </tr>
                  <tr>
                    <td>01/04/2024</td>
                    <td>Add Adjustment</td>
                    <td>Count 5.00</td>
                    <td>System Admin</td>
                    <td>1234 Maple...</td>
                    <td>5.00</td>
                    <td>5.00</td>
                    <td>$40</td>
                    <td>$200</td>
                    <td>$200</td>
                  </tr>
                  <tr>
                    <td>01/04/2024</td>
                    <td>Add Adjustment</td>
                    <td>Count 5.00</td>
                    <td>System Admin</td>
                    <td>1234 Maple...</td>
                    <td>5.00</td>
                    <td>5.00</td>
                    <td>$40</td>
                    <td>$200</td>
                    <td>$200</td>
                  </tr>
                  <tr>
                    <td>01/04/2024</td>
                    <td>Add Adjustment</td>
                    <td>Count 5.00</td>
                    <td>System Admin</td>
                    <td>1234 Maple...</td>
                    <td>5.00</td>
                    <td>5.00</td>
                    <td>$40</td>
                    <td>$200</td>
                    <td>$200</td>
                  </tr>
                  <tr>
                    <th colSpan={5}>Total</th>
                    <th colSpan={5}>25.00</th>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
