import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./settinglisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import useSettings from "../../../Hooks/useSettings";
import Loading from "../../../components/LoadingPage/Loading";
import { toggleClass } from "../../../utils/commonUtils";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import { Setting } from "../../../redux/settings/settingsSlice";
import CommonSearchOnly from "../../../components/commonSearchOnly/commonSearchOnly";
const Settings = () => {
  const navigate = useNavigate();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { getSettingsAPI, getSettingsLoading } = useSettings();
  const { deleteSettingAPI, deleteSettingLoading } = useSettings();
  const [showModal, setShowModal] = useState(false);
  const [settingsList, setSettingsList] = useState<Setting[]>([]);
  const [currentSetting, setCurrentSetting] = useState<Setting>();
  const defaultFilter = { searchText: "" };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const deleteSetting = async () => {
    await deleteSettingAPI({
      id: currentSetting?.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    getSettings();
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };

  const getSettings = () => {
    getSettingsAPI(searchFieldVal)
      .then((res: any) => setSettingsList(res))
      .catch();
  };
  useEffect(() => {
    getSettings();
  }, [searchFieldVal]);
  return (
    <div className="setting-listing-wrapper">
      {getSettingsLoading || deleteSettingLoading ? <Loading /> : null}
      <CommonSearchOnly
        title="Admin Setting"
        buttonTitle="Add New"
        href="/add-setting"
        disRecords={settingsList.length | 0}
        totalRecords={settingsList.length | 0}
        searchVal={searchFieldVal}
        setSearchVal={setSearchFieldVal}
        defaultFilter={defaultFilter}
      />
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>Setting Name</th>
              <th>Setting Value</th>
              <th>Category</th>
              <th>Notes</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {settingsList?.map((x: Setting) => (
              <tr
                key={x.id}
                onDoubleClick={() => navigate(`/view-setting/${x.id}`)}
              >
                <td title={x.settingName}>{x.settingName}</td>
                <td title={x.settingValue}>{x.settingValue}</td>
                <td title={x.category}>{x.category}</td>
                <td title={x.notes}>{x.notes}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href={`/view-setting/${x.id}`}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={viewicon}
                        />
                        View
                      </Dropdown.Item>
                      <Dropdown.Item href={`/edit-setting/${x.id}`}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentSetting(x);
                          toggleModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteSetting}
        recordName={currentSetting?.settingName || ""}
        modelName={`setting`}
      />
    </div>
  );
};

export default Settings;
