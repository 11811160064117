import { useEffect } from "react";
import useAuth from "../../Hooks/useAuth";
import { useAppSelector } from "../../redux/hooks";
import { selectConfigData } from "../../redux/config/configSlice";

const UserGuide = () => {
  const configData = useAppSelector(selectConfigData);
  const { guideApiSliceApi } = useAuth();
  const getURL = async () => {
    await guideApiSliceApi(configData).then((res) => {
      if (res.result.result === "S") {
        if (res.result.data) {
          window.open(
            `https://docs.trinetmedical.com/?code=${res.result.data}`,
            "_blank",
            "noopener,noreferrer",
          );
        }
      }
    });
  };
  useEffect(() => {
    getURL();
  }, []);
  return <div className="dashboard-wrapper">User Guide page</div>;
};

export default UserGuide;
