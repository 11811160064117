import { getErrorMessage } from "../utils/commonUtils";
import {
  useCreateStocksMutation,
  useDeleteStockMutation,
  useEditStocksMutation,
  useGetStocksMutation,
  useSetStockStatusActiveMutation,
  useSetStockStatusDeActiveMutation,
} from "../redux/stock/stockApiSlice";
import { toast } from "react-toastify";

export default function useStocks() {
  const [getStocks, { isLoading: getStocksLoading }] = useGetStocksMutation();
  const [createStocks, { isLoading: createStocksLoading }] =
    useCreateStocksMutation();
  const [editStocks, { isLoading: editStocksLoading }] =
    useEditStocksMutation();

  const [setStockStatusActive, { isLoading: setStockStatusActiveLoading }] =
    useSetStockStatusActiveMutation();
  const [setStockStatusDeActive, { isLoading: setStockStatusDeActiveLoading }] =
    useSetStockStatusDeActiveMutation();
  const [deleteStock, { isLoading: deleteStockLoading }] =
    useDeleteStockMutation();
  const getStocksAPI = async (data: any) => {
    try {
      const response: any = await getStocks(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const createStocksAPI = async (data: any) => {
    try {
      const response: any = await createStocks(data).unwrap();
      toast.success("Success! Stock has been Created.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const editStocksAPI = async (data: any) => {
    try {
      const response: any = await editStocks(data).unwrap();
      toast.success("Success! Stock has been Updated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const activeStockAPI = async (data: any) => {
    try {
      const response: any = await setStockStatusActive(data).unwrap();
      toast.success("Success! Stock has been Activated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deactiveStockAPI = async (data: any) => {
    try {
      const response: any = await setStockStatusDeActive(data).unwrap();
      toast.success("Success! Stock has been Deactivated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deleteStockAPI = async (data: any) => {
    try {
      const response: any = await deleteStock(data).unwrap();
      toast.success("Success! Stock has been Deleted.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    getStocksAPI,
    getStocksLoading,
    createStocksLoading,
    createStocksAPI,
    editStocksAPI,
    editStocksLoading,
    activeStockAPI,
    setStockStatusActiveLoading,
    deactiveStockAPI,
    setStockStatusDeActiveLoading,
    deleteStockAPI,
    deleteStockLoading,
  };
}
