import { getErrorMessage } from "../utils/commonUtils";
import {
  useCreateVendorMutation,
  useDeleteVendorMutation,
  useDuplicateVendorMutation,
  useEditVendorMutation,
  useGetVendorMutation,
  useSetVendorStatusActiveMutation,
  useSetVendorStatusDeActiveMutation,
} from "../redux/vendor/vendorApiSlice";
import { toast } from "react-toastify";

export default function useVendor() {
  const [getVendor, { isLoading: getVendorsLoading }] = useGetVendorMutation();
  const [createVendor, { isLoading: createVendorLoading }] =
    useCreateVendorMutation();
  const [editVendor, { isLoading: editVendorLoading }] =
    useEditVendorMutation();
  const [deleteVendor, { isLoading: deleteVendorLoading }] =
    useDeleteVendorMutation();
  const [setVendorStatusActive, { isLoading: setVendorStatusActiveLoading }] =
    useSetVendorStatusActiveMutation();
  const [
    setVendorStatusDeActive,
    { isLoading: setVendorStatusDeActiveLoading },
  ] = useSetVendorStatusDeActiveMutation();
  const [duplicateVendor, { isLoading: duplicateVendorLoading }] =
    useDuplicateVendorMutation();
  const getVendorsAPI = async (data: any) => {
    try {
      const response: any = await getVendor(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const createVendorAPI = async (data: any) => {
    try {
      const response: any = await createVendor(data).unwrap();
      toast.success("Success! Vendor has been Created.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const activeVendorAPI = async (data: any) => {
    try {
      const response: any = await setVendorStatusActive(data).unwrap();
      toast.success("Success! Vendor has been Activated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deactiveVendorAPI = async (data: any) => {
    try {
      const response: any = await setVendorStatusDeActive(data).unwrap();
      toast.success("Success! Vendor has been Deactivated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const editVendorAPI = async (data: any) => {
    try {
      const response: any = await editVendor(data).unwrap();
      toast.success("Success! Vendor has been Updated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deleteVendorAPI = async (data: any) => {
    try {
      const response: any = await deleteVendor(data).unwrap();
      toast.success("Success! Vendor has been Deleted.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const duplicateVendorAPI = async (data: any) => {
    try {
      const response: any = await duplicateVendor(data).unwrap();
      toast.success("Success! Vendor has been Duplicated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    getVendorsAPI,
    getVendorsLoading,
    createVendorLoading,
    createVendorAPI,
    editVendorAPI,
    editVendorLoading,
    deleteVendorAPI,
    deleteVendorLoading,
    activeVendorAPI,
    setVendorStatusActiveLoading,
    deactiveVendorAPI,
    setVendorStatusDeActiveLoading,
    duplicateVendorLoading,
    duplicateVendorAPI,
  };
}
