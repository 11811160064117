import "./orderingproductprofile.scss";
import OrderingProductProfileHeader from "../../../components/orderingProductProfileHeader/OrderingProductProfileHeader";
import {
  OrderingProduct,
  selectedOP,
  setSelectedOP,
} from "../../../redux/orderingProduct/OPSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Loading from "../../../components/LoadingPage/Loading";
import { useNavigate } from "react-router-dom";
import useOPs from "../../../Hooks/useOP";
import { useState } from "react";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { toggleClass } from "../../../utils/commonUtils";
import { DuplicateModel } from "../OrderingProductListing/popup/duplicateModel";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
const OrderingProductProfile = () => {
  const selectedOPData = useAppSelector(selectedOP);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeOPAPI, setOPStatusActiveLoading } = useOPs();
  const { deactiveOPAPI, setOPStatusDeActiveLoading } = useOPs();
  const { deleteOPAPI, deleteOPLoading } = useOPs();
  const [showModal, setShowModal] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const { duplicateOPAPI, duplicateOPLoading } = useOPs();
  const updateStatus = () => {
    if (selectedOPData.isActive) {
      deactiveOPAPI({
        id: selectedOPData.id,
        updatedBy: currentUserId ?? "",
      })
        .then((res: OrderingProduct) => {
          if (res) {
            dispatch(setSelectedOP(res));
          }
        })
        .catch();
    } else {
      activeOPAPI({
        id: selectedOPData.id,
        updatedBy: currentUserId ?? "",
      })
        .then((res: OrderingProduct) => {
          if (res) {
            dispatch(setSelectedOP(res));
          }
        })
        .catch();
    }
  };
  const deleteOP = async () => {
    await deleteOPAPI({
      id: selectedOPData.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    navigate("/ordering-product-master");
  };

  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const toggleDuplicateModal = () => {
    toggleClass();
    setShowDuplicateModal(!showDuplicateModal);
  };
  const duplicateOP = async () => {
    await duplicateOPAPI({
      id: selectedOPData.id,
      updatedBy: currentUserId ?? "",
    });
    toggleDuplicateModal();
    navigate("/ordering-product-master");
  };
  return (
    <div className="profile-page-wrapper">
      {(setOPStatusActiveLoading ||
        setOPStatusDeActiveLoading ||
        deleteOPLoading ||
        duplicateOPLoading) && <Loading />}
      <OrderingProductProfileHeader
        selectedOPData={selectedOPData}
        updateStatus={updateStatus}
        toggleModal={toggleModal}
        toggleDuplicateModal={toggleDuplicateModal}
      />
      <div className="profile-detail-box-wrapper">
        <div className="profile-detail-box">
          <div className="profile-info-box">
            <label>Product No</label>
            <p>{selectedOPData.productNo}</p>
          </div>
          <div className="profile-info-box">
            <label>Product Name</label>
            <p>{selectedOPData.productDescription}</p>
          </div>
          <div className="profile-info-box">
            <label>Inventory Unit</label>
            <p>{selectedOPData.unit?.unitName || "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Default Vendor</label>
            <p>{selectedOPData.vendorMaster?.name || "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Purchase UOM</label>
            <p>{selectedOPData.purchaseUOM || "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Conversion Factor</label>
            <p>{selectedOPData.conversionFactor}</p>
          </div>
          <div className="profile-info-box">
            <label>Vendor Product No</label>
            <p>{selectedOPData.externalProductNo || "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Status</label>
            <p> {selectedOPData.isActive ? "Active" : "InActive"}</p>
          </div>
        </div>
      </div>

      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteOP}
        recordName={selectedOPData?.productDescription || ""}
        modelName={`ordering product`}
      />
      <DuplicateModel
        showModal={showDuplicateModal}
        toggleDuplicateModal={toggleDuplicateModal}
        duplicateOP={duplicateOP}
        currentOP={selectedOPData}
      />
    </div>
  );
};

export default OrderingProductProfile;
