import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./../store";
export interface InventoryData {
  selectedItem: any;
}
export interface Barcode {
  rxId: string;
  rxSRXId: string;
  isDefault: boolean;
  serialNo: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}
export interface Transaction {
  transactionCode: string;
  transactionName: string;
  isAddTransaction: boolean;
  flag: boolean;
  id: string;
  isActive: boolean;
  createdOnUtc?: string;
  updatedOnUtc?: string;
  createdBy?: string;
  updatedBy?: string;
}
export interface TransactionReceivePayload {
  quantity: number;
  referenceNumber: string;
  transactionId: string;
  locationId: string;
  verifyDate: Date;
  itemId: string;
  userId: string;
}
export interface ItemCreate {
  rxName: string;
  drugName: string;
  manufacturer: string;
  ndcCode: string;
  serialNumber: string;
  lotNo: string;
  cvx: string;
  expiryDate: string | null;
  rxSRXId: string;
  notes: string;
  unitId: string;
  rxTypeId: string;
  stockId: string;
  cptCode: string;
  isRecalled: boolean;
  isActive: boolean;
  orderingProductMasterId: string | null;
  createdBy?: string | null;
  isNeverExpire: boolean;
  isKit: boolean;
  isSerialTracked: boolean;
}
export interface Units {
  conversionFactor: number;
  isDefault: boolean;
  isActive: boolean;
  unitId: string;
}
const storedItem = localStorage.getItem("selectedItem");
const initialState: InventoryData = {
  selectedItem: storedItem ? JSON.parse(storedItem) : null,
};

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setSelectedItem: (state, action: PayloadAction<any>) => {
      state.selectedItem = action.payload;
      localStorage.setItem("selectedItem", JSON.stringify(action.payload));
    },
  },
});
export const { setSelectedItem } = inventorySlice.actions;
export default inventorySlice.reducer;
export const selectedInventoryItem = (state: RootState) =>
  state.inventory.selectedItem;
