import { getErrorMessage } from "../utils/commonUtils";
import {
  useCreateLabelSetupMutation,
  useDeleteLabelSetupMutation,
  useDuplicateLabelSetupMutation,
  useEditLabelSetupMutation,
  useGetLabelSetupByIdMutation,
  useGetLabelSetupMutation,
  useGetLabelTypesMutation,
} from "../redux/labelSetup/labelSetupApiSlice";
import { toast } from "react-toastify";

export default function useLabelSetup() {
  const [getLabelSetup, { isLoading: getLabelSetupLoading }] =
    useGetLabelSetupMutation();
  const [getLabelTypes, { isLoading: getLabelTypesLoading }] =
    useGetLabelTypesMutation();
  const [createLabelSetup, { isLoading: createLabelSetupLoading }] =
    useCreateLabelSetupMutation();
  const [editLabelSetup, { isLoading: editLabelSetupLoading }] =
    useEditLabelSetupMutation();
  const [deleteLabelSetup, { isLoading: deleteLabelSetupLoading }] =
    useDeleteLabelSetupMutation();
  const [getLabelSetupById, { isLoading: getLabelSetupByIdLoading }] =
    useGetLabelSetupByIdMutation();
  const [duplicateLabelSetup, { isLoading: duplicateLabelSetupLoading }] =
    useDuplicateLabelSetupMutation();
  const getLabelTypesAPI = async () => {
    try {
      const response: any = await getLabelTypes({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getLabelSetupAPI = async (data: any) => {
    try {
      const response: any = await getLabelSetup(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getLabelSetupByIdAPI = async (data: any) => {
    try {
      const response: any = await getLabelSetupById(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const createLabelSetupAPI = async (data: any) => {
    try {
      const response: any = await createLabelSetup(data).unwrap();
      toast.success("Success! Label has been Created.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const editLabelSetupAPI = async (data: any) => {
    try {
      const response: any = await editLabelSetup(data).unwrap();
      toast.success("Success! Label has been Updated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const deleteLabelSetupAPI = async (data: any) => {
    try {
      const response: any = await deleteLabelSetup(data).unwrap();
      toast.success("Success! Label has been Deleted.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const duplicateLabelSetupAPI = async (data: any) => {
    try {
      const response: any = await duplicateLabelSetup(data).unwrap();
      toast.success("Success! Label has been Duplicated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    getLabelTypesAPI,
    getLabelTypesLoading,
    getLabelSetupAPI,
    getLabelSetupLoading,
    createLabelSetupLoading,
    createLabelSetupAPI,
    editLabelSetupAPI,
    editLabelSetupLoading,
    deleteLabelSetupAPI,
    deleteLabelSetupLoading,
    getLabelSetupByIdAPI,
    getLabelSetupByIdLoading,
    duplicateLabelSetupAPI,
    duplicateLabelSetupLoading,
  };
}
