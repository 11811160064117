import { Button, Form } from "react-bootstrap";
import CustomModal from "../../../../components/customModal/CustomModal";
import { selectCurrentUserId } from "../../../../redux/auth/authSlice";
import { useAppSelector } from "../../../../redux/hooks";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useInventory from "../../../../Hooks/useInventory";
import Loading from "../../../../components/LoadingPage/Loading";
export const OrderingProductsModel = ({
  showModal,
  toggleModal,
  orderingProdOptions,
  setOrderingProdOptions,
  setSelectedOPValue,
  unitId,
}: any) => {
  const { createOrderingProductsAPI, createOrderingProductsLoading } =
    useInventory();
  const currentUserId = useAppSelector(selectCurrentUserId);

  const closeModel = () => {
    resetForm();
    toggleModal();
  };
  const { handleChange, values, handleSubmit, touched, errors, resetForm } =
    useFormik({
      initialValues: {
        productNo: "",
        productDescription: "",
        conversionFactor: "0",
        isActive: true,
        createdBy: currentUserId,
      },
      validationSchema: Yup.object({
        productNo: Yup.string().required("Product Number is Required"),
        productDescription: Yup.string().required("Product Name is Required"),
      }),

      onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
        try {
          const res = await createOrderingProductsAPI({
            ...values,
            unitId: unitId.value,
          });
          if (res) {
            setSelectedOPValue({
              value: res.id,
              label: `${res.productNo}-${res.productDescription} (${unitId.label})`,
            });
            setOrderingProdOptions([
              {
                value: res.id,
                label: `${res.productNo}-${res.productDescription} (${unitId.label})`,
              },
              ...orderingProdOptions,
            ]);
            closeModel();
          }
        } catch (err: any) {}
        setSubmitting(false);
      },
      enableReinitialize: true,
    });
  return (
    <>
      {createOrderingProductsLoading ? <Loading /> : null}
      {showModal && (
        <CustomModal
          title="New Ordering Products"
          content={
            <>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-4 full-width">
                  <Form.Label>Product Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Product Number"
                    id="productNo"
                    name="productNo"
                    onChange={handleChange}
                    value={values.productNo}
                    isInvalid={touched.productNo && !!errors.productNo}
                  />
                  {touched.productNo && errors.productNo ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.productNo}
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-4 full-width">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Product Name"
                    id="productDescription"
                    name="productDescription"
                    onChange={handleChange}
                    value={values.productDescription}
                    isInvalid={
                      touched.productDescription && !!errors.productDescription
                    }
                  />
                  {touched.productDescription && errors.productDescription ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.productDescription}
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
                <Form.Group className="full-width text-end">
                  <Button
                    onClick={closeModel}
                    style={{ marginRight: "10px" }}
                    variant="outline-primary"
                  >
                    Cancel
                  </Button>
                  <Button type="submit">Add</Button>
                </Form.Group>
              </Form>
            </>
          }
          onClose={closeModel}
        />
      )}
    </>
  );
};
