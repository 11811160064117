import { Sidebar as Sidebr, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import "./sidebar.scss";
import { menuItems, IMenuItem } from "./MenuItems";
import { useEffect, useState } from "react";
import { getSideBarActiveDetails } from "../../utils/commonUtils";
import useAdmin from "../../Hooks/useAdmin";
import {
  getMenuItems,
  getPermissionsList,
  selectedRoleId,
  setMenuItems,
  setPermissions,
} from "../../redux/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ReactSVG } from "react-svg";
import Loading from "../../components/LoadingPage/Loading";
import useRolesAndPermissions from "../../Hooks/useRolesAndPermissions";

const Sidebar = ({ isCollapsed }: any) => {
  const dispatch = useAppDispatch();
  const [menuActive, setMenuActive] = useState<IMenuItem>();
  const [mainMenuActive, setMainMenuActive] = useState<IMenuItem>();
  const location = useLocation();
  const [menuItemList, setMenuItemsList] = useState(
    useAppSelector(getMenuItems) ?? [],
  );
  const permissionsList = useAppSelector(getPermissionsList);

  const { getRolesPermissionAPI, getRolesPermissionLoading } =
    useRolesAndPermissions();

  const { getPermissionsAPI, getPermissionsLoading } = useAdmin();
  const role = useAppSelector(selectedRoleId);

  useEffect(() => {
    if (menuItemList && menuItemList.length <= 0) {
      getPermissionsAPI(role).then((res) => {
        dispatch(setMenuItems(res));
        setMenuItemsList(res);
      });
    }
    if (permissionsList && permissionsList.length <= 0) {
      getPermissions();
    }
  }, []);

  useEffect(() => {
    const { menuItemDetails, subMenuDetails } = getSideBarActiveDetails(
      location.pathname,
    );
    setMenuActive(subMenuDetails);
    setMainMenuActive(menuItemDetails);
  }, [location]);
  const getSubMenuState = (activeMenu: any, menu: string) => {
    return activeMenu === menu;
  };
  const getPermissions = () => {
    getRolesPermissionAPI({ id: role }).then((data: any) => {
      const groupedData = data
        .map((item: { permission: any }) => item.permission)
        .filter(
          (x: { isMenu: boolean; isActive: boolean }) =>
            x.isMenu === false && x.isActive === true,
        );
      dispatch(setPermissions(groupedData));
    });
  };
  return (
    <>
      {getPermissionsLoading || getRolesPermissionLoading ? <Loading /> : null}
      <Sidebr
        rootStyles={{
          backgroundColor: "transparent",
          marginTop: "1rem",
          border: "none",
        }}
        style={{ height: "calc(100vh - 100px)" }}
        collapsed={isCollapsed}
      >
        <Menu
          closeOnClick
          menuItemStyles={{
            button: {
              [`&.active`]: {
                backgroundColor: "#E6F7FE",
                color: "#044D6E",
                fontWeight: "600",
              },
              color: "#333333",
              fontSize: "0.875rem",
              borderRadius: "4px",
              margin: "0 16px",
              height: "35px",
              padding: "8px 0px",
              [`&:hover`]: {
                color: "#044D6E",
              },
            },
          }}
        >
          {menuItemList?.map(
            (menuItem: any) =>
              menuItem.menuText && (
                <SubMenu
                  className="main-menu-items"
                  key={menuItem.menuKey}
                  label={menuItem.menuText}
                  // icon={menuItem.icon}
                  icon={
                    <ReactSVG
                      className="svg-box"
                      wrapper="span"
                      src={menuItem.icon}
                    />
                  }
                  active={mainMenuActive?.name === menuItem.menuText}
                  defaultOpen={getSubMenuState(
                    mainMenuActive?.name,
                    menuItem.menuText,
                  )}
                >
                  {menuItem.childMenus?.map((subMenu: any) => (
                    <MenuItem
                      title={subMenu.menuText}
                      className={"submenu-items"}
                      component={<Link to={subMenu.route} />}
                      icon={
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={menuItem.icon}
                        />
                      }
                      key={subMenu.menuKey}
                      active={menuActive?.path === subMenu.route}
                    >
                      {subMenu.menuText}
                    </MenuItem>
                  ))}
                </SubMenu>
              ),
          )}
        </Menu>
      </Sidebr>
    </>
  );
};

export default Sidebar;
