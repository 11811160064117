import Form from "react-bootstrap/Form";
import "./addrole.scss";
import { Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import useRolesAndPermissions from "../../../Hooks/useRolesAndPermissions";
import { useEffect, useState } from "react";
import Loading from "../../../components/LoadingPage/Loading";
import { Permission } from "../../../redux/rolesAndPermissions/rolesAndPermissionsApiSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface PermissionsList {
  [module: string]: Permission[];
}

const AddRole = () => {
  const { getPermissionsAPI, getPermissionsLoading } = useRolesAndPermissions();
  const { createPermissionsAPI, createPermissionLoading } =
    useRolesAndPermissions();
  const { createRoleAPI, createRoleLoading } = useRolesAndPermissions();

  const { editRoleAPI, editRoleLoading } = useRolesAndPermissions();

  const { getRolesPermissionAPI, getRolesPermissionLoading } =
    useRolesAndPermissions();
  const { getRolesByIdAPI, getRolesByIdLoading } = useRolesAndPermissions();

  const navigate = useNavigate();
  const [permissionsList, setPermissionsList] = useState<PermissionsList>({});
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const getPermissions = () => {
    getPermissionsAPI({})
      .then((data: any) => {
        const groupedData = data.reduce((acc: any, item: any) => {
          (acc[item.module] = acc[item.module] || []).push({
            ...item,
            isChecked: false,
          });
          return acc;
        }, {});
        setPermissionsList(groupedData);
        if (location.pathname !== "/add-role") {
          setIsEdit(true);
          getRolesByIdAPI({ id })
            .then((data: any) => {
              setFieldValue("roleName", data.roleName);
              setFieldValue("roleDescription", data.roleDescription);
            })
            .catch();
          getRolesPermissionAPI({ id }).then((data: any) => {
            const permissionIdsToCheck = new Set(
              data.map((item: any) => item.permissionId),
            );
            // Create a new permissions list with updated isChecked values
            const newPermissionsList = Object.keys(groupedData).reduce(
              (acc, module) => {
                acc[module] = groupedData[module].map(
                  (permission: Permission) => ({
                    ...permission,
                    isChecked: permissionIdsToCheck.has(permission.id),
                  }),
                );
                return acc;
              },
              {} as PermissionsList,
            );
            // Update the state with the new permissions list
            setPermissionsList(newPermissionsList);
          });
        }
      })
      .catch();
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const selectAllPermissions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setPermissionsList((prevList) => {
      const updatedList = Object.keys(prevList).reduce<PermissionsList>(
        (acc, module) => {
          acc[module] = prevList[module].map((permission) => ({
            ...permission,
            isChecked,
          }));
          return acc;
        },
        {},
      );
      return updatedList;
    });
  };

  const setSubPermissions = (
    e: React.ChangeEvent<HTMLInputElement>,
    module: string,
  ) => {
    const isChecked = e.target.checked;
    setPermissionsList((prevList) => ({
      ...prevList,
      [module]: prevList[module].map((permission) => ({
        ...permission,
        isChecked,
      })),
    }));
  };

  const setIndiPermission = (
    isChecked: boolean,
    item: Permission,
    module: string,
  ) => {
    setPermissionsList((prevList) => ({
      ...prevList,
      [module]: prevList[module].map((permission) =>
        permission.id === item.id ? { ...permission, isChecked } : permission,
      ),
    }));
  };

  const allChecked = (module: string): boolean => {
    const permissions = permissionsList[module] || [];
    return permissions.every((permission) => permission.isChecked);
  };

  const setPermissions = (id: string) => {
    const idsWithCheckedPermissions = Object.keys(permissionsList).flatMap(
      (module) =>
        permissionsList[module]
          .filter((permission) => permission.isChecked)
          .map((permission) => permission.id),
    );
    createPermissionsAPI({
      roleId: id,
      permissionIds: idsWithCheckedPermissions,
      createdBy: currentUserId,
    })
      .then(() => navigate("/roles-permissions"))
      .catch();
  };

  const { handleChange, values, handleSubmit, touched, errors, setFieldValue } =
    useFormik({
      initialValues: {
        roleName: "",
        roleDescription: "",
        isActive: true,
      },
      validationSchema: Yup.object({
        roleName: Yup.string()
          .required("Role Name is Required")
          .max(25, "Role Name can not be more than 25 characters"),
        roleDescription: Yup.string()
          .required("Role Description is Required")
          .max(50, "Role Description can not be more than 50 characters"),
      }),

      onSubmit: async (values: any) => {
        if (isEdit) {
          editRoleAPI({ ...values, id: id })
            .then((data: any) => {
              setPermissions(data.id);
            })
            .catch();
        } else {
          createRoleAPI(values)
            .then((data: any) => {
              setPermissions(data.id);
            })
            .catch();
        }
      },
      enableReinitialize: true,
    });
  const allPermissionsChecked = Object.keys(permissionsList).every((module) =>
    permissionsList[module].every((permission) => permission.isChecked),
  );
  return (
    <div className="add-role-wrapper">
      {getPermissionsLoading ||
      createPermissionLoading ||
      createRoleLoading ||
      getRolesPermissionLoading ||
      getRolesByIdLoading ||
      editRoleLoading ? (
        <Loading />
      ) : null}
      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} Role</h2>
      </div>
      <div className="add-role-box">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Label>Role Name</Form.Label>
            <Form.Control
              id="roleName"
              name="roleName"
              type="text"
              placeholder="Enter Role Name"
              onChange={handleChange}
              value={values.roleName}
              isInvalid={touched.roleName && !!errors.roleName}
            />

            {touched.roleName && errors.roleName ? (
              <Form.Control.Feedback type="invalid">
                {errors.roleName as string}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Role Description</Form.Label>
            <Form.Control
              id="roleDescription"
              name="roleDescription"
              type="text"
              placeholder="Enter Role Description"
              onChange={handleChange}
              value={values.roleDescription}
              isInvalid={touched.roleDescription && !!errors.roleDescription}
            />

            {touched.roleDescription && errors.roleDescription ? (
              <Form.Control.Feedback type="invalid">
                {errors.roleDescription as string}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>

          <div className="role-permission-container full-width">
            <div className="role-permission-header">
              <h5>Role Permissions</h5>
              <Form.Check
                inline
                label="Enable All"
                name="checkall"
                type="checkbox"
                checked={allPermissionsChecked}
                onChange={selectAllPermissions}
              />
            </div>
            <div className="role-permission-option-wrapper">
              {Object.keys(permissionsList).map((module) => (
                <Accordion key={module}>
                  <Accordion.Item eventKey={module}>
                    <Accordion.Header>
                      <Form.Check
                        inline
                        label={module}
                        name=""
                        type="checkbox"
                        onChange={(e) => setSubPermissions(e, module)}
                        checked={allChecked(module)}
                        onClick={(e) => e.stopPropagation()} // Prevent event propagation
                      />
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="role-permission-opt-container">
                        {permissionsList[module].map((permission) => (
                          <div
                            className="role-permission-opt-item"
                            key={permission.id}
                          >
                            <Form.Check
                              inline
                              label={permission.name}
                              name=""
                              type="checkbox"
                              checked={permission.isChecked}
                              onChange={(e) =>
                                setIndiPermission(
                                  e.target.checked,
                                  permission,
                                  module,
                                )
                              }
                              onClick={(e) => e.stopPropagation()} // Prevent event propagation
                            />
                          </div>
                        ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </div>
          </div>
          <Form.Group className="full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => navigate("/roles-permissions")}
            >
              Cancel
            </Button>
            <Button type="submit">{isEdit ? "Update" : "Add"}</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default AddRole;
