import Form from "react-bootstrap/Form";
import "./addorderingproduct.scss";
import Select from "react-select";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useOPs from "../../../Hooks/useOP";
import { useLocation, useNavigate } from "react-router-dom";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import Loading from "../../../components/LoadingPage/Loading";
import { useEffect, useState } from "react";
import { selectedOP } from "../../../redux/orderingProduct/OPSlice";
import { DropDownValues } from "../../../utils/types";
import useUnits from "../../../Hooks/useUnits";
import useVendors from "../../../Hooks/useVendors";
import { OPPayload } from "../../../redux/orderingProduct/OPApiSlice";
import { Unit } from "../../../redux/units/unitsSlice";
import { Vendor } from "../../../redux/vendor/vendorSlice";
import { CharacterLength } from "../../../Hooks/constants";

const AddOrderingProduct = () => {
  const [unitOptions, setUnitOptions] = useState<DropDownValues[]>([]);
  const [vendorOptions, setVendorOptions] = useState<DropDownValues[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const masterUrl = "/ordering-product-master";
  const { createOPAPI, createOPLoading } = useOPs();
  const { editOPAPI, editOPLoading } = useOPs();
  const [isEdit, setIsEdit] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const selectedOPData = useAppSelector(selectedOP);
  const { getUnitsAPI, getUnitsLoading } = useUnits();
  const { getVendorsAPI, getVendorsLoading } = useVendors();
  const [dynamicVal, setDynamicVal] = useState<OPPayload>({
    productNo: "",
    productDescription: "",
    unitId: "",
    defaultVendorId: null,
    purchaseUOM: "",
    conversionFactor: 1,
    externalProductNo: "",
    isActive: true,
    createdBy: currentUserId,
  });
  useEffect(() => {
    getUnitsAPI({ searchText: "", includeDeleted: false })
      .then((res: Unit[]) => {
        setUnitOptions(
          res?.map((option: Unit) => {
            return {
              value: option.id,
              label: option.unitName || "NA",
            };
          }),
        );
      })
      .catch();
    getVendorsAPI({ searchText: "", includeDeleted: false })
      .then((res: Vendor[]) => {
        setVendorOptions(
          res?.map((option: Vendor) => {
            return {
              value: option.id,
              label: option.name || "NA",
            };
          }),
        );
      })
      .catch();
    if (location.pathname === "/edit-ordering-product") {
      setIsEdit(true);
      if (selectedOPData?.id) {
        setDynamicVal({
          productNo: selectedOPData.productNo,
          productDescription: selectedOPData.productDescription,
          unitId: selectedOPData.unitId,
          defaultVendorId: selectedOPData.defaultVendorId,
          purchaseUOM: selectedOPData.purchaseUOM,
          conversionFactor: selectedOPData.conversionFactor,
          externalProductNo: selectedOPData.externalProductNo,
          isActive: selectedOPData.isActive,
          createdBy: currentUserId,
        });
      } else {
        navigate(masterUrl);
      }
    }
  }, []);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: dynamicVal,
    validationSchema: Yup.object({
      productNo: Yup.string()
        .required("Product No is required")
        .max(
          CharacterLength.sevenFive,
          "Product Number should not be more than 75 characters",
        ),
      unitId: Yup.string().required("Inventory Unit is required"),
      productDescription: Yup.string()
        .required("Product Name is required")
        .max(
          CharacterLength.twoFiveSix,
          "Product Name should not be more than 256 characters",
        ),
      externalProductNo: Yup.string()
        .nullable()
        .max(
          CharacterLength.sevenFive,
          "Vendor Product Number should not be more than 75 characters",
        ),
      purchaseUOM: Yup.string()
        .nullable()
        .max(
          CharacterLength.sevenFive,
          "Purchase UOM should not be more than 75 characters",
        ),
      conversionFactor: Yup.number()
        .min(1, "Conversion Factor must be a number greater than 0")
        .required("Conversion Factor is required"),
    }),
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        if (isEdit) {
          await editOPAPI({ ...values, id: selectedOPData.id });
        } else {
          await createOPAPI(values);
        }
        navigate(masterUrl);
      } catch (err) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  return (
    <div className="add-ordering-product-wrapper">
      {(createOPLoading ||
        editOPLoading ||
        getUnitsLoading ||
        getVendorsLoading) && <Loading />}
      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} Ordering Product</h2>
      </div>
      <div className="add-ordering-product-box">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Label>
              Product No<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Product No"
              id="productNo"
              name="productNo"
              onChange={handleChange}
              value={values.productNo}
              isInvalid={touched.productNo && !!errors.productNo}
            />
            {touched.productNo && errors.productNo ? (
              <Form.Control.Feedback type="invalid">
                {errors.productNo}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Product Name<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Product Name"
              id="productDescription"
              name="productDescription"
              onChange={handleChange}
              value={values.productDescription}
              isInvalid={
                touched.productDescription && !!errors.productDescription
              }
            />
            {touched.productDescription && errors.productDescription ? (
              <Form.Control.Feedback type="invalid">
                {errors.productDescription}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Inventory Unit<sup>*</sup>
            </Form.Label>
            <Select
              classNamePrefix="react-select"
              options={unitOptions}
              id="unitId"
              name="unitId"
              value={unitOptions?.find(
                (option: DropDownValues) => option.value === values.unitId,
              )}
              onChange={(option: any) => handleChange("unitId")(option.value)}
              className={`${
                touched.unitId && !!errors.unitId ? "is-invalid-border" : ""
              } react-select-container `}
            />
            {touched.unitId && errors.unitId && (
              <Form.Control.Feedback type="invalid">
                {errors.unitId}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Default Vendor</Form.Label>
            <Select
              classNamePrefix="react-select"
              options={vendorOptions}
              id="defaultVendorId"
              name="defaultVendorId"
              value={vendorOptions?.find(
                (option: DropDownValues) =>
                  option.value === values.defaultVendorId,
              )}
              onChange={(option: any) =>
                handleChange("defaultVendorId")(option.value)
              }
              className={`${
                touched.defaultVendorId && !!errors.defaultVendorId
                  ? "is-invalid-border"
                  : ""
              } react-select-container `}
            />
            {touched.defaultVendorId && errors.defaultVendorId && (
              <Form.Control.Feedback type="invalid">
                {errors.defaultVendorId}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Purchase UOM</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Purchase UOM"
              id="purchaseUOM"
              name="purchaseUOM"
              onChange={handleChange}
              value={values.purchaseUOM}
              isInvalid={touched.purchaseUOM && !!errors.purchaseUOM}
            />
            {touched.purchaseUOM && errors.purchaseUOM ? (
              <Form.Control.Feedback type="invalid">
                {errors.purchaseUOM}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Conversion Factor</Form.Label>
            <Form.Control
              type="number"
              min={1}
              placeholder="Enter Conversion Factor"
              id="conversionFactor"
              name="conversionFactor"
              onChange={handleChange}
              value={values.conversionFactor}
              isInvalid={touched.conversionFactor && !!errors.conversionFactor}
            />
            {touched.conversionFactor && errors.conversionFactor ? (
              <Form.Control.Feedback type="invalid">
                {errors.conversionFactor}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Vendor Product No</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Vendor Product No"
              id="externalProductNo"
              name="externalProductNo"
              onChange={handleChange}
              value={values.externalProductNo}
              isInvalid={
                touched.externalProductNo && !!errors.externalProductNo
              }
            />
            {touched.externalProductNo && errors.externalProductNo ? (
              <Form.Control.Feedback type="invalid">
                {errors.externalProductNo}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="Active"
                type="checkbox"
                id="isActive"
                name="isActive"
                onChange={handleChange}
                checked={values.isActive}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-4 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => navigate(masterUrl)}
            >
              Cancel
            </Button>
            <Button type="submit">{isEdit ? "Update" : "Add"}</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default AddOrderingProduct;
