import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface OrderingProduct {
  productNo: string;
  productDescription: string;
  unitId: string;
  unit: Unit;
  defaultVendorId: string;
  vendorMaster: VendorMaster;
  purchaseUOM: string;
  conversionFactor: number;
  externalProductNo: string;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: string;
  createdBy: string;
  updatedBy?: string;
}

export interface Unit {
  unitCode: string;
  unitName: string;
  inventoryUnit: boolean;
  emrUnit: boolean;
  orderingUnit: boolean;
  isActiveInventoryUnit: boolean;
  isActiveEMRUnit: boolean;
  isActiveOrderingUnit: boolean;
  sortOrder: number;
  deletedBy?: string;
  deleted?: boolean;
  deletedOnUtc?: string;
  id: string;
  isActive: boolean;
  createdOnUtc?: string;
  updatedOnUtc?: string;
  createdBy: string;
  updatedBy: string;
}

export interface VendorMaster {
  name: string;
  address: string;
  address2: string;
  state: string;
  city: string;
  zipCode: string;
  email: string;
  phone: string;
  contactPerson: string;
  id: string;
  isActive: boolean;
  createdOnUtc?: string;
  updatedOnUtc?: string;
  createdBy?: string;
  updatedBy?: string;
}
export interface OPData {
  selectedOP: OrderingProduct;
}
const storedOP = localStorage.getItem("selectedOP");
const initialState: OPData = {
  selectedOP: storedOP ? JSON.parse(storedOP) : null,
};

const OPSlice = createSlice({
  name: "OP",
  initialState,
  reducers: {
    setSelectedOP: (state, action: PayloadAction<OrderingProduct>) => {
      state.selectedOP = action.payload;
      localStorage.setItem("selectedOP", JSON.stringify(action.payload));
    },
  },
});
export const { setSelectedOP } = OPSlice.actions;
export default OPSlice.reducer;
export const selectedOP = (state: RootState) => state.OP.selectedOP;
