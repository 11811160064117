import "./warningitemprofile.scss";
import WarningItemProfileHeader from "../../../components/WarningItemProfileHeader/WarningItemProfileHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useItemWarning from "../../../Hooks/useItemWarning";
import { ItemWarning } from "../../../redux/itemWarning/itemWarningSlice";
import Loading from "../../../components/LoadingPage/Loading";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import { toggleClass } from "../../../utils/commonUtils";
import { DuplicateItemModel } from "../WarningItemListing/popUpModels/duplicateItemModel";
const WarningItemProfile = () => {
  const { id } = useParams();
  const [itemWarningDetails, setItemWarningDetails] = useState<ItemWarning>();
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const { getItemWarningByIdAPI, getItemWarningByIdLoading } = useItemWarning();
  const {
    deleteItemWarningAPI,
    deleteItemWarningLoading,
    duplicateItemWarningAPI,
    duplicateItemWarningLoading,
  } = useItemWarning();

  const currentUserId = useAppSelector(selectCurrentUserId);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    getItemWarningByIdAPI(id ?? "").then((res: ItemWarning) => {
      if (res) {
        setItemWarningDetails(res);
      }
    });
  }, []);
  const deleteItemWarning = async () => {
    await deleteItemWarningAPI({
      id: itemWarningDetails?.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    navigate("/item-warning-master");
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const duplicateType = async () => {
    await duplicateItemWarningAPI({
      id: itemWarningDetails?.id,
      updatedBy: currentUserId ?? "",
    });
    navigate("/item-warning-master");
  };
  const toggleDuplicateModal = () => {
    toggleClass();
    setShowDuplicateModal(!showDuplicateModal);
  };
  return (
    <div className="profile-page-wrapper">
      {getItemWarningByIdLoading ||
      deleteItemWarningLoading ||
      duplicateItemWarningLoading ? (
        <Loading />
      ) : null}
      <WarningItemProfileHeader
        title={itemWarningDetails?.itemCode ?? "-"}
        id={id}
        toggleModal={toggleModal}
        duplicateType={toggleDuplicateModal}
      />
      <div className="profile-detail-box-wrapper">
        <div className="profile-detail-box">
          <div className="profile-info-box">
            <label>Item Code</label>
            <p>{itemWarningDetails?.itemCode ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Warning Type</label>
            <p>{itemWarningDetails?.warningType ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Active</label>
            <p>{itemWarningDetails?.isActive ? "Yes" : "No"}</p>
          </div>
          <div className="profile-info-box full-width">
            <label>Warning</label>
            <p>{itemWarningDetails?.warning ?? "-"}</p>
          </div>
        </div>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteItemWarning}
        recordName={itemWarningDetails?.itemCode || ""}
        modelName={`item`}
      />
      <DuplicateItemModel
        selectedItem={itemWarningDetails}
        toggleDuplicateModal={toggleDuplicateModal}
        showModal={showDuplicateModal}
        handleDuplicate={duplicateType}
      />
    </div>
  );
};

export default WarningItemProfile;
