import { getErrorMessage } from "../utils/commonUtils";
import { toast } from "react-toastify";
import {
  useFetchDashboardAltersMutation,
  useFetchInventoryValueByLocationMutation,
  useFetchInventoryValueMutation,
  useFetchInventoryValuesByTypeMutation,
  useFetchTopTransactionItemsMutation,
  useGetUserCountMutation,
} from "../redux/dashboard/dashboardApiSlice";

export default function useDashboard() {
  const [getUserCount, { isLoading: getUserCountLoading }] =
    useGetUserCountMutation();
  const [fetchInventoryValue, { isLoading: inventoryValueLoading }] =
    useFetchInventoryValueMutation();
  const [fetchDashboardAlters, { isLoading: dashboardAlertsLoading }] =
    useFetchDashboardAltersMutation();
  const [fetchTopTransactionItems, { isLoading: topTransactionLoading }] =
    useFetchTopTransactionItemsMutation();
  const [
    fetchInventoryValuesByType,
    { isLoading: inventoryValuesByTypeLoading },
  ] = useFetchInventoryValuesByTypeMutation();
  const [
    fetchInventoryValuesByLocation,
    { isLoading: inventoryValuesByLocationLoading },
  ] = useFetchInventoryValueByLocationMutation();

  const getUserCountAPI = async () => {
    try {
      const response: any = await getUserCount({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const getInventoryValue = async () => {
    try {
      const response = await fetchInventoryValue({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const getDashboardAlerts = async () => {
    try {
      const response = await fetchDashboardAlters({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const getTopTransactionItems = async () => {
    try {
      const response = await fetchTopTransactionItems({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const getInventoryValuesByType = async () => {
    try {
      const response = await fetchInventoryValuesByType({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const getInventoryValueByLocation = async () => {
    try {
      const response = await fetchInventoryValuesByLocation({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  return {
    getUserCountAPI,
    getUserCountLoading,
    getInventoryValue,
    inventoryValueLoading,
    getDashboardAlerts,
    dashboardAlertsLoading,
    getTopTransactionItems,
    topTransactionLoading,
    getInventoryValuesByType,
    inventoryValuesByTypeLoading,
    inventoryValuesByLocationLoading,
    getInventoryValueByLocation,
  };
}
