import CustomModal from "../../../../components/customModal/CustomModal";
import duplicateitemmodalimge from "../../../../assets/images/duplicate-item-modal-image.svg";
import { Form, Button } from "react-bootstrap";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useEffect, useState } from "react";
import useInventory from "../../../../Hooks/useInventory";
import React from "react";
export const DuplicateModel = ({
  showModal,
  toggleDuplicateModal,
  duplicateProduct,
  currentProduct,
}: any) => {
  const { getNewBarcodeAPI } = useInventory();
  const [newBarcode, setNewBarcode] = useState("");
  useEffect(() => {
    if (showModal) {
      getNewBarcodeAPI().then((res: any) => setNewBarcode(res));
    } else {
      setNewBarcode("");
    }
  }, [showModal]);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: { rxSRXId: newBarcode },
    validationSchema: Yup.object({
      rxSRXId: Yup.string().required("Barcode is Required"),
    }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        duplicateProduct(values.rxSRXId);
      } catch (err: any) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  return (
    <>
      {showModal && (
        <CustomModal
          title=""
          content={
            <>
              <div className="modal--content--wrapper">
                <img src={duplicateitemmodalimge} alt="" />
                <h5>
                  Are you sure you want to <br />
                  duplicate this product '{currentProduct.productName}'?
                </h5>
                <div className="modal--inputbox">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-4">
                      <Form.Label>Barcode</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Barcode"
                        id="rxSRXId"
                        name="rxSRXId"
                        onChange={handleChange}
                        value={values.rxSRXId}
                        isInvalid={touched.rxSRXId && !!errors.rxSRXId}
                      />
                      {touched.rxSRXId && errors.rxSRXId ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.rxSRXId}
                        </Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="full-width text-end">
                      <Button
                        onClick={toggleDuplicateModal}
                        style={{ marginRight: "10px" }}
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>
                      <Button type="submit" variant="primary">
                        Duplicate
                      </Button>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </>
          }
          onClose={toggleDuplicateModal}
        />
      )}
    </>
  );
};
