import ReportHeader from "../../../components/reportHeader/ReportHeader";
import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import { Table } from "react-bootstrap";

const LotSearchPatients = () => {
  return (
    <div className="report-content-wrapper">
      <ReportHeader
        reportTitle="Lot Search Results (Patients)"
        reportDate="04 April 2024"
      />
      <div className="report-filter-wrapper">
        <div className="report-filter-item-wrapper">
          <div className="filter-item-box">
            <label className="form-label">NDC</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter NDC"
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">Lot</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter lot no"
            />
          </div>
          <div className="filter-item-box clear-all-box">
            <button type="button" className="clear-filter-btn">
              Clear All
            </button>
          </div>
        </div>
        <div className="report-export-box">
          <button type="button" className="report-export-btn">
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
            Export
          </button>
        </div>
      </div>
      <div className="filter-detail-wrapper">
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-title">
            <h4>Lot Search Results (Patients)</h4>
          </div>
          <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>Patient ID</th>
                  <th>Name</th>
                  <th>DOB</th>
                  <th>Gender</th>
                  <th>Date</th>
                  <th>User</th>
                  <th>RXID</th>
                  <th>RX Name</th>
                  <th>NDC Code</th>
                  <th>LOT NO</th>
                  <th>EXP. DATE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>80-011</td>
                  <td>John Smith</td>
                  <td>12/27/1951</td>
                  <td>M</td>
                  <td>05/03/21 15:21:00</td>
                  <td>SysAdmin</td>
                  <td>RX2020092211464026</td>
                  <td>01A VARIVAX</td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>08/15/2026</td>
                </tr>
                <tr>
                  <td>80-011</td>
                  <td>John Smith</td>
                  <td>12/27/1951</td>
                  <td>M</td>
                  <td>05/03/21 15:21:00</td>
                  <td>SysAdmin</td>
                  <td>RX2020092211464026</td>
                  <td>01A VARIVAX</td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>08/15/2026</td>
                </tr>
                <tr>
                  <td>80-011</td>
                  <td>John Smith</td>
                  <td>12/27/1951</td>
                  <td>M</td>
                  <td>05/03/21 15:21:00</td>
                  <td>SysAdmin</td>
                  <td>RX2020092211464026</td>
                  <td>01A VARIVAX</td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>08/15/2026</td>
                </tr>
                <tr>
                  <td>80-011</td>
                  <td>John Smith</td>
                  <td>12/27/1951</td>
                  <td>M</td>
                  <td>05/03/21 15:21:00</td>
                  <td>SysAdmin</td>
                  <td>RX2020092211464026</td>
                  <td>01A VARIVAX</td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>08/15/2026</td>
                </tr>
                <tr>
                  <td>80-011</td>
                  <td>John Smith</td>
                  <td>12/27/1951</td>
                  <td>M</td>
                  <td>05/03/21 15:21:00</td>
                  <td>SysAdmin</td>
                  <td>RX2020092211464026</td>
                  <td>01A VARIVAX</td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>08/15/2026</td>
                </tr>
                <tr>
                  <td>80-011</td>
                  <td>John Smith</td>
                  <td>12/27/1951</td>
                  <td>M</td>
                  <td>05/03/21 15:21:00</td>
                  <td>SysAdmin</td>
                  <td>RX2020092211464026</td>
                  <td>01A VARIVAX</td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>08/15/2026</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LotSearchPatients;
