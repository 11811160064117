import CustomModal from "../../../../../components/customModal/CustomModal";
import { Form, Button } from "react-bootstrap";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useEffect, useState } from "react";

export const CreateBarcodeModel = ({
  selectedItem,
  selectCurrentUserId,
  toggleCreateBarcodeModal,
  createBarcode,
  newBarcode,
}: any) => {
  const initialFormVal = {
    isActive: true,
    createdBy: selectCurrentUserId,
    rxId: selectedItem.id,
    rxSRXId: newBarcode as string,
    isDefault: false,
    serialNo: "",
  };
  const [formInitialValues, setFormInitialValues] = useState(initialFormVal);

  useEffect(() => {
    setFormInitialValues({ ...formInitialValues, rxSRXId: newBarcode });
  }, [newBarcode]);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      rxSRXId: Yup.string().required("Barcode is Required"),
    }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        createBarcode(values);
      } catch (err: any) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  return (
    <CustomModal
      title="Add New Barcode"
      content={
        <div className="modal--content--wrapper">
          <div className="modal--inputbox">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4">
                <Form.Label>Barcode</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Reference Info"
                  id="rxSRXId"
                  name="rxSRXId"
                  onChange={handleChange}
                  value={values.rxSRXId}
                  isInvalid={touched.rxSRXId && !!errors.rxSRXId}
                />
                {touched.rxSRXId && errors.rxSRXId ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.rxSRXId}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group className="full-width text-end">
                <Button
                  onClick={toggleCreateBarcodeModal}
                  style={{ marginRight: "10px" }}
                  variant="outline-primary"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Add
                </Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      }
      onClose={toggleCreateBarcodeModal}
    />
  );
};
