import { getErrorMessage } from "../utils/commonUtils";
import {
  useCreatePatientMutation,
  useDeletePatientMutation,
  useEditPatientMutation,
  useGetPatientByIdMutation,
  useGetPatientMutation,
} from "../redux/patient/patientApiSlice";
import { toast } from "react-toastify";

export default function usePatient() {
  const [getPatient, { isLoading: getPatientLoading }] =
    useGetPatientMutation();
  const [createPatient, { isLoading: createPatientLoading }] =
    useCreatePatientMutation();
  const [editPatient, { isLoading: editPatientLoading }] =
    useEditPatientMutation();
  const [deletePatient, { isLoading: deletePatientLoading }] =
    useDeletePatientMutation();
  const [getPatientById, { isLoading: getPatientByIdLoading }] =
    useGetPatientByIdMutation();
  const getPatientAPI = async (data: any) => {
    try {
      const response: any = await getPatient(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getPatientByIdAPI = async (data: any) => {
    try {
      const response: any = await getPatientById(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const createPatientAPI = async (data: any) => {
    try {
      const response: any = await createPatient(data).unwrap();
      toast.success("Success! Patient has been Created.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const editPatientAPI = async (data: any) => {
    try {
      const response: any = await editPatient(data).unwrap();
      toast.success("Success! Patient has been Updated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const deletePatientAPI = async (data: any) => {
    try {
      const response: any = await deletePatient(data).unwrap();
      toast.success("Success! Patient has been Deleted.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    getPatientAPI,
    getPatientLoading,
    createPatientLoading,
    createPatientAPI,
    editPatientAPI,
    editPatientLoading,
    deletePatientAPI,
    deletePatientLoading,
    getPatientByIdAPI,
    getPatientByIdLoading,
  };
}
