import { Middleware } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const handleSetCredentials = (action: any) => {
  const { jwtToken, refreshToken, role, id, firstName, lastName, username } =
    action.payload;
  if (jwtToken) {
    const { exp } = jwtDecode(jwtToken);

    localStorage.setItem("exp", exp ? exp.toString() : "");
  }
  localStorage.setItem("jwtToken", jwtToken);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("roleId", role?.id ?? null);
  localStorage.setItem("roleName", role?.roleName ?? "");
  localStorage.setItem("isLoggedIn", jwtToken ? "true" : "false");
  localStorage.setItem("id", id);
  localStorage.setItem("firstName", firstName);
  localStorage.setItem("lastName", lastName);
  localStorage.setItem("username", username);
};

const handleResetAuthState = () => {
  const clientId = localStorage.getItem("clientId");
  const userName = localStorage.getItem("username");
  localStorage.clear();
  sessionStorage.clear();
  localStorage.setItem("clientId", clientId ?? "");
  localStorage.setItem("username", userName ?? "");
};

const handleSetUserId = (action: any) => {
  const { id } = action.payload;
  localStorage.setItem("id", id);
};

const handleResetUserId = () => {
  localStorage.removeItem("id");
};

const handleSetUserProfile = (action: any) => {
  const { phone } = action.payload;
  localStorage.setItem("phone", phone);
};

const actionHandlers: any = {
  "auth/setCredentials": handleSetCredentials,
  "auth/resetAuthState": handleResetAuthState,
  "auth/setUserId": handleSetUserId,
  "auth/resetUserId": handleResetUserId,
  "user/setUserProfile": handleSetUserProfile,
};

const storageMiddleware: Middleware = () => (next) => (action: any) => {
  const handler = actionHandlers[action?.type];
  if (handler) {
    handler(action);
  }
  return next(action);
};

export default storageMiddleware;
