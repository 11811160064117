export const predictions = [
  {
    name: "meningococcal_2024-05-09_08:33:08",
    forecast_plot: {
      data: [
        {
          marker: {
            color: "black",
            size: 4,
          },
          mode: "markers",
          name: "Actual",
          x: [
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
          ],
          y: [
            6, 53, 34, 46, 37, 55, 31, 60, 61, 59, 57, 52, 80, 54, 44, 57, 33,
            72, 65, 70, 69, 99, 67, 67, 66, 53, 65, 33, 49, 44, 41, 67, 71, 93,
            56, 51, 57, 47, 50, 43, 33, 30, 50, 42, 66, 84, 72, 63, 60, 67, 59,
            47, 42, 67, 64, 56, 69, 94, 72, 66, 63, 61, 43, 43, 54, 47, 48, 56,
            75, 110, 53, 39, 46, 35, 29, 49, 57, 62, 74, 69, 64, 95,
          ],
          type: "scatter",
        },
        {
          legendgroup: "Trend Line",
          line: {
            color: "#0072B2",
            width: 2,
          },
          mode: "lines",
          name: "Trend Line",
          x: [
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
          ],
          y: [
            42.673853195727894, 58.326832481610175, 44.20903646051403,
            50.064737335357215, 41.45104050339722, 54.24667122475445,
            54.47827899615879, 58.05423730625951, 69.55248100998,
            87.28616569453506, 65.51492277011695, 57.58910692814892,
            51.475178076977144, 54.13039810909607, 45.84137164082382,
            46.467761115585546, 44.40812392342803, 53.74300233989448,
            52.78087112672604, 60.77167022312596, 67.15593312966773,
            91.91405642499538, 60.16492440639464, 54.94028424259664,
            60.289578690032386, 49.83654898106816, 47.45607694586887,
            42.88219582021515, 47.39602726926355, 53.19773967446792,
            51.117939688349615, 63.491793419238434, 64.76581961545637,
            96.47295109860646, 54.75443778759202, 52.464272916379585,
            69.1085625360443, 45.44764113012975, 49.05335508032354,
            39.31145153107836, 38.527390689989076, 54.70861779357759,
            56.20891020207325, 55.34182175580402, 71.95473292508014,
            82.59232916152013, 70.79846014791423, 60.40866772395748,
            42.6738531957952, 58.32683248162789, 44.209036460502105,
            50.06473733536851, 41.45104050338182, 54.24667122476822,
            54.4782789961994, 58.05423730623442, 69.55248100996128,
            87.2861656945416, 65.51492277014346, 57.58910692815842,
            51.4751780769463, 54.130398109097, 45.84137164084535,
            46.46776111561141, 44.40812392339695, 53.74300233990199,
            52.780871126760815, 60.771670223172954, 67.15593312962577,
            91.91405642501337, 60.16492440638211, 54.94028424258449,
            60.28957869004025, 49.83654898107175, 47.456076945873335,
            42.8821958202399, 47.39602726924748, 53.1977396744563,
            51.117939688354824, 63.49179341921321, 64.76581961541439,
            96.47295109860259,
          ],
          type: "scatter",
        },
        {
          legendgroup: "Predicted",
          line: {
            color: "#33cc33",
            width: 2,
          },
          mode: "lines",
          name: "Predicted",
          x: [
            "2023-07-31T00:00:00",
            "2023-08-31T00:00:00",
            "2023-09-30T00:00:00",
            "2023-10-31T00:00:00",
            "2023-11-30T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-31T00:00:00",
            "2024-02-29T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-30T00:00:00",
            "2024-05-31T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-31T00:00:00",
            "2024-08-31T00:00:00",
            "2024-09-30T00:00:00",
            "2024-10-31T00:00:00",
            "2024-11-30T00:00:00",
            "2024-12-31T00:00:00",
            "2025-01-31T00:00:00",
          ],
          y: [
            96.47295109860259, 54.75443778758849, 52.46427291636588,
            69.1085625360842, 45.44764113013896, 49.053355080304755,
            39.31145153108264, 38.5273906899935, 54.70861779356857,
            56.20891020208627, 55.34182175583875, 71.95473292504471,
            82.59232916154924, 70.79846014788735, 60.40866772396463,
            42.673853195765986, 58.32683248156484, 44.209036460509495,
            50.064737335364484,
          ],
          type: "scatter",
        },
        {
          hoverinfo: "skip",
          legendgroup: "Trend Line",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
          ],
          y: [
            20.929059309254352, 34.69375978011304, 22.767283626931235,
            28.356980415431654, 20.1730931080729, 32.22770503502256,
            31.268579686557636, 35.574649070545114, 48.46191255668265,
            65.53589428936226, 42.95584483108482, 35.48204437467157,
            29.543351465969792, 33.25865798797032, 23.686632647865327,
            26.18462564905319, 22.089009656866068, 30.316259330298507,
            32.85823209082046, 40.20048687904869, 42.54362637297787,
            69.24636265579893, 39.301034978465, 33.870831548977414,
            41.21942663982891, 28.57131328382414, 25.449693431402466,
            22.311157876345565, 26.66196475776495, 30.334761018476744,
            30.566050773655952, 41.826298496753104, 43.45898339259592,
            72.86342096799434, 33.83144305688358, 28.854023595689483,
            45.649492523312595, 21.78409865669426, 28.42437107548916,
            18.260491571620502, 17.661224135041273, 33.486958494641705,
            34.42411147287479, 31.950270464959928, 49.220836985618774,
            59.882232349742075, 50.261451553024585, 37.941025366564844,
            20.71312417107413, 35.892124046481975, 22.470846544691906,
            27.370766475053127, 19.657447080308945, 32.35769376012185,
            30.81297849173541, 37.65146997417482, 45.70690470745029,
            64.41555930928718, 43.59419220695836, 35.77962129140476,
            29.790220233362067, 33.17174182299977, 25.24570283654774,
            24.714033571807644, 23.400085743210745, 32.253273859050516,
            31.89810688463022, 39.25538390891021, 45.98165233335211,
            70.21120759197208, 38.135827228553914, 32.15780893666079,
            38.388584260924375, 30.06728225925817, 25.65461117271749,
            20.96932908052001, 26.98706125195131, 29.76013178002809,
            29.460484089991173, 41.03660572715983, 43.32748214748879,
            74.03155951166863,
          ],
          type: "scatter",
        },
        {
          fill: "tonexty",
          fillcolor: "rgba(0, 114, 178, 0.2)",
          hoverinfo: "skip",
          legendgroup: "Trend Line",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
          ],
          y: [
            64.63957001895191, 80.57943659335038, 65.15756117266604,
            69.91898903300418, 63.12392387457033, 75.0292261399962,
            78.00017926236488, 82.12033000860355, 91.16366980997691,
            109.3504164147816, 86.3620367865807, 79.72008447839315,
            73.22269469762972, 75.56987085368884, 67.78361817011233,
            67.85646798207634, 65.36630204447408, 76.41864645659264,
            74.95177851847797, 81.83901252808704, 90.27717038177704,
            113.01442290384631, 80.71626855416675, 76.13382911247395,
            80.84360646044469, 71.49142195324698, 68.94887101221784,
            64.68452792693382, 68.84721200406709, 75.82514662158901,
            72.85034177127235, 85.34991670434583, 87.04169831905692,
            118.29798952747329, 77.79459566643241, 76.21970607308631,
            91.34473076673963, 66.19532144715102, 71.11143456258209,
            61.02850748756908, 60.37585659587891, 75.56305389665894,
            78.55671914214346, 76.55110497301723, 91.64712528831471,
            104.02993360912892, 93.43873926378058, 81.94725653150972,
            64.97789207891736, 80.85746928574386, 65.29592348210453,
            70.75378627976549, 64.9280690735081, 75.77023956652303,
            76.24115127694023, 79.34414355759421, 91.53926298671502,
            107.20658535441096, 86.96030494893552, 80.61335560113062,
            74.59864341472856, 75.55991648111919, 65.13924805085998,
            68.67854423957081, 66.55692842398317, 75.83838896007504,
            73.52058148255418, 81.30854917186184, 89.44481032588014,
            113.66207660508734, 80.89784031819391, 76.61369868176105,
            82.27342809209307, 70.48100877856224, 69.54719502226432,
            64.68400890794882, 68.10035867602245, 76.40793972204472,
            73.4979518723372, 84.65819570902701, 85.58202386659103,
            117.88476163816067,
          ],
          type: "scatter",
        },
        {
          hoverinfo: "skip",
          legendgroup: "Predicted",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2023-07-31T00:00:00",
            "2023-08-31T00:00:00",
            "2023-09-30T00:00:00",
            "2023-10-31T00:00:00",
            "2023-11-30T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-31T00:00:00",
            "2024-02-29T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-30T00:00:00",
            "2024-05-31T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-31T00:00:00",
            "2024-08-31T00:00:00",
            "2024-09-30T00:00:00",
            "2024-10-31T00:00:00",
            "2024-11-30T00:00:00",
            "2024-12-31T00:00:00",
            "2025-01-31T00:00:00",
          ],
          y: [
            74.03155951166863, 33.482357426726566, 31.419616787383337,
            45.42893561167368, 22.58483360898053, 26.77443143848819,
            16.85437041437535, 18.32115812484802, 32.44809669529222,
            33.97179512838861, 32.772201999541885, 49.137144680983845,
            60.99026395265531, 48.53877974232139, 37.66322890310829,
            20.026868317873674, 36.258676758724526, 24.069096303646944,
            28.436587832335693,
          ],
          type: "scatter",
        },
        {
          fill: "tonexty",
          fillcolor: "rgba(51, 204, 51, 0.2)",
          hoverinfo: "skip",
          legendgroup: "Predicted",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2023-07-31T00:00:00",
            "2023-08-31T00:00:00",
            "2023-09-30T00:00:00",
            "2023-10-31T00:00:00",
            "2023-11-30T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-31T00:00:00",
            "2024-02-29T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-30T00:00:00",
            "2024-05-31T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-31T00:00:00",
            "2024-08-31T00:00:00",
            "2024-09-30T00:00:00",
            "2024-10-31T00:00:00",
            "2024-11-30T00:00:00",
            "2024-12-31T00:00:00",
            "2025-01-31T00:00:00",
          ],
          y: [
            117.88476163816067, 75.08940158617477, 73.69898578707308,
            90.04848811721129, 67.66663203086487, 70.765671177738,
            60.958018056006026, 60.61324910695687, 77.96071721113533,
            76.88569013095272, 78.8103126048459, 95.28688210084324,
            104.52039904901741, 92.26625462495292, 80.70446052953011,
            64.57039112049907, 81.18474654606977, 65.40529110970635,
            71.11474949576619,
          ],
          type: "scatter",
        },
      ],
      layout: {
        showlegend: true,
        width: 850,
        height: 600,
        yaxis: {
          title: "y",
        },
        xaxis: {
          title: "ds",
          type: "date",
          rangeselector: {
            buttons: [
              {
                count: 7,
                label: "1w",
                step: "day",
                stepmode: "backward",
              },
              {
                count: 1,
                label: "1m",
                step: "month",
                stepmode: "backward",
              },
              {
                count: 6,
                label: "6m",
                step: "month",
                stepmode: "backward",
              },
              {
                count: 1,
                label: "1y",
                step: "year",
                stepmode: "backward",
              },
              {
                step: "all",
              },
            ],
          },
          rangeslider: {
            visible: true,
          },
        },
      },
    },
    forecast_components: {
      data: {
        trend: {
          traces: [
            {
              line: {
                color: "#0072B2",
                width: 2,
              },
              mode: "lines",
              name: "trend",
              x: [
                "2016-10-31T00:00:00",
                "2016-11-30T00:00:00",
                "2016-12-31T00:00:00",
                "2017-01-31T00:00:00",
                "2017-02-28T00:00:00",
                "2017-03-31T00:00:00",
                "2017-04-30T00:00:00",
                "2017-05-31T00:00:00",
                "2017-06-30T00:00:00",
                "2017-07-31T00:00:00",
                "2017-08-31T00:00:00",
                "2017-09-30T00:00:00",
                "2017-10-31T00:00:00",
                "2017-11-30T00:00:00",
                "2017-12-31T00:00:00",
                "2018-01-31T00:00:00",
                "2018-02-28T00:00:00",
                "2018-03-31T00:00:00",
                "2018-04-30T00:00:00",
                "2018-05-31T00:00:00",
                "2018-06-30T00:00:00",
                "2018-07-31T00:00:00",
                "2018-08-31T00:00:00",
                "2018-09-30T00:00:00",
                "2018-10-31T00:00:00",
                "2018-11-30T00:00:00",
                "2018-12-31T00:00:00",
                "2019-01-31T00:00:00",
                "2019-02-28T00:00:00",
                "2019-03-31T00:00:00",
                "2019-04-30T00:00:00",
                "2019-05-31T00:00:00",
                "2019-06-30T00:00:00",
                "2019-07-31T00:00:00",
                "2019-08-31T00:00:00",
                "2019-09-30T00:00:00",
                "2019-10-31T00:00:00",
                "2019-11-30T00:00:00",
                "2019-12-31T00:00:00",
                "2020-01-31T00:00:00",
                "2020-02-29T00:00:00",
                "2020-03-31T00:00:00",
                "2020-04-30T00:00:00",
                "2020-05-31T00:00:00",
                "2020-06-30T00:00:00",
                "2020-07-31T00:00:00",
                "2020-08-31T00:00:00",
                "2020-09-30T00:00:00",
                "2020-10-31T00:00:00",
                "2020-11-30T00:00:00",
                "2020-12-31T00:00:00",
                "2021-01-31T00:00:00",
                "2021-02-28T00:00:00",
                "2021-03-31T00:00:00",
                "2021-04-30T00:00:00",
                "2021-05-31T00:00:00",
                "2021-06-30T00:00:00",
                "2021-07-31T00:00:00",
                "2021-08-31T00:00:00",
                "2021-09-30T00:00:00",
                "2021-10-31T00:00:00",
                "2021-11-30T00:00:00",
                "2021-12-31T00:00:00",
                "2022-01-31T00:00:00",
                "2022-02-28T00:00:00",
                "2022-03-31T00:00:00",
                "2022-04-30T00:00:00",
                "2022-05-31T00:00:00",
                "2022-06-30T00:00:00",
                "2022-07-31T00:00:00",
                "2022-08-31T00:00:00",
                "2022-09-30T00:00:00",
                "2022-10-31T00:00:00",
                "2022-11-30T00:00:00",
                "2022-12-31T00:00:00",
                "2023-01-31T00:00:00",
                "2023-02-28T00:00:00",
                "2023-03-31T00:00:00",
                "2023-04-30T00:00:00",
                "2023-05-31T00:00:00",
                "2023-06-30T00:00:00",
                "2023-07-31T00:00:00",
                "2023-08-31T00:00:00",
                "2023-09-30T00:00:00",
                "2023-10-31T00:00:00",
                "2023-11-30T00:00:00",
                "2023-12-31T00:00:00",
                "2024-01-31T00:00:00",
                "2024-02-29T00:00:00",
                "2024-03-31T00:00:00",
                "2024-04-30T00:00:00",
                "2024-05-31T00:00:00",
                "2024-06-30T00:00:00",
                "2024-07-31T00:00:00",
                "2024-08-31T00:00:00",
                "2024-09-30T00:00:00",
                "2024-10-31T00:00:00",
                "2024-11-30T00:00:00",
                "2024-12-31T00:00:00",
                "2025-01-31T00:00:00",
              ],
              y: [
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108, 56.52108, 56.52108,
                56.52108, 56.52108, 56.52108, 56.52108,
              ],
              type: "scatter",
            },
          ],
          xaxis: {
            range: ["2016-06-02T07:12:00", "2025-06-30T16:48:00"],
            type: "date",
          },
          yaxis: {
            rangemode: "normal",
            title: {
              text: "trend",
            },
            zerolinecolor: "#AAA",
          },
        },
        yearly: {
          traces: [
            {
              line: {
                color: "#0072B2",
                width: 2,
              },
              mode: "lines",
              name: "yearly",
              x: [
                "2017-01-01T00:00:00",
                "2017-01-02T00:00:59.178082",
                "2017-01-03T00:01:58.356164",
                "2017-01-04T00:02:57.534246",
                "2017-01-05T00:03:56.712328",
                "2017-01-06T00:04:55.890411",
                "2017-01-07T00:05:55.068493",
                "2017-01-08T00:06:54.246575",
                "2017-01-09T00:07:53.424657",
                "2017-01-10T00:08:52.602739",
                "2017-01-11T00:09:51.780822",
                "2017-01-12T00:10:50.958904",
                "2017-01-13T00:11:50.136986",
                "2017-01-14T00:12:49.315068",
                "2017-01-15T00:13:48.493150",
                "2017-01-16T00:14:47.671232",
                "2017-01-17T00:15:46.849315",
                "2017-01-18T00:16:46.027397",
                "2017-01-19T00:17:45.205479",
                "2017-01-20T00:18:44.383561",
                "2017-01-21T00:19:43.561643",
                "2017-01-22T00:20:42.739726",
                "2017-01-23T00:21:41.917808",
                "2017-01-24T00:22:41.095890",
                "2017-01-25T00:23:40.273972",
                "2017-01-26T00:24:39.452054",
                "2017-01-27T00:25:38.630137",
                "2017-01-28T00:26:37.808219",
                "2017-01-29T00:27:36.986301",
                "2017-01-30T00:28:36.164383",
                "2017-01-31T00:29:35.342465",
                "2017-02-01T00:30:34.520547",
                "2017-02-02T00:31:33.698630",
                "2017-02-03T00:32:32.876712",
                "2017-02-04T00:33:32.054794",
                "2017-02-05T00:34:31.232876",
                "2017-02-06T00:35:30.410958",
                "2017-02-07T00:36:29.589041",
                "2017-02-08T00:37:28.767123",
                "2017-02-09T00:38:27.945205",
                "2017-02-10T00:39:27.123287",
                "2017-02-11T00:40:26.301369",
                "2017-02-12T00:41:25.479452",
                "2017-02-13T00:42:24.657534",
                "2017-02-14T00:43:23.835616",
                "2017-02-15T00:44:23.013698",
                "2017-02-16T00:45:22.191780",
                "2017-02-17T00:46:21.369862",
                "2017-02-18T00:47:20.547945",
                "2017-02-19T00:48:19.726027",
                "2017-02-20T00:49:18.904109",
                "2017-02-21T00:50:18.082191",
                "2017-02-22T00:51:17.260273",
                "2017-02-23T00:52:16.438356",
                "2017-02-24T00:53:15.616438",
                "2017-02-25T00:54:14.794520",
                "2017-02-26T00:55:13.972602",
                "2017-02-27T00:56:13.150684",
                "2017-02-28T00:57:12.328767",
                "2017-03-01T00:58:11.506849",
                "2017-03-02T00:59:10.684931",
                "2017-03-03T01:00:09.863013",
                "2017-03-04T01:01:09.041095",
                "2017-03-05T01:02:08.219177",
                "2017-03-06T01:03:07.397260",
                "2017-03-07T01:04:06.575342",
                "2017-03-08T01:05:05.753424",
                "2017-03-09T01:06:04.931506",
                "2017-03-10T01:07:04.109588",
                "2017-03-11T01:08:03.287671",
                "2017-03-12T01:09:02.465753",
                "2017-03-13T01:10:01.643835",
                "2017-03-14T01:11:00.821917",
                "2017-03-15T01:12:00",
                "2017-03-16T01:12:59.178082",
                "2017-03-17T01:13:58.356164",
                "2017-03-18T01:14:57.534246",
                "2017-03-19T01:15:56.712328",
                "2017-03-20T01:16:55.890411",
                "2017-03-21T01:17:55.068493",
                "2017-03-22T01:18:54.246575",
                "2017-03-23T01:19:53.424657",
                "2017-03-24T01:20:52.602739",
                "2017-03-25T01:21:51.780822",
                "2017-03-26T01:22:50.958904",
                "2017-03-27T01:23:50.136986",
                "2017-03-28T01:24:49.315068",
                "2017-03-29T01:25:48.493150",
                "2017-03-30T01:26:47.671232",
                "2017-03-31T01:27:46.849315",
                "2017-04-01T01:28:46.027397",
                "2017-04-02T01:29:45.205479",
                "2017-04-03T01:30:44.383561",
                "2017-04-04T01:31:43.561643",
                "2017-04-05T01:32:42.739726",
                "2017-04-06T01:33:41.917808",
                "2017-04-07T01:34:41.095890",
                "2017-04-08T01:35:40.273972",
                "2017-04-09T01:36:39.452054",
                "2017-04-10T01:37:38.630137",
                "2017-04-11T01:38:37.808219",
                "2017-04-12T01:39:36.986301",
                "2017-04-13T01:40:36.164383",
                "2017-04-14T01:41:35.342465",
                "2017-04-15T01:42:34.520547",
                "2017-04-16T01:43:33.698630",
                "2017-04-17T01:44:32.876712",
                "2017-04-18T01:45:32.054794",
                "2017-04-19T01:46:31.232876",
                "2017-04-20T01:47:30.410958",
                "2017-04-21T01:48:29.589041",
                "2017-04-22T01:49:28.767123",
                "2017-04-23T01:50:27.945205",
                "2017-04-24T01:51:27.123287",
                "2017-04-25T01:52:26.301369",
                "2017-04-26T01:53:25.479452",
                "2017-04-27T01:54:24.657534",
                "2017-04-28T01:55:23.835616",
                "2017-04-29T01:56:23.013698",
                "2017-04-30T01:57:22.191780",
                "2017-05-01T01:58:21.369862",
                "2017-05-02T01:59:20.547945",
                "2017-05-03T02:00:19.726027",
                "2017-05-04T02:01:18.904109",
                "2017-05-05T02:02:18.082191",
                "2017-05-06T02:03:17.260273",
                "2017-05-07T02:04:16.438356",
                "2017-05-08T02:05:15.616438",
                "2017-05-09T02:06:14.794520",
                "2017-05-10T02:07:13.972602",
                "2017-05-11T02:08:13.150684",
                "2017-05-12T02:09:12.328767",
                "2017-05-13T02:10:11.506849",
                "2017-05-14T02:11:10.684931",
                "2017-05-15T02:12:09.863013",
                "2017-05-16T02:13:09.041095",
                "2017-05-17T02:14:08.219177",
                "2017-05-18T02:15:07.397260",
                "2017-05-19T02:16:06.575342",
                "2017-05-20T02:17:05.753424",
                "2017-05-21T02:18:04.931506",
                "2017-05-22T02:19:04.109588",
                "2017-05-23T02:20:03.287671",
                "2017-05-24T02:21:02.465753",
                "2017-05-25T02:22:01.643835",
                "2017-05-26T02:23:00.821917",
                "2017-05-27T02:24:00",
                "2017-05-28T02:24:59.178082",
                "2017-05-29T02:25:58.356164",
                "2017-05-30T02:26:57.534246",
                "2017-05-31T02:27:56.712328",
                "2017-06-01T02:28:55.890411",
                "2017-06-02T02:29:55.068493",
                "2017-06-03T02:30:54.246575",
                "2017-06-04T02:31:53.424657",
                "2017-06-05T02:32:52.602739",
                "2017-06-06T02:33:51.780822",
                "2017-06-07T02:34:50.958904",
                "2017-06-08T02:35:50.136986",
                "2017-06-09T02:36:49.315068",
                "2017-06-10T02:37:48.493150",
                "2017-06-11T02:38:47.671232",
                "2017-06-12T02:39:46.849315",
                "2017-06-13T02:40:46.027397",
                "2017-06-14T02:41:45.205479",
                "2017-06-15T02:42:44.383561",
                "2017-06-16T02:43:43.561643",
                "2017-06-17T02:44:42.739726",
                "2017-06-18T02:45:41.917808",
                "2017-06-19T02:46:41.095890",
                "2017-06-20T02:47:40.273972",
                "2017-06-21T02:48:39.452054",
                "2017-06-22T02:49:38.630137",
                "2017-06-23T02:50:37.808219",
                "2017-06-24T02:51:36.986301",
                "2017-06-25T02:52:36.164383",
                "2017-06-26T02:53:35.342465",
                "2017-06-27T02:54:34.520547",
                "2017-06-28T02:55:33.698630",
                "2017-06-29T02:56:32.876712",
                "2017-06-30T02:57:32.054794",
                "2017-07-01T02:58:31.232876",
                "2017-07-02T02:59:30.410958",
                "2017-07-03T03:00:29.589041",
                "2017-07-04T03:01:28.767123",
                "2017-07-05T03:02:27.945205",
                "2017-07-06T03:03:27.123287",
                "2017-07-07T03:04:26.301369",
                "2017-07-08T03:05:25.479452",
                "2017-07-09T03:06:24.657534",
                "2017-07-10T03:07:23.835616",
                "2017-07-11T03:08:23.013698",
                "2017-07-12T03:09:22.191780",
                "2017-07-13T03:10:21.369862",
                "2017-07-14T03:11:20.547945",
                "2017-07-15T03:12:19.726027",
                "2017-07-16T03:13:18.904109",
                "2017-07-17T03:14:18.082191",
                "2017-07-18T03:15:17.260273",
                "2017-07-19T03:16:16.438356",
                "2017-07-20T03:17:15.616438",
                "2017-07-21T03:18:14.794520",
                "2017-07-22T03:19:13.972602",
                "2017-07-23T03:20:13.150684",
                "2017-07-24T03:21:12.328767",
                "2017-07-25T03:22:11.506849",
                "2017-07-26T03:23:10.684931",
                "2017-07-27T03:24:09.863013",
                "2017-07-28T03:25:09.041095",
                "2017-07-29T03:26:08.219177",
                "2017-07-30T03:27:07.397260",
                "2017-07-31T03:28:06.575342",
                "2017-08-01T03:29:05.753424",
                "2017-08-02T03:30:04.931506",
                "2017-08-03T03:31:04.109588",
                "2017-08-04T03:32:03.287671",
                "2017-08-05T03:33:02.465753",
                "2017-08-06T03:34:01.643835",
                "2017-08-07T03:35:00.821917",
                "2017-08-08T03:36:00",
                "2017-08-09T03:36:59.178082",
                "2017-08-10T03:37:58.356164",
                "2017-08-11T03:38:57.534246",
                "2017-08-12T03:39:56.712328",
                "2017-08-13T03:40:55.890411",
                "2017-08-14T03:41:55.068493",
                "2017-08-15T03:42:54.246575",
                "2017-08-16T03:43:53.424657",
                "2017-08-17T03:44:52.602739",
                "2017-08-18T03:45:51.780822",
                "2017-08-19T03:46:50.958904",
                "2017-08-20T03:47:50.136986",
                "2017-08-21T03:48:49.315068",
                "2017-08-22T03:49:48.493150",
                "2017-08-23T03:50:47.671232",
                "2017-08-24T03:51:46.849315",
                "2017-08-25T03:52:46.027397",
                "2017-08-26T03:53:45.205479",
                "2017-08-27T03:54:44.383561",
                "2017-08-28T03:55:43.561643",
                "2017-08-29T03:56:42.739726",
                "2017-08-30T03:57:41.917808",
                "2017-08-31T03:58:41.095890",
                "2017-09-01T03:59:40.273972",
                "2017-09-02T04:00:39.452054",
                "2017-09-03T04:01:38.630137",
                "2017-09-04T04:02:37.808219",
                "2017-09-05T04:03:36.986301",
                "2017-09-06T04:04:36.164383",
                "2017-09-07T04:05:35.342465",
                "2017-09-08T04:06:34.520547",
                "2017-09-09T04:07:33.698630",
                "2017-09-10T04:08:32.876712",
                "2017-09-11T04:09:32.054794",
                "2017-09-12T04:10:31.232876",
                "2017-09-13T04:11:30.410958",
                "2017-09-14T04:12:29.589041",
                "2017-09-15T04:13:28.767123",
                "2017-09-16T04:14:27.945205",
                "2017-09-17T04:15:27.123287",
                "2017-09-18T04:16:26.301369",
                "2017-09-19T04:17:25.479452",
                "2017-09-20T04:18:24.657534",
                "2017-09-21T04:19:23.835616",
                "2017-09-22T04:20:23.013698",
                "2017-09-23T04:21:22.191780",
                "2017-09-24T04:22:21.369862",
                "2017-09-25T04:23:20.547945",
                "2017-09-26T04:24:19.726027",
                "2017-09-27T04:25:18.904109",
                "2017-09-28T04:26:18.082191",
                "2017-09-29T04:27:17.260273",
                "2017-09-30T04:28:16.438356",
                "2017-10-01T04:29:15.616438",
                "2017-10-02T04:30:14.794520",
                "2017-10-03T04:31:13.972602",
                "2017-10-04T04:32:13.150684",
                "2017-10-05T04:33:12.328767",
                "2017-10-06T04:34:11.506849",
                "2017-10-07T04:35:10.684931",
                "2017-10-08T04:36:09.863013",
                "2017-10-09T04:37:09.041095",
                "2017-10-10T04:38:08.219177",
                "2017-10-11T04:39:07.397260",
                "2017-10-12T04:40:06.575342",
                "2017-10-13T04:41:05.753424",
                "2017-10-14T04:42:04.931506",
                "2017-10-15T04:43:04.109588",
                "2017-10-16T04:44:03.287671",
                "2017-10-17T04:45:02.465753",
                "2017-10-18T04:46:01.643835",
                "2017-10-19T04:47:00.821917",
                "2017-10-20T04:48:00",
                "2017-10-21T04:48:59.178082",
                "2017-10-22T04:49:58.356164",
                "2017-10-23T04:50:57.534246",
                "2017-10-24T04:51:56.712328",
                "2017-10-25T04:52:55.890411",
                "2017-10-26T04:53:55.068493",
                "2017-10-27T04:54:54.246575",
                "2017-10-28T04:55:53.424657",
                "2017-10-29T04:56:52.602739",
                "2017-10-30T04:57:51.780822",
                "2017-10-31T04:58:50.958904",
                "2017-11-01T04:59:50.136986",
                "2017-11-02T05:00:49.315068",
                "2017-11-03T05:01:48.493150",
                "2017-11-04T05:02:47.671232",
                "2017-11-05T05:03:46.849315",
                "2017-11-06T05:04:46.027397",
                "2017-11-07T05:05:45.205479",
                "2017-11-08T05:06:44.383561",
                "2017-11-09T05:07:43.561643",
                "2017-11-10T05:08:42.739726",
                "2017-11-11T05:09:41.917808",
                "2017-11-12T05:10:41.095890",
                "2017-11-13T05:11:40.273972",
                "2017-11-14T05:12:39.452054",
                "2017-11-15T05:13:38.630137",
                "2017-11-16T05:14:37.808219",
                "2017-11-17T05:15:36.986301",
                "2017-11-18T05:16:36.164383",
                "2017-11-19T05:17:35.342465",
                "2017-11-20T05:18:34.520547",
                "2017-11-21T05:19:33.698630",
                "2017-11-22T05:20:32.876712",
                "2017-11-23T05:21:32.054794",
                "2017-11-24T05:22:31.232876",
                "2017-11-25T05:23:30.410958",
                "2017-11-26T05:24:29.589041",
                "2017-11-27T05:25:28.767123",
                "2017-11-28T05:26:27.945205",
                "2017-11-29T05:27:27.123287",
                "2017-11-30T05:28:26.301369",
                "2017-12-01T05:29:25.479452",
                "2017-12-02T05:30:24.657534",
                "2017-12-03T05:31:23.835616",
                "2017-12-04T05:32:23.013698",
                "2017-12-05T05:33:22.191780",
                "2017-12-06T05:34:21.369862",
                "2017-12-07T05:35:20.547945",
                "2017-12-08T05:36:19.726027",
                "2017-12-09T05:37:18.904109",
                "2017-12-10T05:38:18.082191",
                "2017-12-11T05:39:17.260273",
                "2017-12-12T05:40:16.438356",
                "2017-12-13T05:41:15.616438",
                "2017-12-14T05:42:14.794520",
                "2017-12-15T05:43:13.972602",
                "2017-12-16T05:44:13.150684",
                "2017-12-17T05:45:12.328767",
                "2017-12-18T05:46:11.506849",
                "2017-12-19T05:47:10.684931",
                "2017-12-20T05:48:09.863013",
                "2017-12-21T05:49:09.041095",
                "2017-12-22T05:50:08.219177",
                "2017-12-23T05:51:07.397260",
                "2017-12-24T05:52:06.575342",
                "2017-12-25T05:53:05.753424",
                "2017-12-26T05:54:04.931506",
                "2017-12-27T05:55:04.109588",
                "2017-12-28T05:56:03.287671",
                "2017-12-29T05:57:02.465753",
                "2017-12-30T05:58:01.643835",
                "2017-12-31T05:59:00.821917",
              ],
              y: [
                -19.02001301320866, -26.017807986419466, -33.28753305925455,
                -40.79955302669125, -48.50715406575332, -56.346331438466386,
                -64.23621903931007, -72.0801527214914, -79.76733999750218,
                -87.17509036250978, -94.17154364295247, -100.6188188904947,
                -106.37649382543711, -111.30531500573252, -115.27103201227169,
                -118.14824515109092, -119.82415555479122, -120.20210909839169,
                -119.20483112221338, -116.77725738374613, -112.88887767837821,
                -107.53552183414583, -100.7405329097042, -92.55528896105196,
                -83.05905222769869, -72.35814252177647, -60.584449493586604,
                -47.89331580550652, -34.46083960162722, -20.4806595954465,
                -6.160299208608632, 8.28284282944476, 22.62575944325786,
                36.644257791678726, 50.11743227433941, 62.83208229153427,
                74.58697132744979, 85.19682880334355, 94.49600346607167,
                102.34168661338548, 108.61663490811722, 113.23133556977905,
                116.1255709719331, 117.2693547261274, 116.66322678390966,
                114.33791052906592, 110.35334986094351, 104.79715850845548,
                97.78252691230071, 89.44564366693365, 79.94269845818798,
                69.44654146279771, 58.14308014218341, 46.227498172743374,
                33.90038288404004, 21.363847047888225, 8.817728269048096,
                -3.544055291178291, -15.536920389318487, -26.98874067625073,
                -37.74261951277083, -47.659267962891434, -56.61895298588652,
                -64.5229918999769, -71.2947803171353, -76.88035165856402,
                -81.24847678452983, -84.39032196883696, -86.31869220117937,
                -87.06689444294737, -86.68726184941931, -85.24938502047578,
                -82.83809999635878, -79.55128497159427, -75.49751858912393,
                -70.79365226898658, -65.56234742462588, -59.92962575466512,
                -54.022477221339024, -47.966566013211065, -41.88406990623318,
                -35.89168318240154, -30.098807801415226, -24.605952032923785,
                -19.503350392918577, -14.869813628193649, -10.771812779372901,
                -7.262797107744312, -4.382741969522652, -2.1579195958022668,
                -0.6008832022550137, 0.2893471046669227, 0.5269095712181127,
                0.1385510635633394, -0.837083257757798, -2.3503062964067483,
                -4.341376920893543, -6.741605137280079, -9.47456054759894,
                -12.457370371448421, -15.602093478578226, -18.817156900151883,
                -22.00884111073153, -25.082799984806034, -27.945600722618494,
                -30.506268228021028, -32.677817447048355, -34.37875608959567,
                -35.534539029404634, -36.07895459515731, -35.95542201569269,
                -35.1181785615526, -33.53333453442432, -31.179774278900847,
                -28.049881919131717, -24.150071618345628, -19.501103880127378,
                -14.138171782329769, -8.110744072175814, -1.4821557302948352,
                5.671059104645377, 13.260092145350397, 21.185038941862,
                29.336319315492748, 37.59632396013294, 45.84126515186456,
                53.943203872278595, 61.77222032394809, 69.19868998606545,
                76.09562317866616, 82.34102274902747, 87.82021209284339,
                92.42808441064894, 96.07122395568305, 98.66985112714718,
                100.1595456254358, 100.49270550432479, 99.63970478383276,
                97.58971824258222, 94.35118896337725, 89.95192201087951,
                84.43879608309884, 77.87709388784182, 70.34946111841465,
                61.95451299241354, 52.80511611804259, 43.02638171221282,
                32.75341367044893, 22.128861449223777, 11.300332966157185,
                0.41772657736092955, -10.369456486214503, -20.91475713688883,
                -31.077299790207665, -40.7241715981598, -49.73258535962263,
                -57.99177771158737, -65.40460139110984, -71.88877873471287,
                -77.37779293448332, -81.82140362117515, -85.18578382099277,
                -87.4532859240252, -88.62185469286466, -88.70411522453765,
                -87.72617284500268, -85.7261698900163, -82.75265094614883,
                -78.86279317824525, -74.12056168056718, -68.5948512337114,
                -62.35767536164013, -55.48246115459315, -48.04250400179899,
                -40.10963027650629, -31.753108300375807, -23.03883880425645,
                -14.028845870225446, -4.7810782887556345, 4.650480257480155,
                14.21540541320281, 23.866155378102594, 33.55715849320362,
                43.24371028466545, 52.88073347734207, 62.42146126848911,
                71.81610902225414, 81.01060231782108, 89.94542979310157,
                98.55468740463, 106.76537655644327, 114.497012100317,
                121.66158761705846, 128.16393484999492, 133.90250196138328,
                138.77056174109057, 142.65784640202418, 145.452590569189,
                147.04394895954408, 147.32474052771028, 146.19445698323742,
                143.56246103286736, 139.3512888939087, 133.4999629574413,
                125.96721429829702, 116.73451130519706, 105.80879024977813,
                93.22478625247392, 79.04686886708085, 63.370295352856616,
                46.32180647808561, 28.059504176439003, 8.771967232634358,
                -11.323419984361887, -31.982930365468675, -52.93973175725689,
                -73.90786100459074, -94.58687423085283, -114.66709241492161,
                -133.83534188596207, -151.78107188117073, -168.20271635485554,
                -182.8141552769604, -195.3511221374248, -205.57739962253376,
                -213.2906446896185, -218.32768768712265, -220.56915777307754,
                -219.94329860131432, -216.42885387118372, -210.0569215787808,
                -200.91169823904073, -189.1300594847375, -174.89995067973675,
                -158.45758986408535, -140.08351476012345, -120.09753495855205,
                -98.85267901294019, -76.7282532341975, -54.12215376166079,
                -31.442595293364146, -9.09943807371633, 12.50469120420093,
                32.983279450001625, 51.974598514967994, 69.14995875058472,
                84.22134156054454, 96.94821872648292, 107.14338229820746,
                114.67762952250602, 119.4831721989485, 121.55566844591016,
                120.95480645656681, 117.80340369124747, 112.2850202547801,
                104.64012108720388, 95.16085715608823, 84.18457018277252,
                72.08615768063098, 59.269464405421985, 46.15789193410787,
                33.18443932340891, 20.781404065557055, 9.369983401294487,
                -0.6499788639353238, -8.909855749798437, -15.081473231945576,
                -18.88549434111214, -20.098744743903275, -18.56029679554696,
                -14.176157178833236, -6.922435353662058, 3.153094631825173,
                15.931088726448268, 31.222394478927505, 48.7714506574988,
                68.26125675183249, 89.31980654901976, 111.5278446963752,
                134.4277731586809, 157.53350654314673, 180.34105210938213,
                202.33957247742447, 223.02267704602684, 241.89968224184543,
                258.5065810876323, 272.41646919248035, 283.2491869779113,
                290.67995643582555, 294.44681451032966, 294.3566737159959,
                290.2898731373422, 282.20311868827804, 270.1307495618961,
                254.18430722281653, 234.5504231168847, 211.48708050924705,
                185.3183435543746, 156.4276819307748, 125.25005128065264,
                92.26291752560395, 57.976436214290345, 22.923015875076835,
                -12.353493518294343, -47.30873963901176, -81.40868694291294,
                -114.14007411925925, -145.02027968544292, -173.60638254310084,
                -199.5032262414053, -222.37032197977612, -241.92745529393932,
                -257.9588941465878, -270.31613091180566, -278.91912661389654,
                -283.7560618445401, -284.8816341286748, -282.4139752644832,
                -276.53029349799266, -267.46137356266354, -255.4850919520045,
                -240.91912475106577, -224.1130404977621, -205.4399805750827,
                -185.28813438840112, -164.05221603352447, -142.12514342149598,
                -119.89011014542652, -97.71322512575988, -75.93687572852879,
                -54.87394721903481, -34.8030057318976, -15.964524164468552,
                1.4417987216653638, 17.258605358921557, 31.371341371527276,
                43.70764966962831, 54.23576042967129, 62.96196495315911,
                69.92728258443785, 75.20344754054267, 78.88835626280162,
                81.10112546725165, 81.97691627326131, 81.66168059755823,
                80.30698248398332, 78.06503939683414, 75.08411704290101,
                71.5043964040742, 67.45441385268548, 63.04815504334546,
                58.38286134579732, 53.537584553903834, 48.57250214992752,
                43.52898218606737, 38.430364523085956, 33.2834043473845,
                28.080305136202785, 22.80125204682728, 17.41734348099376,
                11.893808640714502, 6.193392468519663, 0.2797865722872836,
                -5.8790144114790674, -12.307547151954813,
              ],
              type: "scatter",
            },
          ],
          xaxis: {
            range: [
              "2016-12-13T18:54:02.958904115",
              "2018-01-18T11:04:57.863013581",
            ],
            tickformat: "%B %e",
            type: "date",
          },
          yaxis: {
            title: {
              text: "yearly",
            },
            zerolinecolor: "#AAA",
          },
        },
      },
    },
  },
  {
    name: "hib_2024-05-09_08:32:57",
    forecast_plot: {
      data: [
        {
          marker: {
            color: "black",
            size: 4,
          },
          mode: "markers",
          name: "Actual",
          x: [
            "2016-09-30T00:00:00",
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
          ],
          y: [
            1, 12, 91, 91, 112, 105, 118, 97, 113, 89, 114, 98, 120, 110, 128,
            116, 102, 110, 94, 91, 102, 85, 117, 95, 118, 99, 126, 118, 112,
            117, 91, 111, 78, 89, 106, 87, 96, 115, 99, 117, 104, 104, 90, 103,
            85, 97, 97, 118, 111, 100, 119, 127, 86, 104, 87, 92, 95, 110, 86,
            110, 111, 122, 101, 105, 85, 54, 22, 34, 22, 27, 41, 37, 48, 32, 39,
            26, 30, 35, 39, 26, 32, 31, 28,
          ],
          type: "scatter",
        },
        {
          legendgroup: "Trend Line",
          line: {
            color: "#0072B2",
            width: 2,
          },
          mode: "lines",
          name: "Trend Line",
          x: [
            "2016-09-30T00:00:00",
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
          ],
          y: [
            73.24123261375362, 73.11133399472213, 110.878259607249,
            106.26585107235489, 87.87951785544499, 89.99899791471938,
            76.31840328804682, 76.81649769086819, 74.83825564779339,
            77.31654926430522, 86.30718372606908, 94.83280210908791,
            83.41830245738558, 81.95764403714452, 102.8822817722641,
            101.33303509763138, 89.54331590640084, 89.763650296472,
            77.60832363659848, 80.1231164722282, 75.56640415004031,
            74.80605629018321, 83.36633757792902, 86.781541720097,
            93.80309907254072, 90.57644014902606, 94.66062369043719,
            96.47796012620994, 91.30414125466815, 89.56474481429616,
            78.95187372119415, 83.38932332844756, 76.17659183049734,
            72.21306354945753, 80.40614929845974, 78.95856613866475,
            104.3827211734807, 98.95640924185822, 86.2219851044534,
            91.70841355034872, 93.16209963719425, 90.27157625185492,
            75.08479699689106, 73.47413873216426, 73.99333251802138,
            79.74039439778505, 89.22217321792941, 103.10474909166322,
            73.24123261378725, 73.11133399476192, 110.8782596072851,
            106.26585107234153, 87.87951785542668, 89.99899791467635,
            76.31840328807448, 76.81649769085936, 74.83825564784391,
            77.316549264281, 86.30718372601088, 94.832802109119,
            83.4183024573594, 81.9576440370787, 102.88228177226549,
            101.33303509758761, 89.54331590645492, 89.76365029644501,
            77.6083236365528, 80.12311647224726, 75.56640415004466,
            74.80605629014177, 83.36633757794766, 86.78154172005964,
            93.80309907258044, 90.5764401490277, 94.6606236904773,
            96.47796012617871, 91.30414125473285, 89.56474481426768,
            78.95187372110308, 83.3893233284778, 76.17659183046952,
            72.21306354941665, 80.40614929846389,
          ],
          type: "scatter",
        },
        {
          legendgroup: "Predicted",
          line: {
            color: "#33cc33",
            width: 2,
          },
          mode: "lines",
          name: "Predicted",
          x: [
            "2023-07-31T00:00:00",
            "2023-08-31T00:00:00",
            "2023-09-30T00:00:00",
            "2023-10-31T00:00:00",
            "2023-11-30T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-31T00:00:00",
            "2024-02-29T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-30T00:00:00",
            "2024-05-31T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-31T00:00:00",
            "2024-08-31T00:00:00",
            "2024-09-30T00:00:00",
            "2024-10-31T00:00:00",
            "2024-11-30T00:00:00",
            "2024-12-31T00:00:00",
            "2025-01-31T00:00:00",
          ],
          y: [
            80.40614929846389, 78.95856613864716, 104.3827211735609,
            98.95640924189307, 86.22198510443535, 91.70841355029353,
            93.1620996372147, 90.27157625189486, 75.08479699694689,
            73.47413873216682, 73.99333251797657, 79.74039439773352,
            89.22217321792833, 103.10474909165681, 73.24123261373008,
            73.1113339947625, 110.8782596072588, 106.2658510723568,
            87.87951785547853,
          ],
          type: "scatter",
        },
        {
          hoverinfo: "skip",
          legendgroup: "Trend Line",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2016-09-30T00:00:00",
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
          ],
          y: [
            12.39420361556599, 14.251144176422672, 46.599544370953765,
            45.241731490207734, 22.373383257704393, 21.678830147447428,
            11.8145250836954, 15.658774865241583, 11.8940363476104,
            15.399303829098844, 19.85887781004819, 33.859843459196036,
            17.55264893094538, 25.10486669331846, 45.514340661194105,
            40.23416277128568, 27.866589568836204, 29.442769692202443,
            10.80261627592332, 17.04278027184799, 14.144583128475409,
            13.159727104392488, 20.155738082437082, 27.806305140151636,
            29.88793858393972, 26.298205832538976, 29.737893555874088,
            32.356773716081165, 28.98057422532088, 26.206861881102025,
            18.90328673472179, 20.04291372511271, 15.966267590186398,
            10.348303985208968, 18.451064125367335, 16.041344438988602,
            42.68235891617664, 37.73129142560634, 23.383215641917836,
            27.864269634079168, 30.44033188121922, 31.12976007665621,
            13.667294388615423, 13.306706638369862, 10.584088012399468,
            16.66951991909702, 28.711800597577543, 40.13387633448977,
            14.64218032277553, 13.900298334953167, 49.41476393424426,
            41.324732988337765, 27.394797923562926, 31.6538530248445,
            16.468618253672712, 14.00855227852596, 12.284683484281109,
            13.543364365594112, 22.352037782147047, 33.83002583987816,
            22.650649401431508, 19.161568829173245, 44.81591890999447,
            40.27183313692331, 23.46913498225538, 24.935451056245412,
            15.101770900655614, 18.932533128795136, 15.839983032127483,
            13.382278501293936, 18.059572263330057, 19.19888396712157,
            32.17702448020429, 27.398386509387272, 32.30030409233071,
            32.36809063351656, 27.987479899134293, 31.55464391399477,
            17.938801112246704, 20.42987320171888, 10.152003466822697,
            12.114618546569249, 15.338257275118526,
          ],
          type: "scatter",
        },
        {
          fill: "tonexty",
          fillcolor: "rgba(0, 114, 178, 0.2)",
          hoverinfo: "skip",
          legendgroup: "Trend Line",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2016-09-30T00:00:00",
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
          ],
          y: [
            130.52189130718747, 137.1657581357627, 168.46267357971936,
            168.99997374720246, 144.36341625768924, 155.99072184508668,
            140.84956171118768, 140.93702139163142, 135.33148833349324,
            141.2914435865023, 150.69186318526283, 160.02211724204204,
            148.85623805576907, 147.62549870194192, 162.2346860669585,
            164.64540128552042, 149.6349544843793, 153.56606538788748,
            145.39932299185355, 146.31845282540056, 136.35852127643162,
            140.6625979035464, 145.18237205219387, 148.92208244495413,
            158.3604797520161, 155.22963925246648, 158.43464012315283,
            160.15747248535297, 158.76786488936511, 151.7261041374261,
            139.50504438347983, 147.16554180797533, 135.18130200120052,
            136.59471269512156, 138.8893852021167, 140.93371446016366,
            167.4737944771877, 158.96040027029923, 150.7841436349555,
            152.1070320839029, 151.87333068643602, 149.23837591917703,
            140.7237219158511, 134.86374322722563, 132.19714111286748,
            141.01975597186313, 150.8859298894854, 166.00818565473722,
            137.00159561178344, 136.35941680725625, 170.52531543391564,
            169.84435132674793, 153.4049936914602, 149.3992635960969,
            137.94049162662503, 134.6423100719523, 137.95100752884116,
            141.81011976567444, 145.6741634992612, 155.2622992528146,
            146.40830837934587, 147.38505964365723, 164.5566493707022,
            163.03626796693058, 150.40783681948378, 157.91744573498707,
            143.5385726781261, 142.02448783916594, 136.2529776759923,
            137.54167581515586, 144.8215636338572, 152.39319891591865,
            156.0419037140869, 150.76259853112964, 157.8350296981264,
            157.2870603258329, 157.17150209700412, 149.6015837676729,
            137.09542207314902, 150.16469623210278, 140.82013559930542,
            130.79880056460348, 139.57123709341232,
          ],
          type: "scatter",
        },
        {
          hoverinfo: "skip",
          legendgroup: "Predicted",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2023-07-31T00:00:00",
            "2023-08-31T00:00:00",
            "2023-09-30T00:00:00",
            "2023-10-31T00:00:00",
            "2023-11-30T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-31T00:00:00",
            "2024-02-29T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-30T00:00:00",
            "2024-05-31T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-31T00:00:00",
            "2024-08-31T00:00:00",
            "2024-09-30T00:00:00",
            "2024-10-31T00:00:00",
            "2024-11-30T00:00:00",
            "2024-12-31T00:00:00",
            "2025-01-31T00:00:00",
          ],
          y: [
            15.338257275118526, 15.837952619309887, 35.72085953659201,
            38.27428422048655, 26.582560941512845, 27.59091112650787,
            27.45445917402768, 28.38583380247165, 11.8978574064805,
            9.337583913673937, 11.476835415327848, 16.36908019695674,
            27.30750128376879, 44.798189964762926, 9.588008805760017,
            11.353782891110926, 48.98461600703986, 42.348759191555516,
            26.580310438569825,
          ],
          type: "scatter",
        },
        {
          fill: "tonexty",
          fillcolor: "rgba(51, 204, 51, 0.2)",
          hoverinfo: "skip",
          legendgroup: "Predicted",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2023-07-31T00:00:00",
            "2023-08-31T00:00:00",
            "2023-09-30T00:00:00",
            "2023-10-31T00:00:00",
            "2023-11-30T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-31T00:00:00",
            "2024-02-29T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-30T00:00:00",
            "2024-05-31T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-31T00:00:00",
            "2024-08-31T00:00:00",
            "2024-09-30T00:00:00",
            "2024-10-31T00:00:00",
            "2024-11-30T00:00:00",
            "2024-12-31T00:00:00",
            "2025-01-31T00:00:00",
          ],
          y: [
            139.57123709341232, 144.16538647929477, 163.91881520185382,
            160.726451726727, 148.33609689491837, 154.169531880242,
            155.86743051571145, 148.76708616040588, 134.8905639334832,
            131.5149178888541, 134.12841880634363, 141.95239178653668,
            155.3893329093995, 167.84438562491184, 131.92600730900782,
            135.58554198664373, 169.43713838316987, 162.79652533571254,
            150.37608383870514,
          ],
          type: "scatter",
        },
      ],
      layout: {
        showlegend: true,
        width: 850,
        height: 600,
        yaxis: {
          title: "y",
        },
        xaxis: {
          title: "ds",
          type: "date",
          rangeselector: {
            buttons: [
              {
                count: 7,
                label: "1w",
                step: "day",
                stepmode: "backward",
              },
              {
                count: 1,
                label: "1m",
                step: "month",
                stepmode: "backward",
              },
              {
                count: 6,
                label: "6m",
                step: "month",
                stepmode: "backward",
              },
              {
                count: 1,
                label: "1y",
                step: "year",
                stepmode: "backward",
              },
              {
                step: "all",
              },
            ],
          },
          rangeslider: {
            visible: true,
          },
        },
      },
    },
    forecast_components: {
      data: {
        trend: {
          traces: [
            {
              line: {
                color: "#0072B2",
                width: 2,
              },
              mode: "lines",
              name: "trend",
              x: [
                "2016-09-30T00:00:00",
                "2016-10-31T00:00:00",
                "2016-11-30T00:00:00",
                "2016-12-31T00:00:00",
                "2017-01-31T00:00:00",
                "2017-02-28T00:00:00",
                "2017-03-31T00:00:00",
                "2017-04-30T00:00:00",
                "2017-05-31T00:00:00",
                "2017-06-30T00:00:00",
                "2017-07-31T00:00:00",
                "2017-08-31T00:00:00",
                "2017-09-30T00:00:00",
                "2017-10-31T00:00:00",
                "2017-11-30T00:00:00",
                "2017-12-31T00:00:00",
                "2018-01-31T00:00:00",
                "2018-02-28T00:00:00",
                "2018-03-31T00:00:00",
                "2018-04-30T00:00:00",
                "2018-05-31T00:00:00",
                "2018-06-30T00:00:00",
                "2018-07-31T00:00:00",
                "2018-08-31T00:00:00",
                "2018-09-30T00:00:00",
                "2018-10-31T00:00:00",
                "2018-11-30T00:00:00",
                "2018-12-31T00:00:00",
                "2019-01-31T00:00:00",
                "2019-02-28T00:00:00",
                "2019-03-31T00:00:00",
                "2019-04-30T00:00:00",
                "2019-05-31T00:00:00",
                "2019-06-30T00:00:00",
                "2019-07-31T00:00:00",
                "2019-08-31T00:00:00",
                "2019-09-30T00:00:00",
                "2019-10-31T00:00:00",
                "2019-11-30T00:00:00",
                "2019-12-31T00:00:00",
                "2020-01-31T00:00:00",
                "2020-02-29T00:00:00",
                "2020-03-31T00:00:00",
                "2020-04-30T00:00:00",
                "2020-05-31T00:00:00",
                "2020-06-30T00:00:00",
                "2020-07-31T00:00:00",
                "2020-08-31T00:00:00",
                "2020-09-30T00:00:00",
                "2020-10-31T00:00:00",
                "2020-11-30T00:00:00",
                "2020-12-31T00:00:00",
                "2021-01-31T00:00:00",
                "2021-02-28T00:00:00",
                "2021-03-31T00:00:00",
                "2021-04-30T00:00:00",
                "2021-05-31T00:00:00",
                "2021-06-30T00:00:00",
                "2021-07-31T00:00:00",
                "2021-08-31T00:00:00",
                "2021-09-30T00:00:00",
                "2021-10-31T00:00:00",
                "2021-11-30T00:00:00",
                "2021-12-31T00:00:00",
                "2022-01-31T00:00:00",
                "2022-02-28T00:00:00",
                "2022-03-31T00:00:00",
                "2022-04-30T00:00:00",
                "2022-05-31T00:00:00",
                "2022-06-30T00:00:00",
                "2022-07-31T00:00:00",
                "2022-08-31T00:00:00",
                "2022-09-30T00:00:00",
                "2022-10-31T00:00:00",
                "2022-11-30T00:00:00",
                "2022-12-31T00:00:00",
                "2023-01-31T00:00:00",
                "2023-02-28T00:00:00",
                "2023-03-31T00:00:00",
                "2023-04-30T00:00:00",
                "2023-05-31T00:00:00",
                "2023-06-30T00:00:00",
                "2023-07-31T00:00:00",
                "2023-08-31T00:00:00",
                "2023-09-30T00:00:00",
                "2023-10-31T00:00:00",
                "2023-11-30T00:00:00",
                "2023-12-31T00:00:00",
                "2024-01-31T00:00:00",
                "2024-02-29T00:00:00",
                "2024-03-31T00:00:00",
                "2024-04-30T00:00:00",
                "2024-05-31T00:00:00",
                "2024-06-30T00:00:00",
                "2024-07-31T00:00:00",
                "2024-08-31T00:00:00",
                "2024-09-30T00:00:00",
                "2024-10-31T00:00:00",
                "2024-11-30T00:00:00",
                "2024-12-31T00:00:00",
                "2025-01-31T00:00:00",
              ],
              y: [
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952, 85.725952, 85.725952, 85.725952, 85.725952,
                85.725952,
              ],
              type: "scatter",
            },
          ],
          xaxis: {
            range: ["2016-04-30T18:00:00", "2025-07-02T06:00:00"],
            type: "date",
          },
          yaxis: {
            rangemode: "normal",
            title: {
              text: "trend",
            },
            zerolinecolor: "#AAA",
          },
        },
        yearly: {
          traces: [
            {
              line: {
                color: "#0072B2",
                width: 2,
              },
              mode: "lines",
              name: "yearly",
              x: [
                "2017-01-01T00:00:00",
                "2017-01-02T00:00:59.178082",
                "2017-01-03T00:01:58.356164",
                "2017-01-04T00:02:57.534246",
                "2017-01-05T00:03:56.712328",
                "2017-01-06T00:04:55.890411",
                "2017-01-07T00:05:55.068493",
                "2017-01-08T00:06:54.246575",
                "2017-01-09T00:07:53.424657",
                "2017-01-10T00:08:52.602739",
                "2017-01-11T00:09:51.780822",
                "2017-01-12T00:10:50.958904",
                "2017-01-13T00:11:50.136986",
                "2017-01-14T00:12:49.315068",
                "2017-01-15T00:13:48.493150",
                "2017-01-16T00:14:47.671232",
                "2017-01-17T00:15:46.849315",
                "2017-01-18T00:16:46.027397",
                "2017-01-19T00:17:45.205479",
                "2017-01-20T00:18:44.383561",
                "2017-01-21T00:19:43.561643",
                "2017-01-22T00:20:42.739726",
                "2017-01-23T00:21:41.917808",
                "2017-01-24T00:22:41.095890",
                "2017-01-25T00:23:40.273972",
                "2017-01-26T00:24:39.452054",
                "2017-01-27T00:25:38.630137",
                "2017-01-28T00:26:37.808219",
                "2017-01-29T00:27:36.986301",
                "2017-01-30T00:28:36.164383",
                "2017-01-31T00:29:35.342465",
                "2017-02-01T00:30:34.520547",
                "2017-02-02T00:31:33.698630",
                "2017-02-03T00:32:32.876712",
                "2017-02-04T00:33:32.054794",
                "2017-02-05T00:34:31.232876",
                "2017-02-06T00:35:30.410958",
                "2017-02-07T00:36:29.589041",
                "2017-02-08T00:37:28.767123",
                "2017-02-09T00:38:27.945205",
                "2017-02-10T00:39:27.123287",
                "2017-02-11T00:40:26.301369",
                "2017-02-12T00:41:25.479452",
                "2017-02-13T00:42:24.657534",
                "2017-02-14T00:43:23.835616",
                "2017-02-15T00:44:23.013698",
                "2017-02-16T00:45:22.191780",
                "2017-02-17T00:46:21.369862",
                "2017-02-18T00:47:20.547945",
                "2017-02-19T00:48:19.726027",
                "2017-02-20T00:49:18.904109",
                "2017-02-21T00:50:18.082191",
                "2017-02-22T00:51:17.260273",
                "2017-02-23T00:52:16.438356",
                "2017-02-24T00:53:15.616438",
                "2017-02-25T00:54:14.794520",
                "2017-02-26T00:55:13.972602",
                "2017-02-27T00:56:13.150684",
                "2017-02-28T00:57:12.328767",
                "2017-03-01T00:58:11.506849",
                "2017-03-02T00:59:10.684931",
                "2017-03-03T01:00:09.863013",
                "2017-03-04T01:01:09.041095",
                "2017-03-05T01:02:08.219177",
                "2017-03-06T01:03:07.397260",
                "2017-03-07T01:04:06.575342",
                "2017-03-08T01:05:05.753424",
                "2017-03-09T01:06:04.931506",
                "2017-03-10T01:07:04.109588",
                "2017-03-11T01:08:03.287671",
                "2017-03-12T01:09:02.465753",
                "2017-03-13T01:10:01.643835",
                "2017-03-14T01:11:00.821917",
                "2017-03-15T01:12:00",
                "2017-03-16T01:12:59.178082",
                "2017-03-17T01:13:58.356164",
                "2017-03-18T01:14:57.534246",
                "2017-03-19T01:15:56.712328",
                "2017-03-20T01:16:55.890411",
                "2017-03-21T01:17:55.068493",
                "2017-03-22T01:18:54.246575",
                "2017-03-23T01:19:53.424657",
                "2017-03-24T01:20:52.602739",
                "2017-03-25T01:21:51.780822",
                "2017-03-26T01:22:50.958904",
                "2017-03-27T01:23:50.136986",
                "2017-03-28T01:24:49.315068",
                "2017-03-29T01:25:48.493150",
                "2017-03-30T01:26:47.671232",
                "2017-03-31T01:27:46.849315",
                "2017-04-01T01:28:46.027397",
                "2017-04-02T01:29:45.205479",
                "2017-04-03T01:30:44.383561",
                "2017-04-04T01:31:43.561643",
                "2017-04-05T01:32:42.739726",
                "2017-04-06T01:33:41.917808",
                "2017-04-07T01:34:41.095890",
                "2017-04-08T01:35:40.273972",
                "2017-04-09T01:36:39.452054",
                "2017-04-10T01:37:38.630137",
                "2017-04-11T01:38:37.808219",
                "2017-04-12T01:39:36.986301",
                "2017-04-13T01:40:36.164383",
                "2017-04-14T01:41:35.342465",
                "2017-04-15T01:42:34.520547",
                "2017-04-16T01:43:33.698630",
                "2017-04-17T01:44:32.876712",
                "2017-04-18T01:45:32.054794",
                "2017-04-19T01:46:31.232876",
                "2017-04-20T01:47:30.410958",
                "2017-04-21T01:48:29.589041",
                "2017-04-22T01:49:28.767123",
                "2017-04-23T01:50:27.945205",
                "2017-04-24T01:51:27.123287",
                "2017-04-25T01:52:26.301369",
                "2017-04-26T01:53:25.479452",
                "2017-04-27T01:54:24.657534",
                "2017-04-28T01:55:23.835616",
                "2017-04-29T01:56:23.013698",
                "2017-04-30T01:57:22.191780",
                "2017-05-01T01:58:21.369862",
                "2017-05-02T01:59:20.547945",
                "2017-05-03T02:00:19.726027",
                "2017-05-04T02:01:18.904109",
                "2017-05-05T02:02:18.082191",
                "2017-05-06T02:03:17.260273",
                "2017-05-07T02:04:16.438356",
                "2017-05-08T02:05:15.616438",
                "2017-05-09T02:06:14.794520",
                "2017-05-10T02:07:13.972602",
                "2017-05-11T02:08:13.150684",
                "2017-05-12T02:09:12.328767",
                "2017-05-13T02:10:11.506849",
                "2017-05-14T02:11:10.684931",
                "2017-05-15T02:12:09.863013",
                "2017-05-16T02:13:09.041095",
                "2017-05-17T02:14:08.219177",
                "2017-05-18T02:15:07.397260",
                "2017-05-19T02:16:06.575342",
                "2017-05-20T02:17:05.753424",
                "2017-05-21T02:18:04.931506",
                "2017-05-22T02:19:04.109588",
                "2017-05-23T02:20:03.287671",
                "2017-05-24T02:21:02.465753",
                "2017-05-25T02:22:01.643835",
                "2017-05-26T02:23:00.821917",
                "2017-05-27T02:24:00",
                "2017-05-28T02:24:59.178082",
                "2017-05-29T02:25:58.356164",
                "2017-05-30T02:26:57.534246",
                "2017-05-31T02:27:56.712328",
                "2017-06-01T02:28:55.890411",
                "2017-06-02T02:29:55.068493",
                "2017-06-03T02:30:54.246575",
                "2017-06-04T02:31:53.424657",
                "2017-06-05T02:32:52.602739",
                "2017-06-06T02:33:51.780822",
                "2017-06-07T02:34:50.958904",
                "2017-06-08T02:35:50.136986",
                "2017-06-09T02:36:49.315068",
                "2017-06-10T02:37:48.493150",
                "2017-06-11T02:38:47.671232",
                "2017-06-12T02:39:46.849315",
                "2017-06-13T02:40:46.027397",
                "2017-06-14T02:41:45.205479",
                "2017-06-15T02:42:44.383561",
                "2017-06-16T02:43:43.561643",
                "2017-06-17T02:44:42.739726",
                "2017-06-18T02:45:41.917808",
                "2017-06-19T02:46:41.095890",
                "2017-06-20T02:47:40.273972",
                "2017-06-21T02:48:39.452054",
                "2017-06-22T02:49:38.630137",
                "2017-06-23T02:50:37.808219",
                "2017-06-24T02:51:36.986301",
                "2017-06-25T02:52:36.164383",
                "2017-06-26T02:53:35.342465",
                "2017-06-27T02:54:34.520547",
                "2017-06-28T02:55:33.698630",
                "2017-06-29T02:56:32.876712",
                "2017-06-30T02:57:32.054794",
                "2017-07-01T02:58:31.232876",
                "2017-07-02T02:59:30.410958",
                "2017-07-03T03:00:29.589041",
                "2017-07-04T03:01:28.767123",
                "2017-07-05T03:02:27.945205",
                "2017-07-06T03:03:27.123287",
                "2017-07-07T03:04:26.301369",
                "2017-07-08T03:05:25.479452",
                "2017-07-09T03:06:24.657534",
                "2017-07-10T03:07:23.835616",
                "2017-07-11T03:08:23.013698",
                "2017-07-12T03:09:22.191780",
                "2017-07-13T03:10:21.369862",
                "2017-07-14T03:11:20.547945",
                "2017-07-15T03:12:19.726027",
                "2017-07-16T03:13:18.904109",
                "2017-07-17T03:14:18.082191",
                "2017-07-18T03:15:17.260273",
                "2017-07-19T03:16:16.438356",
                "2017-07-20T03:17:15.616438",
                "2017-07-21T03:18:14.794520",
                "2017-07-22T03:19:13.972602",
                "2017-07-23T03:20:13.150684",
                "2017-07-24T03:21:12.328767",
                "2017-07-25T03:22:11.506849",
                "2017-07-26T03:23:10.684931",
                "2017-07-27T03:24:09.863013",
                "2017-07-28T03:25:09.041095",
                "2017-07-29T03:26:08.219177",
                "2017-07-30T03:27:07.397260",
                "2017-07-31T03:28:06.575342",
                "2017-08-01T03:29:05.753424",
                "2017-08-02T03:30:04.931506",
                "2017-08-03T03:31:04.109588",
                "2017-08-04T03:32:03.287671",
                "2017-08-05T03:33:02.465753",
                "2017-08-06T03:34:01.643835",
                "2017-08-07T03:35:00.821917",
                "2017-08-08T03:36:00",
                "2017-08-09T03:36:59.178082",
                "2017-08-10T03:37:58.356164",
                "2017-08-11T03:38:57.534246",
                "2017-08-12T03:39:56.712328",
                "2017-08-13T03:40:55.890411",
                "2017-08-14T03:41:55.068493",
                "2017-08-15T03:42:54.246575",
                "2017-08-16T03:43:53.424657",
                "2017-08-17T03:44:52.602739",
                "2017-08-18T03:45:51.780822",
                "2017-08-19T03:46:50.958904",
                "2017-08-20T03:47:50.136986",
                "2017-08-21T03:48:49.315068",
                "2017-08-22T03:49:48.493150",
                "2017-08-23T03:50:47.671232",
                "2017-08-24T03:51:46.849315",
                "2017-08-25T03:52:46.027397",
                "2017-08-26T03:53:45.205479",
                "2017-08-27T03:54:44.383561",
                "2017-08-28T03:55:43.561643",
                "2017-08-29T03:56:42.739726",
                "2017-08-30T03:57:41.917808",
                "2017-08-31T03:58:41.095890",
                "2017-09-01T03:59:40.273972",
                "2017-09-02T04:00:39.452054",
                "2017-09-03T04:01:38.630137",
                "2017-09-04T04:02:37.808219",
                "2017-09-05T04:03:36.986301",
                "2017-09-06T04:04:36.164383",
                "2017-09-07T04:05:35.342465",
                "2017-09-08T04:06:34.520547",
                "2017-09-09T04:07:33.698630",
                "2017-09-10T04:08:32.876712",
                "2017-09-11T04:09:32.054794",
                "2017-09-12T04:10:31.232876",
                "2017-09-13T04:11:30.410958",
                "2017-09-14T04:12:29.589041",
                "2017-09-15T04:13:28.767123",
                "2017-09-16T04:14:27.945205",
                "2017-09-17T04:15:27.123287",
                "2017-09-18T04:16:26.301369",
                "2017-09-19T04:17:25.479452",
                "2017-09-20T04:18:24.657534",
                "2017-09-21T04:19:23.835616",
                "2017-09-22T04:20:23.013698",
                "2017-09-23T04:21:22.191780",
                "2017-09-24T04:22:21.369862",
                "2017-09-25T04:23:20.547945",
                "2017-09-26T04:24:19.726027",
                "2017-09-27T04:25:18.904109",
                "2017-09-28T04:26:18.082191",
                "2017-09-29T04:27:17.260273",
                "2017-09-30T04:28:16.438356",
                "2017-10-01T04:29:15.616438",
                "2017-10-02T04:30:14.794520",
                "2017-10-03T04:31:13.972602",
                "2017-10-04T04:32:13.150684",
                "2017-10-05T04:33:12.328767",
                "2017-10-06T04:34:11.506849",
                "2017-10-07T04:35:10.684931",
                "2017-10-08T04:36:09.863013",
                "2017-10-09T04:37:09.041095",
                "2017-10-10T04:38:08.219177",
                "2017-10-11T04:39:07.397260",
                "2017-10-12T04:40:06.575342",
                "2017-10-13T04:41:05.753424",
                "2017-10-14T04:42:04.931506",
                "2017-10-15T04:43:04.109588",
                "2017-10-16T04:44:03.287671",
                "2017-10-17T04:45:02.465753",
                "2017-10-18T04:46:01.643835",
                "2017-10-19T04:47:00.821917",
                "2017-10-20T04:48:00",
                "2017-10-21T04:48:59.178082",
                "2017-10-22T04:49:58.356164",
                "2017-10-23T04:50:57.534246",
                "2017-10-24T04:51:56.712328",
                "2017-10-25T04:52:55.890411",
                "2017-10-26T04:53:55.068493",
                "2017-10-27T04:54:54.246575",
                "2017-10-28T04:55:53.424657",
                "2017-10-29T04:56:52.602739",
                "2017-10-30T04:57:51.780822",
                "2017-10-31T04:58:50.958904",
                "2017-11-01T04:59:50.136986",
                "2017-11-02T05:00:49.315068",
                "2017-11-03T05:01:48.493150",
                "2017-11-04T05:02:47.671232",
                "2017-11-05T05:03:46.849315",
                "2017-11-06T05:04:46.027397",
                "2017-11-07T05:05:45.205479",
                "2017-11-08T05:06:44.383561",
                "2017-11-09T05:07:43.561643",
                "2017-11-10T05:08:42.739726",
                "2017-11-11T05:09:41.917808",
                "2017-11-12T05:10:41.095890",
                "2017-11-13T05:11:40.273972",
                "2017-11-14T05:12:39.452054",
                "2017-11-15T05:13:38.630137",
                "2017-11-16T05:14:37.808219",
                "2017-11-17T05:15:36.986301",
                "2017-11-18T05:16:36.164383",
                "2017-11-19T05:17:35.342465",
                "2017-11-20T05:18:34.520547",
                "2017-11-21T05:19:33.698630",
                "2017-11-22T05:20:32.876712",
                "2017-11-23T05:21:32.054794",
                "2017-11-24T05:22:31.232876",
                "2017-11-25T05:23:30.410958",
                "2017-11-26T05:24:29.589041",
                "2017-11-27T05:25:28.767123",
                "2017-11-28T05:26:27.945205",
                "2017-11-29T05:27:27.123287",
                "2017-11-30T05:28:26.301369",
                "2017-12-01T05:29:25.479452",
                "2017-12-02T05:30:24.657534",
                "2017-12-03T05:31:23.835616",
                "2017-12-04T05:32:23.013698",
                "2017-12-05T05:33:22.191780",
                "2017-12-06T05:34:21.369862",
                "2017-12-07T05:35:20.547945",
                "2017-12-08T05:36:19.726027",
                "2017-12-09T05:37:18.904109",
                "2017-12-10T05:38:18.082191",
                "2017-12-11T05:39:17.260273",
                "2017-12-12T05:40:16.438356",
                "2017-12-13T05:41:15.616438",
                "2017-12-14T05:42:14.794520",
                "2017-12-15T05:43:13.972602",
                "2017-12-16T05:44:13.150684",
                "2017-12-17T05:45:12.328767",
                "2017-12-18T05:46:11.506849",
                "2017-12-19T05:47:10.684931",
                "2017-12-20T05:48:09.863013",
                "2017-12-21T05:49:09.041095",
                "2017-12-22T05:50:08.219177",
                "2017-12-23T05:51:07.397260",
                "2017-12-24T05:52:06.575342",
                "2017-12-25T05:53:05.753424",
                "2017-12-26T05:54:04.931506",
                "2017-12-27T05:55:04.109588",
                "2017-12-28T05:56:03.287671",
                "2017-12-29T05:57:02.465753",
                "2017-12-30T05:58:01.643835",
                "2017-12-31T05:59:00.821917",
              ],
              y: [
                0.47698272325380087, 0.7215583825093528, 0.9670884615534938,
                1.2075897436965128, 1.4373350374769531, 1.6510014015680936,
                1.8438027725372539, 2.011603699249274, 2.151011511093407,
                2.2594449334514444, 2.3351778915423913, 2.3773579911486205,
                2.385999909299157, 2.3619546482981786, 2.3068562816556253,
                2.223048431762308, 2.113493249847582, 1.9816661047307107,
                1.8314395173831193, 1.6669600956563944, 1.4925223235956433,
                1.3124430421792987, 1.1309403259640447, 0.952020219566149,
                0.7793744586020834, 0.6162918741246417, 0.4655856824033038,
                0.32953830983057486, 0.2098648138129855, 0.10769535350525272,
                0.02357655832905947, -0.04250894405522154, -0.09110612746232005,
                -0.1232360767991949, -0.14033161682855122, -0.14416261629618987,
                -0.13675378126406773, -0.12029791344545793,
                -0.09706767228271684, -0.06932884343334877,
                -0.039257984166783944, -0.008867093903570156,
                0.020062345476130228, 0.046033988748762966, 0.06787836584233597,
                0.08478067255589039, 0.09629404536222386, 0.10233826066943663,
                0.10318430031464321, 0.09942570301710485, 0.09193806079951328,
                0.08182840611047126, 0.0703765575101093, 0.058970739578606046,
                0.04903995881866069, 0.04198569722790724, 0.03911547714824895,
                0.04158075612294192, 0.05032143273375492, 0.06601899037558692,
                0.08905998470353826, 0.11951120322033526, 0.15710740510988114,
                0.2012521001786548, 0.25103136282334165, 0.305240215736233,
                0.36242067398797506, 0.42091012793187815, 0.47889837670481106,
                0.5344913150634559, 0.5857790351520433, 0.6309059394557827,
                0.6681403771870066, 0.6959413164797238, 0.7130196491119527,
                0.7183918903560268, 0.7114242786757446, 0.6918655904637382,
                0.6598673538872835, 0.615990561128078, 0.5611984264473232,
                0.4968352039239268, 0.4245915482560566, 0.34645735924325494,
                0.2646634805018906, 0.18161401142448358, 0.09981132544179933,
                0.02177615606062039, -0.050034694124744816,
                -0.11331035416733326, -0.16596490153113003,
                -0.20621076207293754, -0.2326240071624845, -0.24419929054322603,
                -0.24039247135975877, -0.2211493396589419, -0.18691928532694219,
                -0.1386532177472562, -0.07778553694875692,
                -0.0062004622196175085, 0.07381647487405399, 0.1596404852735615,
                0.24838139189040656, 0.336967185203643, 0.42223480373087935,
                0.5010254193366668, 0.5702813310141072, 0.6271414834232423,
                0.6690326314507656, 0.6937532700661845, 0.6995476376207327,
                0.6851673753236978, 0.6499187781234657, 0.5936939924519595,
                0.5169849916766283, 0.4208796764799022, 0.3070399890870838,
                0.17766248072323926, 0.03542231405139956, -0.11659780033829809,
                -0.27499175796282016, -0.4361249095351713, -0.5962368589188629,
                -0.7515491941061956, -0.8983749553972735, -1.0332265611142102,
                -1.1529189268169564, -1.2546646292464296, -1.3361581785828336,
                -1.395646766243405, -1.4319852417095476, -1.4446735297965958,
                -1.433875215765915, -1.4004165844936776, -1.3457659848602823,
                -1.271993983922292, -1.1817153593134964, -1.0780145348118326,
                -0.9643565759822137, -0.8444863143735273, -0.722318545706742,
                -0.6018225379435298, -0.48690427948982085, -0.38128998962183,
                -0.28841439899305854, -0.2113171873855162, -0.15255074182796013,
                -0.11410207673487738, -0.09733134807146249,
                -0.10292890743877414, -0.1308922937427246, -0.1805239659355377,
                -0.2504499579051467, -0.3386590047146867, -0.44256106709679505,
                -0.5590635873799623, -0.6846632630630535, -0.815550640985574,
                -0.9477244305164888, -1.0771121212665684, -1.1996932795633306,
                -1.311621795447433, -1.4093433620932208, -1.4897045926958845,
                -1.5500504132063582, -1.5883067062020428, -1.6030456124698598,
                -1.5935314097796431, -1.5597454680069975, -1.5023894090545546,
                -1.4228662599912019, -1.3232400588738096, -1.206175034252345,
                -1.074856111415417, -0.9328930813842844, -0.7842112845884444,
                -0.6329320936472256, -0.4832468151436635, -0.3392878575797247,
                -0.20500112420213945, -0.08402358063109516,
                0.020430182333515123, 0.1056688179630243, 0.1696093110666259,
                0.21084353026992098, 0.22868404040136636, 0.22318759136929955,
                0.1951553751763504, 0.14610985204001614, 0.07824866876831382,
                -0.005623094280311658, -0.10218042893269716,
                -0.20768310832772915, -0.3180944910210983, -0.42921084180154867,
                -0.5367972254247938, -0.6367257806045519, -0.7251120580614213,
                -0.7984451134538029, -0.8537071847368982, -0.8884790512904908,
                -0.9010275623615027, -0.8903723246144183, -0.856329139085836,
                -0.7995284596129897, -0.7214078883042158, -0.6241785072707651,
                -0.5107656465279403, -0.3847254818625918, -0.2501396197367214,
                -0.11149053553488425, 0.026478635085301266, 0.15891591444437886,
                0.28101944891803954, 0.38819886395923703, 0.4762340899721891,
                0.5414267262383203, 0.5807391423752483, 0.59191679418285,
                0.5735896470166395, 0.5253491447856149, 0.4477978219090979,
                0.34256941153425147, 0.21231813501723762, 0.06067674192888761,
                -0.10781621843518274, -0.28781850018498734, -0.4733133571704514,
                -0.6577627150335442, -0.8342804419378984, -0.9958211809722382,
                -1.1353796672682948, -1.246195050257246, -1.3219544897356839,
                -1.35699020271272, -1.3464642114190717, -1.2865352813746633,
                -1.1745029375424014, -1.008923997148252, -0.7896977460434282,
                -0.5181166938512269, -0.196880750218285, 0.16992635409940626,
                0.5768985485310036, 1.017405905449476, 1.4837281851814472,
                1.9672205053356389, 2.458507265301362, 2.947699620345216,
                3.4246310823435104, 3.879105247113731, 4.301149229549701,
                4.681266140028566, 5.0106798684022165, 5.281565558169843,
                5.487259452543147, 5.622442269135706, 5.683290899762682,
                5.667594020221235, 5.574828111523958, 5.406191414981613,
                5.164594441527788, 4.8546068013657315, 4.4823612824220005,
                4.055417253533312, 3.5825865692399956, 3.073726177369674,
                2.539502549415808, 1.991133841763118, 1.4401163303030136,
                0.8979421242342501, 0.37581544284943225, -0.11562517663251448,
                -0.5665714846490384, -0.9682899904455786, -1.3133398461263088,
                -1.5957583610330976, -1.8112091917018212, -1.9570892162743505,
                -2.03259117615526, -2.0387203233487017, -1.9782645183010161,
                -1.85571844778808, -1.6771638424899722, -1.4501087367181407,
                -1.1832898968429304, -0.8864435210299056, -0.5700501544761499,
                -0.24506044855279518, 0.0773910992980763, 0.3862769693351036,
                0.6709644309310395, 0.9214928467072085, 1.1288332548975708,
                1.285123487623173, 1.3838727338024692, 1.4201302676649445,
                1.3906140152995599, 1.293795696496778, 1.1299404286008916,
                0.9010998820511364, 0.6110593013512626, 0.26523991742826936,
                -0.1294405548999431, -0.5647455484129753, -1.031330630604056,
                -1.518982216555111, -2.0168776745664445, -2.513859960084448,
                -2.9987195671610305, -3.4604764341352947, -3.8886544842320614,
                -4.27354172057355, -4.606429220797224, -4.879822976751299,
                -5.087623282308601, -5.2252672657869015, -5.289831167910269,
                -5.280090053670695, -5.196533787198831, -5.041339261406627,
                -4.818300027346737, -4.532715580815287, -4.191243605979435,
                -3.801719420166503, -3.3729476857725955, -2.914472133286692,
                -2.4363295567671055, -1.948794687250823, -1.4621227127241676,
                -0.9862961931825762, -0.5307829179005268, -0.10431087729524147,
                0.285334014819832, 0.6314824942953803, 0.9287246673928246,
                1.1730176395180767, 1.3617557958792148, 1.4938025092286322,
                1.5694831000686205, 1.5905399148155308, 1.5600513891363559,
                1.4823178999773838, 1.3627180558919192, 1.2075398090963683,
                1.0237913756278245, 0.8189974073005217, 0.6009861602961561,
                0.3776735442183127, 0.15684991083513578, -0.05402474331965723,
                -0.24801182412795852, -0.41886657941900496, -0.5611876777035902,
                -0.6705403839461005, -0.7435505861189292, -0.7779677835735811,
                -0.7726960418669289, -0.7277928228706195, -0.6444364882806253,
                -0.5248641241385505, -0.37228212056543597, -0.19075264337036424,
                0.014940265704653122, 0.2394407408224678,
              ],
              type: "scatter",
            },
          ],
          xaxis: {
            range: [
              "2016-12-13T18:54:02.958904115",
              "2018-01-18T11:04:57.863013581",
            ],
            tickformat: "%B %e",
            type: "date",
          },
          yaxis: {
            title: {
              text: "yearly",
            },
            zerolinecolor: "#AAA",
            tickformat: "%",
            hoverformat: ".2%",
          },
        },
      },
    },
  },
  {
    name: "covid-19_2024-05-09_08:33:02",
    forecast_plot: {
      data: [
        {
          marker: {
            color: "black",
            size: 4,
          },
          mode: "markers",
          name: "Actual",
          x: [
            "2021-05-30T00:00:00",
            "2021-06-06T00:00:00",
            "2021-06-13T00:00:00",
            "2021-06-20T00:00:00",
            "2021-06-27T00:00:00",
            "2021-07-04T00:00:00",
            "2021-07-11T00:00:00",
            "2021-07-18T00:00:00",
            "2021-07-25T00:00:00",
            "2021-08-01T00:00:00",
            "2021-08-08T00:00:00",
            "2021-08-15T00:00:00",
            "2021-08-22T00:00:00",
            "2021-08-29T00:00:00",
            "2021-09-05T00:00:00",
            "2021-09-12T00:00:00",
            "2021-09-19T00:00:00",
            "2021-09-26T00:00:00",
            "2021-10-03T00:00:00",
            "2021-10-10T00:00:00",
            "2021-10-17T00:00:00",
            "2021-10-24T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-07T00:00:00",
            "2021-11-14T00:00:00",
            "2021-11-21T00:00:00",
            "2021-11-28T00:00:00",
            "2021-12-05T00:00:00",
            "2021-12-12T00:00:00",
            "2021-12-19T00:00:00",
            "2021-12-26T00:00:00",
            "2022-01-02T00:00:00",
            "2022-01-09T00:00:00",
            "2022-01-16T00:00:00",
            "2022-01-23T00:00:00",
            "2022-01-30T00:00:00",
            "2022-02-06T00:00:00",
            "2022-02-13T00:00:00",
            "2022-02-20T00:00:00",
            "2022-02-27T00:00:00",
            "2022-03-06T00:00:00",
            "2022-03-13T00:00:00",
            "2022-03-20T00:00:00",
            "2022-03-27T00:00:00",
            "2022-04-03T00:00:00",
            "2022-04-10T00:00:00",
            "2022-04-17T00:00:00",
            "2022-04-24T00:00:00",
            "2022-05-01T00:00:00",
            "2022-05-08T00:00:00",
            "2022-05-15T00:00:00",
            "2022-05-22T00:00:00",
            "2022-05-29T00:00:00",
            "2022-06-05T00:00:00",
            "2022-06-12T00:00:00",
            "2022-06-19T00:00:00",
            "2022-06-26T00:00:00",
            "2022-07-03T00:00:00",
            "2022-07-10T00:00:00",
            "2022-07-17T00:00:00",
            "2022-07-24T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-07T00:00:00",
            "2022-08-14T00:00:00",
            "2022-08-21T00:00:00",
            "2022-08-28T00:00:00",
            "2022-09-04T00:00:00",
            "2022-09-11T00:00:00",
            "2022-09-18T00:00:00",
            "2022-09-25T00:00:00",
            "2022-10-02T00:00:00",
            "2022-10-09T00:00:00",
            "2022-10-16T00:00:00",
            "2022-10-23T00:00:00",
            "2022-10-30T00:00:00",
            "2022-11-06T00:00:00",
            "2022-11-13T00:00:00",
            "2022-11-20T00:00:00",
            "2022-11-27T00:00:00",
            "2022-12-04T00:00:00",
            "2022-12-11T00:00:00",
            "2022-12-18T00:00:00",
            "2022-12-25T00:00:00",
            "2023-01-01T00:00:00",
            "2023-01-08T00:00:00",
            "2023-01-15T00:00:00",
            "2023-01-22T00:00:00",
            "2023-01-29T00:00:00",
            "2023-02-05T00:00:00",
            "2023-02-12T00:00:00",
            "2023-02-19T00:00:00",
            "2023-02-26T00:00:00",
            "2023-03-05T00:00:00",
            "2023-03-12T00:00:00",
            "2023-03-19T00:00:00",
            "2023-03-26T00:00:00",
            "2023-04-02T00:00:00",
            "2023-04-09T00:00:00",
            "2023-04-16T00:00:00",
            "2023-04-23T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-07T00:00:00",
            "2023-05-14T00:00:00",
            "2023-05-21T00:00:00",
            "2023-05-28T00:00:00",
            "2023-06-04T00:00:00",
            "2023-06-11T00:00:00",
            "2023-06-18T00:00:00",
            "2023-06-25T00:00:00",
            "2023-07-02T00:00:00",
            "2023-07-09T00:00:00",
            "2023-07-16T00:00:00",
            "2023-07-23T00:00:00",
            "2023-07-30T00:00:00",
            "2023-08-06T00:00:00",
            "2023-08-13T00:00:00",
            "2023-08-20T00:00:00",
            "2023-08-27T00:00:00",
          ],
          y: [
            2, 30, 9, 10, 41, 14, 16, 24, 28, 18, 29, 12, 45, 31, 18, 22, 36,
            23, 16, 13, 14, 11, 7, 7, 19, 68, 73, 43, 86, 41, 76, 92, 53, 30,
            30, 71, 42, 23, 47, 33, 23, 22, 15, 26, 11, 13, 10, 13, 3, 11, 10,
            24, 7, 21, 20, 26, 38, 42, 31, 31, 45, 44, 52, 40, 43, 39, 30, 28,
            32, 27, 26, 42, 36, 35, 42, 40, 31, 23, 36, 21, 38, 18, 24, 11, 28,
            15, 21, 13, 19, 19, 15, 16, 14, 14, 10, 5, 11, 11, 6, 4, 7, 8, 5, 2,
            2, 2, 5, 0, 0, 0, 0, 7, 3, 2, 6, 6, 7, 1,
          ],
          type: "scatter",
        },
        {
          legendgroup: "Trend Line",
          line: {
            color: "#0072B2",
            width: 2,
          },
          mode: "lines",
          name: "Trend Line",
          x: [
            "2021-05-30T00:00:00",
            "2021-06-06T00:00:00",
            "2021-06-13T00:00:00",
            "2021-06-20T00:00:00",
            "2021-06-27T00:00:00",
            "2021-07-04T00:00:00",
            "2021-07-11T00:00:00",
            "2021-07-18T00:00:00",
            "2021-07-25T00:00:00",
            "2021-08-01T00:00:00",
            "2021-08-08T00:00:00",
            "2021-08-15T00:00:00",
            "2021-08-22T00:00:00",
            "2021-08-29T00:00:00",
            "2021-09-05T00:00:00",
            "2021-09-12T00:00:00",
            "2021-09-19T00:00:00",
            "2021-09-26T00:00:00",
            "2021-10-03T00:00:00",
            "2021-10-10T00:00:00",
            "2021-10-17T00:00:00",
            "2021-10-24T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-07T00:00:00",
            "2021-11-14T00:00:00",
            "2021-11-21T00:00:00",
            "2021-11-28T00:00:00",
            "2021-12-05T00:00:00",
            "2021-12-12T00:00:00",
            "2021-12-19T00:00:00",
            "2021-12-26T00:00:00",
            "2022-01-02T00:00:00",
            "2022-01-09T00:00:00",
            "2022-01-16T00:00:00",
            "2022-01-23T00:00:00",
            "2022-01-30T00:00:00",
            "2022-02-06T00:00:00",
            "2022-02-13T00:00:00",
            "2022-02-20T00:00:00",
            "2022-02-27T00:00:00",
            "2022-03-06T00:00:00",
            "2022-03-13T00:00:00",
            "2022-03-20T00:00:00",
            "2022-03-27T00:00:00",
            "2022-04-03T00:00:00",
            "2022-04-10T00:00:00",
            "2022-04-17T00:00:00",
            "2022-04-24T00:00:00",
            "2022-05-01T00:00:00",
            "2022-05-08T00:00:00",
            "2022-05-15T00:00:00",
            "2022-05-22T00:00:00",
            "2022-05-29T00:00:00",
            "2022-06-05T00:00:00",
            "2022-06-12T00:00:00",
            "2022-06-19T00:00:00",
            "2022-06-26T00:00:00",
            "2022-07-03T00:00:00",
            "2022-07-10T00:00:00",
            "2022-07-17T00:00:00",
            "2022-07-24T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-07T00:00:00",
            "2022-08-14T00:00:00",
            "2022-08-21T00:00:00",
            "2022-08-28T00:00:00",
            "2022-09-04T00:00:00",
            "2022-09-11T00:00:00",
            "2022-09-18T00:00:00",
            "2022-09-25T00:00:00",
            "2022-10-02T00:00:00",
            "2022-10-09T00:00:00",
            "2022-10-16T00:00:00",
            "2022-10-23T00:00:00",
            "2022-10-30T00:00:00",
            "2022-11-06T00:00:00",
            "2022-11-13T00:00:00",
            "2022-11-20T00:00:00",
            "2022-11-27T00:00:00",
            "2022-12-04T00:00:00",
            "2022-12-11T00:00:00",
            "2022-12-18T00:00:00",
            "2022-12-25T00:00:00",
            "2023-01-01T00:00:00",
            "2023-01-08T00:00:00",
            "2023-01-15T00:00:00",
            "2023-01-22T00:00:00",
            "2023-01-29T00:00:00",
            "2023-02-05T00:00:00",
            "2023-02-12T00:00:00",
            "2023-02-19T00:00:00",
            "2023-02-26T00:00:00",
            "2023-03-05T00:00:00",
            "2023-03-12T00:00:00",
            "2023-03-19T00:00:00",
            "2023-03-26T00:00:00",
            "2023-04-02T00:00:00",
            "2023-04-09T00:00:00",
            "2023-04-16T00:00:00",
            "2023-04-23T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-07T00:00:00",
            "2023-05-14T00:00:00",
            "2023-05-21T00:00:00",
            "2023-05-28T00:00:00",
            "2023-06-04T00:00:00",
            "2023-06-11T00:00:00",
            "2023-06-18T00:00:00",
            "2023-06-25T00:00:00",
            "2023-07-02T00:00:00",
            "2023-07-09T00:00:00",
            "2023-07-16T00:00:00",
            "2023-07-23T00:00:00",
            "2023-07-30T00:00:00",
            "2023-08-06T00:00:00",
            "2023-08-13T00:00:00",
            "2023-08-20T00:00:00",
            "2023-08-27T00:00:00",
          ],
          y: [
            10.081690838608433, 10.785414820549647, 13.294540730435742,
            17.205661317685582, 20.11234471088498, 20.33114216222379,
            19.12815887828639, 19.414961538168903, 22.253531516875306,
            25.49075066324729, 26.380457221205475, 25.016603399824955,
            24.218398839479047, 25.91259387148027, 28.584742595562197,
            29.144901099886493, 26.925912173839983, 24.645979728703047,
            24.917180798941956, 26.70028134417461, 26.495144764399036,
            23.167968522161694, 20.39386145741288, 23.164750459245266,
            32.024120938239456, 41.83418620084669, 46.82279798463583,
            46.27299336609338, 44.411444482007354, 44.87759319262304,
            46.448244992430304, 44.963955459195475, 38.88016486276015,
            31.65154126825754, 28.140715733563315, 29.376226673173303,
            31.70591417962622, 30.991850688947565, 26.764804183811403,
            21.877084179081532, 18.810866804166736, 17.326162581789568,
            15.733699216075028, 13.428368945878448, 11.32585910565859,
            10.157337397238717, 9.38173044520184, 8.202036109989331,
            6.9910180475904795, 6.895102672818556, 8.128016052767908,
            9.49573094676509, 10.037184799457611, 10.556844966065542,
            12.696533121652106, 16.50137932970493, 19.775643034542732,
            20.472392999899228, 19.312995637339235, 19.161257549956044,
            21.624068693392946, 25.02899769612147, 26.43910653623833,
            25.312807537696663, 24.192398903810787, 25.45141187733446,
            28.184701888897514, 29.282586683131285, 27.43147558841281,
            24.904398165180165, 24.673331437662608, 26.428476567084196,
            26.79282164568965, 23.87935009424311, 20.596181699548026,
            22.15179982593906, 30.175429594140574, 40.313820841304874,
            46.40327366991497, 46.61943357571893, 44.62413415216023,
            44.61108190259342, 46.27495914746764, 45.59099895076897,
            40.202130408527196, 32.78328442903127, 28.375368031746888,
            28.93189781586105, 31.427692056491164, 31.423052178291936,
            27.66878468259056, 22.65558145133708, 19.200805865768455,
            17.5530096519314, 16.078628720432924, 13.86102195052059,
            11.637557444541939, 10.307760852187958, 9.537339653499203,
            8.444336064220131, 7.155908431339721, 6.798501492202181,
            7.853480861841788, 9.303936748253117, 9.989144332391575,
            10.384792662175814, 12.158711014205169, 15.779927385166738,
            19.34824698273987, 20.547736062054682, 19.533767181822196,
            19.00109281975475, 21.028621748329638, 24.496205995635385,
            26.407241943945976, 25.60968158501234, 24.252505516567517,
            25.044186066684755,
          ],
          type: "scatter",
        },
        {
          legendgroup: "Predicted",
          line: {
            color: "#33cc33",
            width: 2,
          },
          mode: "lines",
          name: "Predicted",
          x: [
            "2023-08-27T00:00:00",
            "2023-09-03T00:00:00",
            "2023-09-10T00:00:00",
            "2023-09-17T00:00:00",
            "2023-09-24T00:00:00",
            "2023-10-01T00:00:00",
            "2023-10-08T00:00:00",
            "2023-10-15T00:00:00",
            "2023-10-22T00:00:00",
            "2023-10-29T00:00:00",
            "2023-11-05T00:00:00",
            "2023-11-12T00:00:00",
            "2023-11-19T00:00:00",
            "2023-11-26T00:00:00",
            "2023-12-03T00:00:00",
            "2023-12-10T00:00:00",
            "2023-12-17T00:00:00",
            "2023-12-24T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-07T00:00:00",
            "2024-01-14T00:00:00",
            "2024-01-21T00:00:00",
            "2024-01-28T00:00:00",
            "2024-02-04T00:00:00",
            "2024-02-11T00:00:00",
            "2024-02-18T00:00:00",
            "2024-02-25T00:00:00",
            "2024-03-03T00:00:00",
            "2024-03-10T00:00:00",
            "2024-03-17T00:00:00",
            "2024-03-24T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-07T00:00:00",
            "2024-04-14T00:00:00",
            "2024-04-21T00:00:00",
            "2024-04-28T00:00:00",
            "2024-05-05T00:00:00",
            "2024-05-12T00:00:00",
            "2024-05-19T00:00:00",
            "2024-05-26T00:00:00",
            "2024-06-02T00:00:00",
            "2024-06-09T00:00:00",
            "2024-06-16T00:00:00",
            "2024-06-23T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-07T00:00:00",
            "2024-07-14T00:00:00",
            "2024-07-21T00:00:00",
            "2024-07-28T00:00:00",
            "2024-08-04T00:00:00",
            "2024-08-11T00:00:00",
            "2024-08-18T00:00:00",
            "2024-08-25T00:00:00",
            "2024-09-01T00:00:00",
            "2024-09-08T00:00:00",
            "2024-09-15T00:00:00",
            "2024-09-22T00:00:00",
            "2024-09-29T00:00:00",
            "2024-10-06T00:00:00",
            "2024-10-13T00:00:00",
            "2024-10-20T00:00:00",
            "2024-10-27T00:00:00",
            "2024-11-03T00:00:00",
            "2024-11-10T00:00:00",
            "2024-11-17T00:00:00",
            "2024-11-24T00:00:00",
            "2024-12-01T00:00:00",
            "2024-12-08T00:00:00",
            "2024-12-15T00:00:00",
            "2024-12-22T00:00:00",
            "2024-12-29T00:00:00",
            "2025-01-05T00:00:00",
            "2025-01-12T00:00:00",
            "2025-01-19T00:00:00",
            "2025-01-26T00:00:00",
            "2025-02-02T00:00:00",
            "2025-02-09T00:00:00",
            "2025-02-16T00:00:00",
            "2025-02-23T00:00:00",
          ],
          y: [
            25.044186066684755, 27.726954275802036, 29.31498801809619,
            27.913188220905504, 25.246260278004296, 24.502593649814585,
            26.107996144022184, 26.97118093378512, 24.571558217189928,
            20.96344918986881, 21.36326346817332, 28.399671152871733,
            38.651069159839224, 45.77977828521625, 46.89397274218388,
            44.914079702827706, 44.40955632111764, 46.02164038827516,
            46.05251041327094, 41.452118195334705, 34.02051016875781,
            28.783427647921496, 28.553439231910158, 31.059780382149896,
            31.723415594586015, 28.532067294694443, 23.49193781672892,
            19.65398419589968, 17.78276766355614, 16.39454204659376,
            14.293149630255414, 11.981870700858089, 10.47781428557794,
            9.679358182214447, 8.67709367991115, 7.352487727625618,
            6.75177747056382, 7.592138647865454, 9.082564672858812,
            9.927929605609892, 10.26027453382491, 11.687245390021662,
            15.05829277465461, 18.837763570642196, 20.544567242313526,
            19.771692717204644, 18.932467287914633, 20.484964885395588,
            23.908073931746387, 26.27870674821041, 25.88692069943202,
            24.39037887199254, 24.705833467199884, 27.23095308120596,
            29.240719145052413, 28.34987722911474, 25.658028898100408,
            24.417903453849064, 25.76238665969452, 27.031437598743608,
            25.21522877160452, 21.466112740984748, 20.80469698583189,
            26.735301537779907, 36.87819180968076, 44.946787079232124,
            47.065024979339654, 45.26337915829136, 44.28896902036276,
            45.715883118243056, 46.35138815684021, 42.601928353612166,
            35.334518674167924, 29.36661178161623, 28.269018315058936,
            30.625017731278394, 31.88865740193072, 29.333622601150555,
            24.373986626419786,
          ],
          type: "scatter",
        },
        {
          hoverinfo: "skip",
          legendgroup: "Trend Line",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2021-05-30T00:00:00",
            "2021-06-06T00:00:00",
            "2021-06-13T00:00:00",
            "2021-06-20T00:00:00",
            "2021-06-27T00:00:00",
            "2021-07-04T00:00:00",
            "2021-07-11T00:00:00",
            "2021-07-18T00:00:00",
            "2021-07-25T00:00:00",
            "2021-08-01T00:00:00",
            "2021-08-08T00:00:00",
            "2021-08-15T00:00:00",
            "2021-08-22T00:00:00",
            "2021-08-29T00:00:00",
            "2021-09-05T00:00:00",
            "2021-09-12T00:00:00",
            "2021-09-19T00:00:00",
            "2021-09-26T00:00:00",
            "2021-10-03T00:00:00",
            "2021-10-10T00:00:00",
            "2021-10-17T00:00:00",
            "2021-10-24T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-07T00:00:00",
            "2021-11-14T00:00:00",
            "2021-11-21T00:00:00",
            "2021-11-28T00:00:00",
            "2021-12-05T00:00:00",
            "2021-12-12T00:00:00",
            "2021-12-19T00:00:00",
            "2021-12-26T00:00:00",
            "2022-01-02T00:00:00",
            "2022-01-09T00:00:00",
            "2022-01-16T00:00:00",
            "2022-01-23T00:00:00",
            "2022-01-30T00:00:00",
            "2022-02-06T00:00:00",
            "2022-02-13T00:00:00",
            "2022-02-20T00:00:00",
            "2022-02-27T00:00:00",
            "2022-03-06T00:00:00",
            "2022-03-13T00:00:00",
            "2022-03-20T00:00:00",
            "2022-03-27T00:00:00",
            "2022-04-03T00:00:00",
            "2022-04-10T00:00:00",
            "2022-04-17T00:00:00",
            "2022-04-24T00:00:00",
            "2022-05-01T00:00:00",
            "2022-05-08T00:00:00",
            "2022-05-15T00:00:00",
            "2022-05-22T00:00:00",
            "2022-05-29T00:00:00",
            "2022-06-05T00:00:00",
            "2022-06-12T00:00:00",
            "2022-06-19T00:00:00",
            "2022-06-26T00:00:00",
            "2022-07-03T00:00:00",
            "2022-07-10T00:00:00",
            "2022-07-17T00:00:00",
            "2022-07-24T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-07T00:00:00",
            "2022-08-14T00:00:00",
            "2022-08-21T00:00:00",
            "2022-08-28T00:00:00",
            "2022-09-04T00:00:00",
            "2022-09-11T00:00:00",
            "2022-09-18T00:00:00",
            "2022-09-25T00:00:00",
            "2022-10-02T00:00:00",
            "2022-10-09T00:00:00",
            "2022-10-16T00:00:00",
            "2022-10-23T00:00:00",
            "2022-10-30T00:00:00",
            "2022-11-06T00:00:00",
            "2022-11-13T00:00:00",
            "2022-11-20T00:00:00",
            "2022-11-27T00:00:00",
            "2022-12-04T00:00:00",
            "2022-12-11T00:00:00",
            "2022-12-18T00:00:00",
            "2022-12-25T00:00:00",
            "2023-01-01T00:00:00",
            "2023-01-08T00:00:00",
            "2023-01-15T00:00:00",
            "2023-01-22T00:00:00",
            "2023-01-29T00:00:00",
            "2023-02-05T00:00:00",
            "2023-02-12T00:00:00",
            "2023-02-19T00:00:00",
            "2023-02-26T00:00:00",
            "2023-03-05T00:00:00",
            "2023-03-12T00:00:00",
            "2023-03-19T00:00:00",
            "2023-03-26T00:00:00",
            "2023-04-02T00:00:00",
            "2023-04-09T00:00:00",
            "2023-04-16T00:00:00",
            "2023-04-23T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-07T00:00:00",
            "2023-05-14T00:00:00",
            "2023-05-21T00:00:00",
            "2023-05-28T00:00:00",
            "2023-06-04T00:00:00",
            "2023-06-11T00:00:00",
            "2023-06-18T00:00:00",
            "2023-06-25T00:00:00",
            "2023-07-02T00:00:00",
            "2023-07-09T00:00:00",
            "2023-07-16T00:00:00",
            "2023-07-23T00:00:00",
            "2023-07-30T00:00:00",
            "2023-08-06T00:00:00",
            "2023-08-13T00:00:00",
            "2023-08-20T00:00:00",
            "2023-08-27T00:00:00",
          ],
          y: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3.253783204020108,
            0.3394369949091636, 0, 0, 0, 0, 0, 0, 0, 0, 2.2196882685029498,
            15.142282664251468, 19.289861880029544, 18.661553643767903,
            16.0662330695232, 14.600532148323977, 15.795729156749912,
            15.188939140927253, 8.403145573319673, 4.00900821270651, 0,
            1.0703907514227724, 1.8602057675971342, 0.4279958017579491, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 1.7102310302242036, 0.004476105317291178, 0, 0, 0, 0, 0, 0,
            0, 0.16628567009212952, 9.988877012811148, 16.149153564102946,
            15.508583764142628, 13.752942114548905, 16.884446991413324,
            17.36537362831728, 15.567872013437661, 10.964543988094977,
            1.9035940480816782, 0, 0, 1.6312162403030457, 1.612948115457641, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0,
          ],
          type: "scatter",
        },
        {
          fill: "tonexty",
          fillcolor: "rgba(0, 114, 178, 0.2)",
          hoverinfo: "skip",
          legendgroup: "Trend Line",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2021-05-30T00:00:00",
            "2021-06-06T00:00:00",
            "2021-06-13T00:00:00",
            "2021-06-20T00:00:00",
            "2021-06-27T00:00:00",
            "2021-07-04T00:00:00",
            "2021-07-11T00:00:00",
            "2021-07-18T00:00:00",
            "2021-07-25T00:00:00",
            "2021-08-01T00:00:00",
            "2021-08-08T00:00:00",
            "2021-08-15T00:00:00",
            "2021-08-22T00:00:00",
            "2021-08-29T00:00:00",
            "2021-09-05T00:00:00",
            "2021-09-12T00:00:00",
            "2021-09-19T00:00:00",
            "2021-09-26T00:00:00",
            "2021-10-03T00:00:00",
            "2021-10-10T00:00:00",
            "2021-10-17T00:00:00",
            "2021-10-24T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-07T00:00:00",
            "2021-11-14T00:00:00",
            "2021-11-21T00:00:00",
            "2021-11-28T00:00:00",
            "2021-12-05T00:00:00",
            "2021-12-12T00:00:00",
            "2021-12-19T00:00:00",
            "2021-12-26T00:00:00",
            "2022-01-02T00:00:00",
            "2022-01-09T00:00:00",
            "2022-01-16T00:00:00",
            "2022-01-23T00:00:00",
            "2022-01-30T00:00:00",
            "2022-02-06T00:00:00",
            "2022-02-13T00:00:00",
            "2022-02-20T00:00:00",
            "2022-02-27T00:00:00",
            "2022-03-06T00:00:00",
            "2022-03-13T00:00:00",
            "2022-03-20T00:00:00",
            "2022-03-27T00:00:00",
            "2022-04-03T00:00:00",
            "2022-04-10T00:00:00",
            "2022-04-17T00:00:00",
            "2022-04-24T00:00:00",
            "2022-05-01T00:00:00",
            "2022-05-08T00:00:00",
            "2022-05-15T00:00:00",
            "2022-05-22T00:00:00",
            "2022-05-29T00:00:00",
            "2022-06-05T00:00:00",
            "2022-06-12T00:00:00",
            "2022-06-19T00:00:00",
            "2022-06-26T00:00:00",
            "2022-07-03T00:00:00",
            "2022-07-10T00:00:00",
            "2022-07-17T00:00:00",
            "2022-07-24T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-07T00:00:00",
            "2022-08-14T00:00:00",
            "2022-08-21T00:00:00",
            "2022-08-28T00:00:00",
            "2022-09-04T00:00:00",
            "2022-09-11T00:00:00",
            "2022-09-18T00:00:00",
            "2022-09-25T00:00:00",
            "2022-10-02T00:00:00",
            "2022-10-09T00:00:00",
            "2022-10-16T00:00:00",
            "2022-10-23T00:00:00",
            "2022-10-30T00:00:00",
            "2022-11-06T00:00:00",
            "2022-11-13T00:00:00",
            "2022-11-20T00:00:00",
            "2022-11-27T00:00:00",
            "2022-12-04T00:00:00",
            "2022-12-11T00:00:00",
            "2022-12-18T00:00:00",
            "2022-12-25T00:00:00",
            "2023-01-01T00:00:00",
            "2023-01-08T00:00:00",
            "2023-01-15T00:00:00",
            "2023-01-22T00:00:00",
            "2023-01-29T00:00:00",
            "2023-02-05T00:00:00",
            "2023-02-12T00:00:00",
            "2023-02-19T00:00:00",
            "2023-02-26T00:00:00",
            "2023-03-05T00:00:00",
            "2023-03-12T00:00:00",
            "2023-03-19T00:00:00",
            "2023-03-26T00:00:00",
            "2023-04-02T00:00:00",
            "2023-04-09T00:00:00",
            "2023-04-16T00:00:00",
            "2023-04-23T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-07T00:00:00",
            "2023-05-14T00:00:00",
            "2023-05-21T00:00:00",
            "2023-05-28T00:00:00",
            "2023-06-04T00:00:00",
            "2023-06-11T00:00:00",
            "2023-06-18T00:00:00",
            "2023-06-25T00:00:00",
            "2023-07-02T00:00:00",
            "2023-07-09T00:00:00",
            "2023-07-16T00:00:00",
            "2023-07-23T00:00:00",
            "2023-07-30T00:00:00",
            "2023-08-06T00:00:00",
            "2023-08-13T00:00:00",
            "2023-08-20T00:00:00",
            "2023-08-27T00:00:00",
          ],
          y: [
            38.95173974228139, 38.53685149798952, 43.051990018427844,
            45.644071833947, 48.847822316942306, 48.942677520340105,
            48.32398017278507, 48.36161851331517, 51.84722165080207,
            54.17437029325405, 56.11784187106439, 54.4763886393965,
            53.20120653166648, 53.737246859807854, 58.130570719123554,
            60.738912221918206, 55.43817330900639, 53.30323040686388,
            55.111055634910315, 56.58491276564663, 55.513419649700566,
            51.58178730214971, 48.08207968635001, 50.91029198275422,
            60.099405899906486, 72.11071377872693, 76.63182153237761,
            75.48092701889763, 76.2253966854512, 74.71127523221551,
            76.242645769375, 75.15693467166096, 67.36793946893647,
            62.5716640232674, 58.66002296005728, 58.28404368080805,
            61.883238943943226, 60.08897784934771, 55.01279733713251,
            51.56951083085599, 47.533236410832416, 46.79744365132928,
            46.65873242259945, 41.73469219625623, 41.56437959175675,
            39.227780725003754, 39.59281623298876, 37.432493653819705,
            35.532589681080154, 35.34551186160577, 36.113678302266024,
            41.174758841037686, 39.489836503720625, 40.90581451556068,
            41.93994345909499, 45.923531558527465, 46.79557355492637,
            49.71224263751791, 48.37221058438142, 48.37200471335647,
            50.28307701692221, 55.28867676570373, 56.94390454628978,
            54.69525964189137, 51.4602712935701, 53.52056174023451,
            56.78971257979618, 55.88790603557047, 56.543172400675594,
            55.00118081412753, 53.435932278485055, 57.849458777094405,
            58.748524898162636, 54.467573779536544, 51.91653760053176,
            51.649881410903134, 59.57425232608201, 68.46375176713073,
            75.45202856152126, 77.63621635944301, 71.27845091874694,
            74.16304746194797, 71.94098260936313, 77.43983786418993,
            67.40284507263408, 59.85883072988283, 59.018881141929434,
            56.91576860163956, 61.503364013783965, 61.845492399359244,
            55.596101556094204, 52.035477452003626, 48.50052766504199,
            46.01727193829461, 45.360017235251256, 43.10932802474023,
            40.442468934723195, 40.30170832000942, 39.8847655237892,
            37.78406559513204, 38.275340469148226, 36.989950913382806,
            35.378773827846516, 38.95464901892653, 40.55770008383868,
            39.00661047054251, 40.76710811685519, 43.78823309302462,
            49.97440969381824, 50.380691363354835, 45.83672439713245,
            47.937795941735686, 49.90618664370901, 52.99800036020437,
            54.487674763020195, 53.905118854638644, 54.08253636928715,
            53.80493727129997,
          ],
          type: "scatter",
        },
        {
          hoverinfo: "skip",
          legendgroup: "Predicted",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2023-08-27T00:00:00",
            "2023-09-03T00:00:00",
            "2023-09-10T00:00:00",
            "2023-09-17T00:00:00",
            "2023-09-24T00:00:00",
            "2023-10-01T00:00:00",
            "2023-10-08T00:00:00",
            "2023-10-15T00:00:00",
            "2023-10-22T00:00:00",
            "2023-10-29T00:00:00",
            "2023-11-05T00:00:00",
            "2023-11-12T00:00:00",
            "2023-11-19T00:00:00",
            "2023-11-26T00:00:00",
            "2023-12-03T00:00:00",
            "2023-12-10T00:00:00",
            "2023-12-17T00:00:00",
            "2023-12-24T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-07T00:00:00",
            "2024-01-14T00:00:00",
            "2024-01-21T00:00:00",
            "2024-01-28T00:00:00",
            "2024-02-04T00:00:00",
            "2024-02-11T00:00:00",
            "2024-02-18T00:00:00",
            "2024-02-25T00:00:00",
            "2024-03-03T00:00:00",
            "2024-03-10T00:00:00",
            "2024-03-17T00:00:00",
            "2024-03-24T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-07T00:00:00",
            "2024-04-14T00:00:00",
            "2024-04-21T00:00:00",
            "2024-04-28T00:00:00",
            "2024-05-05T00:00:00",
            "2024-05-12T00:00:00",
            "2024-05-19T00:00:00",
            "2024-05-26T00:00:00",
            "2024-06-02T00:00:00",
            "2024-06-09T00:00:00",
            "2024-06-16T00:00:00",
            "2024-06-23T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-07T00:00:00",
            "2024-07-14T00:00:00",
            "2024-07-21T00:00:00",
            "2024-07-28T00:00:00",
            "2024-08-04T00:00:00",
            "2024-08-11T00:00:00",
            "2024-08-18T00:00:00",
            "2024-08-25T00:00:00",
            "2024-09-01T00:00:00",
            "2024-09-08T00:00:00",
            "2024-09-15T00:00:00",
            "2024-09-22T00:00:00",
            "2024-09-29T00:00:00",
            "2024-10-06T00:00:00",
            "2024-10-13T00:00:00",
            "2024-10-20T00:00:00",
            "2024-10-27T00:00:00",
            "2024-11-03T00:00:00",
            "2024-11-10T00:00:00",
            "2024-11-17T00:00:00",
            "2024-11-24T00:00:00",
            "2024-12-01T00:00:00",
            "2024-12-08T00:00:00",
            "2024-12-15T00:00:00",
            "2024-12-22T00:00:00",
            "2024-12-29T00:00:00",
            "2025-01-05T00:00:00",
            "2025-01-12T00:00:00",
            "2025-01-19T00:00:00",
            "2025-01-26T00:00:00",
            "2025-02-02T00:00:00",
            "2025-02-09T00:00:00",
            "2025-02-16T00:00:00",
            "2025-02-23T00:00:00",
          ],
          y: [
            0, 0, 0, 1.0294406244111525, 0, 0, 0, 0, 0, 0, 0, 0,
            8.420752142178635, 16.931357649263354, 17.445551829306265,
            15.59231887082182, 15.785042905324323, 15.014062241725497,
            16.850243687868225, 12.273015012372978, 3.3447613624581103, 0, 0,
            2.861459464724565, 1.0609784478542728, 0.8003539919644739, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 11.52173996411261,
            17.047967304859068, 19.3454234323538, 16.380893151151746,
            13.158625118360002, 15.037203658336216, 19.921709774396675,
            11.93366330389097, 8.370086996072697, 0, 0.16132929368077759, 0,
            1.9904663023454185, 1.036597468248053, 0,
          ],
          type: "scatter",
        },
        {
          fill: "tonexty",
          fillcolor: "rgba(51, 204, 51, 0.2)",
          hoverinfo: "skip",
          legendgroup: "Predicted",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2023-08-27T00:00:00",
            "2023-09-03T00:00:00",
            "2023-09-10T00:00:00",
            "2023-09-17T00:00:00",
            "2023-09-24T00:00:00",
            "2023-10-01T00:00:00",
            "2023-10-08T00:00:00",
            "2023-10-15T00:00:00",
            "2023-10-22T00:00:00",
            "2023-10-29T00:00:00",
            "2023-11-05T00:00:00",
            "2023-11-12T00:00:00",
            "2023-11-19T00:00:00",
            "2023-11-26T00:00:00",
            "2023-12-03T00:00:00",
            "2023-12-10T00:00:00",
            "2023-12-17T00:00:00",
            "2023-12-24T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-07T00:00:00",
            "2024-01-14T00:00:00",
            "2024-01-21T00:00:00",
            "2024-01-28T00:00:00",
            "2024-02-04T00:00:00",
            "2024-02-11T00:00:00",
            "2024-02-18T00:00:00",
            "2024-02-25T00:00:00",
            "2024-03-03T00:00:00",
            "2024-03-10T00:00:00",
            "2024-03-17T00:00:00",
            "2024-03-24T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-07T00:00:00",
            "2024-04-14T00:00:00",
            "2024-04-21T00:00:00",
            "2024-04-28T00:00:00",
            "2024-05-05T00:00:00",
            "2024-05-12T00:00:00",
            "2024-05-19T00:00:00",
            "2024-05-26T00:00:00",
            "2024-06-02T00:00:00",
            "2024-06-09T00:00:00",
            "2024-06-16T00:00:00",
            "2024-06-23T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-07T00:00:00",
            "2024-07-14T00:00:00",
            "2024-07-21T00:00:00",
            "2024-07-28T00:00:00",
            "2024-08-04T00:00:00",
            "2024-08-11T00:00:00",
            "2024-08-18T00:00:00",
            "2024-08-25T00:00:00",
            "2024-09-01T00:00:00",
            "2024-09-08T00:00:00",
            "2024-09-15T00:00:00",
            "2024-09-22T00:00:00",
            "2024-09-29T00:00:00",
            "2024-10-06T00:00:00",
            "2024-10-13T00:00:00",
            "2024-10-20T00:00:00",
            "2024-10-27T00:00:00",
            "2024-11-03T00:00:00",
            "2024-11-10T00:00:00",
            "2024-11-17T00:00:00",
            "2024-11-24T00:00:00",
            "2024-12-01T00:00:00",
            "2024-12-08T00:00:00",
            "2024-12-15T00:00:00",
            "2024-12-22T00:00:00",
            "2024-12-29T00:00:00",
            "2025-01-05T00:00:00",
            "2025-01-12T00:00:00",
            "2025-01-19T00:00:00",
            "2025-01-26T00:00:00",
            "2025-02-02T00:00:00",
            "2025-02-09T00:00:00",
            "2025-02-16T00:00:00",
            "2025-02-23T00:00:00",
          ],
          y: [
            53.80493727129997, 58.12086602278986, 58.58674526073278,
            56.79109012019406, 53.96428761320164, 53.880141289315446,
            54.713914122041984, 55.6700320611111, 54.190871091091516,
            52.33980647810646, 51.14781968261533, 59.26998469902359,
            67.91660817934017, 73.93660453702219, 74.91459429314544,
            74.51916998640084, 74.19620257025477, 75.04245195291486,
            73.6842392117698, 71.8643419234052, 63.987866433480725,
            58.13091448311969, 60.015341154311265, 61.28733707965888,
            61.48769851060739, 59.045774178925036, 51.63004478924635,
            47.63511645452533, 46.461110478952754, 45.972304873797725,
            43.48614408446706, 39.667941534514206, 40.437157699618275,
            38.890335780287295, 38.17086071861291, 36.92747351648042,
            36.94037598785401, 39.18251564072521, 37.2565284178123,
            37.801891534005186, 39.94740609953686, 39.306082717113135,
            44.34936082622138, 48.81580273745969, 48.48837966821761,
            48.47986048304887, 48.40832718940972, 51.86988432885888,
            54.65327158545913, 55.09704473673907, 54.48332705104491,
            56.16935693058461, 53.01869892691691, 57.94803397075524,
            59.22729180091001, 54.83434054829397, 55.52706611833992,
            55.58239302542707, 56.2769622679184, 55.877880385977264,
            53.95289633703503, 52.06770306676969, 47.646906325570804,
            55.903592851413144, 67.000665416362, 72.58621233744867,
            75.4824473035585, 74.94925386216755, 72.9252624287158,
            75.64477083833069, 74.92815583808421, 72.1958710016884,
            63.98873942976635, 58.535665959910595, 58.11626991026001,
            58.31544774193089, 61.54836397767727, 58.622711128740924,
            54.993305398358196,
          ],
          type: "scatter",
        },
      ],
      layout: {
        showlegend: true,
        width: 850,
        height: 600,
        yaxis: {
          title: "y",
        },
        xaxis: {
          title: "ds",
          type: "date",
          rangeselector: {
            buttons: [
              {
                count: 7,
                label: "1w",
                step: "day",
                stepmode: "backward",
              },
              {
                count: 1,
                label: "1m",
                step: "month",
                stepmode: "backward",
              },
              {
                count: 6,
                label: "6m",
                step: "month",
                stepmode: "backward",
              },
              {
                count: 1,
                label: "1y",
                step: "year",
                stepmode: "backward",
              },
              {
                step: "all",
              },
            ],
          },
          rangeslider: {
            visible: true,
          },
        },
      },
    },
    forecast_components: {
      data: {
        trend: {
          traces: [
            {
              line: {
                color: "#0072B2",
                width: 2,
              },
              mode: "lines",
              name: "trend",
              x: [
                "2021-05-30T00:00:00",
                "2021-06-06T00:00:00",
                "2021-06-13T00:00:00",
                "2021-06-20T00:00:00",
                "2021-06-27T00:00:00",
                "2021-07-04T00:00:00",
                "2021-07-11T00:00:00",
                "2021-07-18T00:00:00",
                "2021-07-25T00:00:00",
                "2021-08-01T00:00:00",
                "2021-08-08T00:00:00",
                "2021-08-15T00:00:00",
                "2021-08-22T00:00:00",
                "2021-08-29T00:00:00",
                "2021-09-05T00:00:00",
                "2021-09-12T00:00:00",
                "2021-09-19T00:00:00",
                "2021-09-26T00:00:00",
                "2021-10-03T00:00:00",
                "2021-10-10T00:00:00",
                "2021-10-17T00:00:00",
                "2021-10-24T00:00:00",
                "2021-10-31T00:00:00",
                "2021-11-07T00:00:00",
                "2021-11-14T00:00:00",
                "2021-11-21T00:00:00",
                "2021-11-28T00:00:00",
                "2021-12-05T00:00:00",
                "2021-12-12T00:00:00",
                "2021-12-19T00:00:00",
                "2021-12-26T00:00:00",
                "2022-01-02T00:00:00",
                "2022-01-09T00:00:00",
                "2022-01-16T00:00:00",
                "2022-01-23T00:00:00",
                "2022-01-30T00:00:00",
                "2022-02-06T00:00:00",
                "2022-02-13T00:00:00",
                "2022-02-20T00:00:00",
                "2022-02-27T00:00:00",
                "2022-03-06T00:00:00",
                "2022-03-13T00:00:00",
                "2022-03-20T00:00:00",
                "2022-03-27T00:00:00",
                "2022-04-03T00:00:00",
                "2022-04-10T00:00:00",
                "2022-04-17T00:00:00",
                "2022-04-24T00:00:00",
                "2022-05-01T00:00:00",
                "2022-05-08T00:00:00",
                "2022-05-15T00:00:00",
                "2022-05-22T00:00:00",
                "2022-05-29T00:00:00",
                "2022-06-05T00:00:00",
                "2022-06-12T00:00:00",
                "2022-06-19T00:00:00",
                "2022-06-26T00:00:00",
                "2022-07-03T00:00:00",
                "2022-07-10T00:00:00",
                "2022-07-17T00:00:00",
                "2022-07-24T00:00:00",
                "2022-07-31T00:00:00",
                "2022-08-07T00:00:00",
                "2022-08-14T00:00:00",
                "2022-08-21T00:00:00",
                "2022-08-28T00:00:00",
                "2022-09-04T00:00:00",
                "2022-09-11T00:00:00",
                "2022-09-18T00:00:00",
                "2022-09-25T00:00:00",
                "2022-10-02T00:00:00",
                "2022-10-09T00:00:00",
                "2022-10-16T00:00:00",
                "2022-10-23T00:00:00",
                "2022-10-30T00:00:00",
                "2022-11-06T00:00:00",
                "2022-11-13T00:00:00",
                "2022-11-20T00:00:00",
                "2022-11-27T00:00:00",
                "2022-12-04T00:00:00",
                "2022-12-11T00:00:00",
                "2022-12-18T00:00:00",
                "2022-12-25T00:00:00",
                "2023-01-01T00:00:00",
                "2023-01-08T00:00:00",
                "2023-01-15T00:00:00",
                "2023-01-22T00:00:00",
                "2023-01-29T00:00:00",
                "2023-02-05T00:00:00",
                "2023-02-12T00:00:00",
                "2023-02-19T00:00:00",
                "2023-02-26T00:00:00",
                "2023-03-05T00:00:00",
                "2023-03-12T00:00:00",
                "2023-03-19T00:00:00",
                "2023-03-26T00:00:00",
                "2023-04-02T00:00:00",
                "2023-04-09T00:00:00",
                "2023-04-16T00:00:00",
                "2023-04-23T00:00:00",
                "2023-04-30T00:00:00",
                "2023-05-07T00:00:00",
                "2023-05-14T00:00:00",
                "2023-05-21T00:00:00",
                "2023-05-28T00:00:00",
                "2023-06-04T00:00:00",
                "2023-06-11T00:00:00",
                "2023-06-18T00:00:00",
                "2023-06-25T00:00:00",
                "2023-07-02T00:00:00",
                "2023-07-09T00:00:00",
                "2023-07-16T00:00:00",
                "2023-07-23T00:00:00",
                "2023-07-30T00:00:00",
                "2023-08-06T00:00:00",
                "2023-08-13T00:00:00",
                "2023-08-20T00:00:00",
                "2023-08-27T00:00:00",
                "2023-09-03T00:00:00",
                "2023-09-10T00:00:00",
                "2023-09-17T00:00:00",
                "2023-09-24T00:00:00",
                "2023-10-01T00:00:00",
                "2023-10-08T00:00:00",
                "2023-10-15T00:00:00",
                "2023-10-22T00:00:00",
                "2023-10-29T00:00:00",
                "2023-11-05T00:00:00",
                "2023-11-12T00:00:00",
                "2023-11-19T00:00:00",
                "2023-11-26T00:00:00",
                "2023-12-03T00:00:00",
                "2023-12-10T00:00:00",
                "2023-12-17T00:00:00",
                "2023-12-24T00:00:00",
                "2023-12-31T00:00:00",
                "2024-01-07T00:00:00",
                "2024-01-14T00:00:00",
                "2024-01-21T00:00:00",
                "2024-01-28T00:00:00",
                "2024-02-04T00:00:00",
                "2024-02-11T00:00:00",
                "2024-02-18T00:00:00",
                "2024-02-25T00:00:00",
                "2024-03-03T00:00:00",
                "2024-03-10T00:00:00",
                "2024-03-17T00:00:00",
                "2024-03-24T00:00:00",
                "2024-03-31T00:00:00",
                "2024-04-07T00:00:00",
                "2024-04-14T00:00:00",
                "2024-04-21T00:00:00",
                "2024-04-28T00:00:00",
                "2024-05-05T00:00:00",
                "2024-05-12T00:00:00",
                "2024-05-19T00:00:00",
                "2024-05-26T00:00:00",
                "2024-06-02T00:00:00",
                "2024-06-09T00:00:00",
                "2024-06-16T00:00:00",
                "2024-06-23T00:00:00",
                "2024-06-30T00:00:00",
                "2024-07-07T00:00:00",
                "2024-07-14T00:00:00",
                "2024-07-21T00:00:00",
                "2024-07-28T00:00:00",
                "2024-08-04T00:00:00",
                "2024-08-11T00:00:00",
                "2024-08-18T00:00:00",
                "2024-08-25T00:00:00",
                "2024-09-01T00:00:00",
                "2024-09-08T00:00:00",
                "2024-09-15T00:00:00",
                "2024-09-22T00:00:00",
                "2024-09-29T00:00:00",
                "2024-10-06T00:00:00",
                "2024-10-13T00:00:00",
                "2024-10-20T00:00:00",
                "2024-10-27T00:00:00",
                "2024-11-03T00:00:00",
                "2024-11-10T00:00:00",
                "2024-11-17T00:00:00",
                "2024-11-24T00:00:00",
                "2024-12-01T00:00:00",
                "2024-12-08T00:00:00",
                "2024-12-15T00:00:00",
                "2024-12-22T00:00:00",
                "2024-12-29T00:00:00",
                "2025-01-05T00:00:00",
                "2025-01-12T00:00:00",
                "2025-01-19T00:00:00",
                "2025-01-26T00:00:00",
                "2025-02-02T00:00:00",
                "2025-02-09T00:00:00",
                "2025-02-16T00:00:00",
                "2025-02-23T00:00:00",
              ],
              y: [
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352, 24.118352, 24.118352, 24.118352, 24.118352,
                24.118352,
              ],
              type: "scatter",
            },
          ],
          xaxis: {
            range: ["2021-03-22T18:00:00", "2025-05-02T06:00:00"],
            type: "date",
          },
          yaxis: {
            rangemode: "normal",
            title: {
              text: "trend",
            },
            zerolinecolor: "#AAA",
          },
        },
        yearly: {
          traces: [
            {
              line: {
                color: "#0072B2",
                width: 2,
              },
              mode: "lines",
              name: "yearly",
              x: [
                "2017-01-01T00:00:00",
                "2017-01-02T00:00:59.178082",
                "2017-01-03T00:01:58.356164",
                "2017-01-04T00:02:57.534246",
                "2017-01-05T00:03:56.712328",
                "2017-01-06T00:04:55.890411",
                "2017-01-07T00:05:55.068493",
                "2017-01-08T00:06:54.246575",
                "2017-01-09T00:07:53.424657",
                "2017-01-10T00:08:52.602739",
                "2017-01-11T00:09:51.780822",
                "2017-01-12T00:10:50.958904",
                "2017-01-13T00:11:50.136986",
                "2017-01-14T00:12:49.315068",
                "2017-01-15T00:13:48.493150",
                "2017-01-16T00:14:47.671232",
                "2017-01-17T00:15:46.849315",
                "2017-01-18T00:16:46.027397",
                "2017-01-19T00:17:45.205479",
                "2017-01-20T00:18:44.383561",
                "2017-01-21T00:19:43.561643",
                "2017-01-22T00:20:42.739726",
                "2017-01-23T00:21:41.917808",
                "2017-01-24T00:22:41.095890",
                "2017-01-25T00:23:40.273972",
                "2017-01-26T00:24:39.452054",
                "2017-01-27T00:25:38.630137",
                "2017-01-28T00:26:37.808219",
                "2017-01-29T00:27:36.986301",
                "2017-01-30T00:28:36.164383",
                "2017-01-31T00:29:35.342465",
                "2017-02-01T00:30:34.520547",
                "2017-02-02T00:31:33.698630",
                "2017-02-03T00:32:32.876712",
                "2017-02-04T00:33:32.054794",
                "2017-02-05T00:34:31.232876",
                "2017-02-06T00:35:30.410958",
                "2017-02-07T00:36:29.589041",
                "2017-02-08T00:37:28.767123",
                "2017-02-09T00:38:27.945205",
                "2017-02-10T00:39:27.123287",
                "2017-02-11T00:40:26.301369",
                "2017-02-12T00:41:25.479452",
                "2017-02-13T00:42:24.657534",
                "2017-02-14T00:43:23.835616",
                "2017-02-15T00:44:23.013698",
                "2017-02-16T00:45:22.191780",
                "2017-02-17T00:46:21.369862",
                "2017-02-18T00:47:20.547945",
                "2017-02-19T00:48:19.726027",
                "2017-02-20T00:49:18.904109",
                "2017-02-21T00:50:18.082191",
                "2017-02-22T00:51:17.260273",
                "2017-02-23T00:52:16.438356",
                "2017-02-24T00:53:15.616438",
                "2017-02-25T00:54:14.794520",
                "2017-02-26T00:55:13.972602",
                "2017-02-27T00:56:13.150684",
                "2017-02-28T00:57:12.328767",
                "2017-03-01T00:58:11.506849",
                "2017-03-02T00:59:10.684931",
                "2017-03-03T01:00:09.863013",
                "2017-03-04T01:01:09.041095",
                "2017-03-05T01:02:08.219177",
                "2017-03-06T01:03:07.397260",
                "2017-03-07T01:04:06.575342",
                "2017-03-08T01:05:05.753424",
                "2017-03-09T01:06:04.931506",
                "2017-03-10T01:07:04.109588",
                "2017-03-11T01:08:03.287671",
                "2017-03-12T01:09:02.465753",
                "2017-03-13T01:10:01.643835",
                "2017-03-14T01:11:00.821917",
                "2017-03-15T01:12:00",
                "2017-03-16T01:12:59.178082",
                "2017-03-17T01:13:58.356164",
                "2017-03-18T01:14:57.534246",
                "2017-03-19T01:15:56.712328",
                "2017-03-20T01:16:55.890411",
                "2017-03-21T01:17:55.068493",
                "2017-03-22T01:18:54.246575",
                "2017-03-23T01:19:53.424657",
                "2017-03-24T01:20:52.602739",
                "2017-03-25T01:21:51.780822",
                "2017-03-26T01:22:50.958904",
                "2017-03-27T01:23:50.136986",
                "2017-03-28T01:24:49.315068",
                "2017-03-29T01:25:48.493150",
                "2017-03-30T01:26:47.671232",
                "2017-03-31T01:27:46.849315",
                "2017-04-01T01:28:46.027397",
                "2017-04-02T01:29:45.205479",
                "2017-04-03T01:30:44.383561",
                "2017-04-04T01:31:43.561643",
                "2017-04-05T01:32:42.739726",
                "2017-04-06T01:33:41.917808",
                "2017-04-07T01:34:41.095890",
                "2017-04-08T01:35:40.273972",
                "2017-04-09T01:36:39.452054",
                "2017-04-10T01:37:38.630137",
                "2017-04-11T01:38:37.808219",
                "2017-04-12T01:39:36.986301",
                "2017-04-13T01:40:36.164383",
                "2017-04-14T01:41:35.342465",
                "2017-04-15T01:42:34.520547",
                "2017-04-16T01:43:33.698630",
                "2017-04-17T01:44:32.876712",
                "2017-04-18T01:45:32.054794",
                "2017-04-19T01:46:31.232876",
                "2017-04-20T01:47:30.410958",
                "2017-04-21T01:48:29.589041",
                "2017-04-22T01:49:28.767123",
                "2017-04-23T01:50:27.945205",
                "2017-04-24T01:51:27.123287",
                "2017-04-25T01:52:26.301369",
                "2017-04-26T01:53:25.479452",
                "2017-04-27T01:54:24.657534",
                "2017-04-28T01:55:23.835616",
                "2017-04-29T01:56:23.013698",
                "2017-04-30T01:57:22.191780",
                "2017-05-01T01:58:21.369862",
                "2017-05-02T01:59:20.547945",
                "2017-05-03T02:00:19.726027",
                "2017-05-04T02:01:18.904109",
                "2017-05-05T02:02:18.082191",
                "2017-05-06T02:03:17.260273",
                "2017-05-07T02:04:16.438356",
                "2017-05-08T02:05:15.616438",
                "2017-05-09T02:06:14.794520",
                "2017-05-10T02:07:13.972602",
                "2017-05-11T02:08:13.150684",
                "2017-05-12T02:09:12.328767",
                "2017-05-13T02:10:11.506849",
                "2017-05-14T02:11:10.684931",
                "2017-05-15T02:12:09.863013",
                "2017-05-16T02:13:09.041095",
                "2017-05-17T02:14:08.219177",
                "2017-05-18T02:15:07.397260",
                "2017-05-19T02:16:06.575342",
                "2017-05-20T02:17:05.753424",
                "2017-05-21T02:18:04.931506",
                "2017-05-22T02:19:04.109588",
                "2017-05-23T02:20:03.287671",
                "2017-05-24T02:21:02.465753",
                "2017-05-25T02:22:01.643835",
                "2017-05-26T02:23:00.821917",
                "2017-05-27T02:24:00",
                "2017-05-28T02:24:59.178082",
                "2017-05-29T02:25:58.356164",
                "2017-05-30T02:26:57.534246",
                "2017-05-31T02:27:56.712328",
                "2017-06-01T02:28:55.890411",
                "2017-06-02T02:29:55.068493",
                "2017-06-03T02:30:54.246575",
                "2017-06-04T02:31:53.424657",
                "2017-06-05T02:32:52.602739",
                "2017-06-06T02:33:51.780822",
                "2017-06-07T02:34:50.958904",
                "2017-06-08T02:35:50.136986",
                "2017-06-09T02:36:49.315068",
                "2017-06-10T02:37:48.493150",
                "2017-06-11T02:38:47.671232",
                "2017-06-12T02:39:46.849315",
                "2017-06-13T02:40:46.027397",
                "2017-06-14T02:41:45.205479",
                "2017-06-15T02:42:44.383561",
                "2017-06-16T02:43:43.561643",
                "2017-06-17T02:44:42.739726",
                "2017-06-18T02:45:41.917808",
                "2017-06-19T02:46:41.095890",
                "2017-06-20T02:47:40.273972",
                "2017-06-21T02:48:39.452054",
                "2017-06-22T02:49:38.630137",
                "2017-06-23T02:50:37.808219",
                "2017-06-24T02:51:36.986301",
                "2017-06-25T02:52:36.164383",
                "2017-06-26T02:53:35.342465",
                "2017-06-27T02:54:34.520547",
                "2017-06-28T02:55:33.698630",
                "2017-06-29T02:56:32.876712",
                "2017-06-30T02:57:32.054794",
                "2017-07-01T02:58:31.232876",
                "2017-07-02T02:59:30.410958",
                "2017-07-03T03:00:29.589041",
                "2017-07-04T03:01:28.767123",
                "2017-07-05T03:02:27.945205",
                "2017-07-06T03:03:27.123287",
                "2017-07-07T03:04:26.301369",
                "2017-07-08T03:05:25.479452",
                "2017-07-09T03:06:24.657534",
                "2017-07-10T03:07:23.835616",
                "2017-07-11T03:08:23.013698",
                "2017-07-12T03:09:22.191780",
                "2017-07-13T03:10:21.369862",
                "2017-07-14T03:11:20.547945",
                "2017-07-15T03:12:19.726027",
                "2017-07-16T03:13:18.904109",
                "2017-07-17T03:14:18.082191",
                "2017-07-18T03:15:17.260273",
                "2017-07-19T03:16:16.438356",
                "2017-07-20T03:17:15.616438",
                "2017-07-21T03:18:14.794520",
                "2017-07-22T03:19:13.972602",
                "2017-07-23T03:20:13.150684",
                "2017-07-24T03:21:12.328767",
                "2017-07-25T03:22:11.506849",
                "2017-07-26T03:23:10.684931",
                "2017-07-27T03:24:09.863013",
                "2017-07-28T03:25:09.041095",
                "2017-07-29T03:26:08.219177",
                "2017-07-30T03:27:07.397260",
                "2017-07-31T03:28:06.575342",
                "2017-08-01T03:29:05.753424",
                "2017-08-02T03:30:04.931506",
                "2017-08-03T03:31:04.109588",
                "2017-08-04T03:32:03.287671",
                "2017-08-05T03:33:02.465753",
                "2017-08-06T03:34:01.643835",
                "2017-08-07T03:35:00.821917",
                "2017-08-08T03:36:00",
                "2017-08-09T03:36:59.178082",
                "2017-08-10T03:37:58.356164",
                "2017-08-11T03:38:57.534246",
                "2017-08-12T03:39:56.712328",
                "2017-08-13T03:40:55.890411",
                "2017-08-14T03:41:55.068493",
                "2017-08-15T03:42:54.246575",
                "2017-08-16T03:43:53.424657",
                "2017-08-17T03:44:52.602739",
                "2017-08-18T03:45:51.780822",
                "2017-08-19T03:46:50.958904",
                "2017-08-20T03:47:50.136986",
                "2017-08-21T03:48:49.315068",
                "2017-08-22T03:49:48.493150",
                "2017-08-23T03:50:47.671232",
                "2017-08-24T03:51:46.849315",
                "2017-08-25T03:52:46.027397",
                "2017-08-26T03:53:45.205479",
                "2017-08-27T03:54:44.383561",
                "2017-08-28T03:55:43.561643",
                "2017-08-29T03:56:42.739726",
                "2017-08-30T03:57:41.917808",
                "2017-08-31T03:58:41.095890",
                "2017-09-01T03:59:40.273972",
                "2017-09-02T04:00:39.452054",
                "2017-09-03T04:01:38.630137",
                "2017-09-04T04:02:37.808219",
                "2017-09-05T04:03:36.986301",
                "2017-09-06T04:04:36.164383",
                "2017-09-07T04:05:35.342465",
                "2017-09-08T04:06:34.520547",
                "2017-09-09T04:07:33.698630",
                "2017-09-10T04:08:32.876712",
                "2017-09-11T04:09:32.054794",
                "2017-09-12T04:10:31.232876",
                "2017-09-13T04:11:30.410958",
                "2017-09-14T04:12:29.589041",
                "2017-09-15T04:13:28.767123",
                "2017-09-16T04:14:27.945205",
                "2017-09-17T04:15:27.123287",
                "2017-09-18T04:16:26.301369",
                "2017-09-19T04:17:25.479452",
                "2017-09-20T04:18:24.657534",
                "2017-09-21T04:19:23.835616",
                "2017-09-22T04:20:23.013698",
                "2017-09-23T04:21:22.191780",
                "2017-09-24T04:22:21.369862",
                "2017-09-25T04:23:20.547945",
                "2017-09-26T04:24:19.726027",
                "2017-09-27T04:25:18.904109",
                "2017-09-28T04:26:18.082191",
                "2017-09-29T04:27:17.260273",
                "2017-09-30T04:28:16.438356",
                "2017-10-01T04:29:15.616438",
                "2017-10-02T04:30:14.794520",
                "2017-10-03T04:31:13.972602",
                "2017-10-04T04:32:13.150684",
                "2017-10-05T04:33:12.328767",
                "2017-10-06T04:34:11.506849",
                "2017-10-07T04:35:10.684931",
                "2017-10-08T04:36:09.863013",
                "2017-10-09T04:37:09.041095",
                "2017-10-10T04:38:08.219177",
                "2017-10-11T04:39:07.397260",
                "2017-10-12T04:40:06.575342",
                "2017-10-13T04:41:05.753424",
                "2017-10-14T04:42:04.931506",
                "2017-10-15T04:43:04.109588",
                "2017-10-16T04:44:03.287671",
                "2017-10-17T04:45:02.465753",
                "2017-10-18T04:46:01.643835",
                "2017-10-19T04:47:00.821917",
                "2017-10-20T04:48:00",
                "2017-10-21T04:48:59.178082",
                "2017-10-22T04:49:58.356164",
                "2017-10-23T04:50:57.534246",
                "2017-10-24T04:51:56.712328",
                "2017-10-25T04:52:55.890411",
                "2017-10-26T04:53:55.068493",
                "2017-10-27T04:54:54.246575",
                "2017-10-28T04:55:53.424657",
                "2017-10-29T04:56:52.602739",
                "2017-10-30T04:57:51.780822",
                "2017-10-31T04:58:50.958904",
                "2017-11-01T04:59:50.136986",
                "2017-11-02T05:00:49.315068",
                "2017-11-03T05:01:48.493150",
                "2017-11-04T05:02:47.671232",
                "2017-11-05T05:03:46.849315",
                "2017-11-06T05:04:46.027397",
                "2017-11-07T05:05:45.205479",
                "2017-11-08T05:06:44.383561",
                "2017-11-09T05:07:43.561643",
                "2017-11-10T05:08:42.739726",
                "2017-11-11T05:09:41.917808",
                "2017-11-12T05:10:41.095890",
                "2017-11-13T05:11:40.273972",
                "2017-11-14T05:12:39.452054",
                "2017-11-15T05:13:38.630137",
                "2017-11-16T05:14:37.808219",
                "2017-11-17T05:15:36.986301",
                "2017-11-18T05:16:36.164383",
                "2017-11-19T05:17:35.342465",
                "2017-11-20T05:18:34.520547",
                "2017-11-21T05:19:33.698630",
                "2017-11-22T05:20:32.876712",
                "2017-11-23T05:21:32.054794",
                "2017-11-24T05:22:31.232876",
                "2017-11-25T05:23:30.410958",
                "2017-11-26T05:24:29.589041",
                "2017-11-27T05:25:28.767123",
                "2017-11-28T05:26:27.945205",
                "2017-11-29T05:27:27.123287",
                "2017-11-30T05:28:26.301369",
                "2017-12-01T05:29:25.479452",
                "2017-12-02T05:30:24.657534",
                "2017-12-03T05:31:23.835616",
                "2017-12-04T05:32:23.013698",
                "2017-12-05T05:33:22.191780",
                "2017-12-06T05:34:21.369862",
                "2017-12-07T05:35:20.547945",
                "2017-12-08T05:36:19.726027",
                "2017-12-09T05:37:18.904109",
                "2017-12-10T05:38:18.082191",
                "2017-12-11T05:39:17.260273",
                "2017-12-12T05:40:16.438356",
                "2017-12-13T05:41:15.616438",
                "2017-12-14T05:42:14.794520",
                "2017-12-15T05:43:13.972602",
                "2017-12-16T05:44:13.150684",
                "2017-12-17T05:45:12.328767",
                "2017-12-18T05:46:11.506849",
                "2017-12-19T05:47:10.684931",
                "2017-12-20T05:48:09.863013",
                "2017-12-21T05:49:09.041095",
                "2017-12-22T05:50:08.219177",
                "2017-12-23T05:51:07.397260",
                "2017-12-24T05:52:06.575342",
                "2017-12-25T05:53:05.753424",
                "2017-12-26T05:54:04.931506",
                "2017-12-27T05:55:04.109588",
                "2017-12-28T05:56:03.287671",
                "2017-12-29T05:57:02.465753",
                "2017-12-30T05:58:01.643835",
                "2017-12-31T05:59:00.821917",
              ],
              y: [
                21.24164237975976, 20.70011768413573, 20.055550669636734,
                19.31329649821257, 18.481184190003006, 17.569336387494104,
                16.589927099625417, 15.556884256446295, 14.485545370384806,
                13.392275826178915, 12.29406027666256, 11.20807828059064,
                10.151275666597284, 9.139943136278672, 8.189313331672965,
                7.313186998707589, 6.523597997483043, 5.830525770070801,
                5.2416625102085845, 4.762240727963834, 4.394925209984807,
                4.139771591676261, 3.994251931549111, 3.9533458616792876,
                4.009694131811393, 4.153809716710487, 4.37434016128573,
                4.658373535847512, 4.99177929870381, 5.359574541990369,
                5.746305549581711, 6.136434334292728, 6.514719849259265,
                6.866583880809684, 7.178452214305137, 7.438062500564078,
                7.634731311028989, 7.759574122138992, 7.805673374848072,
                7.768191272192396, 7.644425561476078, 7.433808152179466,
                7.137848000242117, 6.760021199420628, 6.3056126191104465,
                5.781514677418601, 5.195989904973731, 4.558404811900779,
                3.8789431962105727, 3.168307413088817, 2.437416254188853,
                1.697107965213153, 0.9578565666006849, 0.22950905122210946,
                -0.47894976382276727, -1.1595989302159284, -1.8057449276697204,
                -2.412043644897548, -2.9745786443269626, -3.490894911926653,
                -3.959988625425221, -4.38225475895906, -4.759395543070613,
                -5.094293881002793, -5.390856756151053, -5.653834425537908,
                -5.888621761225757, -6.101048462236088, -6.297165006762633,
                -6.483031148324855, -6.664513485815616, -6.847098168933843,
                -7.035724155181305, -7.234641635855, -7.4472993239823415,
                -7.676263277770606, -7.923168852670331, -8.188706268190206,
                -8.472639177495939, -8.773854572837404, -9.090441380413196,
                -9.419794224285544, -9.758738095927054, -10.103669075767503,
                -10.450705831758219, -10.795846378381189, -11.135124522566588,
                -11.464760549914004, -11.781301008245814, -12.081742913982609,
                -12.363638322302807, -12.625175942899755, -12.865237323398041,
                -13.083426033817366, -13.280069236982888, -13.456191990146259,
                -13.613465560493072, -13.754131921310224, -13.88090739765879,
                -13.996869124772573, -14.105328547140372, -14.209696603680712,
                -14.31334550165944, -14.419472071742469, -14.53096761694023,
                -14.650298922237504, -14.77940468899338, -14.919611112221009,
                -15.071569648498006, -15.235219250054515, -15.409774492363017,
                -15.593740126643446, -15.784951674865534, -15.980640783340816,
                -16.17752319188481, -16.37190638702183, -16.55981331638222,
                -16.73711796985437, -16.899688200767887, -17.043530881860647,
                -17.16493437547545, -17.260603350241485, -17.327781195625775,
                -17.364355665376674, -17.36894390878597, -17.3409537085763,
                -17.280618515081585, -17.18900472357002, -17.067990557685565,
                -16.9202168673338, -16.74901109344445, -16.558286563965147,
                -16.352420135370746, -16.136111953659498, -15.91423175300459,
                -15.69165661689666, -15.473105478196201, -15.262975818259163,
                -15.065188033627198, -14.8830427696061, -14.7190961772563,
                -14.575057542895074, -14.45171308172691, -14.348878899080177,
                -14.265385227697044, -14.199093074707747, -14.146943387635263,
                -14.105037806700588, -14.068749043779519, -14.032857949032453,
                -13.991713426060926, -13.939410564689165, -13.869981703036137,
                -13.77759463013446, -13.656751813942906, -13.502484400033657,
                -13.310534779724232, -13.077521773976606, -12.80108291549237,
                -12.47998892508887, -12.114226252657877, -11.705044466083361,
                -11.25496629680455, -10.767759258332013, -10.248368910625528,
                -9.702815014441025, -9.138052969452927, -8.56180402302957,
                -7.982358739021808, -7.408359095881569, -6.848565313288164,
                -6.3116140613723175, -5.8057750688045155, -5.338713301319262,
                -4.917263824081296, -4.547226189039724, -4.233184707341533,
                -3.978360289706787, -3.7844986821011615, -3.6517989140510423,
                -3.5788846409246173, -3.5628198322774924, -3.5991689714729294,
                -3.682100625262733, -3.8045319544288088, -3.958310506641061,
                -4.134428497457623, -4.323263779678156, -4.514840856112231,
                -4.699104632938841, -4.8661991615035705, -5.00674339082673,
                -5.112095960155566, -5.174601302330347, -5.187809799621266,
                -5.146665422107418, -5.047655166141781, -4.8889156724292455,
                -4.670293610043905, -4.393357729646996, -4.061361878630253,
                -3.67915969221929, -3.2530730863828534, -2.7907180388469834,
                -2.3007924132886814, -1.792831720767257, -1.2769396871938168,
                -0.7635012763467183, -0.2628863799446866, 0.21484728800666353,
                0.660243477742569, 1.0647204302920665, 1.4208196258006296,
                1.722422895496586, 1.9649314193171092, 2.1454012067239683,
                2.262630898948881, 2.317199101314931, 2.3114499149487915,
                2.249426845904884, 2.1367567821136926, 1.980487200665083,
                1.788881155699704, 1.5711758597375305, 1.3373117704959214,
                1.097639997787609, 0.8626165234805979, 0.6424921600450137,
                0.44700734608516274, 0.2851007835850299, 0.16464056244975211,
                0.0921858027036144, 0.07278598935486189, 0.10982410366585454,
                0.20490839739751843, 0.3578162493620096, 0.5664920268802001,
                0.8270992924510477, 1.1341260942680877, 1.4805405050565463,
                1.8579920738040387, 2.2570534735409504, 2.6674954071437296,
                3.0785868088314796, 3.4794115831988295, 3.8591925808601397,
                4.207613238122506, 4.51512731662591, 4.773247469873351,
                4.974803929806185, 5.114165433387457, 5.187415574186685,
                5.192479037095809, 5.129193620027066, 4.999325523132967,
                4.806527048521348, 4.556237553425674, 4.25553018766198,
                3.912908572353195, 3.538059093386019, 3.1415658443253442,
                2.7345964189380196, 2.3285676870355236, 1.9348013604715848,
                1.5641795467371677, 1.2268105822589073, 0.9317152313948096,
                0.6865428333934195, 0.49732619113219073, 0.36828294251219446,
                0.3016698668515536, 0.29769508984248627, 0.3544915032675061,
                0.4681529563383372, 0.6328329542102142, 0.8409037681941782,
                1.083172074360708, 1.349145544582647, 1.6273432663005036,
                1.9056415101362765, 2.171645238652287, 2.4130748893698493,
                2.618157397355036, 2.7760101661834886, 2.877006760498653,
                2.913113479734034, 2.8781866722158114, 2.7682216443877454,
                2.5815452854146095, 2.3189460287529595, 1.9837364688871526,
                1.5817457964025974, 1.121241156859831, 0.612779024286433,
                0.06898965259649856, -0.4957004277833317, -1.0654011195890423,
                -1.6231383188074584, -2.1512705819281748, -2.6319320304769027,
                -3.047489789997414, -3.381003746900712, -3.6166762281030964,
                -3.740279372222843, -3.7395484646681343, -3.604530340020146,
                -3.3278770922094436, -2.9050767461339415, -2.3346141954738364,
                -1.6180575558141437, -0.7600670693744457, 0.23167422671047128,
                1.346606749912807, 2.5715141413479365, 3.890811161031865,
                5.286892461377508, 6.740533327941766, 8.23133205214636,
                9.73818245412383, 11.239764238400458, 12.715038363659342,
                14.143734454847714, 15.50681748687014, 16.786921517832145,
                17.96873913269501, 19.03935645010616, 19.98852501452405,
                20.80886360129516, 21.49598485751466, 22.048543734355377,
                22.468206780293215, 22.759543501991626, 22.92984310067776,
                22.988861899666272, 22.948508637317385, 22.82247645871276,
                22.62583185281887, 22.374571911685784, 22.08516210347658,
                21.774067230050576, 21.457288370098738, 21.14991838752714,
                20.86572801898559, 20.616793660915786, 20.413176780480647,
                20.262663410578938, 20.17057049798103, 20.139624002966123,
                20.169911651066485, 20.258911168366524, 20.401592748673043,
                20.590592461552337, 20.816451370898953, 21.06791334761685,
                21.33227297597006, 21.59576361408277, 21.843974610900137,
                22.06228593268054, 22.236308030899842, 22.352314700446417,
                22.3976569326125, 22.361146352844013, 22.23339773036603,
                22.00712122920678, 21.677356503141837,
              ],
              type: "scatter",
            },
          ],
          xaxis: {
            range: [
              "2016-12-13T18:54:02.958904115",
              "2018-01-18T11:04:57.863013581",
            ],
            tickformat: "%B %e",
            type: "date",
          },
          yaxis: {
            title: {
              text: "yearly",
            },
            zerolinecolor: "#AAA",
          },
        },
      },
    },
  },
  {
    name: "influenza_2024-05-09_08:32:55",
    forecast_plot: {
      data: [
        {
          marker: {
            color: "black",
            size: 4,
          },
          mode: "markers",
          name: "Actual",
          x: [
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
          ],
          y: [
            33, 347, 191, 193, 181, 122, 60, 17, 0, 0, 375, 729, 581, 342, 294,
            204, 125, 80, 71, 17, 0, 0, 420, 877, 717, 343, 283, 169, 169, 133,
            81, 32, 0, 0, 159, 1084, 824, 408, 287, 178, 159, 69, 0, 0, 0, 0,
            576, 1076, 658, 367, 220, 111, 138, 114, 64, 25, 0, 0, 409, 631,
            480, 364, 176, 156, 136, 81, 57, 22, 0, 0, 0, 510, 429, 291, 150,
            108, 109, 43, 28, 8,
          ],
          type: "scatter",
        },
        {
          legendgroup: "Trend Line",
          line: {
            color: "#0072B2",
            width: 2,
          },
          mode: "lines",
          name: "Trend Line",
          x: [
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
          ],
          y: [
            521.396274969481, 352.3177329386595, 227.0773017026791,
            158.16039440851557, 146.59173134330013, 94.68647191624032,
            56.46768865473024, 23.32958257282428, 6.334576555600535,
            3.89783557879079, 321.43123963569906, 804.8032939722175,
            524.3763766297111, 349.3127982461972, 227.26024048284395,
            159.9752481003013, 147.92761446889864, 95.72625431837085,
            56.26509759005319, 22.09953784510688, 4.370452704640343,
            4.66503486044229, 318.59505206763504, 805.2852830790268,
            527.292847110831, 346.299532151545, 227.53605735930017,
            161.84777492264294, 149.23826265048243, 96.72505860569336,
            56.01536884054477, 20.85142819702571, 2.4653159364335977,
            5.528742817292425, 315.79676254344304, 805.6334859543988,
            530.145882853998, 343.2829906877646, 227.9061493343904,
            163.77601045034015, 145.23272044958318, 93.60678919261406,
            56.62213249102933, 24.53898047079116, 8.355151662921656,
            3.2290801500487305, 324.30788219603346, 804.1897233016443,
            521.3962749695015, 352.3177329386393, 227.07730170268763,
            158.16039440851966, 146.59173134325926, 94.68647191624859,
            56.467688654760394, 23.329582572832805, 6.334576555577343,
            3.897835578805541, 321.43123963570343, 804.803293972222,
            524.3763766297045, 349.3127982461935, 227.26024048283446,
            159.97524810031763, 147.92761446891808, 95.72625431836539,
            56.26509759006845, 22.099537845103868, 4.370452704622494,
            4.665034860416284, 318.5950520676096, 805.2852830790232,
            527.2928471108198, 346.299532151574, 227.5360573592857,
            161.84777492264146, 149.23826265046472, 96.7250586056791,
            56.01536884054843, 20.851428197029662,
          ],
          type: "scatter",
        },
        {
          legendgroup: "Predicted",
          line: {
            color: "#33cc33",
            width: 2,
          },
          mode: "lines",
          name: "Predicted",
          x: [
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
            "2023-08-31T00:00:00",
            "2023-09-30T00:00:00",
            "2023-10-31T00:00:00",
            "2023-11-30T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-31T00:00:00",
            "2024-02-29T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-30T00:00:00",
            "2024-05-31T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-31T00:00:00",
            "2024-08-31T00:00:00",
            "2024-09-30T00:00:00",
            "2024-10-31T00:00:00",
            "2024-11-30T00:00:00",
            "2024-12-31T00:00:00",
            "2025-01-31T00:00:00",
          ],
          y: [
            20.851428197029662, 2.4653159364165447, 5.528742817295409,
            315.7967625434267, 805.6334859544043, 530.1458828540142,
            343.28299068774515, 227.90614933438022, 163.77601045033168,
            145.23272044960396, 93.6067891926248, 56.622132491048944,
            24.53898047077496, 8.355151662900028, 3.2290801500392945,
            324.30788219606586, 804.1897233016332, 521.3962749694781,
            352.31773293861346, 227.07730170266203, 158.16039440852077,
          ],
          type: "scatter",
        },
        {
          hoverinfo: "skip",
          legendgroup: "Trend Line",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
          ],
          y: [
            309.1859168400062, 159.88919500281946, 2.994832150486089, 0, 0, 0,
            0, 0, 0, 0, 111.9525203416139, 601.6446151534786,
            303.47802776144727, 130.52460836658614, 8.571131494894727, 0, 0, 0,
            0, 0, 0, 0, 131.17010203893187, 590.2473443957015,
            331.2467120039624, 149.0112268005454, 26.433484842736124, 0, 0, 0,
            0, 0, 0, 0, 104.77486396108702, 599.231392129792, 309.6214526167162,
            127.68163377639604, 7.6128238061666735, 0, 0, 0, 0, 0, 0, 0,
            115.31183209382944, 586.1435023186533, 315.5163862693099,
            128.14512525088617, 7.544106357537574, 0, 0, 0, 0, 0, 0, 0,
            95.2213216202198, 596.005020607696, 307.826204723628,
            124.55295668041865, 8.637023855653421, 0, 0, 0, 0, 0, 0, 0,
            124.38160010517741, 588.4125420896798, 309.3000113458966,
            145.18444874043917, 8.967884998556533, 0, 0, 0, 0, 0,
          ],
          type: "scatter",
        },
        {
          fill: "tonexty",
          fillcolor: "rgba(0, 114, 178, 0.2)",
          hoverinfo: "skip",
          legendgroup: "Trend Line",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
          ],
          y: [
            715.2829633019751, 555.7860628108247, 436.46220482445426,
            372.77846568891823, 367.10633920247676, 310.1682968818575,
            264.3773554288542, 242.17304511012566, 213.6356091471694,
            209.6943893751147, 536.6514162023946, 1016.9876421562697,
            724.3858484101265, 560.5012244875097, 426.99784006883976,
            358.33669316442905, 340.4449139471324, 309.1948238095822,
            275.9800956216732, 221.03570668904092, 208.69669360269063,
            213.58042241377723, 537.9974193575604, 1032.8576785585874,
            734.4741119451888, 569.8713569804573, 436.7683472269703,
            365.8116488969222, 361.45039422093, 295.12673102249033,
            275.81675337026775, 244.60027911492588, 212.95029613998852,
            206.50710721485427, 518.0875110242669, 1009.2915747727351,
            743.5599304426137, 557.7529994590269, 433.5923152446371,
            376.4661845350199, 346.45982627456044, 295.6335624797751,
            261.90682128365734, 227.40990689542704, 208.21606716079444,
            222.05172564047925, 536.9873249446016, 1024.3595607307914,
            731.4326460936248, 557.0044314765137, 441.0801662121391,
            365.95096101014184, 349.5419960459102, 303.61289057402377,
            258.529886554291, 233.05970644480846, 211.7101507733099,
            213.74190556588098, 543.1971296765997, 1020.6718141795197,
            730.8032254984228, 559.8876487296741, 413.70282997933754,
            377.3475429341779, 369.1501891524645, 319.0533501293113,
            251.67791156664072, 251.84042942633113, 222.8867725097379,
            209.71658420772417, 537.3877399780309, 1015.6199021123244,
            748.8728003857722, 552.1473729401623, 434.1962016564177,
            361.8096496474358, 348.3520278540635, 311.77732066431844,
            275.7017516835754, 225.51141934450828,
          ],
          type: "scatter",
        },
        {
          hoverinfo: "skip",
          legendgroup: "Predicted",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
            "2023-08-31T00:00:00",
            "2023-09-30T00:00:00",
            "2023-10-31T00:00:00",
            "2023-11-30T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-31T00:00:00",
            "2024-02-29T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-30T00:00:00",
            "2024-05-31T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-31T00:00:00",
            "2024-08-31T00:00:00",
            "2024-09-30T00:00:00",
            "2024-10-31T00:00:00",
            "2024-11-30T00:00:00",
            "2024-12-31T00:00:00",
            "2025-01-31T00:00:00",
          ],
          y: [
            0, 0, 0, 103.54328129488614, 606.1128078248211, 310.5655918641961,
            118.99569719945109, 19.59180297966676, 0, 0, 0, 0, 0, 0, 0,
            89.75961941063817, 607.2194826763273, 306.75314869142846,
            135.85057528071786, 4.260888682835311, 0,
          ],
          type: "scatter",
        },
        {
          fill: "tonexty",
          fillcolor: "rgba(51, 204, 51, 0.2)",
          hoverinfo: "skip",
          legendgroup: "Predicted",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
            "2023-08-31T00:00:00",
            "2023-09-30T00:00:00",
            "2023-10-31T00:00:00",
            "2023-11-30T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-31T00:00:00",
            "2024-02-29T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-30T00:00:00",
            "2024-05-31T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-31T00:00:00",
            "2024-08-31T00:00:00",
            "2024-09-30T00:00:00",
            "2024-10-31T00:00:00",
            "2024-11-30T00:00:00",
            "2024-12-31T00:00:00",
            "2025-01-31T00:00:00",
          ],
          y: [
            225.51141934450828, 206.38667726186128, 221.726643737881,
            514.1774668818576, 1027.985194401106, 737.3675995187147,
            556.2849097129784, 427.6717582532842, 363.20984225194456,
            349.22001967344517, 323.100495604258, 261.3481020089636,
            238.08842038588838, 222.76931838613027, 206.81699625611776,
            545.9567440279006, 999.2307923175391, 728.1453468866672,
            552.8508630951623, 435.02990477999305, 364.5004253995728,
          ],
          type: "scatter",
        },
      ],
      layout: {
        showlegend: true,
        width: 850,
        height: 600,
        yaxis: {
          title: "y",
        },
        xaxis: {
          title: "ds",
          type: "date",
          rangeselector: {
            buttons: [
              {
                count: 7,
                label: "1w",
                step: "day",
                stepmode: "backward",
              },
              {
                count: 1,
                label: "1m",
                step: "month",
                stepmode: "backward",
              },
              {
                count: 6,
                label: "6m",
                step: "month",
                stepmode: "backward",
              },
              {
                count: 1,
                label: "1y",
                step: "year",
                stepmode: "backward",
              },
              {
                step: "all",
              },
            ],
          },
          rangeslider: {
            visible: true,
          },
        },
      },
    },
    forecast_components: {
      data: {
        trend: {
          traces: [
            {
              line: {
                color: "#0072B2",
                width: 2,
              },
              mode: "lines",
              name: "trend",
              x: [
                "2016-10-31T00:00:00",
                "2016-11-30T00:00:00",
                "2016-12-31T00:00:00",
                "2017-01-31T00:00:00",
                "2017-02-28T00:00:00",
                "2017-03-31T00:00:00",
                "2017-04-30T00:00:00",
                "2017-05-31T00:00:00",
                "2017-06-30T00:00:00",
                "2017-07-31T00:00:00",
                "2017-08-31T00:00:00",
                "2017-09-30T00:00:00",
                "2017-10-31T00:00:00",
                "2017-11-30T00:00:00",
                "2017-12-31T00:00:00",
                "2018-01-31T00:00:00",
                "2018-02-28T00:00:00",
                "2018-03-31T00:00:00",
                "2018-04-30T00:00:00",
                "2018-05-31T00:00:00",
                "2018-06-30T00:00:00",
                "2018-07-31T00:00:00",
                "2018-08-31T00:00:00",
                "2018-09-30T00:00:00",
                "2018-10-31T00:00:00",
                "2018-11-30T00:00:00",
                "2018-12-31T00:00:00",
                "2019-01-31T00:00:00",
                "2019-02-28T00:00:00",
                "2019-03-31T00:00:00",
                "2019-04-30T00:00:00",
                "2019-05-31T00:00:00",
                "2019-06-30T00:00:00",
                "2019-07-31T00:00:00",
                "2019-08-31T00:00:00",
                "2019-09-30T00:00:00",
                "2019-10-31T00:00:00",
                "2019-11-30T00:00:00",
                "2019-12-31T00:00:00",
                "2020-01-31T00:00:00",
                "2020-02-29T00:00:00",
                "2020-03-31T00:00:00",
                "2020-04-30T00:00:00",
                "2020-05-31T00:00:00",
                "2020-06-30T00:00:00",
                "2020-07-31T00:00:00",
                "2020-08-31T00:00:00",
                "2020-09-30T00:00:00",
                "2020-10-31T00:00:00",
                "2020-11-30T00:00:00",
                "2020-12-31T00:00:00",
                "2021-01-31T00:00:00",
                "2021-02-28T00:00:00",
                "2021-03-31T00:00:00",
                "2021-04-30T00:00:00",
                "2021-05-31T00:00:00",
                "2021-06-30T00:00:00",
                "2021-07-31T00:00:00",
                "2021-08-31T00:00:00",
                "2021-09-30T00:00:00",
                "2021-10-31T00:00:00",
                "2021-11-30T00:00:00",
                "2021-12-31T00:00:00",
                "2022-01-31T00:00:00",
                "2022-02-28T00:00:00",
                "2022-03-31T00:00:00",
                "2022-04-30T00:00:00",
                "2022-05-31T00:00:00",
                "2022-06-30T00:00:00",
                "2022-07-31T00:00:00",
                "2022-08-31T00:00:00",
                "2022-09-30T00:00:00",
                "2022-10-31T00:00:00",
                "2022-11-30T00:00:00",
                "2022-12-31T00:00:00",
                "2023-01-31T00:00:00",
                "2023-02-28T00:00:00",
                "2023-03-31T00:00:00",
                "2023-04-30T00:00:00",
                "2023-05-31T00:00:00",
                "2023-06-30T00:00:00",
                "2023-07-31T00:00:00",
                "2023-08-31T00:00:00",
                "2023-09-30T00:00:00",
                "2023-10-31T00:00:00",
                "2023-11-30T00:00:00",
                "2023-12-31T00:00:00",
                "2024-01-31T00:00:00",
                "2024-02-29T00:00:00",
                "2024-03-31T00:00:00",
                "2024-04-30T00:00:00",
                "2024-05-31T00:00:00",
                "2024-06-30T00:00:00",
                "2024-07-31T00:00:00",
                "2024-08-31T00:00:00",
                "2024-09-30T00:00:00",
                "2024-10-31T00:00:00",
                "2024-11-30T00:00:00",
                "2024-12-31T00:00:00",
                "2025-01-31T00:00:00",
              ],
              y: [
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002, 227.45138400000002, 227.45138400000002,
                227.45138400000002,
              ],
              type: "scatter",
            },
          ],
          xaxis: {
            range: ["2016-06-02T07:12:00", "2025-06-30T16:48:00"],
            type: "date",
          },
          yaxis: {
            rangemode: "normal",
            title: {
              text: "trend",
            },
            zerolinecolor: "#AAA",
          },
        },
        yearly: {
          traces: [
            {
              line: {
                color: "#0072B2",
                width: 2,
              },
              mode: "lines",
              name: "yearly",
              x: [
                "2017-01-01T00:00:00",
                "2017-01-02T00:00:59.178082",
                "2017-01-03T00:01:58.356164",
                "2017-01-04T00:02:57.534246",
                "2017-01-05T00:03:56.712328",
                "2017-01-06T00:04:55.890411",
                "2017-01-07T00:05:55.068493",
                "2017-01-08T00:06:54.246575",
                "2017-01-09T00:07:53.424657",
                "2017-01-10T00:08:52.602739",
                "2017-01-11T00:09:51.780822",
                "2017-01-12T00:10:50.958904",
                "2017-01-13T00:11:50.136986",
                "2017-01-14T00:12:49.315068",
                "2017-01-15T00:13:48.493150",
                "2017-01-16T00:14:47.671232",
                "2017-01-17T00:15:46.849315",
                "2017-01-18T00:16:46.027397",
                "2017-01-19T00:17:45.205479",
                "2017-01-20T00:18:44.383561",
                "2017-01-21T00:19:43.561643",
                "2017-01-22T00:20:42.739726",
                "2017-01-23T00:21:41.917808",
                "2017-01-24T00:22:41.095890",
                "2017-01-25T00:23:40.273972",
                "2017-01-26T00:24:39.452054",
                "2017-01-27T00:25:38.630137",
                "2017-01-28T00:26:37.808219",
                "2017-01-29T00:27:36.986301",
                "2017-01-30T00:28:36.164383",
                "2017-01-31T00:29:35.342465",
                "2017-02-01T00:30:34.520547",
                "2017-02-02T00:31:33.698630",
                "2017-02-03T00:32:32.876712",
                "2017-02-04T00:33:32.054794",
                "2017-02-05T00:34:31.232876",
                "2017-02-06T00:35:30.410958",
                "2017-02-07T00:36:29.589041",
                "2017-02-08T00:37:28.767123",
                "2017-02-09T00:38:27.945205",
                "2017-02-10T00:39:27.123287",
                "2017-02-11T00:40:26.301369",
                "2017-02-12T00:41:25.479452",
                "2017-02-13T00:42:24.657534",
                "2017-02-14T00:43:23.835616",
                "2017-02-15T00:44:23.013698",
                "2017-02-16T00:45:22.191780",
                "2017-02-17T00:46:21.369862",
                "2017-02-18T00:47:20.547945",
                "2017-02-19T00:48:19.726027",
                "2017-02-20T00:49:18.904109",
                "2017-02-21T00:50:18.082191",
                "2017-02-22T00:51:17.260273",
                "2017-02-23T00:52:16.438356",
                "2017-02-24T00:53:15.616438",
                "2017-02-25T00:54:14.794520",
                "2017-02-26T00:55:13.972602",
                "2017-02-27T00:56:13.150684",
                "2017-02-28T00:57:12.328767",
                "2017-03-01T00:58:11.506849",
                "2017-03-02T00:59:10.684931",
                "2017-03-03T01:00:09.863013",
                "2017-03-04T01:01:09.041095",
                "2017-03-05T01:02:08.219177",
                "2017-03-06T01:03:07.397260",
                "2017-03-07T01:04:06.575342",
                "2017-03-08T01:05:05.753424",
                "2017-03-09T01:06:04.931506",
                "2017-03-10T01:07:04.109588",
                "2017-03-11T01:08:03.287671",
                "2017-03-12T01:09:02.465753",
                "2017-03-13T01:10:01.643835",
                "2017-03-14T01:11:00.821917",
                "2017-03-15T01:12:00",
                "2017-03-16T01:12:59.178082",
                "2017-03-17T01:13:58.356164",
                "2017-03-18T01:14:57.534246",
                "2017-03-19T01:15:56.712328",
                "2017-03-20T01:16:55.890411",
                "2017-03-21T01:17:55.068493",
                "2017-03-22T01:18:54.246575",
                "2017-03-23T01:19:53.424657",
                "2017-03-24T01:20:52.602739",
                "2017-03-25T01:21:51.780822",
                "2017-03-26T01:22:50.958904",
                "2017-03-27T01:23:50.136986",
                "2017-03-28T01:24:49.315068",
                "2017-03-29T01:25:48.493150",
                "2017-03-30T01:26:47.671232",
                "2017-03-31T01:27:46.849315",
                "2017-04-01T01:28:46.027397",
                "2017-04-02T01:29:45.205479",
                "2017-04-03T01:30:44.383561",
                "2017-04-04T01:31:43.561643",
                "2017-04-05T01:32:42.739726",
                "2017-04-06T01:33:41.917808",
                "2017-04-07T01:34:41.095890",
                "2017-04-08T01:35:40.273972",
                "2017-04-09T01:36:39.452054",
                "2017-04-10T01:37:38.630137",
                "2017-04-11T01:38:37.808219",
                "2017-04-12T01:39:36.986301",
                "2017-04-13T01:40:36.164383",
                "2017-04-14T01:41:35.342465",
                "2017-04-15T01:42:34.520547",
                "2017-04-16T01:43:33.698630",
                "2017-04-17T01:44:32.876712",
                "2017-04-18T01:45:32.054794",
                "2017-04-19T01:46:31.232876",
                "2017-04-20T01:47:30.410958",
                "2017-04-21T01:48:29.589041",
                "2017-04-22T01:49:28.767123",
                "2017-04-23T01:50:27.945205",
                "2017-04-24T01:51:27.123287",
                "2017-04-25T01:52:26.301369",
                "2017-04-26T01:53:25.479452",
                "2017-04-27T01:54:24.657534",
                "2017-04-28T01:55:23.835616",
                "2017-04-29T01:56:23.013698",
                "2017-04-30T01:57:22.191780",
                "2017-05-01T01:58:21.369862",
                "2017-05-02T01:59:20.547945",
                "2017-05-03T02:00:19.726027",
                "2017-05-04T02:01:18.904109",
                "2017-05-05T02:02:18.082191",
                "2017-05-06T02:03:17.260273",
                "2017-05-07T02:04:16.438356",
                "2017-05-08T02:05:15.616438",
                "2017-05-09T02:06:14.794520",
                "2017-05-10T02:07:13.972602",
                "2017-05-11T02:08:13.150684",
                "2017-05-12T02:09:12.328767",
                "2017-05-13T02:10:11.506849",
                "2017-05-14T02:11:10.684931",
                "2017-05-15T02:12:09.863013",
                "2017-05-16T02:13:09.041095",
                "2017-05-17T02:14:08.219177",
                "2017-05-18T02:15:07.397260",
                "2017-05-19T02:16:06.575342",
                "2017-05-20T02:17:05.753424",
                "2017-05-21T02:18:04.931506",
                "2017-05-22T02:19:04.109588",
                "2017-05-23T02:20:03.287671",
                "2017-05-24T02:21:02.465753",
                "2017-05-25T02:22:01.643835",
                "2017-05-26T02:23:00.821917",
                "2017-05-27T02:24:00",
                "2017-05-28T02:24:59.178082",
                "2017-05-29T02:25:58.356164",
                "2017-05-30T02:26:57.534246",
                "2017-05-31T02:27:56.712328",
                "2017-06-01T02:28:55.890411",
                "2017-06-02T02:29:55.068493",
                "2017-06-03T02:30:54.246575",
                "2017-06-04T02:31:53.424657",
                "2017-06-05T02:32:52.602739",
                "2017-06-06T02:33:51.780822",
                "2017-06-07T02:34:50.958904",
                "2017-06-08T02:35:50.136986",
                "2017-06-09T02:36:49.315068",
                "2017-06-10T02:37:48.493150",
                "2017-06-11T02:38:47.671232",
                "2017-06-12T02:39:46.849315",
                "2017-06-13T02:40:46.027397",
                "2017-06-14T02:41:45.205479",
                "2017-06-15T02:42:44.383561",
                "2017-06-16T02:43:43.561643",
                "2017-06-17T02:44:42.739726",
                "2017-06-18T02:45:41.917808",
                "2017-06-19T02:46:41.095890",
                "2017-06-20T02:47:40.273972",
                "2017-06-21T02:48:39.452054",
                "2017-06-22T02:49:38.630137",
                "2017-06-23T02:50:37.808219",
                "2017-06-24T02:51:36.986301",
                "2017-06-25T02:52:36.164383",
                "2017-06-26T02:53:35.342465",
                "2017-06-27T02:54:34.520547",
                "2017-06-28T02:55:33.698630",
                "2017-06-29T02:56:32.876712",
                "2017-06-30T02:57:32.054794",
                "2017-07-01T02:58:31.232876",
                "2017-07-02T02:59:30.410958",
                "2017-07-03T03:00:29.589041",
                "2017-07-04T03:01:28.767123",
                "2017-07-05T03:02:27.945205",
                "2017-07-06T03:03:27.123287",
                "2017-07-07T03:04:26.301369",
                "2017-07-08T03:05:25.479452",
                "2017-07-09T03:06:24.657534",
                "2017-07-10T03:07:23.835616",
                "2017-07-11T03:08:23.013698",
                "2017-07-12T03:09:22.191780",
                "2017-07-13T03:10:21.369862",
                "2017-07-14T03:11:20.547945",
                "2017-07-15T03:12:19.726027",
                "2017-07-16T03:13:18.904109",
                "2017-07-17T03:14:18.082191",
                "2017-07-18T03:15:17.260273",
                "2017-07-19T03:16:16.438356",
                "2017-07-20T03:17:15.616438",
                "2017-07-21T03:18:14.794520",
                "2017-07-22T03:19:13.972602",
                "2017-07-23T03:20:13.150684",
                "2017-07-24T03:21:12.328767",
                "2017-07-25T03:22:11.506849",
                "2017-07-26T03:23:10.684931",
                "2017-07-27T03:24:09.863013",
                "2017-07-28T03:25:09.041095",
                "2017-07-29T03:26:08.219177",
                "2017-07-30T03:27:07.397260",
                "2017-07-31T03:28:06.575342",
                "2017-08-01T03:29:05.753424",
                "2017-08-02T03:30:04.931506",
                "2017-08-03T03:31:04.109588",
                "2017-08-04T03:32:03.287671",
                "2017-08-05T03:33:02.465753",
                "2017-08-06T03:34:01.643835",
                "2017-08-07T03:35:00.821917",
                "2017-08-08T03:36:00",
                "2017-08-09T03:36:59.178082",
                "2017-08-10T03:37:58.356164",
                "2017-08-11T03:38:57.534246",
                "2017-08-12T03:39:56.712328",
                "2017-08-13T03:40:55.890411",
                "2017-08-14T03:41:55.068493",
                "2017-08-15T03:42:54.246575",
                "2017-08-16T03:43:53.424657",
                "2017-08-17T03:44:52.602739",
                "2017-08-18T03:45:51.780822",
                "2017-08-19T03:46:50.958904",
                "2017-08-20T03:47:50.136986",
                "2017-08-21T03:48:49.315068",
                "2017-08-22T03:49:48.493150",
                "2017-08-23T03:50:47.671232",
                "2017-08-24T03:51:46.849315",
                "2017-08-25T03:52:46.027397",
                "2017-08-26T03:53:45.205479",
                "2017-08-27T03:54:44.383561",
                "2017-08-28T03:55:43.561643",
                "2017-08-29T03:56:42.739726",
                "2017-08-30T03:57:41.917808",
                "2017-08-31T03:58:41.095890",
                "2017-09-01T03:59:40.273972",
                "2017-09-02T04:00:39.452054",
                "2017-09-03T04:01:38.630137",
                "2017-09-04T04:02:37.808219",
                "2017-09-05T04:03:36.986301",
                "2017-09-06T04:04:36.164383",
                "2017-09-07T04:05:35.342465",
                "2017-09-08T04:06:34.520547",
                "2017-09-09T04:07:33.698630",
                "2017-09-10T04:08:32.876712",
                "2017-09-11T04:09:32.054794",
                "2017-09-12T04:10:31.232876",
                "2017-09-13T04:11:30.410958",
                "2017-09-14T04:12:29.589041",
                "2017-09-15T04:13:28.767123",
                "2017-09-16T04:14:27.945205",
                "2017-09-17T04:15:27.123287",
                "2017-09-18T04:16:26.301369",
                "2017-09-19T04:17:25.479452",
                "2017-09-20T04:18:24.657534",
                "2017-09-21T04:19:23.835616",
                "2017-09-22T04:20:23.013698",
                "2017-09-23T04:21:22.191780",
                "2017-09-24T04:22:21.369862",
                "2017-09-25T04:23:20.547945",
                "2017-09-26T04:24:19.726027",
                "2017-09-27T04:25:18.904109",
                "2017-09-28T04:26:18.082191",
                "2017-09-29T04:27:17.260273",
                "2017-09-30T04:28:16.438356",
                "2017-10-01T04:29:15.616438",
                "2017-10-02T04:30:14.794520",
                "2017-10-03T04:31:13.972602",
                "2017-10-04T04:32:13.150684",
                "2017-10-05T04:33:12.328767",
                "2017-10-06T04:34:11.506849",
                "2017-10-07T04:35:10.684931",
                "2017-10-08T04:36:09.863013",
                "2017-10-09T04:37:09.041095",
                "2017-10-10T04:38:08.219177",
                "2017-10-11T04:39:07.397260",
                "2017-10-12T04:40:06.575342",
                "2017-10-13T04:41:05.753424",
                "2017-10-14T04:42:04.931506",
                "2017-10-15T04:43:04.109588",
                "2017-10-16T04:44:03.287671",
                "2017-10-17T04:45:02.465753",
                "2017-10-18T04:46:01.643835",
                "2017-10-19T04:47:00.821917",
                "2017-10-20T04:48:00",
                "2017-10-21T04:48:59.178082",
                "2017-10-22T04:49:58.356164",
                "2017-10-23T04:50:57.534246",
                "2017-10-24T04:51:56.712328",
                "2017-10-25T04:52:55.890411",
                "2017-10-26T04:53:55.068493",
                "2017-10-27T04:54:54.246575",
                "2017-10-28T04:55:53.424657",
                "2017-10-29T04:56:52.602739",
                "2017-10-30T04:57:51.780822",
                "2017-10-31T04:58:50.958904",
                "2017-11-01T04:59:50.136986",
                "2017-11-02T05:00:49.315068",
                "2017-11-03T05:01:48.493150",
                "2017-11-04T05:02:47.671232",
                "2017-11-05T05:03:46.849315",
                "2017-11-06T05:04:46.027397",
                "2017-11-07T05:05:45.205479",
                "2017-11-08T05:06:44.383561",
                "2017-11-09T05:07:43.561643",
                "2017-11-10T05:08:42.739726",
                "2017-11-11T05:09:41.917808",
                "2017-11-12T05:10:41.095890",
                "2017-11-13T05:11:40.273972",
                "2017-11-14T05:12:39.452054",
                "2017-11-15T05:13:38.630137",
                "2017-11-16T05:14:37.808219",
                "2017-11-17T05:15:36.986301",
                "2017-11-18T05:16:36.164383",
                "2017-11-19T05:17:35.342465",
                "2017-11-20T05:18:34.520547",
                "2017-11-21T05:19:33.698630",
                "2017-11-22T05:20:32.876712",
                "2017-11-23T05:21:32.054794",
                "2017-11-24T05:22:31.232876",
                "2017-11-25T05:23:30.410958",
                "2017-11-26T05:24:29.589041",
                "2017-11-27T05:25:28.767123",
                "2017-11-28T05:26:27.945205",
                "2017-11-29T05:27:27.123287",
                "2017-11-30T05:28:26.301369",
                "2017-12-01T05:29:25.479452",
                "2017-12-02T05:30:24.657534",
                "2017-12-03T05:31:23.835616",
                "2017-12-04T05:32:23.013698",
                "2017-12-05T05:33:22.191780",
                "2017-12-06T05:34:21.369862",
                "2017-12-07T05:35:20.547945",
                "2017-12-08T05:36:19.726027",
                "2017-12-09T05:37:18.904109",
                "2017-12-10T05:38:18.082191",
                "2017-12-11T05:39:17.260273",
                "2017-12-12T05:40:16.438356",
                "2017-12-13T05:41:15.616438",
                "2017-12-14T05:42:14.794520",
                "2017-12-15T05:43:13.972602",
                "2017-12-16T05:44:13.150684",
                "2017-12-17T05:45:12.328767",
                "2017-12-18T05:46:11.506849",
                "2017-12-19T05:47:10.684931",
                "2017-12-20T05:48:09.863013",
                "2017-12-21T05:49:09.041095",
                "2017-12-22T05:50:08.219177",
                "2017-12-23T05:51:07.397260",
                "2017-12-24T05:52:06.575342",
                "2017-12-25T05:53:05.753424",
                "2017-12-26T05:54:04.931506",
                "2017-12-27T05:55:04.109588",
                "2017-12-28T05:56:03.287671",
                "2017-12-29T05:57:02.465753",
                "2017-12-30T05:58:01.643835",
                "2017-12-31T05:59:00.821917",
              ],
              y: [
                -0.21039810850875423, 1.2898915232603847, 3.968916006240118,
                7.63841643303394, 12.085902211158393, 17.08102863437119,
                22.38238501652289, 27.744497519326956, 32.9248466700516,
                37.69070116758006, 41.825576825592954, 45.135142151703704,
                47.45240970671618, 48.64207448353141, 48.60388641976222,
                47.274973036488575, 44.63105921996294, 40.68656342118267,
                35.49358209479494, 29.13980609161441, 21.745443035816713,
                13.459247595760168, 4.45378620148972, -5.079916511127227,
                -14.938186015022263, -24.910786140326337, -34.78710633489546,
                -44.36226626214445, -53.44296063150343, -61.85287686367402,
                -69.43753271330773, -76.0683998286563, -81.64620183571067,
                -86.10330118764321, -89.40511694068444, -91.55054495599465,
                -92.5713819064336, -92.53078399828712, -91.52081962495703,
                -89.65920142829665, -87.08530668870324, -83.95561492267862,
                -80.43870747627793, -76.70998532289698, -72.94626790093928,
                -69.32043750746232, -65.99629048602225, -63.123748353400146,
                -60.8345693834898, -59.238684423117014, -58.421260393695064,
                -58.440571673940575, -59.32673408814905, -61.081329329040784,
                -63.677920145855, -67.06342936795765, -71.16032963147089,
                -75.8695663218937, -81.07411446291694, -86.64305171569329,
                -92.43601484635676, -98.30789639354428, -104.11363212569583,
                -109.71292836958818, -114.97478143687027, -119.7816490510299,
                -124.03314561501031, -127.64914897405721, -130.57222550807194,
                -132.76930232837196, -134.23253936423217, -134.97937945793063,
                -135.05178045641316, -134.51465889563187, -133.4535994306232,
                -131.97190692155112, -130.18709834581568, -128.22694885733364,
                -126.22521983622326, -124.31720626275877, -122.63524592906742,
                -121.30433372315078, -120.43798047637114, -120.13444778289798,
                -120.47347804166895, -121.51362312310826, -123.29025602283981,
                -125.8143282295452, -129.07191197756342, -133.02454181052343,
                -137.61034471657405, -142.7459232909267, -148.3289327025715,
                -154.24127042367968, -160.35277839657638, -166.52534115887002,
                -172.61725092342647, -178.4877021033834, -184.00127354599857,
                -189.03225692576007, -193.46869434324296, -197.21599704067984,
                -200.20003000781216, -202.3695637194905, -203.6980138113473,
                -204.18441156370653, -203.8535719457847, -202.75545093916082,
                -200.96370913959478, -198.57352345231962, -195.69871227738153,
                -192.4682612017021, -189.02235519824666, -185.50803909110863,
                -182.07464008165203, -178.86909406678532, -176.03132105664275,
                -173.6897940958306, -171.95744072139638, -170.9280063017415,
                -170.672994882937, -171.2392858200525, -172.64750402273245,
                -174.89119870281394, -177.9368607781127, -181.72478330936028,
                -186.17074331487117, -191.16845781396458, -196.59274278107435,
                -202.30328159370774, -208.14889021068097, -213.97215033226504,
                -219.61426967091515, -224.92002059090092, -229.74260501686817,
                -233.9482947938663, -237.42070258324074, -240.06454874933152,
                -241.80880423547333, -242.60910772660287, -242.44937691125295,
                -241.34255775255366, -239.33048164100927, -236.48282734971878,
                -232.89521203480302, -228.68646229293108, -223.9951416886154,
                -218.97543442261474, -213.79250521091427, -208.61747234835778,
                -203.62214381276152, -198.97367470633964, -194.8293080556482,
                -191.33135985061972, -188.602603208329, -186.7421958410674,
                -185.82227988672102, -185.88536404599705, -186.94257541397238,
                -188.97284304394964, -191.92304788337816, -195.7091450769118,
                -200.21823559043236, -205.3115355325541, -210.8281642965146,
                -216.58964752806588, -222.4050087122749, -228.07630453631083,
                -233.4044447057227, -238.19512702384247, -242.26471360414573,
                -245.44587426063953, -247.59282842397243, -248.58602724872893,
                -248.33613262687297, -246.78716919144202, -243.91874853813218,
                -239.74729115035657, -234.32620012914586, -227.74497098064117,
                -220.1272525168126, -211.6279044858525, -202.42912696593896,
                -192.73576396511766, -182.7699082561834, -172.76495550569842,
                -162.95927260445512, -153.58965725024456, -144.88477290832608,
                -137.0587450452127, -130.30510092385927, -124.79122634974192,
                -120.65349879963802, -117.99323774326356, -116.87359020009556,
                -117.31744331227077, -119.30642670998147, -122.78103653431904,
                -127.6418810552215, -133.75201580778034, -140.94030499564548,
                -149.0057164889443, -157.72243093054595, -166.8456220504513,
                -176.11774595768716, -185.27516250554166, -194.05490224652434,
                -202.20138829197114, -209.47292370773113, -215.64776187351256,
                -220.52958932748874, -223.95226766096707, -225.78370253399748,
                -225.9287332252466, -224.33096458183567, -220.97349396274606,
                -215.87851788459014, -209.1058356400822, -200.75029922100484,
                -190.9382894959156, -179.82332687746575, -167.58094982946923,
                -154.40301577680376, -140.49159566365702, -126.05264506506725,
                -111.28964105130821, -96.39737473618777, -81.55608459264144,
                -66.92610532093062, -52.643191608904814, -38.814655973028465,
                -25.51643560900262, -12.791175511498105, -0.6473848582378614,
                10.940308317476845, 22.02981188559261, 32.70917407899026,
                43.09320932610101, 53.31924645190804, 63.54212662767721,
                73.92860111081498, 84.65129708240573, 95.88243321735631,
                107.7874746078668, 120.51891906693068, 134.21040357417874,
                148.97131079188458, 164.882041408544, 181.9900989806784,
                200.30711048622481, 219.80687865385275, 240.4245320949493,
                262.0568071917352, 284.5634625578848, 307.7697936204477,
                331.4701824827689, 355.43258764776306, 379.4038503194037,
                403.1156696835621, 426.29107949936815, 448.6512431380529,
                469.9223742993988, 489.8425863640371, 508.1684747931351,
                524.6812441700451, 539.1922041724667, 551.547476604099,
                561.6317781077666, 569.3711696530426, 574.7346935948173,
                577.7348511530249, 578.4269066487172, 576.9070387616404,
                573.3093924594732, 567.8021171158235, 560.582505740798,
                551.8713763390418, 541.9068584110921, 530.9377648765819,
                519.2167417137791, 506.9933940151683, 494.5075877590112,
                481.98312137082615, 469.62195023045894, 457.5991309959697,
                446.0586314161525, 435.11012581961097, 424.8268674265234,
                415.24469688050357, 406.3622128665638, 398.1420963461532,
                390.51354580449294, 383.37574796950787, 376.60227769462205,
                370.0462929984933, 363.54636744748024, 356.9327828489603,
                350.0340911729603, 342.6837461535659, 334.7266024023665,
                326.02508318255286, 316.4648271605269, 305.95963921951085,
                294.45559036364676, 281.9341363009894, 268.41415275484775,
                253.95281710075182, 238.6452996492688, 222.62326281426476,
                206.05220151135555, 189.12769239787852, 172.0706520033903,
                155.1217334513865, 138.53501747856828, 122.57117505155477,
                107.49029542267252, 93.54458446876093, 80.97114327954091,
                69.98503605273727, 60.77284940896246, 53.48693245571252,
                48.240488656911374, 45.10366731077196, 44.10077486855245,
                45.2086952147454, 48.35657426557464, 53.42678878547294,
                60.25718318541712, 68.64452228223111, 78.34907359076627,
                89.10020066999208, 100.60282027544416, 112.54455139713554,
                124.60336440189481, 136.4555240123448, 147.7836111634266,
                158.28440612556327, 167.6764187546255, 175.70686121805042,
                182.15787379053114, 186.85183487153978, 189.65561166995818,
                190.48363730681325, 189.2997325687802, 186.1176252856971,
                181.00015631276796, 174.05719735469356, 165.4423413473273,
                155.34845981171983, 144.00225256452455, 131.65794254036786,
                118.59029149135011, 105.08713033560248, 91.44161044256076,
                77.94438882425413, 64.87596088903632, 52.49934910466612,
                41.053344787838036, 30.74648362848873, 21.751913961575596,
                14.20329085652952, 8.191799555914882, 3.7643795255472274,
                0.9231862992885425, -0.3737065992655966,
              ],
              type: "scatter",
            },
          ],
          xaxis: {
            range: [
              "2016-12-13T18:54:02.958904115",
              "2018-01-18T11:04:57.863013581",
            ],
            tickformat: "%B %e",
            type: "date",
          },
          yaxis: {
            title: {
              text: "yearly",
            },
            zerolinecolor: "#AAA",
          },
        },
      },
    },
  },
  {
    name: "pneumopcv_2024-05-09_08:33:15",
    forecast_plot: {
      data: [
        {
          marker: {
            color: "black",
            size: 4,
          },
          mode: "markers",
          name: "Actual",
          x: [
            "2016-09-30T00:00:00",
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
          ],
          y: [
            1, 21, 118, 130, 145, 152, 152, 124, 153, 121, 141, 128, 150, 151,
            163, 138, 124, 143, 127, 132, 139, 113, 148, 125, 159, 133, 137,
            154, 136, 157, 126, 140, 113, 129, 134, 112, 120, 133, 130, 148,
            130, 139, 125, 135, 117, 128, 127, 157, 151, 126, 152, 164, 118,
            152, 125, 119, 119, 139, 106, 139, 137, 158, 127, 125, 124, 165,
            111, 136, 104, 114, 152, 118, 138, 112, 144, 119, 113, 143, 123,
            107, 129, 108, 106,
          ],
          type: "scatter",
        },
        {
          legendgroup: "Trend Line",
          line: {
            color: "#0072B2",
            width: 2,
          },
          mode: "lines",
          name: "Trend Line",
          x: [
            "2016-09-30T00:00:00",
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
          ],
          y: [
            103.76581119596551, 104.96544701126767, 147.5795501896038,
            146.2367189893695, 131.28322081365891, 147.67453609968388,
            126.86416913698167, 125.41069434740893, 123.30963020964757,
            123.32652140334245, 130.4837147489861, 138.5774037061062,
            117.95934445464633, 116.09938310788209, 140.8588674687467,
            141.18470199808405, 127.96764362811113, 151.14901785122635,
            126.99646049984179, 128.42593181432548, 125.5234572201973,
            121.11479879045758, 130.73012463448512, 120.97461487945888,
            132.52069324534068, 127.00571977146106, 133.8478480846554,
            136.1149967410617, 124.77546129512923, 154.69723211719457,
            127.1576569925042, 131.4324964259687, 127.62862990658087,
            118.80187857319198, 130.8274502599493, 103.6201299372844,
            147.43381640601848, 137.6689234102547, 126.55310239620154,
            131.03663097035923, 121.71195341742002, 144.27761680240505,
            126.76298596914737, 122.39142317493264, 120.99024205715399,
            125.4342865133824, 130.08269215519417, 156.4114132477056,
            103.76581119598154, 104.9654470113128, 147.5795501896571,
            146.23671898934717, 131.283220813643, 147.67453609963204,
            126.86416913698055, 125.41069434737436, 123.30963020969736,
            123.32652140332247, 130.48371474894498, 138.5774037061699,
            117.95934445459811, 116.09938310779543, 140.8588674687467,
            141.18470199803232, 127.96764362818516, 151.14901785117502,
            126.99646049978622, 128.42593181433685, 125.52345722021656,
            121.11479879039476, 130.7301246345146, 120.9746148794192,
            132.52069324539252, 127.00571977145546, 133.84784808468893,
            136.11499674102436, 124.7754612952434, 154.69723211716234,
            127.15765699241989, 131.4324964259839, 127.62862990653427,
            118.80187857312929, 130.82745025996584,
          ],
          type: "scatter",
        },
        {
          legendgroup: "Predicted",
          line: {
            color: "#33cc33",
            width: 2,
          },
          mode: "lines",
          name: "Predicted",
          x: [
            "2023-07-31T00:00:00",
            "2023-08-31T00:00:00",
            "2023-09-30T00:00:00",
            "2023-10-31T00:00:00",
            "2023-11-30T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-31T00:00:00",
            "2024-02-29T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-30T00:00:00",
            "2024-05-31T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-31T00:00:00",
            "2024-08-31T00:00:00",
            "2024-09-30T00:00:00",
            "2024-10-31T00:00:00",
            "2024-11-30T00:00:00",
            "2024-12-31T00:00:00",
            "2025-01-31T00:00:00",
          ],
          y: [
            130.82745025996584, 103.62012993726069, 147.43381640612614,
            137.66892341030422, 126.55310239618285, 131.03663097027336,
            121.71195341746488, 144.27761680245166, 126.76298596921758,
            122.39142317490375, 120.99024205711828, 125.43428651330501,
            130.08269215520014, 156.41141324764772, 103.76581119591671,
            104.9654470113165, 147.57955018962227, 146.23671898935734,
            131.28322081370857,
          ],
          type: "scatter",
        },
        {
          hoverinfo: "skip",
          legendgroup: "Trend Line",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2016-09-30T00:00:00",
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
          ],
          y: [
            61.81074105054679, 60.56290226658116, 105.57533212817373,
            104.63341557870163, 88.80235956772388, 106.15982794431574,
            85.24192453173805, 82.53226795686724, 82.46943977732546,
            81.23476345807671, 88.21232652497712, 94.40171689224273,
            78.34618315986319, 69.17078942877622, 101.63728558565457,
            100.86684511894065, 80.30383894928588, 109.77047396186178,
            83.65692856230086, 88.14374269575927, 81.28000832594579,
            76.54165798979861, 87.85017745628627, 81.7429715674259,
            93.4547882794104, 83.09792802197838, 90.98616299708452,
            97.04449582001972, 83.77337244662502, 114.84599694929507,
            86.6393729726312, 90.63185545883661, 85.24540413564131,
            77.56864504916621, 90.5401326928995, 63.91625648163204,
            107.1177466925043, 98.69688624034717, 86.20376256351064,
            92.35647255056028, 79.0583438112066, 104.47907870800024,
            86.10585515535928, 81.52226637012878, 82.05878833149339,
            86.048582315058, 90.48751465528727, 112.7866000836238,
            63.46839662617555, 62.4101499094072, 105.86652959973154,
            103.87308946678836, 90.32903435226106, 105.03422333109728,
            86.88245493453284, 85.0268272609346, 79.32465767373716,
            79.52326686559489, 88.76360614277645, 99.1929391410052,
            76.53872601607108, 70.48207165685137, 100.12952758805814,
            97.28910351870233, 86.0934133476127, 108.52102092574385,
            84.99521508772602, 85.50508972390328, 82.5960676740043,
            78.59121450348907, 88.80540648301239, 79.79584998498453,
            90.93184705017715, 87.48983798580996, 94.41463308151914,
            90.44130632185774, 82.44219256489255, 111.97172070657905,
            87.11856688878105, 91.60001481061163, 88.27259884698168,
            79.24931950656341, 87.72991554520307,
          ],
          type: "scatter",
        },
        {
          fill: "tonexty",
          fillcolor: "rgba(0, 114, 178, 0.2)",
          hoverinfo: "skip",
          legendgroup: "Trend Line",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2016-09-30T00:00:00",
            "2016-10-31T00:00:00",
            "2016-11-30T00:00:00",
            "2016-12-31T00:00:00",
            "2017-01-31T00:00:00",
            "2017-02-28T00:00:00",
            "2017-03-31T00:00:00",
            "2017-04-30T00:00:00",
            "2017-05-31T00:00:00",
            "2017-06-30T00:00:00",
            "2017-07-31T00:00:00",
            "2017-08-31T00:00:00",
            "2017-09-30T00:00:00",
            "2017-10-31T00:00:00",
            "2017-11-30T00:00:00",
            "2017-12-31T00:00:00",
            "2018-01-31T00:00:00",
            "2018-02-28T00:00:00",
            "2018-03-31T00:00:00",
            "2018-04-30T00:00:00",
            "2018-05-31T00:00:00",
            "2018-06-30T00:00:00",
            "2018-07-31T00:00:00",
            "2018-08-31T00:00:00",
            "2018-09-30T00:00:00",
            "2018-10-31T00:00:00",
            "2018-11-30T00:00:00",
            "2018-12-31T00:00:00",
            "2019-01-31T00:00:00",
            "2019-02-28T00:00:00",
            "2019-03-31T00:00:00",
            "2019-04-30T00:00:00",
            "2019-05-31T00:00:00",
            "2019-06-30T00:00:00",
            "2019-07-31T00:00:00",
            "2019-08-31T00:00:00",
            "2019-09-30T00:00:00",
            "2019-10-31T00:00:00",
            "2019-11-30T00:00:00",
            "2019-12-31T00:00:00",
            "2020-01-31T00:00:00",
            "2020-02-29T00:00:00",
            "2020-03-31T00:00:00",
            "2020-04-30T00:00:00",
            "2020-05-31T00:00:00",
            "2020-06-30T00:00:00",
            "2020-07-31T00:00:00",
            "2020-08-31T00:00:00",
            "2020-09-30T00:00:00",
            "2020-10-31T00:00:00",
            "2020-11-30T00:00:00",
            "2020-12-31T00:00:00",
            "2021-01-31T00:00:00",
            "2021-02-28T00:00:00",
            "2021-03-31T00:00:00",
            "2021-04-30T00:00:00",
            "2021-05-31T00:00:00",
            "2021-06-30T00:00:00",
            "2021-07-31T00:00:00",
            "2021-08-31T00:00:00",
            "2021-09-30T00:00:00",
            "2021-10-31T00:00:00",
            "2021-11-30T00:00:00",
            "2021-12-31T00:00:00",
            "2022-01-31T00:00:00",
            "2022-02-28T00:00:00",
            "2022-03-31T00:00:00",
            "2022-04-30T00:00:00",
            "2022-05-31T00:00:00",
            "2022-06-30T00:00:00",
            "2022-07-31T00:00:00",
            "2022-08-31T00:00:00",
            "2022-09-30T00:00:00",
            "2022-10-31T00:00:00",
            "2022-11-30T00:00:00",
            "2022-12-31T00:00:00",
            "2023-01-31T00:00:00",
            "2023-02-28T00:00:00",
            "2023-03-31T00:00:00",
            "2023-04-30T00:00:00",
            "2023-05-31T00:00:00",
            "2023-06-30T00:00:00",
            "2023-07-31T00:00:00",
          ],
          y: [
            147.24206597681015, 146.20987796242605, 191.01920278637357,
            186.4357638826983, 174.52636323921575, 188.81120887013012,
            171.08840155744727, 166.57890128395746, 163.5939654599922,
            168.74003227567238, 170.79318713262094, 180.57010768327376,
            160.9970464393823, 154.58760704714544, 182.6556350255579,
            183.47726513325304, 169.30389023630573, 190.8310200152306,
            170.5342749561796, 170.30367460248686, 166.70097730511173,
            162.55678959717008, 173.004917143322, 162.6355987857728,
            177.30705726267476, 169.65270368354993, 177.19105949690442,
            176.99222553909541, 163.25339570681697, 193.51163737336157,
            168.17792504606942, 175.07725580582655, 170.28104557446068,
            162.38623513692497, 173.6270351992759, 141.8149508679549,
            186.84725575821997, 179.1445899607671, 168.62851621268706,
            172.56361032883467, 164.32499725628293, 184.5539507316862,
            170.15529487128507, 161.76518616970873, 162.4694833275039,
            164.57986303423107, 172.63287184991785, 197.7174411057682,
            144.6561236116223, 144.72345582757555, 190.41194747727508,
            189.8826373966078, 172.16910284604057, 186.46710026161637,
            169.461241142974, 167.3028142742727, 165.38966653748633,
            162.78290763750772, 170.0417057639683, 177.7223147073701,
            158.66538751454482, 155.27321179675567, 184.87011560313186,
            181.49609916115995, 171.02104323577976, 190.358843053691,
            166.7604404725383, 168.8990288245905, 165.37737864484302,
            160.8397027271624, 170.56920266485022, 165.07205615979902,
            174.12009770606562, 169.60219823527592, 174.4716359010768,
            178.12149801489085, 167.40533935989473, 196.3564767981804,
            167.52005392973652, 171.05527629039204, 168.27057292293233,
            159.81773856679285, 171.38715826524978,
          ],
          type: "scatter",
        },
        {
          hoverinfo: "skip",
          legendgroup: "Predicted",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2023-07-31T00:00:00",
            "2023-08-31T00:00:00",
            "2023-09-30T00:00:00",
            "2023-10-31T00:00:00",
            "2023-11-30T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-31T00:00:00",
            "2024-02-29T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-30T00:00:00",
            "2024-05-31T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-31T00:00:00",
            "2024-08-31T00:00:00",
            "2024-09-30T00:00:00",
            "2024-10-31T00:00:00",
            "2024-11-30T00:00:00",
            "2024-12-31T00:00:00",
            "2025-01-31T00:00:00",
          ],
          y: [
            87.72991554520307, 58.038664111416914, 107.01141492824603,
            96.01527167987072, 86.19244552725803, 90.27540629041775,
            78.83955981741309, 103.06755044861457, 82.44476330014197,
            80.2008049687165, 80.30178940010637, 83.92491679336183,
            90.12253515663676, 114.21946241005644, 62.07653197166822,
            62.47815770952632, 106.2967568316756, 105.3007811727859,
            87.72140208826816,
          ],
          type: "scatter",
        },
        {
          fill: "tonexty",
          fillcolor: "rgba(51, 204, 51, 0.2)",
          hoverinfo: "skip",
          legendgroup: "Predicted",
          line: {
            width: 0,
          },
          mode: "lines",
          showlegend: false,
          x: [
            "2023-07-31T00:00:00",
            "2023-08-31T00:00:00",
            "2023-09-30T00:00:00",
            "2023-10-31T00:00:00",
            "2023-11-30T00:00:00",
            "2023-12-31T00:00:00",
            "2024-01-31T00:00:00",
            "2024-02-29T00:00:00",
            "2024-03-31T00:00:00",
            "2024-04-30T00:00:00",
            "2024-05-31T00:00:00",
            "2024-06-30T00:00:00",
            "2024-07-31T00:00:00",
            "2024-08-31T00:00:00",
            "2024-09-30T00:00:00",
            "2024-10-31T00:00:00",
            "2024-11-30T00:00:00",
            "2024-12-31T00:00:00",
            "2025-01-31T00:00:00",
          ],
          y: [
            171.38715826524978, 143.68539508157602, 189.17907411828327,
            178.94085592144714, 167.0268136562211, 171.80997308273433,
            161.71839005725857, 185.81039566809378, 170.2736090609578,
            161.85519294708624, 163.00914140449026, 166.08673104084272,
            171.06678900759684, 196.82565961453585, 144.30801267880065,
            146.40967899354305, 189.54929918945706, 186.3723169905932,
            174.30387234794904,
          ],
          type: "scatter",
        },
      ],
      layout: {
        showlegend: true,
        width: 850,
        height: 600,
        yaxis: {
          title: "y",
        },
        xaxis: {
          title: "ds",
          type: "date",
          rangeselector: {
            buttons: [
              {
                count: 7,
                label: "1w",
                step: "day",
                stepmode: "backward",
              },
              {
                count: 1,
                label: "1m",
                step: "month",
                stepmode: "backward",
              },
              {
                count: 6,
                label: "6m",
                step: "month",
                stepmode: "backward",
              },
              {
                count: 1,
                label: "1y",
                step: "year",
                stepmode: "backward",
              },
              {
                step: "all",
              },
            ],
          },
          rangeslider: {
            visible: true,
          },
        },
      },
    },
    forecast_components: {
      data: {
        trend: {
          traces: [
            {
              line: {
                color: "#0072B2",
                width: 2,
              },
              mode: "lines",
              name: "trend",
              x: [
                "2016-09-30T00:00:00",
                "2016-10-31T00:00:00",
                "2016-11-30T00:00:00",
                "2016-12-31T00:00:00",
                "2017-01-31T00:00:00",
                "2017-02-28T00:00:00",
                "2017-03-31T00:00:00",
                "2017-04-30T00:00:00",
                "2017-05-31T00:00:00",
                "2017-06-30T00:00:00",
                "2017-07-31T00:00:00",
                "2017-08-31T00:00:00",
                "2017-09-30T00:00:00",
                "2017-10-31T00:00:00",
                "2017-11-30T00:00:00",
                "2017-12-31T00:00:00",
                "2018-01-31T00:00:00",
                "2018-02-28T00:00:00",
                "2018-03-31T00:00:00",
                "2018-04-30T00:00:00",
                "2018-05-31T00:00:00",
                "2018-06-30T00:00:00",
                "2018-07-31T00:00:00",
                "2018-08-31T00:00:00",
                "2018-09-30T00:00:00",
                "2018-10-31T00:00:00",
                "2018-11-30T00:00:00",
                "2018-12-31T00:00:00",
                "2019-01-31T00:00:00",
                "2019-02-28T00:00:00",
                "2019-03-31T00:00:00",
                "2019-04-30T00:00:00",
                "2019-05-31T00:00:00",
                "2019-06-30T00:00:00",
                "2019-07-31T00:00:00",
                "2019-08-31T00:00:00",
                "2019-09-30T00:00:00",
                "2019-10-31T00:00:00",
                "2019-11-30T00:00:00",
                "2019-12-31T00:00:00",
                "2020-01-31T00:00:00",
                "2020-02-29T00:00:00",
                "2020-03-31T00:00:00",
                "2020-04-30T00:00:00",
                "2020-05-31T00:00:00",
                "2020-06-30T00:00:00",
                "2020-07-31T00:00:00",
                "2020-08-31T00:00:00",
                "2020-09-30T00:00:00",
                "2020-10-31T00:00:00",
                "2020-11-30T00:00:00",
                "2020-12-31T00:00:00",
                "2021-01-31T00:00:00",
                "2021-02-28T00:00:00",
                "2021-03-31T00:00:00",
                "2021-04-30T00:00:00",
                "2021-05-31T00:00:00",
                "2021-06-30T00:00:00",
                "2021-07-31T00:00:00",
                "2021-08-31T00:00:00",
                "2021-09-30T00:00:00",
                "2021-10-31T00:00:00",
                "2021-11-30T00:00:00",
                "2021-12-31T00:00:00",
                "2022-01-31T00:00:00",
                "2022-02-28T00:00:00",
                "2022-03-31T00:00:00",
                "2022-04-30T00:00:00",
                "2022-05-31T00:00:00",
                "2022-06-30T00:00:00",
                "2022-07-31T00:00:00",
                "2022-08-31T00:00:00",
                "2022-09-30T00:00:00",
                "2022-10-31T00:00:00",
                "2022-11-30T00:00:00",
                "2022-12-31T00:00:00",
                "2023-01-31T00:00:00",
                "2023-02-28T00:00:00",
                "2023-03-31T00:00:00",
                "2023-04-30T00:00:00",
                "2023-05-31T00:00:00",
                "2023-06-30T00:00:00",
                "2023-07-31T00:00:00",
                "2023-08-31T00:00:00",
                "2023-09-30T00:00:00",
                "2023-10-31T00:00:00",
                "2023-11-30T00:00:00",
                "2023-12-31T00:00:00",
                "2024-01-31T00:00:00",
                "2024-02-29T00:00:00",
                "2024-03-31T00:00:00",
                "2024-04-30T00:00:00",
                "2024-05-31T00:00:00",
                "2024-06-30T00:00:00",
                "2024-07-31T00:00:00",
                "2024-08-31T00:00:00",
                "2024-09-30T00:00:00",
                "2024-10-31T00:00:00",
                "2024-11-30T00:00:00",
                "2024-12-31T00:00:00",
                "2025-01-31T00:00:00",
              ],
              y: [
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001, 125.16702000000001,
                125.16702000000001, 125.16702000000001,
              ],
              type: "scatter",
            },
          ],
          xaxis: {
            range: ["2016-04-30T18:00:00", "2025-07-02T06:00:00"],
            type: "date",
          },
          yaxis: {
            rangemode: "normal",
            title: {
              text: "trend",
            },
            zerolinecolor: "#AAA",
          },
        },
        yearly: {
          traces: [
            {
              line: {
                color: "#0072B2",
                width: 2,
              },
              mode: "lines",
              name: "yearly",
              x: [
                "2017-01-01T00:00:00",
                "2017-01-02T00:00:59.178082",
                "2017-01-03T00:01:58.356164",
                "2017-01-04T00:02:57.534246",
                "2017-01-05T00:03:56.712328",
                "2017-01-06T00:04:55.890411",
                "2017-01-07T00:05:55.068493",
                "2017-01-08T00:06:54.246575",
                "2017-01-09T00:07:53.424657",
                "2017-01-10T00:08:52.602739",
                "2017-01-11T00:09:51.780822",
                "2017-01-12T00:10:50.958904",
                "2017-01-13T00:11:50.136986",
                "2017-01-14T00:12:49.315068",
                "2017-01-15T00:13:48.493150",
                "2017-01-16T00:14:47.671232",
                "2017-01-17T00:15:46.849315",
                "2017-01-18T00:16:46.027397",
                "2017-01-19T00:17:45.205479",
                "2017-01-20T00:18:44.383561",
                "2017-01-21T00:19:43.561643",
                "2017-01-22T00:20:42.739726",
                "2017-01-23T00:21:41.917808",
                "2017-01-24T00:22:41.095890",
                "2017-01-25T00:23:40.273972",
                "2017-01-26T00:24:39.452054",
                "2017-01-27T00:25:38.630137",
                "2017-01-28T00:26:37.808219",
                "2017-01-29T00:27:36.986301",
                "2017-01-30T00:28:36.164383",
                "2017-01-31T00:29:35.342465",
                "2017-02-01T00:30:34.520547",
                "2017-02-02T00:31:33.698630",
                "2017-02-03T00:32:32.876712",
                "2017-02-04T00:33:32.054794",
                "2017-02-05T00:34:31.232876",
                "2017-02-06T00:35:30.410958",
                "2017-02-07T00:36:29.589041",
                "2017-02-08T00:37:28.767123",
                "2017-02-09T00:38:27.945205",
                "2017-02-10T00:39:27.123287",
                "2017-02-11T00:40:26.301369",
                "2017-02-12T00:41:25.479452",
                "2017-02-13T00:42:24.657534",
                "2017-02-14T00:43:23.835616",
                "2017-02-15T00:44:23.013698",
                "2017-02-16T00:45:22.191780",
                "2017-02-17T00:46:21.369862",
                "2017-02-18T00:47:20.547945",
                "2017-02-19T00:48:19.726027",
                "2017-02-20T00:49:18.904109",
                "2017-02-21T00:50:18.082191",
                "2017-02-22T00:51:17.260273",
                "2017-02-23T00:52:16.438356",
                "2017-02-24T00:53:15.616438",
                "2017-02-25T00:54:14.794520",
                "2017-02-26T00:55:13.972602",
                "2017-02-27T00:56:13.150684",
                "2017-02-28T00:57:12.328767",
                "2017-03-01T00:58:11.506849",
                "2017-03-02T00:59:10.684931",
                "2017-03-03T01:00:09.863013",
                "2017-03-04T01:01:09.041095",
                "2017-03-05T01:02:08.219177",
                "2017-03-06T01:03:07.397260",
                "2017-03-07T01:04:06.575342",
                "2017-03-08T01:05:05.753424",
                "2017-03-09T01:06:04.931506",
                "2017-03-10T01:07:04.109588",
                "2017-03-11T01:08:03.287671",
                "2017-03-12T01:09:02.465753",
                "2017-03-13T01:10:01.643835",
                "2017-03-14T01:11:00.821917",
                "2017-03-15T01:12:00",
                "2017-03-16T01:12:59.178082",
                "2017-03-17T01:13:58.356164",
                "2017-03-18T01:14:57.534246",
                "2017-03-19T01:15:56.712328",
                "2017-03-20T01:16:55.890411",
                "2017-03-21T01:17:55.068493",
                "2017-03-22T01:18:54.246575",
                "2017-03-23T01:19:53.424657",
                "2017-03-24T01:20:52.602739",
                "2017-03-25T01:21:51.780822",
                "2017-03-26T01:22:50.958904",
                "2017-03-27T01:23:50.136986",
                "2017-03-28T01:24:49.315068",
                "2017-03-29T01:25:48.493150",
                "2017-03-30T01:26:47.671232",
                "2017-03-31T01:27:46.849315",
                "2017-04-01T01:28:46.027397",
                "2017-04-02T01:29:45.205479",
                "2017-04-03T01:30:44.383561",
                "2017-04-04T01:31:43.561643",
                "2017-04-05T01:32:42.739726",
                "2017-04-06T01:33:41.917808",
                "2017-04-07T01:34:41.095890",
                "2017-04-08T01:35:40.273972",
                "2017-04-09T01:36:39.452054",
                "2017-04-10T01:37:38.630137",
                "2017-04-11T01:38:37.808219",
                "2017-04-12T01:39:36.986301",
                "2017-04-13T01:40:36.164383",
                "2017-04-14T01:41:35.342465",
                "2017-04-15T01:42:34.520547",
                "2017-04-16T01:43:33.698630",
                "2017-04-17T01:44:32.876712",
                "2017-04-18T01:45:32.054794",
                "2017-04-19T01:46:31.232876",
                "2017-04-20T01:47:30.410958",
                "2017-04-21T01:48:29.589041",
                "2017-04-22T01:49:28.767123",
                "2017-04-23T01:50:27.945205",
                "2017-04-24T01:51:27.123287",
                "2017-04-25T01:52:26.301369",
                "2017-04-26T01:53:25.479452",
                "2017-04-27T01:54:24.657534",
                "2017-04-28T01:55:23.835616",
                "2017-04-29T01:56:23.013698",
                "2017-04-30T01:57:22.191780",
                "2017-05-01T01:58:21.369862",
                "2017-05-02T01:59:20.547945",
                "2017-05-03T02:00:19.726027",
                "2017-05-04T02:01:18.904109",
                "2017-05-05T02:02:18.082191",
                "2017-05-06T02:03:17.260273",
                "2017-05-07T02:04:16.438356",
                "2017-05-08T02:05:15.616438",
                "2017-05-09T02:06:14.794520",
                "2017-05-10T02:07:13.972602",
                "2017-05-11T02:08:13.150684",
                "2017-05-12T02:09:12.328767",
                "2017-05-13T02:10:11.506849",
                "2017-05-14T02:11:10.684931",
                "2017-05-15T02:12:09.863013",
                "2017-05-16T02:13:09.041095",
                "2017-05-17T02:14:08.219177",
                "2017-05-18T02:15:07.397260",
                "2017-05-19T02:16:06.575342",
                "2017-05-20T02:17:05.753424",
                "2017-05-21T02:18:04.931506",
                "2017-05-22T02:19:04.109588",
                "2017-05-23T02:20:03.287671",
                "2017-05-24T02:21:02.465753",
                "2017-05-25T02:22:01.643835",
                "2017-05-26T02:23:00.821917",
                "2017-05-27T02:24:00",
                "2017-05-28T02:24:59.178082",
                "2017-05-29T02:25:58.356164",
                "2017-05-30T02:26:57.534246",
                "2017-05-31T02:27:56.712328",
                "2017-06-01T02:28:55.890411",
                "2017-06-02T02:29:55.068493",
                "2017-06-03T02:30:54.246575",
                "2017-06-04T02:31:53.424657",
                "2017-06-05T02:32:52.602739",
                "2017-06-06T02:33:51.780822",
                "2017-06-07T02:34:50.958904",
                "2017-06-08T02:35:50.136986",
                "2017-06-09T02:36:49.315068",
                "2017-06-10T02:37:48.493150",
                "2017-06-11T02:38:47.671232",
                "2017-06-12T02:39:46.849315",
                "2017-06-13T02:40:46.027397",
                "2017-06-14T02:41:45.205479",
                "2017-06-15T02:42:44.383561",
                "2017-06-16T02:43:43.561643",
                "2017-06-17T02:44:42.739726",
                "2017-06-18T02:45:41.917808",
                "2017-06-19T02:46:41.095890",
                "2017-06-20T02:47:40.273972",
                "2017-06-21T02:48:39.452054",
                "2017-06-22T02:49:38.630137",
                "2017-06-23T02:50:37.808219",
                "2017-06-24T02:51:36.986301",
                "2017-06-25T02:52:36.164383",
                "2017-06-26T02:53:35.342465",
                "2017-06-27T02:54:34.520547",
                "2017-06-28T02:55:33.698630",
                "2017-06-29T02:56:32.876712",
                "2017-06-30T02:57:32.054794",
                "2017-07-01T02:58:31.232876",
                "2017-07-02T02:59:30.410958",
                "2017-07-03T03:00:29.589041",
                "2017-07-04T03:01:28.767123",
                "2017-07-05T03:02:27.945205",
                "2017-07-06T03:03:27.123287",
                "2017-07-07T03:04:26.301369",
                "2017-07-08T03:05:25.479452",
                "2017-07-09T03:06:24.657534",
                "2017-07-10T03:07:23.835616",
                "2017-07-11T03:08:23.013698",
                "2017-07-12T03:09:22.191780",
                "2017-07-13T03:10:21.369862",
                "2017-07-14T03:11:20.547945",
                "2017-07-15T03:12:19.726027",
                "2017-07-16T03:13:18.904109",
                "2017-07-17T03:14:18.082191",
                "2017-07-18T03:15:17.260273",
                "2017-07-19T03:16:16.438356",
                "2017-07-20T03:17:15.616438",
                "2017-07-21T03:18:14.794520",
                "2017-07-22T03:19:13.972602",
                "2017-07-23T03:20:13.150684",
                "2017-07-24T03:21:12.328767",
                "2017-07-25T03:22:11.506849",
                "2017-07-26T03:23:10.684931",
                "2017-07-27T03:24:09.863013",
                "2017-07-28T03:25:09.041095",
                "2017-07-29T03:26:08.219177",
                "2017-07-30T03:27:07.397260",
                "2017-07-31T03:28:06.575342",
                "2017-08-01T03:29:05.753424",
                "2017-08-02T03:30:04.931506",
                "2017-08-03T03:31:04.109588",
                "2017-08-04T03:32:03.287671",
                "2017-08-05T03:33:02.465753",
                "2017-08-06T03:34:01.643835",
                "2017-08-07T03:35:00.821917",
                "2017-08-08T03:36:00",
                "2017-08-09T03:36:59.178082",
                "2017-08-10T03:37:58.356164",
                "2017-08-11T03:38:57.534246",
                "2017-08-12T03:39:56.712328",
                "2017-08-13T03:40:55.890411",
                "2017-08-14T03:41:55.068493",
                "2017-08-15T03:42:54.246575",
                "2017-08-16T03:43:53.424657",
                "2017-08-17T03:44:52.602739",
                "2017-08-18T03:45:51.780822",
                "2017-08-19T03:46:50.958904",
                "2017-08-20T03:47:50.136986",
                "2017-08-21T03:48:49.315068",
                "2017-08-22T03:49:48.493150",
                "2017-08-23T03:50:47.671232",
                "2017-08-24T03:51:46.849315",
                "2017-08-25T03:52:46.027397",
                "2017-08-26T03:53:45.205479",
                "2017-08-27T03:54:44.383561",
                "2017-08-28T03:55:43.561643",
                "2017-08-29T03:56:42.739726",
                "2017-08-30T03:57:41.917808",
                "2017-08-31T03:58:41.095890",
                "2017-09-01T03:59:40.273972",
                "2017-09-02T04:00:39.452054",
                "2017-09-03T04:01:38.630137",
                "2017-09-04T04:02:37.808219",
                "2017-09-05T04:03:36.986301",
                "2017-09-06T04:04:36.164383",
                "2017-09-07T04:05:35.342465",
                "2017-09-08T04:06:34.520547",
                "2017-09-09T04:07:33.698630",
                "2017-09-10T04:08:32.876712",
                "2017-09-11T04:09:32.054794",
                "2017-09-12T04:10:31.232876",
                "2017-09-13T04:11:30.410958",
                "2017-09-14T04:12:29.589041",
                "2017-09-15T04:13:28.767123",
                "2017-09-16T04:14:27.945205",
                "2017-09-17T04:15:27.123287",
                "2017-09-18T04:16:26.301369",
                "2017-09-19T04:17:25.479452",
                "2017-09-20T04:18:24.657534",
                "2017-09-21T04:19:23.835616",
                "2017-09-22T04:20:23.013698",
                "2017-09-23T04:21:22.191780",
                "2017-09-24T04:22:21.369862",
                "2017-09-25T04:23:20.547945",
                "2017-09-26T04:24:19.726027",
                "2017-09-27T04:25:18.904109",
                "2017-09-28T04:26:18.082191",
                "2017-09-29T04:27:17.260273",
                "2017-09-30T04:28:16.438356",
                "2017-10-01T04:29:15.616438",
                "2017-10-02T04:30:14.794520",
                "2017-10-03T04:31:13.972602",
                "2017-10-04T04:32:13.150684",
                "2017-10-05T04:33:12.328767",
                "2017-10-06T04:34:11.506849",
                "2017-10-07T04:35:10.684931",
                "2017-10-08T04:36:09.863013",
                "2017-10-09T04:37:09.041095",
                "2017-10-10T04:38:08.219177",
                "2017-10-11T04:39:07.397260",
                "2017-10-12T04:40:06.575342",
                "2017-10-13T04:41:05.753424",
                "2017-10-14T04:42:04.931506",
                "2017-10-15T04:43:04.109588",
                "2017-10-16T04:44:03.287671",
                "2017-10-17T04:45:02.465753",
                "2017-10-18T04:46:01.643835",
                "2017-10-19T04:47:00.821917",
                "2017-10-20T04:48:00",
                "2017-10-21T04:48:59.178082",
                "2017-10-22T04:49:58.356164",
                "2017-10-23T04:50:57.534246",
                "2017-10-24T04:51:56.712328",
                "2017-10-25T04:52:55.890411",
                "2017-10-26T04:53:55.068493",
                "2017-10-27T04:54:54.246575",
                "2017-10-28T04:55:53.424657",
                "2017-10-29T04:56:52.602739",
                "2017-10-30T04:57:51.780822",
                "2017-10-31T04:58:50.958904",
                "2017-11-01T04:59:50.136986",
                "2017-11-02T05:00:49.315068",
                "2017-11-03T05:01:48.493150",
                "2017-11-04T05:02:47.671232",
                "2017-11-05T05:03:46.849315",
                "2017-11-06T05:04:46.027397",
                "2017-11-07T05:05:45.205479",
                "2017-11-08T05:06:44.383561",
                "2017-11-09T05:07:43.561643",
                "2017-11-10T05:08:42.739726",
                "2017-11-11T05:09:41.917808",
                "2017-11-12T05:10:41.095890",
                "2017-11-13T05:11:40.273972",
                "2017-11-14T05:12:39.452054",
                "2017-11-15T05:13:38.630137",
                "2017-11-16T05:14:37.808219",
                "2017-11-17T05:15:36.986301",
                "2017-11-18T05:16:36.164383",
                "2017-11-19T05:17:35.342465",
                "2017-11-20T05:18:34.520547",
                "2017-11-21T05:19:33.698630",
                "2017-11-22T05:20:32.876712",
                "2017-11-23T05:21:32.054794",
                "2017-11-24T05:22:31.232876",
                "2017-11-25T05:23:30.410958",
                "2017-11-26T05:24:29.589041",
                "2017-11-27T05:25:28.767123",
                "2017-11-28T05:26:27.945205",
                "2017-11-29T05:27:27.123287",
                "2017-11-30T05:28:26.301369",
                "2017-12-01T05:29:25.479452",
                "2017-12-02T05:30:24.657534",
                "2017-12-03T05:31:23.835616",
                "2017-12-04T05:32:23.013698",
                "2017-12-05T05:33:22.191780",
                "2017-12-06T05:34:21.369862",
                "2017-12-07T05:35:20.547945",
                "2017-12-08T05:36:19.726027",
                "2017-12-09T05:37:18.904109",
                "2017-12-10T05:38:18.082191",
                "2017-12-11T05:39:17.260273",
                "2017-12-12T05:40:16.438356",
                "2017-12-13T05:41:15.616438",
                "2017-12-14T05:42:14.794520",
                "2017-12-15T05:43:13.972602",
                "2017-12-16T05:44:13.150684",
                "2017-12-17T05:45:12.328767",
                "2017-12-18T05:46:11.506849",
                "2017-12-19T05:47:10.684931",
                "2017-12-20T05:48:09.863013",
                "2017-12-21T05:49:09.041095",
                "2017-12-22T05:50:08.219177",
                "2017-12-23T05:51:07.397260",
                "2017-12-24T05:52:06.575342",
                "2017-12-25T05:53:05.753424",
                "2017-12-26T05:54:04.931506",
                "2017-12-27T05:55:04.109588",
                "2017-12-28T05:56:03.287671",
                "2017-12-29T05:57:02.465753",
                "2017-12-30T05:58:01.643835",
                "2017-12-31T05:59:00.821917",
              ],
              y: [
                40.923158474322776, 59.809200258845294, 77.19635346153117,
                92.62078899150157, 105.68567292121193, 116.07210457383351,
                123.54754548639039, 127.9715321635228, 129.298548344536,
                127.57801771478807, 122.9514630688439, 115.6469604098605,
                105.97109395381267, 94.29868832506818, 81.06065539563204,
                66.73034358783408, 51.80881569729996, 36.80950643572225,
                22.242722396820334, 8.600444861032377, -3.65811997336098,
                -14.119827443426846, -22.429342229151253, -28.299023227147625,
                -31.516681086842652, -31.951010823586003, -29.55457361085603,
                -24.364276213730992, -16.499371308325376, -6.157074931617112,
                6.394033654833756, 20.822601287776447, 36.7447956404248,
                53.73597550708058, 71.34337118773209, 89.09938538276795,
                106.53511285304303, 123.19367713620747, 138.6429946482718,
                152.487600017709, 164.37920061242968, 174.02567176771961,
                181.1982557338385, 185.7367851888022, 187.55281444718887,
                186.63060630693414, 183.02598780296282, 176.86315199129803,
                168.32954335907752, 157.66901974372072, 145.17353214801543,
                131.17360417380488, 116.0279238482301, 100.11238157554669,
                83.80889831379227, 67.49438768676166, 51.53018476983674,
                36.25225322413516, 21.96245209529659, 8.921105018609776,
                -2.658930906357348, -12.61655017123424, -20.84494603409089,
                -27.292384560367143, -31.96122699659009, -34.90527705231069,
                -36.22558144597104, -36.0648589361917, -34.60077359533571,
                -32.03830108833874, -28.6014612419116, -24.524705558375683,
                -20.04425416547124, -15.389672927222223, -10.775968304825325,
                -6.396455579862891, -2.41662603742353, 1.030798289393713,
                3.8494764293567725, 5.981627944464614, 7.408730885212735,
                8.150739837360916, 8.263875882134153, 7.837088288814245,
                6.98733200231761, 5.853844061862627, 4.591634742068962,
                3.364434426699369, 2.337354222935875, 1.6695265842830458,
                1.506991514424271, 1.9760843224688263, 3.177562758941665,
                5.181685288570255, 8.02441914617159, 11.704917772057359,
                16.184363532372046, 21.386224739681015, 27.19792744479807,
                33.47389386914148, 40.03985228491731, 46.698279171605336,
                53.23479503360438, 59.42530165515954, 65.04362189455223,
                69.86938429858645, 73.6958844489645, 76.33765343429779,
                77.63747123418136, 77.47257890135863, 75.75986775167708,
                72.45985556151516, 67.57929802177112, 61.172327216376175,
                53.34005627828392, 44.228639128336546, 34.02582472170438,
                22.95609488611901, 11.274522035431739, -0.7404737720014412,
                -12.795251169768314, -24.58970885642815, -35.82641023130598,
                -46.219784644322154, -55.505112769555005, -63.44700708454137,
                -69.84711117883218, -74.55076327724538, -77.45239934096979,
                -78.49950851375382, -77.69499736612387, -75.09786805243874,
                -70.82216760523319, -65.0342195477916, -57.94820311469758,
                -49.82019792413008, -40.940861282083375, -31.626949835831944,
                -22.21193559078826, -13.035997116797805, -4.4356890541772716,
                3.2663939614566666, 9.771639607176336, 14.814813779188677,
                18.172533995879014, 19.670550118905723, 19.189596771959632,
                16.669624046719175, 12.112261665600009, 5.5814252773618085,
                -2.797969647851754, -12.843162525686981, -24.318489090851354,
                -36.9419716877285, -50.393368383734575, -64.32344420262464,
                -78.36418809353061, -92.139667857217, -105.2771931790649,
                -117.41844494654457, -128.23022759876312, -137.41451050878578,
                -144.71744413637032, -149.93706637224707, -152.92945330468135,
                -153.61311541656792, -151.9714936064661, -148.0534677886271,
                -141.97185240188227, -133.8999160569587, -124.06602480850047,
                -112.74656822590849, -100.25738264576624, -86.94393497284058,
                -73.17057154130195, -59.30916851912022, -45.727542010462756,
                -32.77798662935666, -20.786310414709483, -10.041721443079727,
                -0.787897628268861, 6.784463394597841, 12.54336178173794,
                16.41928074263459, 18.406899740436195, 18.564660823319638,
                17.012181490046434, 13.925576826408557, 9.53082194650705,
                4.09535089865124, -2.081851932232553, -8.681359214358762,
                -15.375254398107074, -21.83954602317322, -27.766635165578172,
                -32.87741938837556, -36.93262033701678, -39.742938952942794,
                -41.177671879031394, -41.171464281887225, -39.72892696210827,
                -36.92690781676854, -32.914277732033916, -27.909166805059623,
                -22.19366621142554, -16.106091684577287, -10.030984031269389,
                -4.387097924222434, 0.38629997728751336, 3.8444409471851557,
                5.552938203379071, 5.104071734097475, 2.1329747676839,
                -3.66678872813484, -12.528762474069612, -24.600740246685138,
                -39.93396621835928, -58.474798992238405, -80.05918354494747,
                -104.41020213271473, -131.13889230965736, -159.74842979702933,
                -189.64167849598232, -220.13201214425297, -250.4572147575634,
                -279.79617289256345, -307.28798470297943, -332.0530313706145,
                -353.2154882265736, -369.92669789891227, -381.3887879471726,
                -386.8778920817339, -385.76632819483115, -377.54309853199663,
                -361.8321073817471, -338.40753915270614, -307.20590359845687,
                -268.3343337474186, -222.07481385692486, -168.8841171005903,
                -109.38934304325285, -44.37906035684065, 25.209822453954896,
                98.30921947843292, 173.74071256999042, 250.23962483718705,
                326.48177461577126, 401.11228186278356, 472.77573433183335,
                540.1469738703826, 601.9617352123979, 657.0463617717218,
                704.3458355320925, 742.9493911931731, 772.113037484829,
                791.2783798925778, 800.0872271571207, 798.3915666488197,
                786.258608450516, 763.9707217385313, 732.020216607718,
                691.0990564180464, 642.083716538422, 586.0155315410425,
                524.0769910432921, 457.5645512950576, 387.858622384417,
                316.3914669683642, 244.613803678738, 173.9609451167883,
                105.81931562656774, 41.49418727070601, -17.820556276384725,
                -71.06986813369303, -117.36150909128949, -155.98593928154122,
                -186.43163301364118, -208.39543638261685, -221.78771459606094,
                -226.73217002130673, -223.5603491996958, -212.8009938573658,
                -195.1645236284119, -171.52306327833713, -142.88654134716074,
                -110.37548729453623, -75.19123777611071, -38.58432740735835,
                -1.8218835737731198, 33.8451325972805, 67.21399665416786,
                97.16078201389058, 122.66863468274303, 142.8530479350937,
                156.9835056104755, 164.50096120898075, 165.03073207945928,
                158.39051066132274, 144.59332462508456, 123.84541135797814,
                96.53910595815744, 63.24097215412841, 24.67552888397286,
                -18.294961629931645, -64.6940785497219, -113.45754994693706,
                -163.46156712027468, -213.5523430825661, -262.57612677343116,
                -309.4088800144772, -352.9848409540701, -392.32323509887476,
                -426.5524518777437, -454.9310792970168, -476.8652794815144,
                -491.92209116616255, -499.8383585979667, -500.5251066600637,
                -494.0673059914164, -480.7190960618078, -460.89465516262044,
                -435.15502083515906, -404.1912693121103, -368.80455530892255,
                -329.88359156838607, -288.38020893917326, -245.28368094898897,
                -201.59452082915234, -158.2984633062828, -116.34132828496125,
                -76.60542946475643, -39.88813908155163, -6.8831520215014415,
                21.835089458270243, 45.82344277173935, 64.77831867772001,
                78.53998485868418, 87.09287393630915, 90.56196141014009,
                89.20538658098727, 83.40359075215603, 73.64533914100784,
                60.511073517966736, 44.654109514384515, 26.78024409676764,
                7.626373651468793, -12.061259303721094, -31.548572836850877,
                -50.13430527672061, -67.1692615242054, -82.07362931644884,
                -94.35191226293205, -103.60510186186494, -109.53979566194757,
                -111.97406072698556, -110.8399378587639, -106.18257983930349,
                -98.15611345018003, -87.01640747140047, -73.111014668408,
                -56.86663257180972, -38.774493563100954, -19.37414766596603,
                0.7638598336575114, 21.055890175786317,
              ],
              type: "scatter",
            },
          ],
          xaxis: {
            range: [
              "2016-12-13T18:54:02.958904115",
              "2018-01-18T11:04:57.863013581",
            ],
            tickformat: "%B %e",
            type: "date",
          },
          yaxis: {
            title: {
              text: "yearly",
            },
            zerolinecolor: "#AAA",
          },
        },
      },
    },
  },
];
