import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "../commonBaseApiSlice";

export const labelSetupApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLabelSetup: builder.mutation({
      query: (data) => ({
        url: `/label/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    getLabelTypes: builder.mutation({
      query: () => ({
        url: `/labeltype/get`,
        method: "GET",
      }),
    }),
    createLabelSetup: builder.mutation({
      query: (data) => ({
        url: "/label/create",
        method: "POST",
        body: data,
      }),
    }),
    editLabelSetup: builder.mutation({
      query: (data) => ({
        url: "/label/edit",
        method: "PUT",
        body: data,
      }),
    }),
    getLabelSetupById: builder.mutation({
      query: (id) => ({
        url: `/label/getbyid?id=${id}`,
        method: "GET",
      }),
    }),
    deleteLabelSetup: builder.mutation({
      query: (data) => ({
        url: `/label/softdelete?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    duplicateLabelSetup: builder.mutation({
      query: (data) => ({
        url: `/label/duplicate?${getFilters(data)}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetLabelTypesMutation,
  useGetLabelSetupMutation,
  useCreateLabelSetupMutation,
  useEditLabelSetupMutation,
  useDeleteLabelSetupMutation,
  useGetLabelSetupByIdMutation,
  useDuplicateLabelSetupMutation,
} = labelSetupApiSlice;
