import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface VendorData {
  selectedVendor: Vendor;
}
export interface Vendor {
  id: string;
  isActive: boolean;
  createdOnUtc?: string;
  updatedOnUtc?: string;
  createdBy?: string;
  updatedBy?: string;
  name: string;
  address: string;
  address2: string;
  state: string;
  city: string;
  zipCode: string;
  email: string;
  phone: string;
  contactPerson: string;
}
const storedVendor = localStorage.getItem("selectedVendor");
const initialState: VendorData = {
  selectedVendor: storedVendor ? JSON.parse(storedVendor) : null,
};

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setSelectedVendor: (state, action: PayloadAction<Vendor>) => {
      state.selectedVendor = action.payload;
      localStorage.setItem("selectedVendor", JSON.stringify(action.payload));
    },
  },
});
export const { setSelectedVendor } = vendorSlice.actions;
export default vendorSlice.reducer;
export const selectedVendor = (state: RootState) => state.vendor.selectedVendor;
