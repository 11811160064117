import { getErrorMessage } from "../utils/commonUtils";
import { toast } from "react-toastify";
import {
  useGetPermissionsMutation,
  useGetTenantKeyMutation,
} from "../redux/admin/adminApiSlice";

export default function useAdmin() {
  const [getTenantKey, { isLoading: getTenantKeyLoading }] =
    useGetTenantKeyMutation();
  const [getPermissions, { isLoading: getPermissionsLoading }] =
    useGetPermissionsMutation();
  const getTenantKeyAPI = async (data: any) => {
    try {
      const response: any = await getTenantKey(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getPermissionsAPI = async (data: any) => {
    try {
      const response: any = await getPermissions(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  return {
    getTenantKeyAPI,
    getTenantKeyLoading,
    getPermissionsAPI,
    getPermissionsLoading,
  };
}
