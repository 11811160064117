import Form from "react-bootstrap/Form";
import "./addvendor.scss";
import { Button } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useVendor from "../../../Hooks/useVendors";
import { useLocation, useNavigate } from "react-router-dom";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import Loading from "../../../components/LoadingPage/Loading";
import { useEffect, useState } from "react";
import { selectedVendor } from "../../../redux/vendor/vendorSlice";
import { DropDownValues } from "../../../utils/types";
import { statesDDData } from "../../UserManagements/AddUsers/statesDropdownData";
import { CharacterLength } from "../../../Hooks/constants";

const stateOptions = statesDDData.map((state) => ({
  value: state.label,
  label: state.label,
}));

const AddVendor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const masterScreen = "/vendor-master";
  const { createVendorAPI, createVendorLoading } = useVendor();
  const { editVendorAPI, editVendorLoading } = useVendor();
  const [isEdit, setIsEdit] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const selectedVendorData = useAppSelector(selectedVendor);
  const [dynamicVal, setDynamicVal] = useState({
    isActive: true,
    createdBy: currentUserId,
    name: "",
    address: "",
    address2: "",
    state: "",
    city: "",
    zipCode: "",
    email: "",
    phone: "",
    contactPerson: "",
  });
  useEffect(() => {
    if (location.pathname === "/edit-vendor") {
      setIsEdit(true);
      if (selectedVendorData?.id) {
        setDynamicVal({
          name: selectedVendorData.name,
          address: selectedVendorData.address,
          address2: selectedVendorData.address2,
          state: selectedVendorData.state,
          city: selectedVendorData.city,
          zipCode: selectedVendorData.zipCode,
          email: selectedVendorData.email,
          isActive: selectedVendorData.isActive,
          phone: selectedVendorData.phone,
          contactPerson: selectedVendorData.contactPerson,
          createdBy: currentUserId,
        });
      } else {
        navigate(masterScreen);
      }
    }
  }, []);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: dynamicVal,
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .max(
          CharacterLength.twoFiveSix,
          "Name should not be more than 256 characters",
        ),
      zipCode: Yup.string()
        .nullable()
        .max(10, "Zip should not be more than 10 characters"),
      contactPerson: Yup.string()
        .nullable()
        .max(
          CharacterLength.sevenFive,
          "Contact Person should not be more than 75 characters",
        ),
      phone: Yup.string()
        .nullable()
        .max(
          CharacterLength.sevenFive,
          "Phone should not be more than 75 characters",
        ),
      city: Yup.string()
        .nullable()
        .max(25, "City should not be more than 25 characters"),
      address: Yup.string()
        .nullable()
        .max(
          CharacterLength.twoFiveSix,
          "Address 1 should not be more than 256 characters",
        ),
      address2: Yup.string()
        .nullable()
        .max(
          CharacterLength.twoFiveSix,
          "Address 2 should not be more than 256 characters",
        ),
      email: Yup.string()
        .nullable()
        .max(
          CharacterLength.sevenFive,
          "Email ID should not be more than 75 characters",
        ),
    }),
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        if (isEdit) {
          await editVendorAPI({ ...values, id: selectedVendorData.id });
        } else {
          await createVendorAPI(values);
        }
        navigate(masterScreen);
      } catch (err) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  return (
    <div className="add-vendor-wrapper">
      {(createVendorLoading || editVendorLoading) && <Loading />}
      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} Vendor</h2>
      </div>
      <div className="add-vendor-box">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Label>
              Name<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              id="name"
              name="name"
              onChange={handleChange}
              value={values.name}
              isInvalid={touched.name && !!errors.name}
            />
            {touched.name && errors.name ? (
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Address 1</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address 1"
              id="address"
              name="address"
              onChange={handleChange}
              value={values.address}
              isInvalid={touched.address && !!errors.address}
            />
            {touched.address && errors.address ? (
              <Form.Control.Feedback type="invalid">
                {errors.address}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Address 2</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address 2"
              id="address2"
              name="address2"
              onChange={handleChange}
              value={values.address2}
              isInvalid={touched.address2 && !!errors.address2}
            />
            {touched.address2 && errors.address2 ? (
              <Form.Control.Feedback type="invalid">
                {errors.address2}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter City"
              id="city"
              name="city"
              onChange={handleChange}
              value={values.city}
              isInvalid={touched.city && !!errors.city}
            />
            {touched.city && errors.city ? (
              <Form.Control.Feedback type="invalid">
                {errors.city}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>State</Form.Label>
            <Select
              classNamePrefix="react-select"
              options={stateOptions}
              id="state"
              name="state"
              value={stateOptions?.find(
                (option: DropDownValues) => option.value === values.state,
              )}
              onChange={(option: any) => handleChange("state")(option.value)}
              className={`${
                touched.state && !!errors.state ? "is-invalid-border" : ""
              } react-select-container `}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Zip"
              id="zipCode"
              name="zipCode"
              onChange={handleChange}
              value={values.zipCode}
              isInvalid={touched.zipCode && !!errors.zipCode}
            />
            {touched.zipCode && errors.zipCode ? (
              <Form.Control.Feedback type="invalid">
                {errors.zipCode}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Email ID</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email ID"
              id="email"
              name="email"
              onChange={handleChange}
              value={values.email}
              isInvalid={touched.email && !!errors.email}
            />
            {touched.email && errors.email ? (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Phone"
              id="phone"
              name="phone"
              onChange={handleChange}
              value={values.phone}
              isInvalid={touched.phone && !!errors.phone}
            />
            {touched.phone && errors.phone ? (
              <Form.Control.Feedback type="invalid">
                {errors.phone}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Contact Person</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Contact Person"
              id="contactPerson"
              name="contactPerson"
              onChange={handleChange}
              value={values.contactPerson}
              isInvalid={touched.contactPerson && !!errors.contactPerson}
            />
            {touched.contactPerson && errors.contactPerson ? (
              <Form.Control.Feedback type="invalid">
                {errors.contactPerson}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="Active"
                name="isActive"
                type="checkbox"
                id="isActive"
                onChange={handleChange}
                checked={values.isActive}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-4 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => navigate(masterScreen)}
            >
              Cancel
            </Button>
            <Button type="submit">{isEdit ? "Update" : "Add"}</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default AddVendor;
