import { getErrorMessage } from "../utils/commonUtils";
import { toast } from "react-toastify";
import {
  useInvOrderCreateMutation,
  useInvOrderListMutation,
  useInvOrdersMutation,
  useOrderStatusMasterMutation,
  useInvOrderByIdMutation,
} from "../redux/inventoryOrder/inventoryOrderApiSlice";

export default function useInventoryOrder() {
  const [InvOrderList, { isLoading: InvOrderListLoading }] =
    useInvOrderListMutation();
  const [orderStatusMaster, { isLoading: orderStatusMasterLoading }] =
    useOrderStatusMasterMutation();
  const [InvOrders, { isLoading: InvOrdersLoading }] = useInvOrdersMutation();
  const [InvOrderById, { isLoading: InvOrderByIdLoading }] =
    useInvOrderByIdMutation();
  const [invOrderCreate, { isLoading: invOrderCreateLoading }] =
    useInvOrderCreateMutation();

  const orderStatusMasterAPI = async () => {
    try {
      const response: any = await orderStatusMaster({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const InvOrderCreateAPI = async (data: any) => {
    try {
      const response: any = await invOrderCreate(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const InvOrderListAPI = async (data: any) => {
    try {
      const response: any = await InvOrderList(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const InvOrdersAPI = async (data: any) => {
    try {
      const response: any = await InvOrders(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const InvOrderByIdAPI = async (data: any) => {
    try {
      const response: any = await InvOrderById(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    InvOrderListAPI,
    InvOrderListLoading,
    InvOrdersAPI,
    InvOrdersLoading,
    InvOrderCreateAPI,
    invOrderCreateLoading,
    orderStatusMasterAPI,
    orderStatusMasterLoading,
    InvOrderByIdAPI,
    InvOrderByIdLoading,
  };
}
