import Form from "react-bootstrap/Form";
import "./addunit.scss";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import useUnits from "../../../Hooks/useUnits";
import { useEffect, useState } from "react";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import { selectedUnit } from "../../../redux/units/unitsSlice";
import Loading from "../../../components/LoadingPage/Loading";
const AddUnit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { createUnitsAPI, createUnitsLoading } = useUnits();
  const { editUnitsAPI, editUnitsLoading } = useUnits();
  const [isEdit, setIsEdit] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const selectedUnitData = useAppSelector(selectedUnit);
  const [dynamicVal, setDynamicVal] = useState({
    isActive: true,
    createdBy: currentUserId,
    unitCode: "",
    unitName: "",
    sortOrder: 0,
  });
  useEffect(() => {
    if (location.pathname === "/edit-unit") {
      setIsEdit(true);
      if (selectedUnitData?.id) {
        setDynamicVal({
          isActive: selectedUnitData.isActive,
          createdBy: selectedUnitData.createdBy,
          unitCode: selectedUnitData.unitCode,
          unitName: selectedUnitData.unitName,
          sortOrder: selectedUnitData.sortOrder,
        });
      } else {
        navigate("/unit-master");
      }
    }
  }, []);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: dynamicVal,
    validationSchema: Yup.object({
      unitCode: Yup.string()
        .max(10, "Unit ID should not be more than 10 characters")
        .required("Unit ID is required"),
      unitName: Yup.string()
        .required("Unit Name is required")
        .max(256, "Unit Name should not be more than 256 characters."),
      sortOrder: Yup.number()
        .required("Sort Order is required")
        .integer("Sort order must be a whole number between 0 and 999")
        .min(0, "Sort order must be a whole number between 0 and 999")
        .max(999, "Sort order must be a whole number between 0 and 999"),
    }),
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        if (isEdit) {
          await editUnitsAPI({ ...values, id: selectedUnitData.id });
        } else {
          await createUnitsAPI(values);
        }
        navigate("/unit-master");
      } catch (err) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  return (
    <div className="add-loc-wrapper">
      {(createUnitsLoading || editUnitsLoading) && <Loading />}
      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} Unit</h2>
      </div>
      <div className="add-loc-box add-unit-master">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Label>
              Unit ID<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Unit ID"
              id="unitCode"
              name="unitCode"
              onChange={handleChange}
              value={values.unitCode}
              isInvalid={touched.unitCode && !!errors.unitCode}
            />
            {touched.unitCode && errors.unitCode ? (
              <Form.Control.Feedback type="invalid">
                {errors.unitCode}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Unit Name<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Unit Name"
              id="unitName"
              name="unitName"
              onChange={handleChange}
              value={values.unitName}
              isInvalid={touched.unitName && !!errors.unitName}
            />
            {touched.unitName && errors.unitName ? (
              <Form.Control.Feedback type="invalid">
                {errors.unitName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Sort Order</Form.Label>
            <Form.Control
              type="number"
              min={0}
              placeholder="Enter Sort Order"
              id="sortOrder"
              name="sortOrder"
              onChange={handleChange}
              value={values.sortOrder}
              isInvalid={touched.sortOrder && !!errors.sortOrder}
            />
            {touched.sortOrder && errors.sortOrder ? (
              <Form.Control.Feedback type="invalid">
                {errors.sortOrder}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="Active"
                type="checkbox"
                id="isActive"
                name="isActive"
                onChange={handleChange}
                checked={values.isActive}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-4 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => navigate("/unit-master")}
            >
              Cancel
            </Button>
            <Button type="submit">{isEdit ? "Update" : "Add"}</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default AddUnit;
