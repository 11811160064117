import CustomModal from "../../../../../components/customModal/CustomModal";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";

export const EditBarcodeModel = ({
  currentRecord,
  currentUserId,
  toggleEditBarcodeModal,
  editBarcode,
}: any) => {
  const statusDD = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];
  const defaultDD = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const initialFormVal = {
    id: currentRecord.id,
    isActive: currentRecord.isActive as boolean,
    updatedBy: currentUserId,
    rxId: currentRecord.rxId,
    rxSRXId: currentRecord.rxSRXId as string,
    isDefault: currentRecord.isDefault as boolean,
    serialNo: currentRecord.serialNo,
  };

  const { handleChange, values, handleSubmit, touched, errors, setFieldValue } =
    useFormik({
      initialValues: initialFormVal,
      validationSchema: Yup.object({
        rxSRXId: Yup.string().required("Barcode is Required"),
      }),

      onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
        try {
          editBarcode(values);
        } catch (err: any) {}
        setSubmitting(false);
      },
      enableReinitialize: true,
    });
  const selectedStatusOption = statusDD.find(
    (option) => option.value === values.isActive,
  );
  const selectedDefaultOption = defaultDD.find(
    (option) => option.value === values.isDefault,
  );
  return (
    <CustomModal
      title="Edit"
      content={
        <div className="modal--content--wrapper">
          <div className="modal--inputbox">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Barcode</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Level"
                  isInvalid={touched.rxSRXId && !!errors.rxSRXId}
                  id="rxSRXId"
                  name="rxSRXId"
                  onChange={handleChange}
                  value={values.rxSRXId}
                />
                {touched.rxSRXId && errors.rxSRXId ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.rxSRXId}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Status</Form.Label>
                <Select
                  classNamePrefix="react-select"
                  options={statusDD}
                  id="isActive"
                  name="isActive"
                  value={selectedStatusOption}
                  onChange={(option) =>
                    setFieldValue("isActive", option?.value)
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Default</Form.Label>
                <Select
                  classNamePrefix="react-select"
                  options={defaultDD}
                  id="isDefault"
                  name="isDefault"
                  value={selectedDefaultOption}
                  onChange={(option) =>
                    setFieldValue("isDefault", option?.value)
                  }
                />
              </Form.Group>
              <Form.Group className="full-width text-end">
                <Button
                  onClick={toggleEditBarcodeModal}
                  style={{ marginRight: "10px" }}
                  variant="outline-primary"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  onClick={handleSubmit as any}
                >
                  Update
                </Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      }
      onClose={toggleEditBarcodeModal}
    />
  );
};
