import CustomModal from "../../../../../components/customModal/CustomModal";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";

export const EditOrderManageDetailModel = ({
  locationDropDownVal,
  currentRecord,
  currentUserId,
  toggleEditOrderMDModel,
  OMEdit,
  selectedItemId,
  selectedItemName,
}: any) => {
  const initialVal = {
    id: currentRecord.id,
    isActive: currentRecord.isActive,
    updatedBy: currentUserId,
    orderingProductId: selectedItemName?.id ?? ("" as string),
    locationCode: currentRecord.locationId as string,
    reorderLevel: (currentRecord.reorderLevel as number) || 0,
    reorderQty: (currentRecord.reorderQty as number) || 0,
  };
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: initialVal,
    validationSchema: Yup.object({
      locationCode: Yup.string().required("Location is Required"),
      reorderQty: Yup.string().required("Reorder Quantity is Required"),
    }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        OMEdit(values);
      } catch (err: any) {}
      setSubmitting(false);
    },
  });
  const customStyles = {
    dropdownIndicator: (
      provided: any,
      state: { selectProps: { isDisabled: any } },
    ) => ({
      ...provided,
      display: state.selectProps.isDisabled ? "none" : "block",
    }),
  };
  return (
    <CustomModal
      title="Edit Record"
      content={
        <div className="modal--content--wrapper">
          <div className="modal--inputbox">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Select Location</Form.Label>
                <Select
                  classNamePrefix="react-select"
                  options={locationDropDownVal}
                  id="locationCode"
                  name="locationCode"
                  value={locationDropDownVal?.find(
                    (option: any) => option.value === values.locationCode,
                  )}
                  onChange={(option: any) => {
                    handleChange("locationCode")(option.value);
                  }}
                  styles={customStyles}
                  className={`${
                    touched.locationCode && !!errors.locationCode
                      ? "is-invalid-border"
                      : ""
                  } react-select-container `}
                  isDisabled={true}
                />
                {touched.locationCode && errors.locationCode && (
                  <Form.Control.Feedback type="invalid">
                    {errors.locationCode}
                  </Form.Control.Feedback>
                )}
                <p style={{ color: "#737373", fontSize: "12px" }}>
                  Qty. on Hand : {currentRecord.qtyOnHand || 0.0}, Qty. on Order
                  : {currentRecord.orderQty || 0.0}
                </p>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Ordering Product Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Ordering Product Number"
                  value={selectedItemName?.productNo ?? "-"}
                  disabled={true}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Reorder Level</Form.Label>
                <Form.Control
                  min={0}
                  type="number"
                  placeholder="Enter Reorder Level"
                  isInvalid={touched.reorderLevel && !!errors.reorderLevel}
                  id="reorderLevel"
                  name="reorderLevel"
                  onChange={handleChange}
                  value={values.reorderLevel}
                />
                {touched.reorderLevel && errors.reorderLevel ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.reorderLevel}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Reorder Quantity</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  id="reorderQty"
                  name="reorderQty"
                  placeholder="Enter Reorder Quantity"
                  isInvalid={touched.reorderQty && !!errors.reorderQty}
                  onChange={handleChange}
                  value={values.reorderQty}
                />
                {touched.reorderQty && errors.reorderQty ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.reorderQty}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group className="full-width text-end">
                <Button
                  onClick={toggleEditOrderMDModel}
                  style={{ marginRight: "10px" }}
                  variant="outline-primary"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  onClick={handleSubmit as any}
                >
                  Update
                </Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      }
      onClose={toggleEditOrderMDModel}
    />
  );
};
