import Form from "react-bootstrap/Form";
import "./addlocation.scss";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useLocations from "../../../Hooks/useLocations";
import { useLocation, useNavigate } from "react-router-dom";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import Loading from "../../../components/LoadingPage/Loading";
import { useEffect, useState } from "react";
import { selectedLocation } from "../../../redux/location/locationSlice";
const AddLocation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { createLocationsAPI, createLocationsLoading } = useLocations();
  const { editLocationsAPI, editLocationsLoading } = useLocations();
  const [isEdit, setIsEdit] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const selectedLocationData = useAppSelector(selectedLocation);
  const [dynamicVal, setDynamicVal] = useState({
    isActive: true,
    createdBy: currentUserId,
    locationCode: "",
    locationName: "",
    sortOrder: 0,
  });
  useEffect(() => {
    if (location.pathname === "/edit-location") {
      setIsEdit(true);
      if (selectedLocationData?.id) {
        setDynamicVal({
          isActive: selectedLocationData.isActive,
          createdBy: selectedLocationData.createdBy,
          locationCode: selectedLocationData.locationCode,
          locationName: selectedLocationData.locationName,
          sortOrder: selectedLocationData.sortOrder,
        });
      } else {
        navigate("/location-master");
      }
    }
  }, []);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: dynamicVal,
    validationSchema: Yup.object({
      locationCode: Yup.string()
        .required("Location ID is required")
        .max(125, "Location ID can not be more than 125 characters"),
      locationName: Yup.string()
        .required("Location Name is required")
        .max(125, "Location Name can not be more than 125 characters"),
      sortOrder: Yup.number()
        .integer("Sort order must be a whole number between 0 and 999")
        .min(0, "Sort order must be a whole number between 0 and 999")
        .max(999, "Sort order must be a whole number between 0 and 999")
        .required("Sort Order is required"),
    }),
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        if (isEdit) {
          await editLocationsAPI({ ...values, id: selectedLocationData.id });
        } else {
          await createLocationsAPI(values);
        }
        navigate("/location-master");
      } catch (err) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  return (
    <div className="add-loc-wrapper">
      {(createLocationsLoading || editLocationsLoading) && <Loading />}
      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} Location</h2>
      </div>
      <div className="add-loc-box">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Label>
              Location ID<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Location ID"
              id="locationCode"
              name="locationCode"
              onChange={handleChange}
              value={values.locationCode}
              isInvalid={touched.locationCode && !!errors.locationCode}
            />
            {touched.locationCode && errors.locationCode ? (
              <Form.Control.Feedback type="invalid">
                {errors.locationCode}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Location Name<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Location Name"
              id="locationName"
              name="locationName"
              onChange={handleChange}
              value={values.locationName}
              isInvalid={touched.locationName && !!errors.locationName}
            />
            {touched.locationName && errors.locationName ? (
              <Form.Control.Feedback type="invalid">
                {errors.locationName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Sort Order</Form.Label>
            <Form.Control
              type="number"
              min={0}
              placeholder="Enter Sort Order"
              id="sortOrder"
              name="sortOrder"
              onChange={handleChange}
              value={values.sortOrder}
              isInvalid={touched.sortOrder && !!errors.sortOrder}
            />
            {touched.sortOrder && errors.sortOrder ? (
              <Form.Control.Feedback type="invalid">
                {errors.sortOrder}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="Active"
                type="checkbox"
                id="isActive"
                name="isActive"
                onChange={handleChange}
                checked={values.isActive}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-4 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => navigate("/location-master")}
            >
              Cancel
            </Button>
            <Button type="submit">{isEdit ? "Update" : "Add"}</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default AddLocation;
