import { getErrorMessage } from "../utils/commonUtils";
import {
  useCreateItemTypeMutation,
  useDeleteTypeMutation,
  useDuplicateTypeMutation,
  useEditItemTypeMutation,
  useGetItemTypesMutation,
  useSetTypeStatusActiveMutation,
  useSetTypeStatusDeActiveMutation,
} from "../redux/itemType/itemTypeApiSlice";
import { toast } from "react-toastify";
export default function useItemTypes() {
  const [getItemTypes, { isLoading: getItemTypesLoading }] =
    useGetItemTypesMutation();
  const [createItemType, { isLoading: createItemTypeLoading }] =
    useCreateItemTypeMutation();
  const [editItemType, { isLoading: editItemTypeLoading }] =
    useEditItemTypeMutation();
  const [setTypeStatusActive, { isLoading: setItemStatusActiveLoading }] =
    useSetTypeStatusActiveMutation();
  const [setTypeStatusDeActive, { isLoading: setItemStatusDeActiveLoading }] =
    useSetTypeStatusDeActiveMutation();
  const [deleteType, { isLoading: deleteTypeLoading }] =
    useDeleteTypeMutation();
  const [duplicateType, { isLoading: duplicateTypeLoading }] =
    useDuplicateTypeMutation();
  const getItemTypesAPI = async (data: any) => {
    try {
      const response: any = await getItemTypes(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const createItemTypeAPI = async (data: any) => {
    try {
      const response: any = await createItemType(data).unwrap();
      toast.success("Success! Item Type has been Created.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const editItemTypeAPI = async (data: any) => {
    try {
      const response: any = await editItemType(data).unwrap();
      toast.success("Success! Item Type has been Updated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const activeItemTypeAPI = async (data: any) => {
    try {
      const response: any = await setTypeStatusActive(data).unwrap();
      toast.success("Success! Item Type has been Activated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deActiveItemTypeAPI = async (data: any) => {
    try {
      const response: any = await setTypeStatusDeActive(data).unwrap();
      toast.success("Success! Item Type has been Deactivated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deleteTypeAPI = async (data: any) => {
    try {
      const response: any = await deleteType(data).unwrap();
      toast.success("Success! Item Type has been Deleted.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const duplicateTypeAPI = async (data: any) => {
    try {
      const response: any = await duplicateType(data).unwrap();
      toast.success("Success! Item Type has been Duplicated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    getItemTypesAPI,
    getItemTypesLoading,
    createItemTypeLoading,
    createItemTypeAPI,
    editItemTypeAPI,
    editItemTypeLoading,
    activeItemTypeAPI,
    setItemStatusActiveLoading,
    deActiveItemTypeAPI,
    setItemStatusDeActiveLoading,
    deleteTypeAPI,
    deleteTypeLoading,
    duplicateTypeLoading,
    duplicateTypeAPI,
  };
}
