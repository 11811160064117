import "./inventoryprofileheader.scss";

const InventoryProfileHeader = ({ status, itemName }: any) => {
  return (
    <div className="inventory-profile-header-wrapper">
      <div className="inventory-title-status-box">
        <h2>{itemName}</h2>
        <div
          className={`status-box 
          ${status ? "active" : "inactive"}`}
        >
          <span className="status-circle"></span>
          <span className="status-text">{status ? "Active" : "InActive"}</span>
        </div>
      </div>
    </div>
  );
};

export default InventoryProfileHeader;
