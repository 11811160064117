import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-calendar/dist/Calendar.css";
import "./styles/global.scss";
import "./styles/responsive.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <div className="App">
        <ToastContainer
          autoClose={1500}
          draggable={false}
          position="top-right"
          hideProgressBar={false}
          limit={1}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnHover
        />
        <App />
      </div>
    </React.StrictMode>
  </Provider>,
);
// Create a modal and append it to the end of the body
const modalRoot = document.createElement("div");
modalRoot.setAttribute("id", "modal-root");
document.body.appendChild(modalRoot);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
