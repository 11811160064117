import "./predictions.scss";
import Plot from "react-plotly.js";
import { predictions } from "./../../predictions";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import React from "react";
const Predictions = () => {
  const [predictionsData] = useState<any>(predictions);

  return (
    <div className="prediction-graph-wrapper">
      <div className="prediction-header-box">
        <h2>
          The Graphs below Represent Trend and Forecast for each Drug Category
          based on Historical usage.
        </h2>
        <h5>
          The Forecasts are Generated by Training an AI (forecasting) Model
          using Historical Data
        </h5>
      </div>
      <Row>
        {predictionsData.map((data: any) => (
          <Col key={data.name}>
            <div className="graph">
              <h3>{data.name.split("_")[0]}</h3>
              <Plot
                data={data.forecast_plot.data}
                layout={data.forecast_plot.layout}
              />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default Predictions;
