import { Button, Dropdown, Table } from "react-bootstrap";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import circlecheckicon from "../../../assets/images/circle-check-icon.svg";
import "./orderdetail.scss";
import { ReactSVG } from "react-svg";
import { useEffect, useState } from "react";
import useInventoryOrder from "../../../Hooks/useInventoryOrder";
import { useParams } from "react-router-dom";
const OrderDetail = () => {
  const { id } = useParams();
  const [orderdetails, setOrderDetails] = useState();
  const { InvOrderByIdAPI, InvOrderByIdLoading } = useInventoryOrder();
  useEffect(() => {
    InvOrderByIdAPI({ id }).then((res) => {
      if (res) {
        setOrderDetails(res);
      }
    });
  }, []);
  return (
    <div className="order-page-wrapper">
      <div className="order-page-title-box">
        <h2>ORD8</h2>
      </div>
      <div className="order-detail-box-wrapper">
        <div className="profile-detail-box">
          <div className="profile-info-box">
            <label>Order Date</label>
            <p>01/01/2015</p>
          </div>
          <div className="profile-info-box">
            <label>Active</label>
            <p>Yes</p>
          </div>
          <div className="profile-info-box">
            <label>Vendor Name</label>
            <p>AmericansourceBergen</p>
          </div>
        </div>
        <div className="inventory-order-table-wrapper">
          <div className="listing-table-wrapper">
            <Table responsive="md" hover={true}>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Product No</th>
                  <th>Description</th>
                  <th>Location</th>
                  <th>Reorder Level</th>
                  <th>Quantity on Hand</th>
                  <th>Order Quantity</th>
                  <th>Unit</th>
                  <th>Status</th>
                  <th>Vendor Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ORD8</td>
                  <td>Varivax</td>
                  <td>Varivax</td>
                  <td>1234 Maple Street Springfield, Anytown, USA</td>
                  <td>35.00</td>
                  <td>
                    <span className="negative-value">-169.00</span>
                  </td>
                  <td>100.00</td>
                  <td>ml</td>
                  <td>
                    <span className="status-tag active">Active</span>
                  </td>
                  <td>AmericansourceBergen</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="link">
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={threedotvertical}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <ReactSVG
                            className="svg-box"
                            wrapper="span"
                            src={deleteicon}
                          />
                          Delete
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <ReactSVG
                            className="svg-box"
                            wrapper="span"
                            src={circlecheckicon}
                          />
                          Mark as Complete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td>ORD8</td>
                  <td>Varivax-VFC</td>
                  <td>Varivax</td>
                  <td>5678 Oak Avenue Riverside, Smalltown, USA</td>
                  <td>1.00</td>
                  <td>
                    <span className="negative-value">-3.00</span>
                  </td>
                  <td>3.00</td>
                  <td>ml</td>
                  <td>
                    <span className="status-tag active">Active</span>
                  </td>
                  <td>AmericansourceBergen</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="link">
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={threedotvertical}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <ReactSVG
                            className="svg-box"
                            wrapper="span"
                            src={deleteicon}
                          />
                          Delete
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <ReactSVG
                            className="svg-box"
                            wrapper="span"
                            src={circlecheckicon}
                          />
                          Mark as Complete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td>ORD8</td>
                  <td>Varivax</td>
                  <td>Varivax</td>
                  <td>1234 Maple Street Springfield, Anytown, USA</td>
                  <td>35.00</td>
                  <td>
                    <span className="negative-value">-169.00</span>
                  </td>
                  <td>100.00</td>
                  <td>ml</td>
                  <td>
                    <span className="status-tag active">Active</span>
                  </td>
                  <td>AmericansourceBergen</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="link">
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={threedotvertical}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <ReactSVG
                            className="svg-box"
                            wrapper="span"
                            src={deleteicon}
                          />
                          Delete
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <ReactSVG
                            className="svg-box"
                            wrapper="span"
                            src={circlecheckicon}
                          />
                          Mark as Complete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
