import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "../commonBaseApiSlice";

export const productMApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.mutation({
      query: (data) => ({
        url: `/productmaster/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    createProduct: builder.mutation({
      query: (data) => ({
        url: "/productmaster/create",
        method: "POST",
        body: data,
      }),
    }),
    editProduct: builder.mutation({
      query: (data) => ({
        url: "/productmaster/edit",
        method: "PUT",
        body: data,
      }),
    }),
    setProductStatusActive: builder.mutation({
      query: (data) => ({
        url: `/productmaster/activate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    setProductStatusDeActive: builder.mutation({
      query: (data) => ({
        url: `/productmaster/deactivate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    deleteProduct: builder.mutation({
      query: (data) => ({
        url: `/productmaster/delete?${getFilters(data)}`,
        method: "DELETE",
      }),
    }),
    duplicateProduct: builder.mutation({
      query: (data) => ({
        url: `/productmaster/duplicate?${getFilters(data)}`,
        method: "POST",
      }),
    }),
    exportProductMasterList: builder.mutation({
      query: () => ({
        url: "/productmaster/export",
        method: "GET",
        responseType: "blob",
      }),
      providesTags: undefined,
    }),
    importProductMasterExcelFile: builder.mutation({
      query: (data) => {
        return {
          url: "/productmaster/import",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetProductsMutation,
  useCreateProductMutation,
  useEditProductMutation,
  useDeleteProductMutation,
  useSetProductStatusActiveMutation,
  useSetProductStatusDeActiveMutation,
  useDuplicateProductMutation,
  useExportProductMasterListMutation,
  useImportProductMasterExcelFileMutation,
} = productMApiSlice;
