import Form from "react-bootstrap/Form";
import "./addwarningitem.scss";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useItemWarning from "../../../Hooks/useItemWarning";
import {} from "../../../utils/commonUtils";
import Loading from "../../../components/LoadingPage/Loading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { DropDownValues } from "../../../utils/types";
const warningTypeOptions = [
  {
    label: "Warn",
    value: "Warn",
  },
  {
    label: "Block",
    value: "Block",
  },
];
const AddWarningItem = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const {
    createItemWarningLoading,
    createItemWarningAPI,
    getItemWarningByIdAPI,
    getItemWarningByIdLoading,
    editItemWarningAPI,
    editItemWarningLoading,
  } = useItemWarning();
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const [dynamicVal, setDynamicVal] = useState({
    isActive: true,
    itemCode: "",
    warning: "",
    warningType: "",
    createdBy: currentUserId ?? "",
  });
  useEffect(() => {
    if (location.pathname !== "/add-item-warning") {
      setIsEdit(true);
      if (id) {
        getItemWarningByIdAPI(id)
          .then((data: any) => {
            setDynamicVal({
              isActive: data.isActive,
              itemCode: data.itemCode,
              warning: data.warning,
              warningType: data.warningType,
              createdBy: data.createdBy,
            });
          })
          .catch();
      } else {
        navigate("/item-warning-master");
      }
    }
  }, []);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: dynamicVal,
    validationSchema: Yup.object({
      itemCode: Yup.string()
        .required("Item Code is Required")
        .max(100, "Item Code should not be more than 100 characters"),
      warning: Yup.string()
        .required("Warning is Required")
        .max(1024, "Warning should not be more than 1024 characters"),
      warningType: Yup.string().required("Warning Type is Required"),
    }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        if (isEdit) {
          await editItemWarningAPI({ ...values, id: id });
        } else {
          await createItemWarningAPI(values);
        }
        navigate("/item-warning-master");
      } catch (err: any) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  return (
    <div className="add-patients-wrapper">
      {createItemWarningLoading ||
      getItemWarningByIdLoading ||
      editItemWarningLoading ? (
        <Loading />
      ) : null}
      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} Warning Item</h2>
      </div>
      <div className="add-patients-box">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Label>Item Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Item Code"
              id="itemCode"
              onChange={(e) => handleChange("itemCode")(e.target.value ?? "")}
              value={values.itemCode}
              isInvalid={touched.itemCode && !!errors.itemCode}
            />
            {touched.itemCode && errors.itemCode ? (
              <Form.Control.Feedback type="invalid">
                {errors.itemCode}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Warning Type</Form.Label>
            <Select
              placeholder="Select Warning Type"
              classNamePrefix="react-select"
              options={warningTypeOptions}
              id="warningType"
              value={warningTypeOptions?.find(
                (option: DropDownValues) => option.value === values.warningType,
              )}
              onChange={(option: any) =>
                handleChange("warningType")(option.value)
              }
              className={`${
                touched.warningType && !!errors.warningType
                  ? "is-invalid-border"
                  : ""
              } react-select-container `}
            />

            {touched.warningType && errors.warningType && (
              <Form.Control.Feedback type="invalid">
                {errors.warningType}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Warning</Form.Label>
            <Form.Control
              placeholder="Enter Warning Message"
              as="textarea"
              rows={3}
              id="warning"
              onChange={(e) => handleChange("warning")(e.target.value ?? "")}
              value={values.warning}
              isInvalid={touched.warning && !!errors.warning}
            />
            {touched.warning && errors.warning ? (
              <Form.Control.Feedback type="invalid">
                {errors.warning}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label></Form.Label>
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="Active"
                type="checkbox"
                id="isActive"
                name="isActive"
                onChange={handleChange}
                checked={values.isActive}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-4 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => navigate("/item-warning-master")}
            >
              Cancel
            </Button>
            <Button type="submit">{isEdit ? "Update" : "Add"}</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default AddWarningItem;
