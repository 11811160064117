import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./../store";
export interface StockData {
  selectedStock: any;
}
const storedStock = localStorage.getItem("selectedStock");
const initialState: StockData = {
  selectedStock: storedStock ? JSON.parse(storedStock) : null,
};

const stockSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    setSelectedStock: (state, action: PayloadAction<any>) => {
      state.selectedStock = action.payload;
      localStorage.setItem("selectedStock", JSON.stringify(action.payload));
    },
  },
});
export const { setSelectedStock } = stockSlice.actions;
export default stockSlice.reducer;
export const selectedStock = (state: RootState) => state.stock.selectedStock;
