import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "../commonBaseApiSlice";

export interface LocationList {
  locationCode: string;
  locationName: string;
  sortOrder: number;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}
export const locationApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.mutation({
      query: (data) => ({
        url: `/location/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    createLocations: builder.mutation({
      query: (data) => ({
        url: "/location/create",
        method: "POST",
        body: data,
      }),
    }),
    editLocations: builder.mutation({
      query: (data) => ({
        url: "/location/edit",
        method: "PUT",
        body: data,
      }),
    }),
    setLocationStatusActive: builder.mutation({
      query: (data) => ({
        url: `/location/activate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    setLocationStatusDeActive: builder.mutation({
      query: (data) => ({
        url: `/location/deactivate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    deleteLocation: builder.mutation({
      query: (data) => ({
        url: `/location/delete?${getFilters(data)}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetLocationsMutation,
  useCreateLocationsMutation,
  useEditLocationsMutation,
  useSetLocationStatusActiveMutation,
  useSetLocationStatusDeActiveMutation,
  useDeleteLocationMutation,
} = locationApiSlice;
