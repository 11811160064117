import CustomModal from "../../../../components/customModal/CustomModal";
import duplicateitemmodalimge from "../../../../assets/images/duplicate-item-modal-image.svg";
import { Button } from "react-bootstrap";
export const DuplicateModel = ({
  showModal,
  toggleDuplicateModal,
  duplicateOP,
  currentOP,
}: any) => {
  return (
    <>
      {showModal && (
        <CustomModal
          title=""
          content={
            <>
              <div className="modal--content--wrapper">
                <img src={duplicateitemmodalimge} alt="" />
                <h5>
                  Are you sure you want to <br />
                  duplicate this product '{currentOP.productNo}'?
                </h5>
              </div>
              <div className="modal--action--btn--wrapper">
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={toggleDuplicateModal}
                >
                  Cancel
                </Button>
                <Button type="button" variant="primary" onClick={duplicateOP}>
                  Duplicate
                </Button>
              </div>
            </>
          }
          onClose={toggleDuplicateModal}
        />
      )}
    </>
  );
};
