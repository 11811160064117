import { commonBaseApiSlice } from "../commonBaseApiSlice";

export interface AthenaPatient {
  patientID: string;
  fName: string;
  mi: any;
  lName: string;
  dob: string;
  gender: string;
  address: string;
  address2: any;
  state: string;
  city: string;
  zipCode: string;
  phone: string;
  mobile: string;
  ssn: any;
  email: string;
  userDef1: any;
  userDef2: any;
  userDef3: any;
  userDef4: any;
  userDef5: any;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}
export interface AthenaList {
  athenaPatient: AthenaPatient;
  internalNote: string;
  patientId: number;
  assignedTo: string;
  departmentId: string;
  ndc: string;
  lotNumber: string;
  encounterId: string;
  dateCreated: string;
  orderType: string;
  orderingMode: string;
  documentDescription: string;
  documentSubclass: string;
  providerId: string;
  athenaId: number;
  versionNo: number;
  givenDate: any;
  dateModified: string;
  manufacturer: string;
  documentClass: string;
  documentTypeId: string;
  quantity: number;
  documentSource: string;
  status: string;
  uom: string;
  refillsAllowed: any;
  dosageDuration: number;
  expDate: string;
  actionNote: string;
  medId: number;
  dosageFrequencyUnit: string;
  dosageFrequencyValue: string;
  dosageDurationUnit: string;
  dosageAdditionalInstructions: string;
  dosageQuantityValue: string;
  dosageQuantityUnit: string;
  sig: string;
  totalQuantity: any;
  srxStatus: string;
  srxMessage: string;
  dateCreatedForDetail: string;
  dateModifiedForDetail: string;
  notes: string;
  administeredBy: string;
  srxBarcode: any;
  manualProcessMetadata: any;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}
export interface AthenaSettingsModel {
  locations: Location[];
  athenaEnvironments: AthenaEnvironment[];
  administerStatuses: AdministerStatuse[];
  processUsingOptions: ProcessUsingOption[];
  orderTypes: OrderType[];
  orderingModes: OrderingMode[];
  orderStatuses: OrderStatuse[];
  athenaHealthSetting: AthenaHealthSetting;
}

export interface Location {
  key: string;
  value: string;
}

export interface AthenaEnvironment {
  environment: string;
  athenaURL: string;
  athenaTokenURL: string;
}

export interface AdministerStatuse {
  key: string;
  value: string;
}

export interface ProcessUsingOption {
  key: string;
  value: string;
}

export interface OrderType {
  key: string;
  value: string;
}

export interface OrderingMode {
  key: string;
  value: string;
}

export interface OrderStatuse {
  key: string;
  value: string;
}

export interface AthenaHealthSetting {
  environment: string;
  athenaURL: string;
  athenaTokenURL: string;
  practiceId: number;
  pageSize: number;
  departmentIds: string[];
  showLoadInRxMaster: boolean;
  administerStatuses: string[];
  validOrderTypes: ValidOrderType[];
  obfuscateInformation: boolean;
  obfuscateRulesObject: ObfuscateRulesObject;
  validateLot: boolean;
  processUsing: string;
  locationMapSettings: any[];
  leaveUnprocessed: boolean;
  postToLabResults: boolean;
  noOfAttempts: number;
  deltaMinutes: number;
}

export interface ValidOrderType {
  type: string;
  validOrderingModes: string[];
  requestType: string;
  statuses: string[];
}

export interface ObfuscateRulesObject {
  defaultDate: string;
  keepFirstNChars: number;
}

export interface AthenaTrnsSetIniVal {
  environment: string;
  athenaURL: string;
  athenaTokenURL: string;
  practiceId: number;
  pageSize: number;
  departmentIds: string[];
  showLoadInRxMaster: boolean;
  administerStatuses: string[];
  validOrderTypes: ValidOrderType[];
  obfuscateInformation: boolean;
  obfuscateRulesObject: ObfuscateRulesObject;
  validateLot: boolean;
  processUsing: string;
  locationMapSettings: LocationMapSetting[];
  leaveUnprocessed: boolean;
  postToLabResults: boolean;
  noOfAttempts: number;
  deltaMinutes: number;
  currentUserId?: string | null;
}

export interface ValidOrderType {
  type: string;
  validOrderingModes: string[];
  requestType: string;
  statuses: string[];
}

export interface ObfuscateRulesObject {
  defaultDate: string;
  keepFirstNChars: number;
}

export interface LocationMapSetting {
  fromLocations: string[];
  toLocationId: string;
}

export const athenaApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    athenaTransDD: builder.mutation({
      query: () => ({
        url: "/athena/renderathenatransactionpage",
        method: "GET",
      }),
    }),
    athenaTransList: builder.mutation({
      query: (data) => ({
        url: "/athena/getathenaorders",
        method: "POST",
        body: data,
      }),
    }),
    athenaTransGet: builder.mutation({
      query: (id) => ({
        url: `/athena/getathenaorderbyid?id=${id}`,
        method: "GET",
      }),
    }),

    athenaSettings: builder.mutation({
      query: (id) => ({
        url: `/athena/renderathenahealthsettingpage`,
        method: "GET",
      }),
    }),

    athenaSettingsEdit: builder.mutation({
      query: (data) => ({
        url: `/athena/edit?UpdatedBy=${data.currentUserId}`,
        method: "PUT",
        body: data,
      }),
    }),

    loadAthenaProviders: builder.mutation({
      query: (data) => ({
        url: "/athena/loadathenaproviders",
        method: "POST",
        body: data,
      }),
    }),
    loadAthenaLocations: builder.mutation({
      query: (data) => ({
        url: "/athena/loadathenalocations",
        method: "POST",
        body: data,
      }),
    }),
    loadAthenaOrders: builder.mutation({
      query: (data) => ({
        url: "/athena/loadathenaorders",
        method: "POST",
        body: data,
      }),
    }),
    deleteAthenaOrder: builder.mutation({
      query: (data) => ({
        url: `/athena/deleteathenaorder?id=${data}`,
        method: "POST",
        body: {},
      }),
    }),
    markAthenaOrderunProcessed: builder.mutation({
      query: (data) => ({
        url: `/athena/markathenaorderunprocessed?id=${data}`,
        method: "POST",
        body: {},
      }),
    }),
    discardAthenaOrder: builder.mutation({
      query: (data) => ({
        url: `/athena/discardathenaorder?id=${data}`,
        method: "POST",
        body: {},
      }),
    }),
    processAthenaOrder: builder.mutation({
      query: (data) => ({
        url: "/athena/processathenaorder",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useAthenaTransDDMutation,
  useAthenaTransListMutation,
  useAthenaTransGetMutation,
  useLoadAthenaProvidersMutation,
  useLoadAthenaLocationsMutation,
  useLoadAthenaOrdersMutation,
  useDeleteAthenaOrderMutation,
  useMarkAthenaOrderunProcessedMutation,
  useDiscardAthenaOrderMutation,
  useProcessAthenaOrderMutation,
  useAthenaSettingsMutation,
  useAthenaSettingsEditMutation,
} = athenaApiSlice;
