import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./locationlisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import deactivateicon from "../../../assets/images/deactivate-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import { ReactSVG } from "react-svg";
import useLocations from "../../../Hooks/useLocations";
import Loading from "../../../components/LoadingPage/Loading";
import { setSelectedLocation } from "../../../redux/location/locationSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { toggleClass } from "../../../utils/commonUtils";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import CommonHeaderWithImpoExpo from "../../../components/commonHeaderWithImpoExpo/commonHeaderWithImpoExpo";
const LocationListing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { getLocationsAPI, getLocationsLoading } = useLocations();
  const { activeLocationAPI, setLocationStatusActiveLoading } = useLocations();
  const { deactiveLocationAPI, setLocationStatusDeActiveLoading } =
    useLocations();
  const { deleteLocationAPI, deleteLocationLoading } = useLocations();
  const [showModal, setShowModal] = useState(false);
  const [locationsList, setLocationsList] = useState<any>([]);
  const [currentLocation, setCurrentLocation] = useState<any>();
  const defaultFilter = { searchText: "", active: true };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const deleteLocation = async () => {
    await deleteLocationAPI({
      id: currentLocation.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    getLocations();
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };

  const getLocations = () => {
    getLocationsAPI(searchFieldVal)
      .then((res: any) => setLocationsList(res))
      .catch();
  };
  const editLocation = (location: any) => {
    dispatch(setSelectedLocation(location));
    navigate("/edit-location");
  };
  const navigateToView = (location: any) => {
    dispatch(setSelectedLocation(location));
    navigate("/view-location");
  };
  const updateStatus = async (location: any) => {
    if (location.isActive) {
      await deactiveLocationAPI({
        id: location.id,
        updatedBy: currentUserId ?? "",
      });
    } else {
      await activeLocationAPI({
        id: location.id,
        updatedBy: currentUserId ?? "",
      });
    }
    getLocations();
  };
  useEffect(() => {
    getLocations();
  }, [searchFieldVal]);
  return (
    <div className="location-listing-wrapper">
      {getLocationsLoading ||
      setLocationStatusActiveLoading ||
      setLocationStatusDeActiveLoading ||
      deleteLocationLoading ? (
        <Loading />
      ) : null}
      <CommonHeaderWithImpoExpo
        title="Location Master"
        buttonTitle="Add New"
        href="/add-location"
        disRecords={locationsList.length || 0}
        totalRecords={locationsList.length || 0}
        searchVal={searchFieldVal}
        setSearchVal={setSearchFieldVal}
        defaultFilter={defaultFilter}
        isAddPermitted={true}
      />
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>Location ID</th>
              <th>Location Name</th>
              <th>Sort Order</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {locationsList?.map((x: any) => (
              <tr
                key={x.id}
                onDoubleClick={() => {
                  navigateToView(x);
                }}
              >
                <td title={x.locationCode}>{x.locationCode}</td>
                <td title={x.locationName}>{x.locationName}</td>
                <td title={x.sortOrder}>{x.sortOrder}</td>
                <td>
                  <span
                    className={`status-circle ${x.isActive ? "active" : "inactive"}`}
                  >
                    {x.isActive ? "Active" : "InActive"}
                  </span>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigateToView(x)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={viewicon}
                        />
                        View
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          updateStatus(x);
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deactivateicon}
                        />
                        {x.isActive ? "Deactivate" : "Activate"}
                      </Dropdown.Item>

                      <Dropdown.Item onClick={() => editLocation(x)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentLocation(x);
                          toggleModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteLocation}
        recordName={currentLocation?.locationName || ""}
        modelName={`location`}
      />
    </div>
  );
};

export default LocationListing;
