import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "./../commonBaseApiSlice";

export const stockApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStocks: builder.mutation({
      query: (data) => ({
        url: `/stock/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    createStocks: builder.mutation({
      query: (data) => ({
        url: "/stock/create",
        method: "POST",
        body: data,
      }),
    }),
    editStocks: builder.mutation({
      query: (data) => ({
        url: "/stock/edit",
        method: "PUT",
        body: data,
      }),
    }),
    setStockStatusActive: builder.mutation({
      query: (data) => ({
        url: `/stock/activate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    setStockStatusDeActive: builder.mutation({
      query: (data) => ({
        url: `/stock/deactivate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    deleteStock: builder.mutation({
      query: (data) => ({
        url: `/stock/softdelete?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useGetStocksMutation,
  useCreateStocksMutation,
  useEditStocksMutation,
  useSetStockStatusActiveMutation,
  useSetStockStatusDeActiveMutation,
  useDeleteStockMutation,
} = stockApiSlice;
