import { BaseQueryApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { commonBaseApiSlice } from "./commonBaseApiSlice";
import { AuthData, resetAuthState, setCredentials } from "./auth/authSlice";
import { RootState } from "./store";
import { checkTokenExpiration, trimObjectValues } from "../utils/commonUtils";
import { toast } from "react-toastify";

const unAuthorizeStatus = 401;

// Function to refresh the token
async function refreshToken(
  api: BaseQueryApi,
  baseQuery: any,
  extraOptions: any,
): Promise<string | null> {
  const refreshResult = await baseQuery(
    {
      url: `${(api.getState() as RootState).config.configData?.authApiBaseUrl}/authentication/refresh`,
      method: "POST",
      body: {
        refreshToken: (api.getState() as RootState).auth.refreshToken,
        accessToken: (api.getState() as RootState).auth.jwtToken,
      },
    },
    api,
    extraOptions,
  );

  if (refreshResult.data) {
    api.dispatch(setCredentials(refreshResult.data as AuthData));
    return refreshResult.data.accessToken;
  } else {
    api.dispatch(resetAuthState());
    api.dispatch(commonBaseApiSlice.util.resetApiState());
    return null;
  }
}

// Base query function
export const apiSliceBaseQuery = async (
  args: any,
  api: BaseQueryApi,
  extraOptions: any,
  baseUrl: string | undefined,
): Promise<any> => {
  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers, { getState }) {
      const currentToken = (getState() as RootState).auth.jwtToken;
      if (currentToken) {
        headers.set("Authorization", `Bearer ${currentToken}`);
      }
      // For file upload, don't set 'Content-Type', let the browser handle it for FormData
      if (args.body instanceof FormData) {
        headers.delete("Content-Type"); // FormData automatically sets the correct headers
      } else {
        if (args.body) {
          args.body = trimObjectValues(args.body);
        }
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
    responseHandler: async (response: Response) => {
      if (response.headers.get("content-type")?.includes("application/json")) {
        return await response.json();
      } else if (
        response.headers
          .get("content-type")
          ?.includes(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          )
      ) {
        return await response.blob();
      } else {
        return await response.text();
      }
    },
  });

  let result = await baseQuery(args, api, extraOptions);
  const exp = +localStorage.getItem("exp")!
    ? +localStorage.getItem("exp")!
    : null;
  const state = checkTokenExpiration(exp, 60 * 5);
  if (
    state ||
    (result.error &&
      result.error.status === unAuthorizeStatus &&
      localStorage.getItem("jwtToken"))
  ) {
    toast.dismiss();
    const newToken = await refreshToken(api, baseQuery, extraOptions);
    if (newToken) {
      // retry the initial query with new token
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
