import { Button } from "react-bootstrap";
import CustomModal from "./../customModal/CustomModal";
import deleteusermodalimge from "../../assets/images/delete-user-modal-image.svg";
export const DeleteConfModel = ({
  showModal,
  toggleModal,
  deleteAction,
  modelName,
  recordName,
}: any) => {
  return (
    <>
      {showModal && (
        <CustomModal
          title=""
          content={
            <>
              <div className="modal--content--wrapper">
                <img src={deleteusermodalimge} alt="" />
                <h5>
                  Are you sure you want to <br />
                  delete this {modelName}{" "}
                  <span className="action-item-name" title={recordName || ""}>
                    '{recordName || ""}'
                  </span>
                  ?
                </h5>
                <div className="modal--inputbox">
                  <label>Reason to delete</label>
                  <textarea
                    className="form-control"
                    rows={3}
                    placeholder="Reason"
                  ></textarea>
                </div>
              </div>
              <div className="modal--action--btn--wrapper">
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={toggleModal}
                >
                  Cancel
                </Button>
                <Button type="button" variant="primary" onClick={deleteAction}>
                  Delete
                </Button>
              </div>
            </>
          }
          onClose={toggleModal}
        />
      )}
    </>
  );
};
