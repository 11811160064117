import { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./rolelisting.scss";
import deleteitemmodalimge from "../../../assets/images/delete-item-modal-image.svg";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import ListingTitle from "../../../components/listingTitle/ListingTitle";
import CustomModal from "../../../components/customModal/CustomModal";
import { ReactSVG } from "react-svg";
import { toggleClass } from "../../../utils/commonUtils";
import useRolesAndPermissions from "../../../Hooks/useRolesAndPermissions";
import Loading from "../../../components/LoadingPage/Loading";
import CommonSearchOnly from "../../../components/commonSearchOnly/commonSearchOnly";
const RoleListing = () => {
  const { getRolesAPI, getRolesLoading } = useRolesAndPermissions();
  const [rolesList, setRolesList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const defaultFilter = { searchText: "" };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const onClose = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const getRoles = () => {
    getRolesAPI(searchFieldVal)
      .then((res: any) => setRolesList(res))
      .catch();
  };
  useEffect(() => {
    getRoles();
  }, [searchFieldVal]);
  return (
    <div className="role-listing-wrapper">
      {getRolesLoading ? <Loading /> : null}

      <CommonSearchOnly
        title="Roles & Permissions"
        buttonTitle="Add Role"
        href="/add-role"
        disRecords={rolesList.length || 0}
        totalRecords={rolesList.length || 0}
        searchVal={searchFieldVal}
        setSearchVal={setSearchFieldVal}
        defaultFilter={defaultFilter}
      />
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>Role ID</th>
              <th>Role Name</th>
              <th>DESCRIPTION</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rolesList?.map((x: any) => (
              <tr key={x.id}>
                <td>{x.id}</td>
                <td>{x.roleName}</td>
                <td>{x.roleDescription}</td>
                <td key={x.id}>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href={`/edit-role/${x.id}`}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      {/* <Dropdown.Item href="#" onClick={toggleModal}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {showModal && (
        <CustomModal
          title=""
          content={
            <>
              <div className="modal--content--wrapper">
                <img src={deleteitemmodalimge} alt="" />
                <h5>
                  Are you sure you want to <br />
                  delete this role 'Super Admin'?
                </h5>
                <div className="modal--inputbox">
                  <label>Reason to delete</label>
                  <textarea
                    className="form-control"
                    rows={3}
                    placeholder="Reason"
                  ></textarea>
                </div>
              </div>
              <div className="modal--action--btn--wrapper">
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button type="button" variant="primary">
                  Delete
                </Button>
              </div>
            </>
          }
          onClose={toggleModal}
        />
      )}
    </div>
  );
};

export default RoleListing;
