import { commonBaseApiSlice } from "../commonBaseApiSlice";

export const dashboardApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserCount: builder.mutation({
      query: () => ({
        url: "/reports/dashboard/getcounts",
        method: "GET",
      }),
    }),
    fetchInventoryValue: builder.mutation({
      query: () => ({
        url: "/reports/dashboard/getinventoryvalues",
        method: "GET",
      }),
    }),
    fetchDashboardAlters: builder.mutation({
      query: () => ({
        url: "/reports/dashboard/getalerts",
        method: "GET",
      }),
    }),
    fetchTopTransactionItems: builder.mutation({
      query: () => ({
        url: "/reports/dashboard/gettoptransactionitems",
        method: "GET",
      }),
    }),
    fetchInventoryValuesByType: builder.mutation({
      query: () => ({
        url: "/reports/dashboard/getinventoryvaluesbytype",
        method: "GET",
      }),
    }),
    fetchInventoryValueByLocation: builder.mutation({
      query: () => ({
        url: "/reports/dashboard/inventoryvaluesbylocation",
        method: "GET",
      }),
    }),
  }),
});
export const {
  useGetUserCountMutation,
  useFetchInventoryValueMutation,
  useFetchDashboardAltersMutation,
  useFetchTopTransactionItemsMutation,
  useFetchInventoryValuesByTypeMutation,
  useFetchInventoryValueByLocationMutation,
} = dashboardApiSlice;
