import { Button, Dropdown } from "react-bootstrap";
import "./itemprofileheader.scss";
import threedotvertical from "../../assets/images/three-dots-vertical.svg";
import editicon from "../../assets/images/edit-icon.svg";
import deleteicon from "../../assets/images/delete-icon.svg";
import duplicateicon from "../../assets/images/duplicate-icon.svg";
import deactivateicon from "../../assets/images/deactivate-icon.svg";
import { ReactSVG } from "react-svg";

const ItemProfileHeader = ({
  selectedTypeData,
  updateStatus,
  toggleModal,
  duplicateType,
}: any) => {
  return (
    <div className="user-profile-header-wrapper">
      <div className="profile-title-status-box">
        <h2>{selectedTypeData.rxTypeName}</h2>
        <div
          className={`status-box 
          ${selectedTypeData?.isActive ? "active" : "inactive"}`}
        >
          <span className="status-circle"></span>
          {selectedTypeData?.isActive ? "Active" : "InActive"}
        </div>
      </div>
      <div className="profile-actions-box">
        <div className="edit-btn-box">
          <Button variant="primary" href="/edit-item-master">
            <i className="bi bi-pencil-square"></i> Edit
          </Button>
        </div>
        <Dropdown>
          <Dropdown.Toggle variant="link" id="dropdown-basic">
            <ReactSVG
              className="svg-box"
              wrapper="span"
              src={threedotvertical}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={updateStatus}>
              <ReactSVG
                className="svg-box"
                wrapper="span"
                src={deactivateicon}
              />
              {selectedTypeData.isActive ? "Deactivate" : "Activate"}
            </Dropdown.Item>
            <Dropdown.Item onClick={toggleModal}>
              <ReactSVG className="svg-box" wrapper="span" src={deleteicon} />
              Delete
            </Dropdown.Item>
            <Dropdown.Item onClick={duplicateType}>
              <ReactSVG
                className="svg-box"
                wrapper="span"
                src={duplicateicon}
              />
              Duplicate
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default ItemProfileHeader;
