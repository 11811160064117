import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./vendorlisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import deactivateicon from "../../../assets/images/deactivate-icon.svg";
import duplicateicon from "../../../assets/images/duplicate-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import ListingTitle from "../../../components/listingTitle/ListingTitle";
import { ReactSVG } from "react-svg";
import useVendors from "../../../Hooks/useVendors";
import Loading from "../../../components/LoadingPage/Loading";
import { Vendor, setSelectedVendor } from "../../../redux/vendor/vendorSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { toggleClass } from "../../../utils/commonUtils";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import ItemListingTitle from "../../../components/itemListingTitle/itemListingTitle";

const VendorListing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { getVendorsAPI, getVendorsLoading } = useVendors();
  const { activeVendorAPI, setVendorStatusActiveLoading } = useVendors();
  const { deactiveVendorAPI, setVendorStatusDeActiveLoading } = useVendors();
  const { deleteVendorAPI, deleteVendorLoading } = useVendors();
  const [showModal, setShowModal] = useState(false);
  const [vendorsList, setVendorsList] = useState<Vendor[]>([]);
  const [currentVendor, setCurrentVendor] = useState<Vendor>();
  const { duplicateVendorAPI, duplicateVendorLoading } = useVendors();
  const defaultFilter = { searchText: "", active: true };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const deleteVendor = async () => {
    await deleteVendorAPI({
      id: currentVendor?.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    getVendors();
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };

  const getVendors = () => {
    getVendorsAPI(searchFieldVal)
      .then((res: Vendor[]) => setVendorsList(res))
      .catch();
  };
  const editVendor = (vendor: Vendor) => {
    dispatch(setSelectedVendor(vendor));
    navigate("/edit-vendor");
  };
  const navigateToView = (vendor: Vendor) => {
    dispatch(setSelectedVendor(vendor));
    navigate("/view-vendor");
  };
  const updateStatus = async (vendor: Vendor) => {
    if (vendor.isActive) {
      await deactiveVendorAPI({
        id: vendor.id,
        updatedBy: currentUserId ?? "",
      });
    } else {
      await activeVendorAPI({ id: vendor.id, updatedBy: currentUserId ?? "" });
    }
    getVendors();
  };
  const duplicateVendor = async (vendor: Vendor) => {
    await duplicateVendorAPI({
      id: vendor.id,
      updatedBy: currentUserId ?? "",
    });
    getVendors();
  };
  useEffect(() => {
    getVendors();
  }, [searchFieldVal]);
  return (
    <div className="vendor-listing-wrapper">
      {getVendorsLoading ||
      deleteVendorLoading ||
      setVendorStatusActiveLoading ||
      setVendorStatusDeActiveLoading ||
      duplicateVendorLoading ? (
        <Loading />
      ) : null}

      <ItemListingTitle
        title="Vendor Master"
        buttonTitle="Add New"
        href="add-vendor"
        disRecords={vendorsList.length || 0}
        totalRecords={vendorsList.length || 0}
        searchVal={searchFieldVal}
        setSearchVal={setSearchFieldVal}
        defaultFilter={defaultFilter}
        isAddPermitted={true}
      />
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>Name</th>
              <th>City</th>
              <th>State</th>
              <th>Zip</th>
              <th>Email Id</th>
              <th>Phone</th>
              <th>Contact Person</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {vendorsList?.map((vendor: Vendor) => (
              <tr
                key={vendor.id}
                onDoubleClick={() => {
                  navigateToView(vendor);
                }}
              >
                <td title={vendor.name}>{vendor.name}</td>
                <td>{vendor.city}</td>
                <td>{vendor.state}</td>
                <td>{vendor.zipCode}</td>
                <td title={vendor.email}>{vendor.email}</td>
                <td title={vendor.phone}>{vendor.phone}</td>
                <td title={vendor.contactPerson}>{vendor.contactPerson}</td>
                <td>
                  <span
                    className={`status-circle ${vendor.isActive ? "active" : "inactive"}`}
                  >
                    {vendor.isActive ? "Active" : "InActive"}
                  </span>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigateToView(vendor)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={viewicon}
                        />
                        View
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          updateStatus(vendor);
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deactivateicon}
                        />
                        {vendor.isActive ? "Deactivate" : "Activate"}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => editVendor(vendor)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentVendor(vendor);
                          toggleModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          duplicateVendor(vendor);
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={duplicateicon}
                        />
                        Duplicate
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteVendor}
        recordName={currentVendor?.name || ""}
        modelName={`vendor`}
      />
    </div>
  );
};

export default VendorListing;
