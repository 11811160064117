import { getErrorMessage } from "../utils/commonUtils";
import {
  useRolesListMutation,
  usePermissionsListMutation,
  useCreateRolePermissionMutation,
  useCreateRoleMutation,
  useEditRoleMutation,
  useGetRolesPermissionMutation,
  useGetRolesByIdMutation,
} from "../redux/rolesAndPermissions/rolesAndPermissionsApiSlice";
import { toast } from "react-toastify";

export default function useRolesAndPermissions() {
  const [getRoles, { isLoading: getRolesLoading }] = useRolesListMutation();

  const [getRolesPermission, { isLoading: getRolesPermissionLoading }] =
    useGetRolesPermissionMutation();

  const [getPermissions, { isLoading: getPermissionsLoading }] =
    usePermissionsListMutation();
  const [createRole, { isLoading: createRoleLoading }] =
    useCreateRoleMutation();
  const [createRolePermission, { isLoading: createPermissionLoading }] =
    useCreateRolePermissionMutation();

  const [editRole, { isLoading: editRoleLoading }] = useEditRoleMutation();
  const [getRolesById, { isLoading: getRolesByIdLoading }] =
    useGetRolesByIdMutation();
  const getRolesAPI = async (data: any) => {
    try {
      const response: any = await getRoles(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const getRolesPermissionAPI = async (data: any) => {
    try {
      const response: any = await getRolesPermission(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const getPermissionsAPI = async (data: any) => {
    try {
      const response: any = await getPermissions(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const createPermissionsAPI = async (data: any) => {
    try {
      const response: any = await createRolePermission(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const createRoleAPI = async (data: any) => {
    try {
      const response: any = await createRole(data).unwrap();
      toast.success("New Role added successfully");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const editRoleAPI = async (data: any) => {
    try {
      const response: any = await editRole(data).unwrap();
      toast.success("Data updated successfully");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getRolesByIdAPI = async (data: any) => {
    try {
      const response: any = await getRolesById(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  return {
    getPermissionsAPI,
    getRolesAPI,
    getRolesLoading,
    getPermissionsLoading,
    createPermissionsAPI,
    createRoleLoading,
    createRoleAPI,
    createPermissionLoading,
    editRoleLoading,
    editRoleAPI,
    getRolesPermissionAPI,
    getRolesPermissionLoading,
    getRolesByIdAPI,
    getRolesByIdLoading,
  };
}
