import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "../commonBaseApiSlice";
export interface UsageTransList {
  patientId: string;
  patient: Patient;
  providerId: string;
  provider: Provider;
  userId: string;
  user: any;
  itemId: string;
  item: Item;
  locationId: string;
  location: Location;
  shotDate: string;
  dose: number;
  wasted: boolean;
  notes: any;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface Patient {
  patientCode: string;
  firstName: string;
  mi: any;
  lastName: string;
  dob: string;
  gender: any;
  addressLine1: any;
  addressLine2: any;
  addressLine3: any;
  city: any;
  state: any;
  zipCode: any;
  phone: any;
  mobile: any;
  ssn: any;
  email: any;
  userDef1: any;
  userDef2: any;
  userDef3: any;
  userDef4: any;
  userDef5: any;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface Provider {
  spSRXID: string;
  userName: string;
  password: any;
  title: any;
  fName: string;
  lName: string;
  mi: string;
  lastUsedDateAsHCP: string;
  toalCountAsHCP: number;
  lastUsedDateAsUser: string;
  toalCountAsUser: number;
  forceChangePassword: boolean;
  notes: any;
  deaNumber: string;
  email: any;
  userValue: number;
  clinicalRoleId: string;
  clinicalRole: any;
  emrId: any;
  roleId: string;
  role: any;
  refreshToken: any;
  refreshTokenExpiryTime: any;
  profilePicture: any;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface Item {
  rxSRXId: string;
  rxName: string;
  drugName: string;
  ndcCode: string;
  manufacturer: string;
  lotNo: string;
  expiryDate: string;
  cptCode: string;
  rxTypeId: string;
  rxType: any;
  stockId: string;
  stock: any;
  notes: string;
  userDef1: any;
  userDef2: any;
  userDef3: any;
  userDef4: any;
  userDef5: any;
  isRecalled: boolean;
  isKit: any;
  isSerialTracked: any;
  cvx: string;
  unitId: string;
  unit: any;
  useUnits: any;
  orderingProductMasterId: any;
  orderingProductMaster: any;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface Location {
  locationCode: string;
  locationName: string;
  sortOrder: number;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export const usageTransactionsApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsageTransList: builder.mutation({
      query: (data) => ({
        url: `/usagetransaction/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    getUsageTransById: builder.mutation({
      query: (id) => ({
        url: `usagetransaction/getbyid?id=${id}`,
        method: "GET",
      }),
    }),
    usageTransDelete: builder.mutation({
      query: (data) => ({
        url: `usagetransaction/softdelete?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
  }),
});
export const {
  useGetUsageTransListMutation,
  useGetUsageTransByIdMutation,
  useUsageTransDeleteMutation,
} = usageTransactionsApiSlice;
