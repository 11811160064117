import ReportHeader from "../../../components/reportHeader/ReportHeader";
import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import { useState } from "react";
import DatePicker from "react-date-picker";
import { Table } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
const stockOptions = [
  { label: "Private", value: "private" },
  { label: "Federal", value: "federal" },
  { label: "Petients", value: "petients" },
];

const typeOptions = [
  { label: "Pediatric Vaccine (PDV)", value: "Pediatric Vaccine" },
  { label: "Vaccine (VAC)", value: "Vaccine" },
  { label: "Procedure (PROC)", value: "Procedure" },
  { label: "Injection (INJEC)", value: "Injection" },
  { label: "IT Equipment 1 (ITEQUIP)", value: "IT Equipment 1 (ITEQUIP)" },
  { label: "Covid 19 Test (COVID 19)", value: "Covid 19 Test (COVID 19)" },
];

const locationOptions = [
  {
    label: "1234 Maple Street Springfield, Anytown, USA",
    value: "1234 Maple Street Springfield, Anytown, USA",
  },
  {
    label: "5678 Oak Avenue Riverside, Smalltown, USA",
    value: "5678 Oak Avenue Riverside, Smalltown, USA",
  },
  {
    label: "910 Elm Street Lakeside, Anytown, USA",
    value: "910 Elm Street Lakeside, Anytown, USA",
  },
  {
    label: "246 Pine Drive Sunnyvale, Suburbia, USA",
    value: "246 Pine Drive Sunnyvale, Suburbia, USA",
  },
  {
    label: "789 Washington Boulevard Hillcrest, Middle America, USA",
    value: "789 Washington Boulevard Hillcrest, Middle America, USA",
  },
  {
    label: "910 Elm Street Lakeside, Anytown, USA",
    value: "910 Elm Street Lakeside, Anytown, USA",
  },
  {
    label: "246 Pine Drive Sunnyvale, Suburbia, USA",
    value: "246 Pine Drive Sunnyvale, Suburbia, USA",
  },
];

const overrideStrings = {
  search: "Search",
  selectAll: "All",
  selectSomeItems: "All",
};
const AdjusmentDetailsReport = () => {
  const [selectedStock, setSelectedStock] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedLoc, setSelectedLoc] = useState([]);
  const [date, setDate] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const handleCalChange = (value: any) => {
    setDate(value.toLocaleDateString());
    setShowCalendar(false);
  };
  return (
    <div className="report-content-wrapper">
      <ReportHeader
        reportTitle="Inventory Reconciliation Report - Adjustment Details"
        reportDate="04 April 2024"
      />
      <div className="report-filter-wrapper">
        <div className="report-filter-item-wrapper">
          <div className="filter-item-box">
            <label className="form-label">Start Date</label>
            {/* <input
              value={date}
              onFocus={() => setShowCalendar(true)}
              className="exp-date-input form-control"
              placeholder="Enter Date"
            /> */}
            <DatePicker
              format="MM/dd/yyyy"
              openCalendarOnFocus={false}
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              className="form-control"
              value={date}
              clearIcon={null}
              calendarIcon={<i className="bi bi-calendar"></i>}
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">End Date</label>
            {/* <input
              value={date}
              onFocus={() => setShowCalendar(true)}
              className="exp-date-input form-control"
              placeholder="Enter Date"
            /> */}
            <DatePicker
              format="MM/dd/yyyy"
              openCalendarOnFocus={false}
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              className="form-control"
              value={date}
              clearIcon={null}
              calendarIcon={<i className="bi bi-calendar"></i>}
            />
          </div>
          <div className="filter-item-box type-filter-item">
            <label className="form-label">Type</label>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={typeOptions}
              value={selectedStock}
              onChange={setSelectedStock}
              labelledBy="Select"
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">Stock</label>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={stockOptions}
              value={selectedType}
              onChange={setSelectedType}
              labelledBy="Select"
              disableSearch={true}
            />
          </div>
          <div className="filter-item-box location-filter-item">
            <label className="form-label">Location</label>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={locationOptions}
              value={selectedLoc}
              onChange={setSelectedLoc}
              labelledBy="Select"
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">User</label>
            <select className="form-select">
              <option selected>All</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className="filter-item-box clear-all-box">
            <button type="button" className="clear-filter-btn">
              Clear All
            </button>
          </div>
        </div>
        <div className="report-export-box">
          <button type="button" className="report-export-btn">
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
            Export
          </button>
        </div>
      </div>
      <div className="filter-detail-wrapper">
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-title">
            <h4> (1 Tablet(s)) () Merck Sharp & Dohme Corp.</h4>
          </div>
          <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>RX ID</th>
                  <th>RX Name</th>
                  <th>MANUFACTURER</th>
                  <th>STATUS</th>
                  <th>LOCATION</th>
                  <th>NDC</th>
                  <th>LOT NO.</th>
                  <th>EXP. DATE</th>
                  <th>Stock</th>
                  <th>Type</th>
                  <th>Cost</th>
                  <th>Quantity</th>
                  <th>Cur. Value</th>
                  <th>UOM</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>901-2 Mepple... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>901-2 Mepple... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>23456 Street... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>23456 Street... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>23456 Street... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td colSpan={11}>Total</td>
                  <td>
                    <strong>31.00</strong>
                  </td>
                  <td>
                    <strong>$1400</strong>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-title">
            <h4> (1 Tablet(s)) () Merck Sharp & Dohme Corp.</h4>
          </div>
          <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>RX ID</th>
                  <th>RX Name</th>
                  <th>MANUFACTURER</th>
                  <th>STATUS</th>
                  <th>LOCATION</th>
                  <th>NDC</th>
                  <th>LOT NO.</th>
                  <th>EXP. DATE</th>
                  <th>Stock</th>
                  <th>Type</th>
                  <th>Cost</th>
                  <th>Quantity</th>
                  <th>Cur. Value</th>
                  <th>UOM</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>23456 Street... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>23456 Street... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>23456 Street... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>23456 Street... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>23456 Street... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td colSpan={11}>Total</td>
                  <td>
                    <strong>31.00</strong>
                  </td>
                  <td>
                    <strong>$1400</strong>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-title">
            <h4> (1 Tablet(s)) () Merck Sharp & Dohme Corp.</h4>
          </div>
          <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>RX ID</th>
                  <th>RX Name</th>
                  <th>MANUFACTURER</th>
                  <th>STATUS</th>
                  <th>LOCATION</th>
                  <th>NDC</th>
                  <th>LOT NO.</th>
                  <th>EXP. DATE</th>
                  <th>Stock</th>
                  <th>Type</th>
                  <th>Cost</th>
                  <th>Quantity</th>
                  <th>Cur. Value</th>
                  <th>UOM</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>23456 Street... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>901-2 Maple... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>901-2 Maple... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>901-2 Maple... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td>RX2022100608235995</td>
                  <td>01A VARIVAX</td>
                  <td>Mark Sharp...</td>
                  <td>
                    <span className="status-circle active">Active</span>
                  </td>
                  <td>901-2 Maple... </td>
                  <td>0006-4827-01</td>
                  <td>K003420</td>
                  <td>03/03/2024</td>
                  <td>SAMP</td>
                  <td>VAC</td>
                  <td>40.00</td>
                  <td>8.00</td>
                  <td>$320</td>
                  <td>Tablet(s)</td>
                </tr>
                <tr>
                  <td colSpan={11}>Total</td>
                  <td>
                    <strong>31.00</strong>
                  </td>
                  <td>
                    <strong>$1400</strong>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdjusmentDetailsReport;
