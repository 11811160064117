import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "../commonBaseApiSlice";

export const settingApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getItemWarning: builder.mutation({
      query: (data) => ({
        url: `/itemwarningmaster/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    createItemWarning: builder.mutation({
      query: (data) => ({
        url: "/itemwarningmaster/create",
        method: "POST",
        body: data,
      }),
    }),
    editItemWarning: builder.mutation({
      query: (data) => ({
        url: "/itemwarningmaster/edit",
        method: "PUT",
        body: data,
      }),
    }),
    getItemWarningById: builder.mutation({
      query: (id) => ({
        url: `/itemwarningmaster/getbyid?id=${id}`,
        method: "GET",
      }),
    }),
    deleteItemWarning: builder.mutation({
      query: (data) => ({
        url: `/itemwarningmaster/delete?${getFilters(data)}`,
        method: "DELETE",
      }),
    }),
    duplicateItemWarning: builder.mutation({
      query: (data) => ({
        url: `/itemwarningmaster/duplicate?${getFilters(data)}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetItemWarningMutation,
  useCreateItemWarningMutation,
  useEditItemWarningMutation,
  useDeleteItemWarningMutation,
  useGetItemWarningByIdMutation,
  useDuplicateItemWarningMutation,
} = settingApiSlice;
