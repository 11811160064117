import { useEffect, useState } from "react";
import { Badge, Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./itemlisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import duplicateicon from "../../../assets/images/duplicate-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import printicon from "../../../assets/images/print-icon.svg";
import { ReactSVG } from "react-svg";
import useInventory from "./../../../Hooks/useInventory";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setSelectedItem } from "../../../redux/inventory/inventorySlice";
import { checkPermission, toggleClass } from "../../../utils/commonUtils";
import Loading from "../../../components/LoadingPage/Loading";
import { DeleteConfirmModel } from "./popUpModels/deleteConfModel";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { DuplicateItemModel } from "./popUpModels/duplicateItemModel";
import { PrintBarcodePopUp } from "../InventoryProfile/printBarcodePopUp/printBarcodePopUp";
import moment from "moment";
import ItemListingTitle from "../../../components/itemListingTitle/itemListingTitle";
import {
  PERMISSION_IDENTIFIERS,
  PERMISSION_MODULES,
} from "../../../Hooks/constants";
const ItemListing = () => {
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { deleteInventoryItemAPI, deleteInventoryItemLoading, barcodePrint } =
    useInventory();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const { getInventoryItemsList, inventoryItemListLoading, getBarcodesAPI } =
    useInventory();
  const [itemsList, setItemsList] = useState([]);
  const [currentItem, setCurrentItem] = useState<any>();
  const [showBarcodeModal, setShowBarcodeModal] = useState(false);
  const [selectedItemRow, setSelectedItemRow] = useState<any>();
  const [defaultBarcodes, setDefaultBarcodes] = useState<any>();
  const defaultFilter = { searchText: "", active: true };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const toggleBarcodeModal = () => {
    toggleClass();
    setShowBarcodeModal(!showBarcodeModal);
  };
  const getBarcodeList = () => {
    if (selectedItemRow?.id) {
      getBarcodesAPI(selectedItemRow?.id).then((res: any) => {
        if (res) {
          setDefaultBarcodes(res.find((x: any) => x.isDefault));
        }
      });
    }
  };
  useEffect(() => {
    getBarcodeList();
  }, [selectedItemRow?.id]);

  const printBarcode = (label: string, barcode: string) => {
    barcodePrint({
      lableId: label,
      id: barcode,
    }).then((res: any) => {
      window.open(res.data.result, "_blank", "noopener,noreferrer");
    });
  };
  const navigateToView = (item: any) => {
    dispatch(setSelectedItem(item));
    navigate("/view-inventory");
  };
  const toggleModal = (item: any) => {
    setCurrentItem(item);
    toggleClass();
    setShowModal(!showModal);
  };
  const toggleDuplicateModal = () => {
    toggleClass();
    setShowDuplicateModal(!showDuplicateModal);
  };
  const getListItems = () => {
    getInventoryItemsList(searchFieldVal).then((res: any) => {
      if (res) {
        setItemsList(res);
      } else {
        setItemsList([]);
      }
    });
  };
  useEffect(() => {
    dispatch(setSelectedItem(null));
    getListItems();
  }, [searchFieldVal]);

  const editItem = (item: any) => {
    dispatch(setSelectedItem(item));
    navigate("/edit-inventory");
  };
  const onClose = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const deleteItem = async () => {
    await deleteInventoryItemAPI({
      id: currentItem?.id,
      deletedBy: currentUserId,
    });
    onClose();
    getListItems();
  };

  return (
    <div className="item-listing-wrapper">
      {inventoryItemListLoading || deleteInventoryItemLoading ? (
        <Loading />
      ) : null}
      <ItemListingTitle
        title={"Inventory"}
        buttonTitle="Add Item"
        href="/create-inventory"
        disRecords={itemsList.length || 0}
        totalRecords={itemsList.length || 0}
        searchVal={searchFieldVal}
        setSearchVal={setSearchFieldVal}
        defaultFilter={defaultFilter}
        isAddPermitted={checkPermission(
          PERMISSION_MODULES.INVENTORY,
          PERMISSION_IDENTIFIERS.ADD_INVENTORY_ITEM,
        )}
      />
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>rXSRX ID</th>
              <th>sTOCK</th>
              <th>rx NAME</th>
              <th>manufacturer</th>
              <th>ndc</th>
              <th>lot</th>
              <th>expiry date</th>
              <th>Rx Group</th>
              <th>CVX</th>
              <th>Rcal</th>
              <th>STATUS</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <>
              {itemsList?.map((x: any) => (
                <tr
                  key={x.id}
                  onDoubleClick={() => {
                    navigateToView(x);
                  }}
                >
                  <td>{x.rxSRXId}</td>
                  <td>{x.stock.stockName}</td>
                  <td title={x.rxName}>{x.rxName}</td>
                  <td title={x.manufacturer}>{x.manufacturer}</td>
                  <td title={x.ndcCode}>{x.ndcCode}</td>
                  <td title={x.lotNo}>{x.lotNo}</td>
                  <td>
                    {x.isNeverExpire
                      ? "NA"
                      : moment(x.expiryDate).format("MM/DD/YYYY")}
                  </td>
                  <td title={x.drugName}>{x.drugName}</td>
                  <td title={x.cvx}>{x.cvx}</td>
                  <td>{x.isRecalled ? "Yes" : "No"}</td>
                  <td>
                    <span
                      className={
                        "status-circle " + (x.isActive ? "active" : "inactive")
                      }
                    >
                      {/* <Badge
                      pill
                      bg={x.isActive ? "success" : "danger"}
                      className="status-badge"
                    >
                      &nbsp;
                    </Badge> */}
                      {x.isActive ? "Active" : "InActive"}
                    </span>
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="link" id="dropdown-basic">
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={threedotvertical}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            navigateToView(x);
                          }}
                        >
                          <ReactSVG
                            className="svg-box"
                            wrapper="span"
                            src={viewicon}
                          />
                          View
                        </Dropdown.Item>
                        {checkPermission(
                          PERMISSION_MODULES.INVENTORY,
                          PERMISSION_IDENTIFIERS.EDIT_INVENTORY_ITEM,
                        ) && (
                          <Dropdown.Item
                            onClick={() => {
                              editItem(x);
                            }}
                          >
                            <ReactSVG
                              className="svg-box"
                              wrapper="span"
                              src={editicon}
                            />
                            Edit
                          </Dropdown.Item>
                        )}
                        {checkPermission(
                          PERMISSION_MODULES.INVENTORY,
                          PERMISSION_IDENTIFIERS.DELETE_INVENTORY_ITEM,
                        ) && (
                          <Dropdown.Item
                            onClick={() => {
                              toggleModal(x);
                            }}
                          >
                            <ReactSVG
                              className="svg-box"
                              wrapper="span"
                              src={deleteicon}
                            />
                            Delete
                          </Dropdown.Item>
                        )}
                        {checkPermission(
                          PERMISSION_MODULES.INVENTORY,
                          PERMISSION_IDENTIFIERS.DUPLICATE_INVENTORY_ITEM,
                        ) && (
                          <Dropdown.Item
                            onClick={() => {
                              setCurrentItem(x);
                              toggleDuplicateModal();
                            }}
                          >
                            <ReactSVG
                              className="svg-box"
                              wrapper="span"
                              src={duplicateicon}
                            />
                            Duplicate
                          </Dropdown.Item>
                        )}

                        <Dropdown.Item
                          onClick={() => {
                            setSelectedItemRow(x);
                            toggleBarcodeModal();
                          }}
                        >
                          <ReactSVG
                            className="svg-box"
                            wrapper="span"
                            src={printicon}
                          />
                          Print Barcode
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </>
          </tbody>
        </Table>
      </div>
      <PrintBarcodePopUp
        showBarcodeModal={showBarcodeModal}
        toggleBarcodeModal={toggleBarcodeModal}
        printBarcode={printBarcode}
        defaultBarcodes={defaultBarcodes}
      />
      <DuplicateItemModel
        selectedItem={currentItem}
        toggleDuplicateModal={toggleDuplicateModal}
        showModal={showDuplicateModal}
        reloadListing={getListItems}
      />
      <DeleteConfirmModel
        showModal={showModal}
        onClose={onClose}
        item={currentItem}
        deleteItem={deleteItem}
      />
    </div>
  );
};

export default ItemListing;
