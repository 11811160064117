import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "../commonBaseApiSlice";
export interface OPPayload {
  productNo: string;
  productDescription: string;
  unitId: string;
  defaultVendorId: null | string;
  purchaseUOM: string;
  conversionFactor: number;
  externalProductNo: string;
  isActive: boolean;
  createdBy: string | null;
}

export const OPMApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOPs: builder.mutation({
      query: (data) => ({
        url: `/orderingproductmaster/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    createOP: builder.mutation({
      query: (data) => ({
        url: "/orderingproductmaster/create",
        method: "POST",
        body: data,
      }),
    }),
    editOP: builder.mutation({
      query: (data) => ({
        url: "/orderingproductmaster/edit",
        method: "PUT",
        body: data,
      }),
    }),
    setOPStatusActive: builder.mutation({
      query: (data) => ({
        url: `/orderingproductmaster/activate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    setOPStatusDeActive: builder.mutation({
      query: (data) => ({
        url: `/orderingproductmaster/deactivate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    deleteOP: builder.mutation({
      query: (data) => ({
        url: `/orderingproductmaster/delete?${getFilters(data)}`,
        method: "DELETE",
      }),
    }),
    duplicateOP: builder.mutation({
      query: (data) => ({
        url: `/orderingproductmaster/duplicate?${getFilters(data)}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetOPsMutation,
  useCreateOPMutation,
  useEditOPMutation,
  useDeleteOPMutation,
  useSetOPStatusActiveMutation,
  useSetOPStatusDeActiveMutation,
  useDuplicateOPMutation,
} = OPMApiSlice;
