import { useEffect, useState } from "react";
import "./breadcrum.scss";
import { Link, useLocation } from "react-router-dom";
import { IMenuItem } from "./../../global/Sidebar/MenuItems";
import { getSideBarActiveDetails } from "./../../utils/commonUtils";
import { selectedInventoryItem } from "../../redux/inventory/inventorySlice";
import { useAppSelector } from "../../redux/hooks";

const Breadcrum = () => {
  const initialMenus: IMenuItem = {
    name: null,
  };
  const selectedItem = useAppSelector(selectedInventoryItem);
  const [childPath, setChildPath] = useState<IMenuItem>(initialMenus);
  const [mainPath, setMainPath] = useState<IMenuItem>(initialMenus);
  const [pagePath, setPagePath] = useState<IMenuItem>(initialMenus);
  const location = useLocation();

  useEffect(() => {
    const { menuItemDetails, subMenuDetails, pageDetails } =
      getSideBarActiveDetails(location.pathname);
    setMainPath(menuItemDetails || initialMenus);
    setChildPath(subMenuDetails || initialMenus);
    setPagePath(pageDetails || initialMenus);
  }, [location]);

  return (
    <div className="breadcrum-box">
      <ul>
        <li>
          <Link to="#">
            {mainPath?.name && (
              <>
                {mainPath?.name} <i className="bi bi-chevron-right"></i>
              </>
            )}
          </Link>
        </li>
        <li>
          <Link to={childPath.path || "#"} className="active">
            {childPath?.name}
            {pagePath?.name ? <i className="bi bi-chevron-right"></i> : null}
          </Link>
        </li>
        {pagePath?.name ? (
          <li>
            <Link to={pagePath.path || "/"} className="active">
              {pagePath.path === "/view-inventory"
                ? selectedItem?.rxName || ""
                : pagePath.name}
            </Link>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default Breadcrum;
