import Form from "react-bootstrap/Form";
import "./addpatients.scss";
import DatePicker from "react-date-picker";
import { Button } from "react-bootstrap";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import usePatient from "../../../Hooks/usePatient";
import Loading from "../../../components/LoadingPage/Loading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";

const AddPatients = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const {
    createPatientLoading,
    createPatientAPI,
    getPatientByIdAPI,
    getPatientByIdLoading,
    editPatientAPI,
    editPatientLoading,
  } = usePatient();
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const [dynamicVal, setDynamicVal] = useState({
    isActive: true,
    createdBy: currentUserId,
    patientCode: "",
    firstName: "",
    mi: "",
    lastName: "",
    dob: null,
    gender: "Male",
  });
  useEffect(() => {
    if (location.pathname !== "/add-patient") {
      setIsEdit(true);
      if (id) {
        getPatientByIdAPI(id)
          .then((data: any) => {
            setDynamicVal({
              isActive: data.isActive,
              createdBy: data.createdBy,
              patientCode: data.patientCode,
              firstName: data.firstName,
              mi: data.mi,
              lastName: data.lastName,
              dob: data.dob,
              gender: data.gender,
            });
          })
          .catch();
      } else {
        navigate("/patient-master");
      }
    }
  }, []);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: dynamicVal,
    validationSchema: Yup.object({
      patientCode: Yup.string().required("Patient ID is Required"),
      mi: Yup.string().max(
        1,
        "Middle Initials should not be more than 1 character.",
      ),
    }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        if (isEdit) {
          await editPatientAPI({ ...values, id: id });
        } else {
          await createPatientAPI(values);
        }
        navigate("/patient-master");
      } catch (err: any) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  const handleGenderChange = (checked: boolean, value: string) => {
    if (checked) {
      handleChange("gender")(value);
    } else {
      handleChange("gender")("");
    }
  };
  return (
    <div className="add-patients-wrapper">
      {createPatientLoading || getPatientByIdLoading || editPatientLoading ? (
        <Loading />
      ) : null}
      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} Patient</h2>
      </div>
      <div className="add-patients-box">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Label>
              Patient ID<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Patient ID"
              id="patientCode"
              onChange={(e) =>
                handleChange("patientCode")(e.target.value ?? "")
              }
              value={values.patientCode}
              isInvalid={touched.patientCode && !!errors.patientCode}
            />
            {touched.patientCode && errors.patientCode ? (
              <Form.Control.Feedback type="invalid">
                {errors.patientCode}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              id="firstName"
              onChange={(e) => handleChange("firstName")(e.target.value ?? "")}
              value={values.firstName}
              isInvalid={touched.firstName && !!errors.firstName}
            />
            {touched.firstName && errors.firstName ? (
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Middle Initial</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Middle Initial"
              id="mi"
              onChange={(e) => handleChange("mi")(e.target.value ?? "")}
              value={values.mi}
              isInvalid={touched.mi && !!errors.mi}
            />
            {touched.mi && errors.mi ? (
              <Form.Control.Feedback type="invalid">
                {errors.mi}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Last Name"
              id="lastName"
              onChange={(e) => handleChange("lastName")(e.target.value ?? "")}
              value={values.lastName}
              isInvalid={touched.lastName && !!errors.lastName}
            />
            {touched.lastName && errors.lastName ? (
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>DOB</Form.Label>
            <DatePicker
              format="MM/dd/yyyy"
              maxDate={new Date()}
              openCalendarOnFocus={false}
              className={`${
                touched.dob && !!errors.dob ? "is-invalid-border" : ""
              } form-control`}
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              showLeadingZeros={true}
              value={values.dob}
              clearIcon={null}
              onChange={(date: any) =>
                handleChange({
                  target: { name: "dob", value: moment(date).format() },
                })
              }
              calendarIcon={<i className="bi bi-calendar"></i>}
            />
            {touched.dob && errors.dob && (
              <Form.Control.Feedback type="invalid">
                {errors.dob}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Gender</Form.Label>
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="Male"
                name="gender"
                type="radio"
                onChange={(option) =>
                  handleGenderChange(option.target.checked, "Male")
                }
                checked={values.gender === "Male"}
              />
              <Form.Check
                inline
                label="Female"
                name="gender"
                type="radio"
                onChange={(option) =>
                  handleGenderChange(option.target.checked, "Female")
                }
                checked={values.gender === "Female"}
              />
              <Form.Check
                inline
                label="Unknown"
                name="gender"
                type="radio"
                onChange={(option) =>
                  handleGenderChange(option.target.checked, "Unknown")
                }
                checked={values.gender === "Unknown"}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-4 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => navigate("/patient-master")}
            >
              Cancel
            </Button>
            <Button type="submit">{isEdit ? "Update" : "Add"}</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default AddPatients;
