import "./patientprofile.scss";
import PatientsMaterProfileHeader from "../../../components/PatientsMaterProfileHeader/PatientsMaterProfileHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import usePatient from "../../../Hooks/usePatient";
import { Patient } from "../../../redux/patient/patientSlice";
import Loading from "../../../components/LoadingPage/Loading";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import { toggleClass } from "../../../utils/commonUtils";
import moment from "moment";
const PatientProfile = () => {
  const { id } = useParams();
  const [patientDetails, setPatientDetails] = useState<Patient>();
  const { getPatientByIdAPI, getPatientByIdLoading } = usePatient();
  const { deletePatientAPI, deletePatientLoading } = usePatient();

  const currentUserId = useAppSelector(selectCurrentUserId);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    getPatientByIdAPI(id ?? "").then((res: Patient) => {
      if (res) {
        setPatientDetails(res);
      }
    });
  }, []);
  const deletePatient = async () => {
    await deletePatientAPI({
      id: patientDetails?.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    navigate("/patient-master");
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  return (
    <div className="profile-page-wrapper">
      {getPatientByIdLoading || deletePatientLoading ? <Loading /> : null}
      <PatientsMaterProfileHeader
        title={`${patientDetails?.firstName ?? "-"} ${patientDetails?.lastName ?? "-"}`}
        id={id}
        toggleModal={toggleModal}
      />
      <div className="profile-detail-box-wrapper">
        <div className="profile-detail-box">
          <div className="profile-info-box">
            <label>Patient ID</label>
            <p>{patientDetails?.patientCode ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>First Name</label>
            <p>{patientDetails?.firstName ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Middle Initial</label>
            <p>{patientDetails?.mi ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Last Name</label>
            <p>{patientDetails?.lastName ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>DOB</label>
            <p>
              {patientDetails?.dob
                ? moment(patientDetails.dob).format("L")
                : "-"}
            </p>
          </div>
          <div className="profile-info-box">
            <label>Gender</label>
            <p>{patientDetails?.gender ?? "-"}</p>
          </div>
        </div>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deletePatient}
        recordName={patientDetails?.patientCode || ""}
        modelName={`patient`}
      />
    </div>
  );
};

export default PatientProfile;
