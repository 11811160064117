import CustomModal from "../../../../../components/customModal/CustomModal";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";

export const InventoryAdjustModel = ({
  locationDropDownVal,
  selectedItem,
  selectCurrentUserId,
  toggleInventoryAdjustModal,
  inventoryTransactionsList,
  invTransAdjust,
  activeLocation,
}: any) => {
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      quantity: "",
      referenceNumber: "",
      transactionId: "",
      verifyDate: new Date(),
      locationId: activeLocation ?? "",
      itemId: selectedItem.id,
      userId: selectCurrentUserId,
    },
    validationSchema: Yup.object({
      quantity: Yup.number()
        .min(0, "Quantity must be a positive number")
        .required("Quantity is Required"),
      locationId: Yup.string().required("Location is Required"),
      transactionId: Yup.string().required("Transaction Type is Required"),
    }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        invTransAdjust(values);
      } catch (err: any) {}
      setSubmitting(false);
    },
  });

  return (
    <CustomModal
      title="Adjust Inventory"
      content={
        <div className="modal--content--wrapper">
          <div className="modal--inputbox">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4">
                <Form.Label>Select Location</Form.Label>
                <Select
                  classNamePrefix="react-select"
                  options={locationDropDownVal}
                  id="locationId"
                  name="locationId"
                  value={locationDropDownVal?.find(
                    (option: any) => option.value === values.locationId,
                  )}
                  onChange={(option: any) =>
                    handleChange("locationId")(option.value)
                  }
                  className={`${
                    touched.locationId && !!errors.locationId
                      ? "is-invalid-border"
                      : ""
                  } react-select-container `}
                />
                {touched.locationId && errors.locationId && (
                  <Form.Control.Feedback type="invalid">
                    {errors.locationId as string}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Transaction Type</Form.Label>
                <Select
                  classNamePrefix="react-select"
                  options={inventoryTransactionsList}
                  id="transactionId"
                  name="transactionId"
                  value={inventoryTransactionsList?.find(
                    (option: any) => option.id === values.transactionId,
                  )}
                  onChange={(option: any) =>
                    handleChange("transactionId")(option.value)
                  }
                  className={`${
                    touched.transactionId && !!errors.transactionId
                      ? "is-invalid-border"
                      : ""
                  } react-select-container `}
                />
                {touched.transactionId && errors.transactionId && (
                  <Form.Control.Feedback type="invalid">
                    {errors.transactionId as string}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>
                  Quantity ({selectedItem.unit.unitName ?? ""})
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Quantity"
                  id="quantity"
                  name="quantity"
                  onChange={handleChange}
                  value={values.quantity}
                  isInvalid={touched.quantity && !!errors.quantity}
                />
                {touched.quantity && errors.quantity ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.quantity}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Reference Info</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Reference Info"
                  id="referenceNumber"
                  name="referenceNumber"
                  onChange={handleChange}
                  value={values.referenceNumber}
                  isInvalid={
                    touched.referenceNumber && !!errors.referenceNumber
                  }
                />
                {touched.referenceNumber && errors.referenceNumber ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.referenceNumber}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group className="full-width text-end">
                <Button
                  onClick={toggleInventoryAdjustModal}
                  style={{ marginRight: "10px" }}
                  variant="outline-primary"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Adjust
                </Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      }
      onClose={toggleInventoryAdjustModal}
    />
  );
};
