import ReportHeader from "../../../components/reportHeader/ReportHeader";
import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import { Table } from "react-bootstrap";

const LocationsList = () => {
  return (
    <div className="report-content-wrapper">
      <ReportHeader
        reportTitle="SRX Report - List of Locations"
        reportDate="04 April 2024"
      />
      <div className="report-filter-wrapper">
        <div className="report-filter-item-wrapper">
          <div className="filter-item-box filter-checkbox-items">
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="checkbox" />
              <label className="form-check-label">Active</label>
            </div>
          </div>
        </div>
        <div className="report-export-box">
          <button type="button" className="report-export-btn">
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
            Export
          </button>
        </div>
      </div>
      <div className="filter-detail-wrapper">
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-title">
            <h4></h4>
          </div>
          <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>Location ID</th>
                  <th>Location Name</th>
                  <th>Sort Order</th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>169</td>
                  <td>16.11 Testing</td>
                  <td>1</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>9536</td>
                  <td>19th Street</td>
                  <td>1</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>160</td>
                  <td>6 Hills</td>
                  <td>1</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>111</td>
                  <td>Amarillo</td>
                  <td>1</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>169</td>
                  <td>16.11 Testing</td>
                  <td>1</td>
                  <td>Yes</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationsList;
