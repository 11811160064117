import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./../store";
import { UserProfileType } from "./../../redux/user/userApiSlice";
const storedUser = localStorage.getItem("selectedUser");

const initialState: UserProfileType = {
  firstName: "",
  lastName: "",
  photoUrl: "",
  fullName: "",
  phone: localStorage.getItem("phone") ? localStorage.getItem("phone") : "",
  username: "",
  id: "",
  selectedUser: storedUser ? JSON.parse(storedUser) : null,
  npiData: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<UserProfileType>) => {
      const { firstName, lastName, photoUrl, phone, username, id } =
        action.payload;
      state.fullName = `${firstName} ${lastName}`;
      state.photoUrl = photoUrl;
      state.phone = phone;
      state.username = username;
      state.id = id;
      state.firstName = firstName;
    },
    resetUserProfile: (state) => {
      state.fullName = "";
      state.photoUrl = "";
      state.phone = "";
      state.username = "";
      state.id = "";
      state.firstName = "";
      state.lastName = "";
    },
    setSelectedUser: (state, action: PayloadAction<any>) => {
      state.selectedUser = action.payload;
      localStorage.setItem("selectedUser", JSON.stringify(action.payload));
    },
    setSelectedNpiData: (state, action: PayloadAction<any>) => {
      state.npiData = action.payload;
      localStorage.setItem("selectedNpiData", JSON.stringify(action.payload));
    },
  },
});

export const {
  setUserProfile,
  resetUserProfile,
  setSelectedUser,
  setSelectedNpiData,
} = userSlice.actions;

export default userSlice.reducer;

// Selectors
export const selectedUserId = (state: RootState) => state.user.id;
export const selectedUser = (state: RootState) => state.user.selectedUser;
export const selectedNpiData = (state: RootState) => state.user.npiData;
