import { Button, Table } from "react-bootstrap";
import CustomModal from "../../../../../components/customModal/CustomModal";
import { useEffect, useState } from "react";
import useInventory from "../../../../../Hooks/useInventory";
import Loading from "../../../../../components/LoadingPage/Loading";

export const OrderManagementDetails = ({
  showOrderMngtDetailModal,
  toggleOrderMngtDetailModal,
  selectedOrdManItem,
  selectedItem,
}: any) => {
  const [invOrdManGetQtyData, setInvOrdManGetQtyData] = useState([]);
  const { invOrdManGetQtyOnHandGet, invOrdManGetQtyOnHandLoading } =
    useInventory();
  useEffect(() => {
    if (selectedOrdManItem) {
      invOrdManGetQtyOnHandGet({
        RxSRXId: selectedItem.id,
        LocationId: selectedOrdManItem.locationId,
      }).then((res: any) => {
        if (res) {
          setInvOrdManGetQtyData(res);
        }
      });
    }
  }, [selectedOrdManItem]);
  const getTotal = () => {
    let sum = 0;
    invOrdManGetQtyData?.forEach((x: any) => {
      sum = sum + parseInt(x.netqty);
    });
    return sum;
  };
  return (
    <>
      {invOrdManGetQtyOnHandLoading ? <Loading /> : null}
      {showOrderMngtDetailModal && (
        <CustomModal
          id="ordermngtDetailModal"
          title="Details"
          content={
            <div
              id="mngt-order-detail-modal"
              className="modal--content--wrapper"
            >
              <div className="modal--inputbox">
                <Table>
                  <thead>
                    <tr>
                      <th>Barcode</th>
                      <th>EXPIRY DATE</th>
                      <th style={{ textAlign: "right" }}>QUANTITY ON HAND</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invOrdManGetQtyData?.map((x: any) => (
                      <tr key={x.id}>
                        <td>{x.rzsrxid}</td>
                        <td>{x.expiryDate}</td>
                        <td>{x.netqty}</td>
                      </tr>
                    ))}

                    <tr>
                      <td style={{ fontWeight: "bold" }} colSpan={2}>
                        Total
                      </td>
                      <td style={{ textAlign: "right", fontWeight: "bold" }}>
                        {getTotal()}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          }
          footer={
            <div className="modal--action--btn--wrapper text-end">
              <Button
                type="button"
                variant="outline-primary"
                onClick={toggleOrderMngtDetailModal}
              >
                Cancel
              </Button>
              <Button type="button" variant="primary">
                Ok
              </Button>
            </div>
          }
          onClose={toggleOrderMngtDetailModal}
        />
      )}
    </>
  );
};
