import { getErrorMessage } from "../utils/commonUtils";
import {
  useCreateLocationsMutation,
  useDeleteLocationMutation,
  useEditLocationsMutation,
  useGetLocationsMutation,
  useSetLocationStatusActiveMutation,
  useSetLocationStatusDeActiveMutation,
} from "../redux/location/locationApiSlice";
import { toast } from "react-toastify";

export default function useLocations() {
  const [getLocations, { isLoading: getLocationsLoading }] =
    useGetLocationsMutation();
  const [createLocations, { isLoading: createLocationsLoading }] =
    useCreateLocationsMutation();
  const [editLocations, { isLoading: editLocationsLoading }] =
    useEditLocationsMutation();

  const [
    setLocationStatusActive,
    { isLoading: setLocationStatusActiveLoading },
  ] = useSetLocationStatusActiveMutation();
  const [
    setLocationStatusDeActive,
    { isLoading: setLocationStatusDeActiveLoading },
  ] = useSetLocationStatusDeActiveMutation();
  const [deleteLocation, { isLoading: deleteLocationLoading }] =
    useDeleteLocationMutation();
  const getLocationsAPI = async (data: any) => {
    try {
      const response: any = await getLocations(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const createLocationsAPI = async (data: any) => {
    try {
      const response: any = await createLocations(data).unwrap();
      toast.success("Success! Location has been Created.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const editLocationsAPI = async (data: any) => {
    try {
      const response: any = await editLocations(data).unwrap();
      toast.success("Success! Location has been Updated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const activeLocationAPI = async (data: any) => {
    try {
      const response: any = await setLocationStatusActive(data).unwrap();
      toast.success("Success! Location has been Activated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deactiveLocationAPI = async (data: any) => {
    try {
      const response: any = await setLocationStatusDeActive(data).unwrap();
      toast.success("Success! Location has been Deactivated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deleteLocationAPI = async (data: any) => {
    try {
      const response: any = await deleteLocation(data).unwrap();
      toast.success("Success! Location has been Deleted.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    getLocationsAPI,
    getLocationsLoading,
    createLocationsLoading,
    createLocationsAPI,
    editLocationsAPI,
    editLocationsLoading,
    activeLocationAPI,
    setLocationStatusActiveLoading,
    deactiveLocationAPI,
    setLocationStatusDeActiveLoading,
    deleteLocationAPI,
    deleteLocationLoading,
  };
}
