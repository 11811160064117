import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./../store";
export interface ItemTypeData {
  selectedItemType: any;
}
const storedItemType = localStorage.getItem("selectedItemType");
const initialState: ItemTypeData = {
  selectedItemType: storedItemType ? JSON.parse(storedItemType) : null,
};

const itemTypeSlice = createSlice({
  name: "itemType",
  initialState,
  reducers: {
    setSelectedItemType: (state, action: PayloadAction<any>) => {
      state.selectedItemType = action.payload;
      localStorage.setItem("selectedItemType", JSON.stringify(action.payload));
    },
  },
});
export const { setSelectedItemType } = itemTypeSlice.actions;
export default itemTypeSlice.reducer;
export const selectedItemType = (state: RootState) =>
  state.itemType.selectedItemType;
