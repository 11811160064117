import { Button, Dropdown } from "react-bootstrap";
import "./settingprofileheader.scss";
import threedotvertical from "../../assets/images/three-dots-vertical.svg";
import editicon from "../../assets/images/edit-icon.svg";
import deleteicon from "../../assets/images/delete-icon.svg";
import { ReactSVG } from "react-svg";

const SettingProfileHeader = ({ title, id, toggleModal }: any) => {
  return (
    <div className="setting-profile-header-wrapper">
      <div className="profile-title-status-box">
        <h2>{title}</h2>
      </div>
      <div className="profile-actions-box">
        <div className="edit-btn-box">
          <Button variant="primary" href={`/edit-setting/${id}`}>
            <i className="bi bi-pencil-square"></i> Edit
          </Button>
        </div>
        <Dropdown>
          <Dropdown.Toggle variant="link" id="dropdown-basic">
            <ReactSVG
              className="svg-box"
              wrapper="span"
              src={threedotvertical}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={toggleModal}>
              <ReactSVG className="svg-box" wrapper="span" src={deleteicon} />
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default SettingProfileHeader;
