import { Button, Dropdown, Form, Table } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import CustomModal from "../../../../components/customModal/CustomModal";
import threedotvertical from "../../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../../assets/images/edit-icon.svg";
import deleteicon from "../../../../assets/images/delete-icon.svg";
import defaulticon from "../../../../assets/images/default-icon.svg";
import Select from "react-select";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useEffect, useState } from "react";
import { DropDownValues } from "../../../../utils/types";

export const UseUnitsModel = ({
  showUseUnitModal,
  toggleUseUnitModal,
  unitsList,
  invUnitVal,
  usUnits,
  usUnitsList,
}: any) => {
  const [UseUnitsInputsList, setUnitsUnitsInputsList] =
    useState<any[]>(usUnitsList);
  useEffect(() => {
    setUnitsUnitsInputsList(usUnitsList);
  }, [usUnitsList]);
  const {
    handleChange,
    values,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      conversionFactor: 1,
      isDefault: false,
      isActive: true,
      isDeleted: false,
      unitId: "",
    },
    validationSchema: Yup.object({
      unitId: Yup.string().required("Units are required"),
      conversionFactor: Yup.number()
        .required("Value is required")
        .moreThan(0, "Value should be greater than 0"),
    }),
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      await UseUnitsInputs({ ...values });
      resetForm();
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  const UseUnitsInputs = async (orderUnit: any) => {
    const existedOrdUnitIndex = UseUnitsInputsList.findIndex(
      (unit: any) => unit.unitId === orderUnit.unitId,
    );
    if (existedOrdUnitIndex !== -1) {
      setUnitsUnitsInputsList((prevData) =>
        prevData.map((item) =>
          item.unitId === orderUnit.unitId
            ? { ...item, conversionFactor: orderUnit.conversionFactor }
            : item,
        ),
      );
    } else {
      if (UseUnitsInputsList.length > 0) {
        setUnitsUnitsInputsList(UseUnitsInputsList.concat(orderUnit));
      } else {
        setUnitsUnitsInputsList(
          UseUnitsInputsList.concat({ ...orderUnit, isDefault: true }),
        );
      }
    }
    return UseUnitsInputsList;
  };
  const usUnitsInputDel = (id: string) => {
    setUnitsUnitsInputsList(
      UseUnitsInputsList.filter((item: any) => item.unitId !== id),
    );
  };
  const usUnitsInputDef = (id: string) => {
    setUnitsUnitsInputsList((prevData) =>
      prevData.map((item) =>
        item.unitId === id
          ? { ...item, isDefault: true }
          : { ...item, isDefault: false },
      ),
    );
  };
  const setEditVal = (unit: any) => {
    setFieldValue("conversionFactor", unit.conversionFactor);
    setFieldValue("unitId", unit.unitId);
  };
  const clearData = () => {
    resetForm();
    toggleUseUnitModal();
  };
  useEffect(() => {
    usUnits(UseUnitsInputsList);
  }, [UseUnitsInputsList]);
  return (
    <>
      {showUseUnitModal && (
        <CustomModal
          id="useunitmodal"
          title="Use Units"
          content={
            <>
              <div className="add-unit-item-box">
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-4">
                    <Form.Label>1 {invUnitVal} =</Form.Label>
                    <Form.Control
                      id="conversionFactor"
                      name="conversionFactor"
                      type="number"
                      min={1}
                      placeholder=""
                      onChange={handleChange}
                      value={values.conversionFactor}
                      isInvalid={
                        touched.conversionFactor && !!errors.conversionFactor
                      }
                    />
                    {touched.conversionFactor && errors.conversionFactor && (
                      <Form.Control.Feedback type="invalid">
                        {errors.conversionFactor}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Units*</Form.Label>
                    <Select
                      id="unitId"
                      name="unitId"
                      classNamePrefix="react-select"
                      options={unitsList.filter(
                        (unit: DropDownValues) =>
                          unit.label.toLowerCase() !== invUnitVal.toLowerCase(),
                      )}
                      value={
                        unitsList?.find(
                          (option: DropDownValues) =>
                            option.value === values.unitId,
                        ) ?? ""
                      }
                      onChange={(option: any) => {
                        handleChange("unitId")(option.value);
                      }}
                      className="react-select-container"
                    />
                    {touched.unitId && errors.unitId && (
                      <Form.Control.Feedback type="invalid">
                        {errors.unitId}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>&nbsp;</Form.Label>
                    <button
                      className={
                        values.unitId && values.conversionFactor > 0
                          ? "blue-btn"
                          : "add-unit-btn"
                      }
                      type="submit"
                      onClick={handleSubmit as any}
                    >
                      Add / Update
                    </button>
                  </Form.Group>
                </Form>

                <div className="use-unit-item-table-wrapper full-width mb-4">
                  <Table bordered={false} hover={true}>
                    <thead>
                      <tr>
                        <th>Use Units</th>
                        <th>Conv. FACTOR</th>
                        <th>DEFAULT</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {UseUnitsInputsList?.map((unit: any) => (
                        <tr key={unit.unitId}>
                          <td>
                            {
                              unitsList.find(
                                (option: any) => option.value == unit.unitId,
                              )?.label
                            }
                          </td>
                          <td>{unit.conversionFactor}</td>
                          <td>{unit.isDefault ? "Yes" : "No"}</td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="link"
                                id="dropdown-basic"
                              >
                                <ReactSVG
                                  className="svg-box"
                                  wrapper="span"
                                  src={threedotvertical}
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setEditVal(unit)}>
                                  <ReactSVG
                                    className="svg-box"
                                    wrapper="span"
                                    src={editicon}
                                  />
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => usUnitsInputDel(unit.unitId)}
                                >
                                  <ReactSVG
                                    className="svg-box"
                                    wrapper="span"
                                    src={deleteicon}
                                  />
                                  Delete
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => usUnitsInputDef(unit.unitId)}
                                >
                                  <ReactSVG
                                    className="svg-box"
                                    wrapper="span"
                                    src={defaulticon}
                                  />
                                  Make Default
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <Form.Group className="full-width text-end">
                  <Button
                    style={{ marginRight: "10px" }}
                    variant="outline-primary"
                    onClick={() => {
                      clearData();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => {
                      clearData();
                    }}
                  >
                    Ok
                  </Button>
                </Form.Group>
              </div>
            </>
          }
          onClose={clearData}
        />
      )}
    </>
  );
};
