import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "../commonBaseApiSlice";

export const inventoryOrderApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    InvOrderList: builder.mutation({
      query: (data) => ({
        url: `/inventoryorder/getinventoryordersuggestions?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    InvOrderById: builder.mutation({
      query: (data) => ({
        url: `/inventoryorder/getbyid?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    InvOrders: builder.mutation({
      query: (data) => ({
        url: `/inventoryorder/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    invOrderCreate: builder.mutation({
      query: (data) => ({
        url: `/inventoryorder/create`,
        method: "POST",
        body: data,
      }),
    }),
    orderStatusMaster: builder.mutation({
      query: () => ({
        url: `/orderstatusmaster/get`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useInvOrderListMutation,
  useInvOrdersMutation,
  useInvOrderCreateMutation,
  useOrderStatusMasterMutation,
  useInvOrderByIdMutation,
} = inventoryOrderApiSlice;
