import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./unitlisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import deactivateicon from "../../../assets/images/deactivate-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import ListingTitle from "../../../components/listingTitle/ListingTitle";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import useUnits from "../../../Hooks/useUnits";
import { setSelectedUnit } from "../../../redux/units/unitsSlice";
import Loading from "../../../components/LoadingPage/Loading";
import { toggleClass } from "../../../utils/commonUtils";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import ItemListingTitle from "../../../components/itemListingTitle/itemListingTitle";
const UnitListing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { getUnitsAPI, getUnitsLoading } = useUnits();
  const { activeUnitAPI, setUnitStatusActiveLoading } = useUnits();
  const { deactiveUnitAPI, setUnitStatusDeActiveLoading } = useUnits();
  const { deleteUnitAPI, deleteUnitLoading } = useUnits();
  const [showModal, setShowModal] = useState(false);
  const [unitsList, setUnitsList] = useState([]);
  const [currentUnit, setCurrentUnit] = useState<any>();
  const defaultFilter = { searchText: "", active: true };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const deleteUnit = async () => {
    await deleteUnitAPI({
      id: currentUnit.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    getUnits();
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };

  const getUnits = () => {
    getUnitsAPI(searchFieldVal)
      .then((res: any) => setUnitsList(res))
      .catch();
  };
  const editUnit = (Unit: any) => {
    dispatch(setSelectedUnit(Unit));
    navigate("/edit-unit");
  };
  const navigateToView = (Unit: any) => {
    dispatch(setSelectedUnit(Unit));
    navigate("/view-unit");
  };
  const updateStatus = async (Unit: any) => {
    if (Unit.isActive) {
      await deactiveUnitAPI({ id: Unit.id, updatedBy: currentUserId ?? "" });
    } else {
      await activeUnitAPI({ id: Unit.id, updatedBy: currentUserId ?? "" });
    }
    getUnits();
  };
  useEffect(() => {
    getUnits();
  }, [searchFieldVal]);
  return (
    <div className="unit-listing-wrapper">
      {getUnitsLoading ||
      setUnitStatusActiveLoading ||
      setUnitStatusDeActiveLoading ||
      deleteUnitLoading ? (
        <Loading />
      ) : null}
      <ItemListingTitle
        title="List of Units"
        buttonTitle="Add New"
        href="/add-unit"
        disRecords={unitsList.length || 0}
        totalRecords={unitsList.length || 0}
        searchVal={searchFieldVal}
        setSearchVal={setSearchFieldVal}
        defaultFilter={defaultFilter}
        isAddPermitted={true}
      />
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>Unit ID</th>
              <th>Unit Name</th>
              <th>Sort Order</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {unitsList?.map((x: any) => (
              <tr
                key={x.id}
                onDoubleClick={() => {
                  navigateToView(x);
                }}
              >
                <td>{x.unitCode}</td>
                <td title={x.unitName}>{x.unitName}</td>
                <td>{x.sortOrder}</td>
                <td>
                  <span
                    className={`status-circle ${x.isActive ? "active" : "inactive"}`}
                  >
                    {x.isActive ? "Active" : "InActive"}
                  </span>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigateToView(x)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={viewicon}
                        />
                        View
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          updateStatus(x);
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deactivateicon}
                        />
                        {x.isActive ? "Deactivate" : "Activate"}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => editUnit(x)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentUnit(x);
                          toggleModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteUnit}
        recordName={currentUnit?.unitName || ""}
        modelName={`unit`}
      />
    </div>
  );
};

export default UnitListing;
