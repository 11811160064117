import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./warningitemlisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import duplicateicon from "../../../assets/images/duplicate-icon.svg";
import { ReactSVG } from "react-svg";
import { toggleClass } from "../../../utils/commonUtils";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import useItemWarning from "../../../Hooks/useItemWarning";
import Loading from "../../../components/LoadingPage/Loading";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import { ItemWarning } from "../../../redux/itemWarning/itemWarningSlice";
import { DuplicateItemModel } from "./popUpModels/duplicateItemModel";
import CommonSearchOnly from "../../../components/commonSearchOnly/commonSearchOnly";
const WarningItemListing = () => {
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const navigate = useNavigate();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const defaultFilter = { searchText: "" };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const {
    getItemWarningAPI,
    getItemWarningLoading,
    duplicateItemWarningAPI,
    duplicateItemWarningLoading,
  } = useItemWarning();

  const { deleteItemWarningAPI, deleteItemWarningLoading } = useItemWarning();
  const [showModal, setShowModal] = useState(false);
  const [ItemWarningList, setItemWarningList] = useState<ItemWarning[]>([]);
  const [currentItemWarning, setCurrentItemWarning] = useState<ItemWarning>();
  const [currentItem, setCurrentItem] = useState<any>();
  const deleteItemWarning = async () => {
    await deleteItemWarningAPI({
      id: currentItemWarning?.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    getItemWarning();
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };

  const getItemWarning = () => {
    getItemWarningAPI(searchFieldVal)
      .then((res: any) => setItemWarningList(res))
      .catch();
  };
  useEffect(() => {
    getItemWarning();
  }, [searchFieldVal]);
  const handleDuplicate = async () => {
    await duplicateItemWarningAPI({
      id: currentItem.id,
      createdBy: currentUserId ?? "",
    });
    toggleDuplicateModal();
  };
  const toggleDuplicateModal = () => {
    toggleClass();
    setShowDuplicateModal(!showDuplicateModal);
    getItemWarning();
  };

  return (
    <div className="patients-listing-wrapper">
      {getItemWarningLoading ||
      deleteItemWarningLoading ||
      duplicateItemWarningLoading ? (
        <Loading />
      ) : null}
      <CommonSearchOnly
        title="Item Warning Master"
        buttonTitle="Add New"
        href="/add-item-warning"
        disRecords={ItemWarningList.length | 0}
        totalRecords={ItemWarningList.length | 0}
        searchVal={searchFieldVal}
        setSearchVal={setSearchFieldVal}
        defaultFilter={defaultFilter}
      />
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>Item Code</th>
              <th>Warning</th>
              <th>Warning Type</th>
              <th>Active</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {ItemWarningList?.map((x: ItemWarning) => (
              <tr
                key={x.id}
                onDoubleClick={() => navigate(`/item-warning-profile/${x.id}`)}
              >
                <td title={x.itemCode ?? "-"}>{x.itemCode ?? "-"}</td>
                <td title={x.warning}>{x.warning ?? "-"}</td>
                <td>{x.warningType ?? "-"}</td>
                <td>
                  <span
                    className={`status-circle ${x.isActive ? "active" : "inactive"}`}
                  >
                    {x.isActive ? "Yes" : "No"}
                  </span>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href={`/item-warning-profile/${x.id}`}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={viewicon}
                        />
                        View
                      </Dropdown.Item>
                      <Dropdown.Item href={`/edit-item-warning/${x.id}`}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentItemWarning(x);
                          toggleModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentItem(x);
                          toggleDuplicateModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={duplicateicon}
                        />
                        Duplicate
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteItemWarning}
        recordName={currentItemWarning?.itemCode || ""}
        modelName={`item`}
      />
      <DuplicateItemModel
        selectedItem={currentItem}
        toggleDuplicateModal={toggleDuplicateModal}
        showModal={showDuplicateModal}
        handleDuplicate={handleDuplicate}
      />
    </div>
  );
};

export default WarningItemListing;
