import LocationProfileHeader from "../../../components/locationprofileheader/LocationProfileHeader";
import {
  selectedLocation,
  setSelectedLocation,
} from "../../../redux/location/locationSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Loading from "../../../components/LoadingPage/Loading";
import { useNavigate } from "react-router-dom";
import useLocations from "../../../Hooks/useLocations";
import { useState } from "react";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { toggleClass } from "../../../utils/commonUtils";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
const LocationProfile = () => {
  const selectedLocationData = useAppSelector(selectedLocation);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeLocationAPI, setLocationStatusActiveLoading } = useLocations();
  const { deactiveLocationAPI, setLocationStatusDeActiveLoading } =
    useLocations();
  const { deleteLocationAPI, deleteLocationLoading } = useLocations();
  const [showModal, setShowModal] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const updateStatus = () => {
    if (selectedLocationData.isActive) {
      deactiveLocationAPI({
        id: selectedLocationData.id,
        updatedBy: currentUserId ?? "",
      })
        .then((res) => {
          if (res) {
            dispatch(setSelectedLocation(res));
          }
        })
        .catch();
    } else {
      activeLocationAPI({
        id: selectedLocationData.id,
        updatedBy: currentUserId ?? "",
      })
        .then((res) => {
          if (res) {
            dispatch(setSelectedLocation(res));
          }
        })
        .catch();
    }
  };
  const deleteLocation = async () => {
    await deleteLocationAPI({
      id: selectedLocationData.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    navigate("/location-master");
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };

  return (
    <div className="profile-page-wrapper">
      {(setLocationStatusActiveLoading ||
        setLocationStatusDeActiveLoading ||
        deleteLocationLoading) && <Loading />}
      <LocationProfileHeader
        selectedLocationData={selectedLocationData}
        updateStatus={updateStatus}
        toggleModal={toggleModal}
      />
      <div className="profile-detail-box-wrapper">
        <div className="profile-detail-box">
          <div className="profile-info-box">
            <label>Source Code ID</label>
            <p>{selectedLocationData.locationCode}</p>
          </div>
          <div className="profile-info-box">
            <label>Source Code Name</label>
            <p>{selectedLocationData.locationName}</p>
          </div>
          <div className="profile-info-box">
            <label>Sort Order</label>
            <p>{selectedLocationData.sortOrder}</p>
          </div>
          <div className="profile-info-box">
            <label>Status</label>
            <p> {selectedLocationData.isActive ? "Active" : "InActive"}</p>
          </div>
        </div>
      </div>

      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteLocation}
        recordName={selectedLocationData?.locationName || ""}
        modelName={`location`}
      />
    </div>
  );
};

export default LocationProfile;
