import "./apimsglog.scss";
import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { Dropdown, Form, Table } from "react-bootstrap";
import threedotvertical from "../../assets/images/three-dots-vertical.svg";
import viewicon from "../../assets/images/view-icon.svg";
import CustomModal from "../../components/customModal/CustomModal";
import { ReactSVG } from "react-svg";
import useRecordUsage from "../../Hooks/useRecordUsage";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import {
  ApiLog,
  MessageLog,
  TypeLog,
} from "../../redux/recordUsage/recordUsageApiSlice";
import { DropDownValues } from "../../utils/types";
import Select from "react-select";
import moment from "moment";
import Loading from "../../components/LoadingPage/Loading";
const ApiMsgLog = () => {
  const [typeOptions, setTypeOptions] = useState([]);
  const [errorCodeOptions, setErrorCodeOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<ApiLog>();
  const [logList, setLogList] = useState<ApiLog[]>([]);
  const initialForm = {
    message: "",
    barcode: "",
    type: "",
    fromDate: moment().subtract(7, "days").toISOString(),
    toDate: moment().toISOString(),
    rowCount: 10,
  };
  const [dynamicForm, setDynamicForm] = useState(initialForm);
  const { logDDCalAPI, logDDCalLoading, getLogListAPI, getLogListLoading } =
    useRecordUsage();

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const getList = (values: any) => {
    getLogListAPI(values).then((res) => setLogList(res));
  };
  useEffect(() => {
    logDDCalAPI().then((res) => {
      if (res?.messages) {
        setErrorCodeOptions(
          res?.messages.map((item: MessageLog) => {
            return {
              label: item.value,
              value: item.key,
            };
          }),
        );
      }
      if (res?.types) {
        setTypeOptions(
          res?.types.map((item: TypeLog) => {
            return {
              label: item.value,
              value: item.key,
            };
          }),
        );
      }
    });
    getList(initialForm);
  }, []);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: dynamicForm,
    validationSchema: Yup.object({
      rowCount: Yup.number()
        .integer("Positive value only")
        .positive("Positive value only")
        .required("Positive value only"),
      toDate: Yup.string().required("End Date is Required"),
      fromDate: Yup.string().required("Start Date is Required"),
    }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        getList(values);
      } catch (err: any) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  const clearAllFilter = () => {
    setDynamicForm(initialForm);
    getList(initialForm);
  };
  useEffect(() => {
    handleSubmit();
  }, [values]);
  return (
    <div className="api-log-content-wrapper">
      {logDDCalLoading || getLogListLoading ? <Loading /> : null}
      <div className="api-log-header">
        <h2>API Message Log</h2>
      </div>
      <div className="report-filter-wrapper">
        <Form onSubmit={handleSubmit}>
          <div className="report-filter-item-wrapper">
            <div className="filter-item-box">
              <Form.Label className="form-label"># of Records</Form.Label>
              <Form.Control
                type="number"
                min={0}
                className="form-control"
                placeholder="No. of Records"
                id="rowCount"
                onChange={(e: any) => {
                  handleChange("rowCount")(e.target.value);
                }}
                isInvalid={touched.rowCount && !!errors.rowCount}
                value={values.rowCount}
              />
              {touched.rowCount && errors.rowCount ? (
                <Form.Control.Feedback type="invalid">
                  {errors.rowCount}
                </Form.Control.Feedback>
              ) : null}
            </div>
            <div className="filter-item-box type-filter-item">
              <Form.Label className="form-label">Error Code</Form.Label>
              <Select
                isSearchable={true}
                classNamePrefix="react-select"
                options={errorCodeOptions}
                className="react-select-container"
                id="message"
                name="message"
                value={errorCodeOptions?.find(
                  (option: DropDownValues) => option.value === values.message,
                )}
                onChange={(option: any) => {
                  handleChange("message")(option.value);
                }}
              />
            </div>
            <div className="filter-item-box">
              <Form.Label className="form-label">Barcode</Form.Label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Barcode"
                id="barcode"
                onChange={(e) => {
                  handleChange("barcode")(e.target.value ?? "");
                  handleSubmit();
                }}
                value={values.barcode}
              />
            </div>
            <div className="filter-item-box">
              <label className="form-label">Type</label>
              <Select
                isSearchable={true}
                classNamePrefix="react-select"
                options={typeOptions}
                className="react-select-container"
                id="type"
                name="type"
                value={typeOptions?.find(
                  (option: DropDownValues) => option.value === values.type,
                )}
                onChange={(option: any) => {
                  handleChange("type")(option.value);
                }}
              />
            </div>
            <div className="filter-item-box">
              <Form.Label className="form-label">Start Date</Form.Label>
              <DatePicker
                format="MM/dd/yyyy"
                openCalendarOnFocus={false}
                value={values.fromDate}
                showLeadingZeros={true}
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                clearIcon={null}
                calendarIcon={<i className="bi bi-calendar"></i>}
                maxDate={values.toDate ? new Date(values.toDate) : new Date()}
                onChange={(date) => {
                  handleChange({
                    target: { name: "fromDate", value: date },
                  });
                }}
                className={`${
                  touched.fromDate && !!errors.fromDate
                    ? "is-invalid-border"
                    : ""
                } form-control`}
              />
              {touched.fromDate && errors.fromDate && (
                <Form.Control.Feedback type="invalid">
                  {errors.fromDate}
                </Form.Control.Feedback>
              )}
            </div>
            <div className="filter-item-box">
              <Form.Label className="form-label">End Date</Form.Label>
              <DatePicker
                format="MM/dd/yyyy"
                openCalendarOnFocus={false}
                value={values.toDate}
                showLeadingZeros={true}
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                clearIcon={null}
                calendarIcon={<i className="bi bi-calendar"></i>}
                maxDate={new Date()}
                minDate={new Date(values.fromDate)}
                onChange={(date) => {
                  handleChange({
                    target: { name: "toDate", value: date },
                  });
                }}
                className={`${
                  touched.toDate && !!errors.toDate ? "is-invalid-border" : ""
                } form-control`}
              />
              {touched.toDate && errors.toDate && (
                <Form.Control.Feedback type="invalid">
                  {errors.toDate}
                </Form.Control.Feedback>
              )}
            </div>
            <div className="filter-item-box clear-all-box">
              <button
                type="button"
                className="clear-filter-btn"
                onClick={clearAllFilter}
              >
                Clear All
              </button>
            </div>
          </div>
        </Form>
      </div>
      <div className="filter-detail-wrapper">
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>Request Date</th>
                  <th>BARCODE</th>
                  <th>Rx name</th>
                  <th>Ndc code</th>
                  <th>Units</th>
                  <th>LOCATION</th>
                  <th>Query</th>
                  <th>User ID</th>
                  <th>HCP ID</th>
                  <th>Error Message</th>
                  <th>Comments</th>
                  <th>Record Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {logList?.map((item: ApiLog) => (
                  <tr key={item.id}>
                    <td>
                      {moment(item.eDate).format("MM/DD/YYYY hh:mm:ss A")}
                    </td>
                    <td>{item.barcode}</td>
                    <td>{item.rxName}</td>
                    <td>{item.ndcCode}</td>
                    <td>{item.units}</td>
                    <td>{item.location}</td>
                    <td>
                      <span
                        className={`status-tag
          ${item?.isActive ? "active" : "inactive"}`}
                      >
                        {item?.isActive ? "True" : "False"}
                      </span>
                    </td>
                    <td>{item.userId}</td>
                    <td>{item.hcpId}</td>
                    <td title={item.message}>{item.message}</td>
                    <td title={item.comment}>{item.comment}</td>
                    <td>{item.recordType}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="link" id="dropdown-basic">
                          <ReactSVG
                            className="svg-box"
                            wrapper="span"
                            src={threedotvertical}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedRow(item);
                              toggleModal();
                            }}
                          >
                            <ReactSVG
                              className="svg-box"
                              wrapper="span"
                              src={viewicon}
                            />
                            View Params
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {showModal && (
        <CustomModal
          id="param_detail_modal"
          title="Params Details"
          content={
            <>
              <div className="modal--content--wrapper">
                <div className="param-content-box">
                  <h4>Request</h4>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    value={selectedRow?.reqString}
                  />
                </div>
                <div className="param-content-box">
                  <h4>Result</h4>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    value={selectedRow?.resString}
                  />
                </div>
              </div>
            </>
          }
          onClose={toggleModal}
        />
      )}
    </div>
  );
};

export default ApiMsgLog;
