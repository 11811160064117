import "./itemtypeprofile.scss";
import ItemProfileHeader from "../../../components/ItemTypeprofileheader/ItemProfileHeader";
import {
  selectedItemType,
  setSelectedItemType,
} from "../../../redux/itemType/itemTypeSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useItemTypes from "../../../Hooks/useItemTypes";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import Loading from "../../../components/LoadingPage/Loading";
import { toggleClass } from "../../../utils/commonUtils";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
const ItemTypeProfile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedTypeData = useAppSelector(selectedItemType);
  const { activeItemTypeAPI, setItemStatusActiveLoading } = useItemTypes();
  const { deActiveItemTypeAPI, setItemStatusDeActiveLoading } = useItemTypes();
  const { deleteTypeAPI, deleteTypeLoading } = useItemTypes();
  const { duplicateTypeAPI, duplicateTypeLoading } = useItemTypes();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const updateStatus = () => {
    if (selectedTypeData.isActive) {
      deActiveItemTypeAPI({
        id: selectedTypeData.id,
        updatedBy: currentUserId ?? "",
      })
        .then((res) => {
          if (res) {
            dispatch(setSelectedItemType(res));
          }
        })
        .catch();
    } else {
      activeItemTypeAPI({
        id: selectedTypeData.id,
        updatedBy: currentUserId ?? "",
      })
        .then((res) => {
          if (res) {
            dispatch(setSelectedItemType(res));
          }
        })
        .catch();
    }
  };
  const deleteType = async () => {
    await deleteTypeAPI({
      id: selectedTypeData.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    navigate("/item-type-master");
  };
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const duplicateType = async () => {
    await duplicateTypeAPI({
      id: selectedTypeData.id,
      updatedBy: currentUserId ?? "",
    });
    navigate("/item-type-master");
  };
  return (
    <div className="profile-page-wrapper">
      {(setItemStatusActiveLoading ||
        setItemStatusDeActiveLoading ||
        deleteTypeLoading ||
        duplicateTypeLoading) && <Loading />}
      <ItemProfileHeader
        selectedTypeData={selectedTypeData}
        updateStatus={updateStatus}
        duplicateType={duplicateType}
        toggleModal={toggleModal}
      />
      <div className="profile-detail-box-wrapper">
        <div className="profile-detail-box">
          <div className="profile-info-box">
            <label>RX Type ID</label>
            <p>{selectedTypeData.rxTypeCode}</p>
          </div>
          <div className="profile-info-box">
            <label>RX Type Name</label>
            <p>{selectedTypeData.rxTypeName}</p>
          </div>
          <div className="profile-info-box">
            <label>Vaccine</label>
            <p>{selectedTypeData.isVaccine ? "Yes" : "No"}</p>
          </div>
          <div className="profile-info-box">
            <label>Sort Order</label>
            <p>{selectedTypeData.sortOrder}</p>
          </div>
          <div className="profile-info-box">
            <label>Status</label>
            <p> {selectedTypeData.isActive ? "Active" : "InActive"}</p>
          </div>
        </div>
      </div>

      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteType}
        recordName={selectedTypeData?.rxTypeName || ""}
        modelName={"type"}
      />
    </div>
  );
};

export default ItemTypeProfile;
