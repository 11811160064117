import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./orderingproductlisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import deactivateicon from "../../../assets/images/deactivate-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import duplicateicon from "../../../assets/images/duplicate-icon.svg";
import ListingTitle from "../../../components/listingTitle/ListingTitle";
import { ReactSVG } from "react-svg";
import useOPs from "../../../Hooks/useOP";
import Loading from "../../../components/LoadingPage/Loading";
import {
  OrderingProduct,
  setSelectedOP,
} from "../../../redux/orderingProduct/OPSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { toggleClass } from "../../../utils/commonUtils";
import { DuplicateModel } from "./popup/duplicateModel";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import ItemListingTitle from "../../../components/itemListingTitle/itemListingTitle";
const OrderingProductListing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { getOPAPI, getOPLoading } = useOPs();
  useOPs();
  const { deleteOPAPI, deleteOPLoading } = useOPs();
  const [showModal, setShowModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);

  const [oPsList, setOPsList] = useState<OrderingProduct[]>([]);
  const [currentOP, setCurrentOP] = useState<OrderingProduct>();
  const { activeOPAPI, setOPStatusActiveLoading } = useOPs();
  const { deactiveOPAPI, setOPStatusDeActiveLoading } = useOPs();
  const { duplicateOPAPI, duplicateOPLoading } = useOPs();
  const defaultFilter = { searchText: "", active: true };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const deleteOP = async () => {
    await deleteOPAPI({
      id: currentOP?.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    getOPs();
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const toggleDuplicateModal = () => {
    toggleClass();
    setShowDuplicateModal(!showDuplicateModal);
  };

  const getOPs = () => {
    getOPAPI(searchFieldVal)
      .then((res: OrderingProduct[]) => setOPsList(res))
      .catch();
  };
  const editOP = (OP: OrderingProduct) => {
    dispatch(setSelectedOP(OP));
    navigate("/edit-ordering-product");
  };
  const navigateToView = (OP: OrderingProduct) => {
    dispatch(setSelectedOP(OP));
    navigate("/view-ordering-product");
  };
  const updateStatus = async (OP: OrderingProduct) => {
    if (OP.isActive) {
      await deactiveOPAPI({
        id: OP.id,
        updatedBy: currentUserId ?? "",
      });
    } else {
      await activeOPAPI({
        id: OP.id,
        updatedBy: currentUserId ?? "",
      });
    }
    getOPs();
  };
  const duplicateOP = async () => {
    await duplicateOPAPI({
      id: currentOP?.id,
      updatedBy: currentUserId ?? "",
    });
    toggleDuplicateModal();
    getOPs();
  };
  useEffect(() => {
    getOPs();
  }, [searchFieldVal]);
  return (
    <div className="ordering-product-listing-wrapper">
      {getOPLoading ||
      deleteOPLoading ||
      setOPStatusActiveLoading ||
      setOPStatusDeActiveLoading ||
      duplicateOPLoading ? (
        <Loading />
      ) : null}

      <ItemListingTitle
        title="Ordering Product Master"
        buttonTitle="Add New"
        href="/add-ordering-product"
        disRecords={oPsList.length || 0}
        totalRecords={oPsList.length || 0}
        searchVal={searchFieldVal}
        setSearchVal={setSearchFieldVal}
        defaultFilter={defaultFilter}
        isAddPermitted={true}
      />
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>Product No</th>
              <th>Product Description</th>
              <th>Unit</th>
              <th>Default Vendor</th>
              <th style={{ textAlign: "center" }}>Purchase UOM</th>
              <th style={{ textAlign: "center" }}>CONV. FACT.</th>
              <th>Vendor Product No</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {oPsList?.map((x: OrderingProduct) => (
              <tr
                key={x.id}
                onDoubleClick={() => {
                  navigateToView(x);
                }}
              >
                <td title={x.productNo}>{x.productNo}</td>
                <td title={x.productDescription}>{x.productDescription}</td>
                <td>{x.unit.unitName}</td>
                <td>{x.vendorMaster?.name}</td>
                <td style={{ textAlign: "center" }}>{x.purchaseUOM}</td>
                <td style={{ textAlign: "center" }}>{x.conversionFactor}</td>
                <td title={x.externalProductNo}>{x.externalProductNo}</td>
                <td>
                  <span
                    className={`status-circle ${x.isActive ? "active" : "inactive"}`}
                  >
                    {x.isActive ? "Active" : "InActive"}
                  </span>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigateToView(x)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={viewicon}
                        />
                        View
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          updateStatus(x);
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deactivateicon}
                        />
                        {x.isActive ? "Deactivate" : "Activate"}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => editOP(x)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentOP(x);
                          toggleModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentOP(x);
                          toggleDuplicateModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={duplicateicon}
                        />
                        Duplicate
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteOP}
        recordName={currentOP?.productDescription || ""}
        modelName={`ordering product`}
      />

      <DuplicateModel
        showModal={showDuplicateModal}
        toggleDuplicateModal={toggleDuplicateModal}
        duplicateOP={duplicateOP}
        currentOP={currentOP}
      />
    </div>
  );
};

export default OrderingProductListing;
