import CustomModal from "../../../../components/customModal/CustomModal";
import deleteitemmodalimge from "../../../../assets/images/delete-item-modal-image.svg";
import { Button } from "react-bootstrap";
export const DeleteConfirmModel = ({
  showModal,
  onClose,
  item,
  deleteItem,
}: any) => {
  return (
    <>
      {showModal && (
        <CustomModal
          title=""
          content={
            <>
              <div className="modal--content--wrapper">
                <img src={deleteitemmodalimge} alt="" />
                <h5>
                  Are you sure you want to <br />
                  delete this item '{item.rxName}'
                </h5>
                <div className="modal--inputbox">
                  <label>Reason to delete</label>
                  <textarea
                    className="form-control"
                    rows={3}
                    placeholder="Reason"
                  ></textarea>
                </div>
              </div>
              <div className="modal--action--btn--wrapper">
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button type="button" variant="primary" onClick={deleteItem}>
                  Delete
                </Button>
              </div>
            </>
          }
          onClose={onClose}
        />
      )}
    </>
  );
};
