import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "./../commonBaseApiSlice";

export const itemTypeApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getItemTypes: builder.mutation({
      query: (data) => ({
        url: `/rxtype/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    createItemType: builder.mutation({
      query: (data) => ({
        url: "/rxtype/create",
        method: "POST",
        body: data,
      }),
    }),
    editItemType: builder.mutation({
      query: (data) => ({
        url: "/rxtype/edit",
        method: "PUT",
        body: data,
      }),
    }),
    setTypeStatusActive: builder.mutation({
      query: (data) => ({
        url: `/rxtype/activate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    setTypeStatusDeActive: builder.mutation({
      query: (data) => ({
        url: `/rxtype/deactivate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    deleteType: builder.mutation({
      query: (data) => ({
        url: `/rxtype/softdelete?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    duplicateType: builder.mutation({
      query: (data) => ({
        url: `/rxtype/duplicate?${getFilters(data)}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetItemTypesMutation,
  useCreateItemTypeMutation,
  useEditItemTypeMutation,
  useSetTypeStatusActiveMutation,
  useSetTypeStatusDeActiveMutation,
  useDeleteTypeMutation,
  useDuplicateTypeMutation,
} = itemTypeApiSlice;
