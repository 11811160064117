import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "../commonBaseApiSlice";

export const unitApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUnits: builder.mutation({
      query: (data) => ({
        url: `/unit/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    createUnits: builder.mutation({
      query: (data) => ({
        url: "/unit/create",
        method: "POST",
        body: data,
      }),
    }),
    editUnits: builder.mutation({
      query: (data) => ({
        url: "/unit/edit",
        method: "PUT",
        body: data,
      }),
    }),
    setUnitStatusActive: builder.mutation({
      query: (data) => ({
        url: `/unit/activate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    setUnitStatusDeActive: builder.mutation({
      query: (data) => ({
        url: `/unit/deactivate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    deleteUnit: builder.mutation({
      query: (data) => ({
        url: `/unit/delete?${getFilters(data)}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetUnitsMutation,
  useCreateUnitsMutation,
  useEditUnitsMutation,
  useSetUnitStatusActiveMutation,
  useSetUnitStatusDeActiveMutation,
  useDeleteUnitMutation,
} = unitApiSlice;
