import { Button } from "react-bootstrap";
import "../listingTitle/listingtitle.scss";
const CommonSearchOnly = ({
  title,
  buttonTitle,
  href,
  disRecords,
  totalRecords,
  searchVal,
  setSearchVal,
  defaultFilter,
}: any) => {
  return (
    <div className="listing-title-box">
      <div className="listing-title-info">
        <h2>{title}</h2>
        <p>
          Displaying {disRecords} of {totalRecords} Results
        </p>
      </div>
      <div
        className="listing-searchbar-wrapper"
        style={{ justifyContent: "space-evenly" }}
      >
        <div
          className="listing-searchbar-item-wrapper"
          style={{ flex: "none", display: "contents" }}
        >
          <div className="listing-searchbar-item-box">
            <input
              type="text"
              min={0}
              className="form-control"
              placeholder="Search"
              onChange={(e: any) => {
                setSearchVal({ ...searchVal, searchText: e.target.value });
              }}
              value={searchVal?.searchText ?? ""}
            />
          </div>
          <Button
            type="button"
            variant="outline-secondary"
            className="btn-search"
            onClick={() => {
              setSearchVal({ ...defaultFilter });
            }}
          >
            Clear
          </Button>
        </div>
      </div>
      <div className="listing-action-box">
        <>
          <div className="action-box">
            <Button variant="primary" href={href}>
              <i className="bi bi-plus"></i>
              {buttonTitle}
            </Button>
          </div>
          {/* <div className="filter-box">
            <ReactSVG className="svg-box" wrapper="span" src={filtericon} />
          </div> */}
        </>
      </div>
    </div>
  );
};

export default CommonSearchOnly;
