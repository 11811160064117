import DashBoard from "./Dashboard/DashBoard";

const Dashboard = () => {
  return (
    <div className="dashboard-wrapper">
      <DashBoard />
    </div>
  );
};

export default Dashboard;
