import { Button } from "react-bootstrap";
import "../listingTitle/listingtitle.scss";
const ItemListingTitle = ({
  title,
  buttonTitle,
  href,
  disRecords,
  totalRecords,
  searchVal,
  setSearchVal,
  defaultFilter,
  isAddPermitted,
}: any) => {
  return (
    <div className="listing-title-box">
      <div className="listing-title-info">
        <h2>{title}</h2>
        <p>
          Displaying {disRecords} of {totalRecords} Results
        </p>
      </div>
      <div className="listing-searchbar-wrapper">
        <div className="listing-searchbar-item-wrapper">
          <div className="listing-searchbar-item-box">
            <input
              type="text"
              min={0}
              className="form-control"
              placeholder="Search"
              onChange={(e: any) => {
                setSearchVal({ ...searchVal, searchText: e.target.value });
              }}
              value={searchVal?.searchText ?? ""}
            />
          </div>
          <div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                checked={searchVal?.active ?? false}
                onChange={(e) => {
                  setSearchVal({
                    ...searchVal,
                    active: e.target.checked ? true : null,
                  });
                }}
              />
              <label className="form-check-label">Active</label>
            </div>
          </div>
          <Button
            type="button"
            variant="outline-secondary"
            className="btn-search"
            onClick={() => {
              setSearchVal({ ...defaultFilter });
            }}
          >
            Clear All
          </Button>
        </div>
      </div>
      <div className="listing-action-box">
        {isAddPermitted && (
          <>
            <div className="action-box">
              <Button variant="primary" href={href}>
                <i className="bi bi-plus"></i>
                {buttonTitle}
              </Button>
            </div>
            {/* <div className="filter-box">
              <ReactSVG className="svg-box" wrapper="span" src={filtericon} />
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default ItemListingTitle;
