import Form from "react-bootstrap/Form";
import "./additemtype.scss";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useItemTypes from "../../../Hooks/useItemTypes";
import { useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { selectedItemType } from "../../../redux/itemType/itemTypeSlice";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import Loading from "../../../components/LoadingPage/Loading";

const AddItemType = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { createItemTypeAPI, createItemTypeLoading } = useItemTypes();
  const { editItemTypeAPI, editItemTypeLoading } = useItemTypes();

  const [isEdit, setIsEdit] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const selectedItemTypeData = useAppSelector(selectedItemType);
  const [dynamicVal, setDynamicVal] = useState({
    isActive: true,
    createdBy: currentUserId,
    deleted: false,
    rxTypeCode: "",
    rxTypeName: "",
    sortOrder: 0,
    isVaccine: false,
  });
  useEffect(() => {
    if (location.pathname === "/edit-item-master") {
      setIsEdit(true);
      if (selectedItemTypeData?.id) {
        setDynamicVal({
          isActive: selectedItemTypeData.isActive,
          createdBy: selectedItemTypeData.createdBy,
          deleted: selectedItemTypeData.deleted,
          rxTypeCode: selectedItemTypeData.rxTypeCode,
          rxTypeName: selectedItemTypeData.rxTypeName,
          sortOrder: selectedItemTypeData.sortOrder,
          isVaccine: selectedItemTypeData.isVaccine,
        });
      } else {
        navigate("/item-type-master");
      }
    }
  }, []);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: dynamicVal,
    validationSchema: Yup.object({
      rxTypeCode: Yup.string()
        .max(10, "RX Type ID should not be more than 10 characters")
        .required("RX Type ID is required"),
      rxTypeName: Yup.string()
        .max(20, "RX Type Name should not be more than 20 characters")
        .required("RX Type Name is required"),
      sortOrder: Yup.number()
        .integer("Sort order must be a whole number between 0 and 999")
        .min(0, "Sort order must be a whole number between 0 and 999")
        .max(999, "Sort order must be a whole number between 0 and 999")
        .required("Sort Order is required"),
    }),
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        if (isEdit) {
          await editItemTypeAPI({ ...values, id: selectedItemTypeData.id });
        } else {
          await createItemTypeAPI(values);
        }
        navigate("/item-type-master");
      } catch (err) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  return (
    <div className="add-item-type-wrapper">
      {(createItemTypeLoading || editItemTypeLoading) && <Loading />}
      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} Item Type</h2>
      </div>
      <div className="add-item-type-box">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Label>
              RX Type ID<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter RX Type ID"
              id="rxTypeCode"
              name="rxTypeCode"
              onChange={handleChange}
              value={values.rxTypeCode}
              isInvalid={touched.rxTypeCode && !!errors.rxTypeCode}
            />
            {touched.rxTypeCode && errors.rxTypeCode ? (
              <Form.Control.Feedback type="invalid">
                {errors.rxTypeCode}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              RX Type Name<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter RX Type Name"
              id="rxTypeName"
              name="rxTypeName"
              onChange={handleChange}
              value={values.rxTypeName}
              isInvalid={touched.rxTypeName && !!errors.rxTypeName}
            />
            {touched.rxTypeName && errors.rxTypeName ? (
              <Form.Control.Feedback type="invalid">
                {errors.rxTypeName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Sort Order</Form.Label>
            <Form.Control
              type="number"
              min={0}
              placeholder="Enter Sort Order"
              id="sortOrder"
              name="sortOrder"
              onChange={handleChange}
              value={values.sortOrder}
              isInvalid={touched.sortOrder && !!errors.sortOrder}
            />
            {touched.sortOrder && errors.sortOrder ? (
              <Form.Control.Feedback type="invalid">
                {errors.sortOrder}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="Vaccine"
                type="checkbox"
                id="isVaccine"
                name="isVaccine"
                onChange={handleChange}
                checked={values.isVaccine}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-4">
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="Active"
                type="checkbox"
                id="isActive"
                name="isActive"
                onChange={handleChange}
                checked={values.isActive}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-4 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => navigate("/item-type-master")}
            >
              Cancel
            </Button>
            <Button type="submit">{isEdit ? "Update" : "Add"}</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default AddItemType;
