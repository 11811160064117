import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "../commonBaseApiSlice";
export const patientApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatient: builder.mutation({
      query: (data) => ({
        url: `/patient/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    createPatient: builder.mutation({
      query: (data) => ({
        url: "/patient/create",
        method: "POST",
        body: data,
      }),
    }),
    editPatient: builder.mutation({
      query: (data) => ({
        url: "/patient/edit",
        method: "PUT",
        body: data,
      }),
    }),
    getPatientById: builder.mutation({
      query: (id) => ({
        url: `/patient/getbyid?id=${id}`,
        method: "GET",
      }),
    }),
    deletePatient: builder.mutation({
      query: (data) => ({
        url: `/patient/delete?${getFilters(data)}`,
        method: "DELETE",
      }),
    }),
  }),
});
export const {
  useGetPatientMutation,
  useCreatePatientMutation,
  useEditPatientMutation,
  useDeletePatientMutation,
  useGetPatientByIdMutation,
} = patientApiSlice;
