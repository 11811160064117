const Loading = () => {
  return (
    <>
      <div
        className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-white opacity-50"
        style={{ zIndex: 10 }}
      >
        <div className="spinner-border" style={{ color: "#054d6e" }}>
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  );
};

export default Loading;
