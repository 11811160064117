import Form from "react-bootstrap/Form";
import "./addlabel.scss";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useLabelSetup from "../../../Hooks/useLabelSetup";
import Loading from "../../../components/LoadingPage/Loading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const AddLabel = () => {
  const [labelTypeOptions, setLabelTypeOptions] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const {
    createLabelSetupLoading,
    createLabelSetupAPI,
    getLabelSetupByIdAPI,
    getLabelSetupByIdLoading,
    editLabelSetupAPI,
    editLabelSetupLoading,
    getLabelTypesAPI,
    getLabelTypesLoading,
  } = useLabelSetup();
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const [dynamicVal, setDynamicVal] = useState({
    isActive: true,
    labelName: "",
    labelJSON: "",
    templateHTML: "",
    labelTypeId: "",
    sortOrder: 0,
    createdBy: currentUserId ?? "",
  });
  useEffect(() => {
    getLabelTypesAPI().then((res) => {
      setLabelTypeOptions(
        res?.map((item: { id: any; labelTypeName: any }) => {
          return {
            label: item.labelTypeName,
            value: item.id,
          };
        }),
      );
    });
    if (location.pathname !== "/add-label") {
      setIsEdit(true);
      if (id) {
        getLabelSetupByIdAPI(id)
          .then((data: any) => {
            setDynamicVal({
              isActive: data.isActive,
              labelName: data.labelName,
              labelJSON: data.labelJSON,
              labelTypeId: data.labelTypeId,
              sortOrder: data.sortOrder,
              templateHTML: data.templateHTML,
              createdBy: data.createdBy,
            });
          })
          .catch();
      } else {
        navigate("/label-setup");
      }
    }
  }, []);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: dynamicVal,
    validationSchema: Yup.object({
      labelName: Yup.string()
        .required("Label Name is Required")
        .max(25, "Label Name should not be more than 25 characters"),
      labelJSON: Yup.string().required("Label JSON is Required"),
      templateHTML: Yup.string().required("Template HTML is Required"),
      labelTypeId: Yup.string().required("Label Type is Required"),
      sortOrder: Yup.number()
        .integer("Sort order must be a whole number between 0 and 999")
        .min(0, "Sort order must be a whole number between 0 and 999")
        .max(999, "Sort order must be a whole number between 0 and 999")
        .required("Sort Order is required"),
    }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        if (isEdit) {
          await editLabelSetupAPI({ ...values, id: id });
        } else {
          await createLabelSetupAPI(values);
        }
        navigate("/label-setup");
      } catch (err: any) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  return (
    <div className="add-label-wrapper">
      {createLabelSetupLoading ||
      getLabelSetupByIdLoading ||
      editLabelSetupLoading ||
      getLabelTypesLoading ? (
        <Loading />
      ) : null}
      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} Label</h2>
      </div>
      <div className="add-label-box">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>
              Label Name<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Label Name"
              id="labelName"
              name="labelName"
              onChange={(e) => handleChange("labelName")(e.target.value ?? "")}
              value={values.labelName}
              isInvalid={touched.labelName && !!errors.labelName}
            />
            {touched.labelName && errors.labelName ? (
              <Form.Control.Feedback type="invalid">
                {errors.labelName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Label Type<sup>*</sup>
            </Form.Label>
            <Select
              placeholder="Select Label Type"
              classNamePrefix="react-select"
              options={labelTypeOptions}
              id="labelTypeId"
              name="labelTypeId"
              value={labelTypeOptions?.find(
                (option: any) => option.value === values.labelTypeId,
              )}
              onChange={(option: any) =>
                handleChange("labelTypeId")(option.value)
              }
              className={`${
                touched.labelTypeId && !!errors.labelTypeId
                  ? "is-invalid-border"
                  : ""
              } react-select-container `}
            />
            {touched.labelTypeId && errors.labelTypeId && (
              <Form.Control.Feedback type="invalid">
                {errors.labelTypeId}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Label JSON<sup>*</sup>
            </Form.Label>
            <Form.Control
              placeholder="Enter Label JSON"
              as="textarea"
              rows={3}
              id="labelJSON"
              name="labelJSON"
              onChange={(e) => handleChange("labelJSON")(e.target.value ?? "")}
              value={values.labelJSON}
              isInvalid={touched.labelJSON && !!errors.labelJSON}
            />
            {touched.labelJSON && errors.labelJSON ? (
              <Form.Control.Feedback type="invalid">
                {errors.labelJSON}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              Template HTML<sup>*</sup>
            </Form.Label>
            <Form.Control
              placeholder="Enter Template HTML"
              as="textarea"
              rows={3}
              id="templateHTML"
              name="templateHTML"
              onChange={(e) =>
                handleChange("templateHTML")(e.target.value ?? "")
              }
              value={values.templateHTML}
              isInvalid={touched.templateHTML && !!errors.templateHTML}
            />
            {touched.templateHTML && errors.templateHTML ? (
              <Form.Control.Feedback type="invalid">
                {errors.templateHTML}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Sort Order</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Sort Order"
              id="sortOrder"
              name="sortOrder"
              onChange={(e) => handleChange("sortOrder")(e.target.value)}
              value={values.sortOrder}
              isInvalid={touched.sortOrder && !!errors.sortOrder}
            />
            {touched.sortOrder && errors.sortOrder ? (
              <Form.Control.Feedback type="invalid">
                {errors.sortOrder}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label></Form.Label>
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="Active"
                type="checkbox"
                id="isActive"
                name="isActive"
                onChange={handleChange}
                checked={values.isActive}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => navigate("/label-setup")}
            >
              Cancel
            </Button>
            <Button type="submit">{isEdit ? "Update" : "Add"}</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default AddLabel;
