import "./addathenatransaction.scss";
import AthenaProfileHeader from "../../../components/AthenaProfileHeader/AthenaProfileHeader";
import { useEffect, useState } from "react";
import { toggleClass } from "../../../utils/commonUtils";
import { useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import useAthena from "../../../Hooks/useAthena";
import Loading from "../../../components/LoadingPage/Loading";
import { AthenaList } from "../../../redux/athena/athenaApiSlice";
import useLocations from "../../../Hooks/useLocations";
import { LocationList } from "../../../redux/location/locationApiSlice";
import { useAppSelector } from "../../../redux/hooks";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
const AthenaTransactionAdd = () => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [athenaDetails, setAthenaDetails] = useState<AthenaList>();
  const { getAthenaByIdAPI, getAthenaByIdLoading } = useAthena();
  const [locationOptions, setLocationOptions] = useState<LocationList[]>([]);
  const { getLocationsAPI, getLocationsLoading } = useLocations();
  const { processAthenaOrderAPI, processAthenaOrderLoading } = useAthena();
  const { markAthenaOrderunProcessedAPI, markAthenaOrderunProcessedLoading } =
    useAthena();
  const { discardAthenaOrderAPI, discardAthenaOrderLoading } = useAthena();
  const { deleteAthenaOrderAPI, deleteAthenaOrderLoading } = useAthena();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const initialFormVal = {
    departmentId: "",
    quantityBefore: "",
    quantityAfter: "",
    departmentIdBefore: "",
    departmentIdAfter: "",
    uomBefore: "",
    uomAfter: "",
    internalNoteBefore: "",
    internalNoteAfter: "",
    timestamp: new Date(),
    userId: currentUserId,
    internalNote: "",
    UOM: "",
  };
  const [defaultForm, setDefaultForm] = useState(initialFormVal);

  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  useEffect(() => {
    getLocationsAPI({ searchText: "", includeDeleted: false })
      .then((res: any) => setLocationOptions(res))
      .catch();

    getAthenaByIdAPI(id ?? "").then((res: any) => {
      if (res) {
        setAthenaDetails(res);
        setDefaultForm({
          quantityBefore: res?.quantity || "",
          quantityAfter: res?.quantity || "",
          departmentIdBefore: res?.departmentId || "",
          departmentIdAfter: res?.departmentId || "",
          uomBefore: res?.uom || "",
          uomAfter: res?.uom || "",
          internalNoteBefore: res?.internalNote || "",
          internalNoteAfter: res?.internalNote || "",
          timestamp: new Date(),
          departmentId: res?.departmentId,
          internalNote: res?.internalNote,
          UOM: res?.uom,
          userId: currentUserId,
        });
      }
    });
  }, []);
  const MAOP = () => {
    markAthenaOrderunProcessedAPI(athenaDetails?.id || "").then();
  };
  const disAthOrd = () => {
    discardAthenaOrderAPI(athenaDetails?.id || "").then();
  };
  const delAth = () => {
    deleteAthenaOrderAPI(athenaDetails?.id || "").then((res) => toggleModal());
  };
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: defaultForm,
    validationSchema: Yup.object({
      quantityAfter: Yup.number()
        .min(0, "Quantity must be a positive number")
        .required("Quantity is Required"),
      departmentIdAfter: Yup.string().required("Location is Required"),
      uomAfter: Yup.string().required("Reference No is Required"),
      internalNoteAfter: Yup.string().required("Reference No is Required"),
    }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        processAthenaOrderAPI(values);
      } catch (err: any) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  return (
    <div className="usage-trans-page-wrapper">
      {getAthenaByIdLoading ||
      getLocationsLoading ||
      processAthenaOrderLoading ||
      markAthenaOrderunProcessedLoading ||
      discardAthenaOrderLoading ||
      deleteAthenaOrderLoading ? (
        <Loading />
      ) : null}
      <AthenaProfileHeader
        title={`
          ${athenaDetails?.athenaPatient?.fName || ""} ${athenaDetails?.athenaPatient?.lName || ""}`}
        id={id}
        srxMessage={athenaDetails?.srxMessage}
        markAthenaOrderunProcessedAPI={MAOP}
        discardAthenaOrderAPI={disAthOrd}
        deleteAthenaOrderAPI={delAth}
        isEdit={true}
        recordName={athenaDetails?.athenaId || ""}
        toggleModal={toggleModal}
        showModal={showModal}
        srxStatus={athenaDetails?.srxStatus}
      />
      <div className="add-athena-trans-wrapper">
        <div className="add-athena-trans-box">
          <Form onSubmit={handleSubmit}>
            <h5 className="profile-info-box profile-section-title full-width">
              Overview
            </h5>
            <Form.Group className="mb-3">
              <Form.Label>Manufacturer</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.manufacturer || "-"}
                disabled={true}
                placeholder="Enter Manufacturer"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>NDC</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.ndc || "-"}
                disabled={true}
                placeholder="Enter NDC"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Lot Number</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.lotNumber || "-"}
                disabled={true}
                placeholder="Enter Lot Number"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Expiry Date</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.expDate || "-"}
                disabled={true}
                placeholder="Enter Expiry Date"
              />
            </Form.Group>

            <div className="profile-info-box">
              <Form.Group>
                <Form.Label>Internal Note / Barcode</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Internal Note / Barcode"
                  id="internalNoteAfter"
                  name="internalNoteAfter"
                  onChange={(e) =>
                    handleChange("internalNoteAfter")(e.target.value ?? "")
                  }
                  value={values.internalNoteAfter}
                  isInvalid={
                    touched.internalNoteAfter && !!errors.internalNoteAfter
                  }
                />
                {touched.internalNoteAfter && errors.internalNoteAfter ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.internalNoteAfter}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </div>
            <div className="profile-info-box">
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Form.Group>
                  <Form.Label>Department/Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Department/Location"
                    id="departmentIdAfter"
                    name="departmentIdAfter"
                    onChange={(e) =>
                      handleChange("departmentIdAfter")(e.target.value ?? "")
                    }
                    value={values.departmentIdAfter}
                    isInvalid={
                      touched.departmentIdAfter && !!errors.departmentIdAfter
                    }
                  />
                  {touched.departmentIdAfter && errors.departmentIdAfter ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.departmentIdAfter}
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
                <span style={{ fontSize: "14px", paddingTop: "8px" }}>
                  {locationOptions?.find(
                    (location: LocationList) =>
                      athenaDetails?.departmentId === location.locationCode,
                  )?.locationName ?? ""}
                </span>
              </div>
            </div>
            <div className="profile-info-box">
              <Form.Group>
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Quantity"
                  id="quantityAfter"
                  name="quantityAfter"
                  onChange={(e) =>
                    handleChange("quantityAfter")(e.target.value)
                  }
                  value={values.quantityAfter}
                  isInvalid={touched.quantityAfter && !!errors.quantityAfter}
                />
                {touched.quantityAfter && errors.quantityAfter ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.quantityAfter}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </div>
            <div className="profile-info-box">
              <Form.Group>
                <Form.Label>UOM</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter UOM"
                  id="uomAfter"
                  name="uomAfter"
                  onChange={(e) =>
                    handleChange("uomAfter")(e.target.value ?? "")
                  }
                  value={values.uomAfter}
                  isInvalid={touched.uomAfter && !!errors.uomAfter}
                />
                {touched.uomAfter && errors.uomAfter ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.uomAfter}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </div>
            <h5 className="profile-info-box profile-section-title full-width">
              Athena Order Details
            </h5>
            <Form.Group className="mb-3">
              <Form.Label>Athena ID</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.athenaId || "-"}
                disabled={true}
                placeholder="Enter Athena ID"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Encounter ID</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.encounterId || "-"}
                disabled={true}
                placeholder="Enter Encounter ID"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Patient ID</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.athenaPatient?.patientID || "-"}
                disabled={true}
                placeholder="Enter Patient ID"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Patient Name</Form.Label>
              <Form.Control
                type="text"
                value={
                  athenaDetails?.athenaPatient?.fName +
                    " " +
                    athenaDetails?.athenaPatient?.lName || "-"
                }
                disabled={true}
                placeholder="Enter Patient Name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Provider ID</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.providerId || "-"}
                disabled={true}
                placeholder="Enter Provider ID"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.status || "-"}
                disabled={true}
                placeholder="Enter Status"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Order Type</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.orderType || "-"}
                disabled={true}
                placeholder="Enter Order Type"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Document Class</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.documentClass || "-"}
                disabled={true}
                placeholder="Enter Document Class"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Version #</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.versionNo || "-"}
                disabled={true}
                placeholder="Enter Version #"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Refills Allowed</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.refillsAllowed || "-"}
                disabled={true}
                placeholder="Enter Refills Allowed"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Dosage Duration</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.dosageDuration || "-"}
                disabled={true}
                placeholder="Enter Dosage Duration"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Action Note</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.actionNote || "-"}
                disabled={true}
                placeholder="Enter Action Note"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Dosage Frequency Unit</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.dosageFrequencyUnit || "-"}
                disabled={true}
                placeholder="Enter Dosage Frequency Unit"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Dosage Duration Unit</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.dosageDurationUnit || "-"}
                disabled={true}
                placeholder="Enter Dosage Duration Unit"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Dosage Quantity</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.dosageQuantityValue || "-"}
                disabled={true}
                placeholder="Enter Dosage Quantity"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Signature</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.sig || "-"}
                disabled={true}
                placeholder="Enter Signature"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>SRX Status</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.srxStatus || "-"}
                disabled={true}
                placeholder="Enter SRX Status"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Ordering Mode</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.orderingMode || "-"}
                disabled={true}
                placeholder="Enter Ordering Mode"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Administered By (Username)</Form.Label>
              <Form.Control
                type="text"
                value={athenaDetails?.administeredBy || "-"}
                disabled={true}
                placeholder="Enter Administered User"
              />
            </Form.Group>
            <Form.Group className="mb-3 full-width text-end">
              <Button style={{ marginRight: "10px" }} variant="outline-primary">
                Cancel
              </Button>
              <Button type="submit">Process</Button>
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AthenaTransactionAdd;
