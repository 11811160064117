import UserListing from "./UserManagements/UserListing/UserListing";
const UserManagement = () => {
  return (
    <div>
      <UserListing />
    </div>
  );
};

export default UserManagement;
