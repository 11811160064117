import Form from "react-bootstrap/Form";
import "./addstock.scss";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useStocks from "../../../Hooks/useStocks";
import { useLocation, useNavigate } from "react-router-dom";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import Loading from "../../../components/LoadingPage/Loading";
import { useEffect, useState } from "react";
import { selectedStock } from "../../../redux/stock/stockSlice";
const AddStock = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { createStocksAPI, createStocksLoading } = useStocks();
  const { editStocksAPI, editStocksLoading } = useStocks();
  const [isEdit, setIsEdit] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const selectedStockData = useAppSelector(selectedStock);
  const [dynamicVal, setDynamicVal] = useState({
    isActive: true,
    createdBy: currentUserId,
    stockCode: "",
    stockName: "",
    sortOrder: 0,
  });
  useEffect(() => {
    if (location.pathname === "/edit-stock") {
      setIsEdit(true);
      if (selectedStockData?.id) {
        setDynamicVal({
          isActive: selectedStockData.isActive,
          createdBy: selectedStockData.createdBy,
          stockCode: selectedStockData.stockCode,
          stockName: selectedStockData.stockName,
          sortOrder: selectedStockData.sortOrder,
        });
      } else {
        navigate("/stock-master");
      }
    }
  }, []);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: dynamicVal,
    validationSchema: Yup.object({
      stockCode: Yup.string()
        .max(10, "Source Code ID should not be more than 10 characters")
        .required("Source Code ID is required"),
      stockName: Yup.string()
        .max(20, "Source Code Name should not be more than 20 characters")
        .required("Source Code Name is required"),
      sortOrder: Yup.number()
        .integer("Sort order must be a whole number between 0 and 999")
        .min(0, "Sort order must be a whole number between 0 and 999")
        .max(999, "Sort order must be a whole number between 0 and 999")
        .required("Sort Order is required"),
    }),
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        if (isEdit) {
          await editStocksAPI({ ...values, id: selectedStockData.id });
        } else {
          await createStocksAPI(values);
        }
        navigate("/stock-master");
      } catch (err) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  return (
    <div className="add-loc-wrapper">
      {(createStocksLoading || editStocksLoading) && <Loading />}

      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} Stock</h2>
      </div>
      <div className="add-loc-box">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Label>
              Source Code ID<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Source Code ID"
              id="stockCode"
              name="stockCode"
              onChange={handleChange}
              value={values.stockCode}
              isInvalid={touched.stockCode && !!errors.stockCode}
            />
            {touched.stockCode && errors.stockCode ? (
              <Form.Control.Feedback type="invalid">
                {errors.stockCode}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>
              Source Code Name<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Source Code Name"
              id="stockName"
              name="stockName"
              onChange={handleChange}
              value={values.stockName}
              isInvalid={touched.stockName && !!errors.stockName}
            />
            {touched.stockName && errors.stockName ? (
              <Form.Control.Feedback type="invalid">
                {errors.stockName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Sort Order</Form.Label>
            <Form.Control
              type="number"
              min={0}
              placeholder="Enter Sort Order"
              id="sortOrder"
              name="sortOrder"
              onChange={handleChange}
              value={values.sortOrder}
              isInvalid={touched.sortOrder && !!errors.sortOrder}
            />
            {touched.sortOrder && errors.sortOrder ? (
              <Form.Control.Feedback type="invalid">
                {errors.sortOrder}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="Active"
                type="checkbox"
                id="isActive"
                name="isActive"
                onChange={handleChange}
                checked={values.isActive}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-4 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => navigate("/stock-master")}
            >
              Cancel
            </Button>
            <Button type="submit">{isEdit ? "Update" : "Add"}</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default AddStock;
