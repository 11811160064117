import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Product {
  productCode: string;
  barcode: string;
  productName: string;
  category: string;
  manufacturer: string;
  model: string;
  isMedicine: boolean;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc?: string;
  createdBy: string;
  updatedBy: string;
}

export interface ProductData {
  selectedProduct: Product;
}
const storedProduct = localStorage.getItem("selectedProduct");
const initialState: ProductData = {
  selectedProduct: storedProduct ? JSON.parse(storedProduct) : null,
};

const ProductSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setSelectedProduct: (state, action: PayloadAction<Product>) => {
      state.selectedProduct = action.payload;
      localStorage.setItem("selectedProduct", JSON.stringify(action.payload));
    },
  },
});
export const { setSelectedProduct } = ProductSlice.actions;
export default ProductSlice.reducer;
export const selectedProduct = (state: RootState) =>
  state.product.selectedProduct;
