import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "../commonBaseApiSlice";

export const vendorApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVendor: builder.mutation({
      query: (data) => ({
        url: `/vendormaster/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    createVendor: builder.mutation({
      query: (data) => ({
        url: "/vendormaster/create",
        method: "POST",
        body: data,
      }),
    }),
    editVendor: builder.mutation({
      query: (data) => ({
        url: "/vendormaster/edit",
        method: "PUT",
        body: data,
      }),
    }),
    setVendorStatusActive: builder.mutation({
      query: (data) => ({
        url: `/vendormaster/activate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    setVendorStatusDeActive: builder.mutation({
      query: (data) => ({
        url: `/vendormaster/deactivate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    deleteVendor: builder.mutation({
      query: (data) => ({
        url: `/vendormaster/delete?${getFilters(data)}`,
        method: "DELETE",
      }),
    }),
    duplicateVendor: builder.mutation({
      query: (data) => ({
        url: `/vendormaster/duplicate?${getFilters(data)}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetVendorMutation,
  useCreateVendorMutation,
  useEditVendorMutation,
  useDeleteVendorMutation,
  useSetVendorStatusActiveMutation,
  useSetVendorStatusDeActiveMutation,
  useDuplicateVendorMutation,
} = vendorApiSlice;
