import { commonBaseApiSlice } from "../commonBaseApiSlice";

export const adminApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTenantKey: builder.mutation({
      query: () => ({
        url: "/adminsetting/get?searchText=WSACCESsKEY",
        method: "GET",
      }),
    }),
    getPermissions: builder.mutation({
      query: (id) => ({
        url: `/rolepermission/getmenubyroleid?roleId=${id}`,
        method: "GET",
      }),
    }),
  }),
});
export const { useGetTenantKeyMutation, useGetPermissionsMutation } =
  adminApiSlice;
