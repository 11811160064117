import { ReactSVG } from "react-svg";
import searchicon from "../../../../assets/images/search.svg";
import CustomModal from "../../../../components/customModal/CustomModal";
import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { LabelSetup } from "../../../../redux/labelSetup/labelSetupSlice";
import useLabelSetup from "../../../../Hooks/useLabelSetup";
export const PrintBarcodePopUp = ({
  showBarcodeModal,
  toggleBarcodeModal,
  printBarcode,
  defaultBarcodes,
}: any) => {
  const [searchText, setSearchText] = useState<string>("");
  const [printLabelId, setPrintLabelId] = useState<LabelSetup[]>([]);
  const { getLabelSetupAPI } = useLabelSetup();
  const [selectedLabel, setSelectedLabel] = useState<LabelSetup>();
  const getLabels = () => {
    getLabelSetupAPI({ searchText }).then((res) => {
      setPrintLabelId(res);
    });
  };
  useEffect(() => {
    getLabels();
  }, [searchText]);
  return (
    showBarcodeModal && (
      <CustomModal
        title="Print Barcode"
        content={
          <div className="modal--content--wrapper">
            <div className="modal--inputbox">
              {/* <Searchbar /> */}
              {/* <div className="searchbar">
                <ReactSVG className="svg-box" wrapper="span" src={searchicon} />
                <Form>
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    value={searchText}
                  />
                </Form>
              </div> */}
              <div className="barcodes-list-wrapper">
                <label>Select Label</label>
                <div className="barcodes-list-box">
                  <ul>
                    {printLabelId?.map((label) => (
                      <li key={label.id}>
                        <Form.Check
                          inline
                          label={label.labelName}
                          name="barcodes-no"
                          type="radio"
                          checked={selectedLabel?.id === label.id}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedLabel(label);
                            }
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="modal--action--btn--wrapper text-end">
              <Button
                type="button"
                variant="outline-primary"
                onClick={toggleBarcodeModal}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={() =>
                  printBarcode(
                    selectedLabel?.id ?? "",
                    defaultBarcodes?.id ?? "",
                  )
                }
                disabled={!!!selectedLabel?.id}
              >
                Print
              </Button>
            </div>
          </div>
        }
        onClose={toggleBarcodeModal}
      />
    )
  );
};
