import { configureStore } from "@reduxjs/toolkit";
import { commonBaseApiSlice } from "./commonBaseApiSlice";
import authReducer from "./auth/authSlice";
import inventoryReducer from "./inventory/inventorySlice";
import configReducer from "./config/configSlice";
import storageMiddleware from "./middlewares/storageMiddleware";
import userReducer from "./user/userSlice";
import stockReducer from "./stock/stockSlice";
import locationReducer from "./location/locationSlice";
import OPReducer from "./orderingProduct/OPSlice";
import productReducer from "./product/productSlice";
import unitReducer from "./units/unitsSlice";
import vendorReducer from "./vendor/vendorSlice";
import itemTypeReducer from "./itemType/itemTypeSlice";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    inventory: inventoryReducer,
    stock: stockReducer,
    location: locationReducer,
    unit: unitReducer,
    vendor: vendorReducer,
    itemType: itemTypeReducer,
    [commonBaseApiSlice.reducerPath]: commonBaseApiSlice.reducer,
    config: configReducer,
    user: userReducer,
    OP: OPReducer,
    product: productReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(storageMiddleware, commonBaseApiSlice.middleware),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
