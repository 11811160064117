import { getErrorMessage } from "../utils/commonUtils";
import {
  useCreateUnitsMutation,
  useDeleteUnitMutation,
  useEditUnitsMutation,
  useGetUnitsMutation,
  useSetUnitStatusActiveMutation,
  useSetUnitStatusDeActiveMutation,
} from "../redux/units/unitsApiSlice";
import { toast } from "react-toastify";

export default function useUnits() {
  const [getUnits, { isLoading: getUnitsLoading }] = useGetUnitsMutation();
  const [createUnits, { isLoading: createUnitsLoading }] =
    useCreateUnitsMutation();
  const [editUnits, { isLoading: editUnitsLoading }] = useEditUnitsMutation();

  const [setUnitStatusActive, { isLoading: setUnitStatusActiveLoading }] =
    useSetUnitStatusActiveMutation();
  const [setUnitStatusDeActive, { isLoading: setUnitStatusDeActiveLoading }] =
    useSetUnitStatusDeActiveMutation();
  const [deleteUnit, { isLoading: deleteUnitLoading }] =
    useDeleteUnitMutation();
  const getUnitsAPI = async (data: any) => {
    try {
      const response: any = await getUnits(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const createUnitsAPI = async (data: any) => {
    try {
      const response: any = await createUnits(data).unwrap();
      toast.success("Success! Unit has been Created.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const editUnitsAPI = async (data: any) => {
    try {
      const response: any = await editUnits(data).unwrap();
      toast.success("Success! Unit has been Updated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const activeUnitAPI = async (data: any) => {
    try {
      const response: any = await setUnitStatusActive(data).unwrap();
      toast.success("Success! Unit has been Activated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deactiveUnitAPI = async (data: any) => {
    try {
      const response: any = await setUnitStatusDeActive(data).unwrap();
      toast.success("Success! Unit has been Deactivated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deleteUnitAPI = async (data: any) => {
    try {
      const response: any = await deleteUnit(data).unwrap();
      toast.success("Success! Unit has been Deleted.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    getUnitsAPI,
    getUnitsLoading,
    createUnitsLoading,
    createUnitsAPI,
    editUnitsAPI,
    editUnitsLoading,
    activeUnitAPI,
    setUnitStatusActiveLoading,
    deactiveUnitAPI,
    setUnitStatusDeActiveLoading,
    deleteUnitAPI,
    deleteUnitLoading,
  };
}
