import ReportHeader from "../../../components/reportHeader/ReportHeader";
import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import { Table } from "react-bootstrap";

const ProvidersList = () => {
  return (
    <div className="report-content-wrapper">
      <ReportHeader
        reportTitle="SRX Report - List of Providers"
        reportDate="04 April 2024"
      />
      <div className="report-filter-wrapper">
        <div className="report-filter-item-wrapper"></div>
        <div className="report-export-box">
          <button type="button" className="report-export-btn">
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
            Export
          </button>
        </div>
      </div>
      <div className="filter-detail-wrapper">
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-title">
            <h4></h4>
          </div>
          <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th>Clinical Role</th>
                  <th>User Type</th>
                  <th>Active</th>
                  <th>NPI</th>
                  <th>SPID</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Healthometer</td>
                  <td>Abraham</td>
                  <td>Debra</td>
                  <td>Provider</td>
                  <td>WSUser</td>
                  <td>Yes</td>
                  <td>1234567890</td>
                  <td>SP20191209064257999</td>
                  <td>Added from Allscripts API</td>
                </tr>
                <tr>
                  <td>Callmydoc</td>
                  <td>Abbott</td>
                  <td>Lara</td>
                  <td>Provider</td>
                  <td>WSUser</td>
                  <td>Yes</td>
                  <td>1231112311</td>
                  <td>SP20220216100315356</td>
                  <td>Added from Allscripts API</td>
                </tr>
                <tr>
                  <td>Intellidose</td>
                  <td>Achen</td>
                  <td>Susan</td>
                  <td>Provider</td>
                  <td>WSUser</td>
                  <td>Yes</td>
                  <td>1588855357</td>
                  <td>SP20220629151959851</td>
                  <td>Added from Allscripts API</td>
                </tr>
                <tr>
                  <td>Healthometer</td>
                  <td>Abraham</td>
                  <td>Debra</td>
                  <td>Provider</td>
                  <td>WSUser</td>
                  <td>Yes</td>
                  <td>1234567890</td>
                  <td>SP20191209064257999</td>
                  <td>Added from Allscripts API</td>
                </tr>
                <tr>
                  <td>Healthometer</td>
                  <td>Abraham</td>
                  <td>Debra</td>
                  <td>Provider</td>
                  <td>WSUser</td>
                  <td>Yes</td>
                  <td>1234567890</td>
                  <td>SP20191209064257999</td>
                  <td>Added from Allscripts API</td>
                </tr>
                <tr>
                  <td>Callmydoc</td>
                  <td>Abbott</td>
                  <td>Lara</td>
                  <td>Provider</td>
                  <td>WSUser</td>
                  <td>Yes</td>
                  <td>1231112311</td>
                  <td>SP20220216100315356</td>
                  <td>Added from Allscripts API</td>
                </tr>
                <tr>
                  <td>2F41ABB8-7A4E</td>
                  <td>Achen</td>
                  <td>Susan</td>
                  <td>Provider</td>
                  <td>WSUser</td>
                  <td>Yes</td>
                  <td>1588855357</td>
                  <td>SP20220629151959851</td>
                  <td>Added from Allscripts API</td>
                </tr>
                <tr>
                  <td>Healthometer</td>
                  <td>Abraham</td>
                  <td>Debra</td>
                  <td>Provider</td>
                  <td>WSUser</td>
                  <td>Yes</td>
                  <td>1234567890</td>
                  <td>SP20191209064257999</td>
                  <td>Added from Allscripts API</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvidersList;
