import Form from "react-bootstrap/Form";
import "./addproductmaster.scss";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useProducts from "../../../Hooks/useProduct";
import { useLocation, useNavigate } from "react-router-dom";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import Loading from "../../../components/LoadingPage/Loading";
import { useEffect, useState } from "react";
import { Product, selectedProduct } from "../../../redux/product/productSlice";
import { CharacterLength } from "../../../Hooks/constants";

const AddProductMaster = () => {
  const productMasterUrl = "/product-master";
  const navigate = useNavigate();
  const location = useLocation();
  const { createProductAPI, createProductLoading } = useProducts();
  const { editProductAPI, editProductLoading } = useProducts();
  const [isEdit, setIsEdit] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const selectedProductData = useAppSelector<Product | null>(selectedProduct);
  const [dynamicVal, setDynamicVal] = useState({
    productCode: "",
    barcode: "",
    productName: "",
    category: "",
    manufacturer: "",
    model: "",
    isMedicine: false,
    isActive: true,
    createdBy: currentUserId,
  });
  useEffect(() => {
    if (location.pathname === "/edit-product-master") {
      setIsEdit(true);
      if (selectedProductData?.id) {
        setDynamicVal({
          productCode: selectedProductData.productCode,
          barcode: selectedProductData.barcode,
          productName: selectedProductData.productName,
          category: selectedProductData.category,
          manufacturer: selectedProductData.manufacturer,
          model: selectedProductData.model,
          isMedicine: selectedProductData.isMedicine,
          isActive: true,
          createdBy: currentUserId,
        });
      } else {
        navigate(productMasterUrl);
      }
    }
  }, []);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: dynamicVal,
    validationSchema: Yup.object({
      productCode: Yup.string()
        .required("Product Code is required")
        .max(
          CharacterLength.fifty,
          "Product Code should not be more than 50 characters",
        ),
      productName: Yup.string()
        .required("Product Name is required")
        .max(
          CharacterLength.fiveHundred,
          "Product Name should not be more than 500 characters",
        ),
      barcode: Yup.string()
        .required("Barcode is required")
        .max(
          CharacterLength.fifty,
          "Barcode should not be more than 50 characters",
        ),
      manufacturer: Yup.string()
        .required("Manufacturer is required")
        .max(
          CharacterLength.twoFiveSix,
          "Manufacturer should not be more than 256 characters",
        ),
      category: Yup.string()
        .required("Category is required")
        .max(
          CharacterLength.oneTwoFive,
          "Category should not be more than 125 characters",
        ),
      model: Yup.string()
        .required("Model is required")
        .max(
          CharacterLength.hundred,
          "Model should not be more than 100 characters",
        ),
    }),
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        if (isEdit) {
          await editProductAPI({ ...values, id: selectedProductData?.id });
        } else {
          await createProductAPI(values);
        }
        navigate(productMasterUrl);
      } catch (err) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  return (
    <div className="add-product-master-wrapper">
      {(createProductLoading || editProductLoading) && <Loading />}
      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} Product</h2>
      </div>
      <div className="add-product-master-box">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>
              Product Code / NDC<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Product Code"
              id="productCode"
              name="productCode"
              onChange={handleChange}
              value={values.productCode}
              isInvalid={touched.productCode && !!errors.productCode}
            />
            {touched.productCode && errors.productCode ? (
              <Form.Control.Feedback type="invalid">
                {errors.productCode}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Barcode<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Barcode"
              id="barcode"
              name="barcode"
              onChange={handleChange}
              value={values.barcode}
              isInvalid={touched.barcode && !!errors.barcode}
            />
            {touched.barcode && errors.barcode ? (
              <Form.Control.Feedback type="invalid">
                {errors.barcode}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Product Name<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Product Name"
              id="productName"
              name="productName"
              onChange={handleChange}
              value={values.productName}
              isInvalid={touched.productName && !!errors.productName}
            />
            {touched.productName && errors.productName ? (
              <Form.Control.Feedback type="invalid">
                {errors.productName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Category / RXGroup<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Category"
              id="category"
              name="category"
              onChange={handleChange}
              value={values.category}
              isInvalid={touched.category && !!errors.category}
            />
            {touched.category && errors.category ? (
              <Form.Control.Feedback type="invalid">
                {errors.category}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Manufacturer<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Manufacturer"
              id="manufacturer"
              name="manufacturer"
              onChange={handleChange}
              value={values.manufacturer}
              isInvalid={touched.manufacturer && !!errors.manufacturer}
            />
            {touched.manufacturer && errors.manufacturer ? (
              <Form.Control.Feedback type="invalid">
                {errors.manufacturer}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Model / CVX<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Model"
              id="model"
              name="model"
              onChange={handleChange}
              value={values.model}
              isInvalid={touched.model && !!errors.model}
            />
            {touched.model && errors.model ? (
              <Form.Control.Feedback type="invalid">
                {errors.model}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-3">
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="IsMed"
                type="checkbox"
                id="isMedicine"
                name="isMedicine"
                onChange={handleChange}
                checked={values.isMedicine}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => navigate(productMasterUrl)}
            >
              Cancel
            </Button>
            <Button type="submit">{isEdit ? "Update" : "Add"}</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default AddProductMaster;
