import { Button, Dropdown, Modal, Image } from "react-bootstrap";
import "./userprofileheader.scss";
import statusmodalimage from "../../assets/images/status-modal-image.svg";
import threedotvertical from "../../assets/images/three-dots-vertical.svg";
import deleteicon from "../../assets/images/delete-icon.svg";
import duplicateicon from "../../assets/images/duplicate-icon.svg";
import deactivateicon from "../../assets/images/deactivate-icon.svg";
import resetpasswordicon from "../../assets/images/reset-password-icon.svg";
import loadicon from "../../assets/images/load-icon.svg";
import { useState } from "react";
import { ReactSVG } from "react-svg";
import useUser from "../../Hooks/useUser";
import { useNavigate } from "react-router-dom";
import Loading from "../LoadingPage/Loading";
import { toggleClass } from "../../utils/commonUtils";
import { DeleteConfModel } from "../DeleteConfModel/deleteConfModel";
import { toast } from "react-toastify";

const UserProfileHeader = ({
  selectedUserData,
  updateStatus,
  currentUserId,
}: any) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showModal, setShowModal] = useState(false);
  const { deleteUserAPI, deleteUserLoading } = useUser();
  const {
    userDuplicate,
    duplicateUserLoading,
    onResetPassword,
    resetPasswordLoading,
  } = useUser();
  const navigate = useNavigate();
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const onClose = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const duplicateUser = async () => {
    await userDuplicate({ id: selectedUserData.id, updatedBy: currentUserId });
    navigate("/users");
  };
  const deleteUser = async () => {
    await deleteUserAPI({ id: selectedUserData.id, deletedBy: currentUserId });
    onClose();
    navigate("/users");
  };

  const resetPassword = async () => {
    const userName = selectedUserData.userName;
    const { result } = await onResetPassword({
      userName,
      updatedBy: currentUserId,
    });
    if (result) {
      toast.success(result);
    }
  };
  return (
    <div className="user-profile-header-wrapper">
      {deleteUserLoading || duplicateUserLoading || resetPasswordLoading ? (
        <Loading />
      ) : null}
      <div className="profile-title-status-box">
        <h2>{selectedUserData.fName + " " + selectedUserData.lName}</h2>
        <div
          className={`status-box 
          ${selectedUserData?.isActive ? "active" : "inactive"}`}
        >
          <span className="status-circle"></span>
          <span className="status-text">
            {selectedUserData?.isActive ? "Active" : "InActive"}
          </span>
        </div>
      </div>
      <div className="profile-actions-box">
        <div className="edit-btn-box">
          <Button variant="primary" href="/edit-user">
            <i className="bi bi-pencil-square"></i> Edit
          </Button>
        </div>
        <Dropdown>
          <Dropdown.Toggle variant="link" id="dropdown-basic">
            <ReactSVG
              className="svg-box"
              wrapper="span"
              src={threedotvertical}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <ReactSVG
                style={{ marginLeft: "-3px" }}
                className="svg-box"
                wrapper="span"
                src={loadicon}
              />
              Load Usage
            </Dropdown.Item>
            <Dropdown.Item onClick={duplicateUser}>
              <ReactSVG
                className="svg-box"
                wrapper="span"
                src={duplicateicon}
              />
              Duplicate User
            </Dropdown.Item>
            <Dropdown.Item onClick={toggleModal}>
              <ReactSVG className="svg-box" wrapper="span" src={deleteicon} />
              Delete
            </Dropdown.Item>
            <Dropdown.Item onClick={updateStatus}>
              <ReactSVG
                className="svg-box"
                wrapper="span"
                src={deactivateicon}
              />
              {selectedUserData?.isActive ? "Deactivate" : "Activate"}
            </Dropdown.Item>
            <Dropdown.Item onClick={resetPassword}>
              <ReactSVG
                style={{ marginLeft: "-3px" }}
                className="svg-box"
                wrapper="span"
                src={resetpasswordicon}
              />
              Reset Password
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Modal id="statusmodal" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Image src={statusmodalimage} alt="" />
          <h4>
            Are you sure you want to <br></br>approve this user?
          </h4>
          <p>rachel.steward@test.com</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Approve
          </Button>
        </Modal.Footer>
      </Modal>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={onClose}
        deleteAction={deleteUser}
        modelName={`User`}
        recordName={selectedUserData.email || ""}
      />
    </div>
  );
};

export default UserProfileHeader;
