import "./stockprofile.scss";
import StockProfileHeader from "../../../components/stockprofileheader/StockProfileHeader";
import {
  selectedStock,
  setSelectedStock,
} from "../../../redux/stock/stockSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Loading from "../../../components/LoadingPage/Loading";
import { useNavigate } from "react-router-dom";
import useStocks from "../../../Hooks/useStocks";
import { useState } from "react";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { toggleClass } from "../../../utils/commonUtils";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
const StockProfile = () => {
  const selectedStockData = useAppSelector(selectedStock);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeStockAPI, setStockStatusActiveLoading } = useStocks();
  const { deactiveStockAPI, setStockStatusDeActiveLoading } = useStocks();
  const { deleteStockAPI, deleteStockLoading } = useStocks();
  const [showModal, setShowModal] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const updateStatus = () => {
    if (selectedStockData.isActive) {
      deactiveStockAPI({
        id: selectedStockData.id,
        updatedBy: currentUserId ?? "",
      })
        .then((res) => {
          if (res) {
            dispatch(setSelectedStock(res));
          }
        })
        .catch();
    } else {
      activeStockAPI({
        id: selectedStockData.id,
        updatedBy: currentUserId ?? "",
      })
        .then((res) => {
          if (res) {
            dispatch(setSelectedStock(res));
          }
        })
        .catch();
    }
  };
  const deleteStock = async () => {
    await deleteStockAPI({
      id: selectedStockData.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    navigate("/stock-master");
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };

  return (
    <div className="profile-page-wrapper">
      {(setStockStatusActiveLoading ||
        setStockStatusDeActiveLoading ||
        deleteStockLoading) && <Loading />}
      <StockProfileHeader
        selectedStockData={selectedStockData}
        updateStatus={updateStatus}
        toggleModal={toggleModal}
      />
      <div className="profile-detail-box-wrapper">
        <div className="profile-detail-box">
          <div className="profile-info-box">
            <label>Source Code ID</label>
            <p>{selectedStockData.stockCode}</p>
          </div>
          <div className="profile-info-box">
            <label>Source Code Name</label>
            <p>{selectedStockData.stockName}</p>
          </div>
          <div className="profile-info-box">
            <label>Sort Order</label>
            <p>{selectedStockData.sortOrder}</p>
          </div>
          <div className="profile-info-box">
            <label>Status</label>
            <p> {selectedStockData.isActive ? "Active" : "InActive"}</p>
          </div>
        </div>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteStock}
        recordName={selectedStockData?.stockName || ""}
        modelName={`stock`}
      />
    </div>
  );
};

export default StockProfile;
