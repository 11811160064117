import { getErrorMessage } from "../utils/commonUtils";
import { toast } from "react-toastify";
import {
  useGetUserslistMutation,
  useTrReportsDDMutation,
  useTrReportsMutation,
} from "../redux/reports/reportsApiSlice";
import { date } from "yup";

export default function useReports() {
  const [getUserslist, { isLoading: getUserslistLoading }] =
    useGetUserslistMutation();
  const [trReportsDD, { isLoading: trReportsDDLoading }] =
    useTrReportsDDMutation();
  const [trReports, { isLoading: trReportsLoading }] = useTrReportsMutation();
  const getUserslistAPI = async (data: any) => {
    try {
      const response: any = await getUserslist(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const trReportsDDAPI = async () => {
    try {
      const response: any = await trReportsDD({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const trReportsAPI = async (data: any) => {
    try {
      const response: any = await trReports(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    getUserslistAPI,
    getUserslistLoading,
    trReportsDDAPI,
    trReportsDDLoading,
    trReportsAPI,
    trReportsLoading,
  };
}
