import "./unitprofile.scss";
import UnitProfileHeader from "../../../components/unitprofileheader/UnitProfileHeader";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectedUnit, setSelectedUnit } from "../../../redux/units/unitsSlice";
import useUnits from "../../../Hooks/useUnits";
import { useNavigate } from "react-router-dom";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useState } from "react";
import Loading from "../../../components/LoadingPage/Loading";
import { toggleClass } from "../../../utils/commonUtils";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
const UnitProfile = () => {
  const selectedUnitData = useAppSelector(selectedUnit);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeUnitAPI, setUnitStatusActiveLoading } = useUnits();
  const { deactiveUnitAPI, setUnitStatusDeActiveLoading } = useUnits();
  const { deleteUnitAPI, deleteUnitLoading } = useUnits();
  const [showModal, setShowModal] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const updateStatus = () => {
    if (selectedUnitData.isActive) {
      deactiveUnitAPI({
        id: selectedUnitData.id,
        updatedBy: currentUserId ?? "",
      })
        .then((res) => {
          if (res) {
            dispatch(setSelectedUnit(res));
          }
        })
        .catch();
    } else {
      activeUnitAPI({
        id: selectedUnitData.id,
        updatedBy: currentUserId ?? "",
      })
        .then((res) => {
          if (res) {
            dispatch(setSelectedUnit(res));
          }
        })
        .catch();
    }
  };
  const deleteUnit = async () => {
    await deleteUnitAPI({
      id: selectedUnitData.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    navigate("/unit-master");
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  return (
    <div className="profile-page-wrapper">
      {(setUnitStatusActiveLoading ||
        setUnitStatusDeActiveLoading ||
        deleteUnitLoading) && <Loading />}
      <UnitProfileHeader
        selectedUnitData={selectedUnitData}
        updateStatus={updateStatus}
        toggleModal={toggleModal}
      />
      <div className="profile-detail-box-wrapper">
        <div className="profile-detail-box">
          <div className="profile-info-box">
            <label>Unit ID</label>
            <p>{selectedUnitData.unitCode}</p>
          </div>
          <div className="profile-info-box">
            <label>Unit Name</label>
            <p>{selectedUnitData.unitName}</p>
          </div>
          <div className="profile-info-box">
            <label>Sort Order</label>
            <p>{selectedUnitData.sortOrder}</p>
          </div>
          <div className="profile-info-box">
            <label>Status</label>
            <p> {selectedUnitData.isActive ? "Active" : "InActive"}</p>
          </div>
        </div>
      </div>

      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteUnit}
        recordName={selectedUnitData?.unitName || ""}
        modelName={`unit`}
      />
    </div>
  );
};

export default UnitProfile;
