import { commonBaseApiSlice } from "../commonBaseApiSlice";

export interface Provider {
  fullName?: string;
  userName: string;
  title?: any;
  fName: string;
  lName: string;
  mi: string;
  lastUsedDateAsHCP?: string;
  toalCountAsHCP?: number;
  lastUsedDateAsUser?: string;
  toalCountAsUser?: number;
  forceChangePassword?: boolean;
  notes?: any;
  deaNumber?: string;
  email?: any;
  userValue?: number;
  clinicalRoleId?: string;
  clinicalRole?: ClinicalRole;
  emrId?: any;
  roleId?: string;
  role?: Role;
  refreshToken?: any;
  refreshTokenExpiryTime?: any;
  profilePicture?: any;
  deletedBy?: any;
  deleted?: boolean;
  deletedOnUtc?: any;
  id: string;
  isActive?: boolean;
  createdOnUtc?: string;
  updatedOnUtc?: any;
  createdBy?: string;
  updatedBy?: any;
}

export interface ClinicalRole {
  roleName: string;
  roleDescription: string;
  sortOrder: number;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface Role {
  roleName: string;
  roleDescription: string;
  sortOrder: number;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface RecordUsageRes {
  status: string;
  msg: string;
  manufacturer: string;
  rxCategory: string;
  rxName: string;
  ndc: string;
  ndC11: string;
  lotNo: string;
  expDate: string;
  cvx: string;
  uom: string;
  text1: any;
  text2: any;
  text3: string;
  list1: string;
  list2: string;
  bcScan: string;
  inventory?: Inventory[];
  useUnits?: any[];
  expiringInventoryList?: any[];
}
export interface Payload {
  accessKey: string;
  patID: string;
  patFName: string;
  patMI: string;
  patLName: string;
  patDOB: string;
  patGender: string;
  userID: string;
  userFName: string;
  userMI: string;
  userLName: string;
  userNum: string;
  hcpid: string;
  hcpfName: string;
  hcpmi: string;
  hcplName: string;
  hcpNum: string;
  serverDate: string;
  serverTime: string;
  location: string;
  barcode: string;
  qty: string;
  units: string | null;
  query: string;
  uniqueId: string;
  inventory: boolean;
  expiringInventory: boolean;
  emrOrderId: string;
  name: string;
  encounterId: string;
  encounterDate: string;
  emrOrderDate: string;
  reason: string;
  waste: string;
  clientId: string;
}

export interface Inventory {
  itemId: string;
  item: Item;
  quantity: number;
  locationId: string;
  location: Location;
  userId: string;
  user: any;
  verifyDate: string;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface Item {
  rxSRXId: string;
  rxName: string;
  drugName: string;
  ndcCode: string;
  manufacturer: string;
  lotNo: string;
  expiryDate: string;
  cptCode: string;
  rxTypeId: string;
  rxType: RxType;
  stockId: string;
  stock: Stock;
  notes: string;
  userDef1: any;
  userDef2: any;
  userDef3: any;
  userDef4: any;
  userDef5: any;
  isRecalled: boolean;
  isKit: any;
  isSerialTracked: any;
  cvx: string;
  unitId: string;
  unit: Unit;
  useUnits: UseUnit[];
  orderingProductMasterId: any;
  orderingProductMaster: any;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface RxType {
  rxTypeCode: string;
  rxTypeName: string;
  isVaccine: boolean;
  sortOrder: number;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface Stock {
  stockCode: string;
  stockName: string;
  sortOrder: number;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface Unit {
  unitCode: string;
  unitName: string;
  inventoryUnit: boolean;
  emrUnit: boolean;
  orderingUnit: boolean;
  isActiveInventoryUnit: boolean;
  isActiveEMRUnit: boolean;
  isActiveOrderingUnit: boolean;
  sortOrder: number;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface UseUnit {
  itemId: string;
  unitId: string;
  unit: Unit2;
  conversionFactor: number;
  isDefault: boolean;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface Unit2 {
  unitCode: string;
  unitName: string;
  inventoryUnit: boolean;
  emrUnit: boolean;
  orderingUnit: boolean;
  isActiveInventoryUnit: boolean;
  isActiveEMRUnit: boolean;
  isActiveOrderingUnit: boolean;
  sortOrder: number;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface Location {
  locationCode: string;
  locationName: string;
  sortOrder: number;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}
export interface MessageLog {
  key: string;
  value: string;
}
export interface ApiLog {
  barcode: string;
  query: string;
  units: string;
  location: string;
  comment: string;
  userId: string;
  hcpId: string;
  message: string;
  recordType: string;
  rxName: string;
  ndcCode: string;
  eDate: string;
  reqString: string;
  resString: string;
  sessionRefNo: any;
  shotId: any;
  shot: any;
  patientId: any;
  patient: any;
  itemId: any;
  item: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}
export interface TypeLog {
  key: string;
  value: string;
}
export const recordUsageApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDDvalues: builder.mutation({
      query: () => ({
        url: "/recordusage/renderrecordusagepage",
        method: "GET",
      }),
    }),

    getRecordUsageBarcodeDetails: builder.mutation({
      query: (data) => ({
        url: `/recordusage/getbarcodeinfo?tenantId=${localStorage.getItem("profileClientId")}`,
        method: "POST",
        body: data,
      }),
    }),

    logDDCal: builder.mutation({
      query: () => ({
        url: "/recordusage/renderwslogpage",
        method: "GET",
      }),
    }),
    getLogList: builder.mutation({
      query: (data) => ({
        url: `/recordusage/getwslogs`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});
export const {
  useGetDDvaluesMutation,
  useGetRecordUsageBarcodeDetailsMutation,
  useLogDDCalMutation,
  useGetLogListMutation,
} = recordUsageApiSlice;
