import { Button, Dropdown, Tab, Table, Tabs } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import "./invertoryorderlist.scss";
import { ReactSVG } from "react-svg";
import Select from "react-select";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import downloadicon from "../../../assets/images/download-icon.svg";
import exporticon from "../../../assets/images/export-icon.svg";
import printicon from "../../../assets/images/print-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import circlecheckicon from "../../../assets/images/circle-check-icon.svg";
import srxlogowithtext from "../../../assets/images/SRX-logo-with-text.svg";
import sflogo from "../../../assets/images/SourceFuse-logo.png";
import duplicateitemmodalimge from "../../../assets/images/duplicate-item-modal-image.svg";
import CustomModal from "../../../components/customModal/CustomModal";
import DatePicker from "react-date-picker";
import { toggleClass } from "../../../utils/commonUtils";
import useInventoryOrder from "../../../Hooks/useInventoryOrder";
import useVendors from "../../../Hooks/useVendors";
import Loading from "../../../components/LoadingPage/Loading";
import { Vendor } from "../../../redux/vendor/vendorSlice";
import { DropDownValues } from "../../../utils/types";
import moment from "moment";
import { FormikHelpers, useFormik } from "formik";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import React from "react";
const orderNoOptions = [
  { value: "All", label: "All" },
  { value: "ORD1", label: "ORD1" },
  { value: "ORD2", label: "ORD2" },
  { value: "ORD3", label: "ORD3" },
  { value: "ORD4", label: "ORD4" },
  { value: "ORD5", label: "ORD5" },
  { value: "ORD6", label: "ORD6" },
];
const InventoryOrderList = () => {
  const [invOrdList, setInvOrdList] = useState([]);
  const [orderStatusOptions, setOrderStatusOptions] = useState<any>([]);
  const [selectedInvOrd, setSelectedInvOrd] = useState<any>([]);
  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedVender, setSelectedVender] = useState<string>("");

  const onCloseReportModal = () => setShowReportModal(false);
  const onShowReportModal = () => setShowReportModal(true);
  const {
    InvOrderListAPI,
    InvOrderListLoading,
    InvOrdersAPI,
    InvOrdersLoading,
    InvOrderCreateAPI,
    invOrderCreateLoading,
    orderStatusMasterAPI,
    orderStatusMasterLoading,
  } = useInventoryOrder();
  const [showModal, setShowModal] = useState(false);
  const { getVendorsAPI, getVendorsLoading } = useVendors();
  const [orderList, setOrderList] = useState([]);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [vendorOptions, setVendorOptions] = useState<DropDownValues[]>([]);
  const DynamicInitialValues = {
    orderId: "",
    orderStatus: "",
    startDate: moment().subtract(2, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };
  const [formInitialValues, setFormInitialValues] =
    useState(DynamicInitialValues);

  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const getInvOrdList = () => {
    InvOrderListAPI(selectedVender ? { venderId: selectedVender } : {}).then(
      (res: any) => setInvOrdList(res),
    );
  };
  useEffect(() => {
    orderStatusMasterAPI().then((res) => {
      setOrderStatusOptions([
        {
          value: "",
          label: "All",
        },
        ...res?.map((option: any) => {
          return {
            value: option.id,
            label: option.description || "",
          };
        }),
      ]);
    });
    getVendorsAPI({ searchText: "", includeDeleted: false })
      .then((res: Vendor[]) => {
        setVendorOptions([
          {
            value: "",
            label: "All",
          },
          ...res?.map((option: Vendor) => {
            return {
              value: option.id,
              label: option.name || "NA",
            };
          }),
        ]);
      })
      .catch();
    handleSubmit();
  }, []);
  useEffect(() => {
    getInvOrdList();
    setSelectedInvOrd([]);
  }, [selectedVender]);
  const itemSelectHandler = (checked: boolean, item: any) => {
    if (checked) {
      setSelectedInvOrd([...selectedInvOrd, item]);
    } else {
      setSelectedInvOrd(selectedInvOrd.filter((x: any) => x.id !== item.id));
    }
  };

  const { handleChange, values, handleSubmit, resetForm } = useFormik({
    initialValues: formInitialValues,
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        InvOrdersAPI(values).then((res) => {
          setOrderList(res);
        });
      } catch (err) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  const handleCreateInvOrd = () => {
    const payload = selectedInvOrd.map((x: any) => {
      let data = {
        id: x.id,
        orderingProductId: x.orderingProductId,
        locationCode: x.locationCode,
        isActive: true,
        updatedBy: currentUserId,
        orderQty: x.orderqty,
        orderDate: x.orderdate,
        orderStatusId: x.orderstatusid,
        vendorId: x.vendorid,
        refNo: x.refno || "",
        createdBy: currentUserId,
        comments: "",
      };
      delete data.id;
      return {
        ...data,
      };
    });
    InvOrderCreateAPI(payload).then((res) => {
      getInvOrdList();
    });
  };
  const selectAllHandler = (checked: boolean) => {
    if (checked) {
      setSelectedInvOrd([...invOrdList]);
    } else {
      setSelectedInvOrd([]);
    }
  };
  return (
    <div className="inventory-order-wrapper">
      {(InvOrderListLoading ||
        getVendorsLoading ||
        InvOrdersLoading ||
        invOrderCreateLoading ||
        orderStatusMasterLoading) && <Loading />}
      <div className="inventory-order-header">
        <h2>Inventory Order</h2>
      </div>
      <div className="inventory-order-detail-box">
        <Tabs
          defaultActiveKey="invtCreateOrder"
          id="create-order-tab"
          className="mb-3"
        >
          <Tab eventKey="invtCreateOrder" title="Create Order">
            <div className="invt-create-order-content-wrapper">
              <div className="create-order-action-container">
                <div className="create-order-action-box">
                  <label className="form-label">Vendor</label>
                  <Select
                    classNamePrefix="react-select"
                    className="vendorselectbox react-select-container"
                    options={vendorOptions}
                    value={vendorOptions?.find(
                      (option: DropDownValues) =>
                        option.value === selectedVender,
                    )}
                    onChange={(e: any) => {
                      setSelectedVender(e.value);
                      getInvOrdList();
                    }}
                  />
                </div>
                <div className="create-order-action-box">
                  <label className="form-label d-block">&nbsp;</label>
                  <Button variant="primary" onClick={handleCreateInvOrd}>
                    Create Order
                  </Button>
                </div>
                <div className="create-order-action-box clear-all-box">
                  <span
                    onClick={() => {
                      setSelectedVender("");
                      getInvOrdList();
                    }}
                  >
                    Clear All
                  </span>
                </div>
              </div>
              <div className="inventory-order-table-wrapper">
                <div className="listing-table-wrapper">
                  <Table responsive="md" hover={true}>
                    <thead>
                      <tr>
                        <th>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={(e) => selectAllHandler(e.target.checked)}
                            checked={
                              selectedInvOrd.length === invOrdList.length
                            }
                          />
                        </th>
                        <th>Product No</th>
                        <th>Description</th>
                        <th>LOCATION</th>
                        <th>Reorder Level</th>
                        <th>QUANTITY ON HAND</th>
                        <th>ORDER QUANTITY</th>
                        <th>UNIT</th>
                        <th>VENDOR NAME</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invOrdList?.map((item: any) => (
                        <tr key={item.id}>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={selectedInvOrd.includes(item)}
                              onChange={(e) =>
                                itemSelectHandler(e.target.checked, item)
                              }
                            />
                          </td>
                          <td>{item.productNo ?? "-"}</td>
                          <td>{item.productDescription ?? "-"}</td>
                          <td>{item.locationName ?? "-"}</td>
                          <td>{item.reorderLevel ?? "-"}</td>
                          <td>
                            <span
                              className={
                                item.qtyonhand < 0 ? "negative-value" : ""
                              }
                            >
                              {item.qtyonhand ?? "-"}
                            </span>
                          </td>
                          <td>{item.orderqty ?? "-"}</td>
                          <td>{item.unitName ?? "-"}</td>
                          <td>{item.defaultvendorname ?? "-"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="invtorder" title="Orders">
            <div className="order-filter-wrapper">
              <div className="order-filter-item-wrapper">
                <div className="filter-item-box location-filter-item">
                  <label className="form-label">Status</label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={orderStatusOptions}
                    placeholder="Status"
                    id="orderStatus"
                    name="orderStatus"
                    value={orderStatusOptions?.find(
                      (option: DropDownValues) =>
                        option.value === values.orderStatus,
                    )}
                    onChange={(option: any) => {
                      handleChange("orderStatus")(option.value);
                      handleSubmit();
                    }}
                  />
                </div>
                <div className="filter-item-box type-filter-item">
                  <label className="form-label">Order</label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={orderNoOptions}
                    id="orderId"
                    name="orderId"
                    value={orderStatusOptions?.find(
                      (option: DropDownValues) =>
                        option.value === values.orderId,
                    )}
                    onChange={(option: any) => {
                      handleChange("orderId")(option.value);
                      handleSubmit();
                    }}
                  />
                </div>
                <div className="filter-item-box">
                  <label className="form-label">Start Date</label>
                  <DatePicker
                    format="MM/dd/yyyy"
                    openCalendarOnFocus={false}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    className="form-control"
                    clearIcon={null}
                    calendarIcon={<i className="bi bi-calendar"></i>}
                    showLeadingZeros={true}
                    value={values.startDate}
                    onChange={(date: any) => {
                      handleChange({
                        target: {
                          name: "startDate",
                          value: moment(date).format("YYYY-MM-DD"),
                        },
                      });
                      handleSubmit();
                    }}
                  />
                </div>
                <div className="filter-item-box">
                  <label className="form-label">End Date</label>
                  <DatePicker
                    format="MM/dd/yyyy"
                    openCalendarOnFocus={false}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    className="form-control"
                    clearIcon={null}
                    calendarIcon={<i className="bi bi-calendar"></i>}
                    showLeadingZeros={true}
                    value={values.endDate}
                    onChange={(date: any) => {
                      handleChange({
                        target: {
                          name: "endDate",
                          value: moment(date).format("YYYY-MM-DD"),
                        },
                      });
                      handleSubmit();
                    }}
                  />
                </div>
                <div className="filter-item-box clear-all-box">
                  <button
                    type="button"
                    className="clear-filter-btn"
                    onClick={() => {
                      resetForm();
                      handleSubmit();
                    }}
                  >
                    Clear All
                  </button>
                </div>
              </div>
              <div className="order-action-box">
                <button
                  type="button"
                  className="order-action-btn order-print-btn"
                  onClick={onShowReportModal}
                >
                  <ReactSVG
                    className="svg-box"
                    wrapper="span"
                    src={printicon}
                  />{" "}
                  Print Order
                </button>
                <button
                  type="button"
                  className="order-action-btn report-export-btn"
                >
                  <ReactSVG
                    className="svg-box"
                    wrapper="span"
                    src={exporticon}
                  />{" "}
                  Export
                </button>
              </div>
            </div>
            <div className="inventory-order-table-wrapper">
              <div className="listing-table-wrapper">
                <Table responsive="md" hover={true}>
                  <thead>
                    <tr>
                      <th>Order No</th>
                      <th>Order Date</th>
                      <th>Status</th>
                      <th>Vendor Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderList.map((data: any) => (
                      <tr>
                        <td>{data.id}</td>
                        <td>
                          {data.orderDate
                            ? moment(data.orderDate).format("MM/DD/YYYY")
                            : "NA"}
                        </td>
                        <td>
                          <span className="status-tag active">Active</span>
                        </td>
                        <td>AmericansourceBergen</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle variant="link">
                              <ReactSVG
                                className="svg-box"
                                wrapper="span"
                                src={threedotvertical}
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                href={`/inventory-order-detail/${data.id}`}
                              >
                                <ReactSVG
                                  className="svg-box"
                                  wrapper="span"
                                  src={viewicon}
                                />
                                View Detail
                              </Dropdown.Item>
                              <Dropdown.Item href="#" onClick={toggleModal}>
                                <ReactSVG
                                  className="svg-box"
                                  wrapper="span"
                                  src={deleteicon}
                                />
                                Delete Order
                              </Dropdown.Item>
                              <Dropdown.Item href="#">
                                <ReactSVG
                                  className="svg-box"
                                  wrapper="span"
                                  src={circlecheckicon}
                                />
                                Mark as Complete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>

      {/* Delete Order Modal */}
      {showModal && (
        <CustomModal
          title=""
          content={
            <>
              <div className="modal--content--wrapper">
                <img src={duplicateitemmodalimge} alt="" />
                <h5>
                  Are you sure you want to <br />
                  delete this order 'ORD8'?
                </h5>
                <div className="modal--inputbox">
                  <label>Reason to delete</label>
                  <textarea
                    className="form-control"
                    rows={3}
                    placeholder="Reason"
                  ></textarea>
                </div>
                <div className="modal--action--btn--wrapper">
                  <Button
                    type="button"
                    variant="outline-primary"
                    onClick={toggleModal}
                  >
                    Cancel
                  </Button>
                  <Button type="button" variant="primary">
                    Delete
                  </Button>
                </div>
              </div>
            </>
          }
          //footer={<button onClick={toggleModal}>Close Modal</button>}
          onClose={toggleModal}
        />
      )}

      {/* Transaction Report Modal */}

      <Modal
        size="xl"
        id="trans-report-modal"
        show={showReportModal}
        onHide={onCloseReportModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Purchase Order</h4>
            <div className="trans-report-action-wrapper">
              <div className="trans-report-action-box download-report-box">
                <ReactSVG
                  className="svg-box"
                  wrapper="span"
                  src={downloadicon}
                />
              </div>
              <div className="trans-report-action-box export-report-opt-box">
                <ReactSVG className="svg-box" wrapper="span" src={printicon} />
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="trans-report-content-wrapper">
            <div className="trans-report-content-header">
              <div className="header-content">
                <h5>Order Report</h5>
                <p>30/05/2024</p>
              </div>
              <div className="header-logo-wrapper">
                <div className="header-logo-box srx-logo-box">
                  <img src={srxlogowithtext} alt="srx" />
                </div>
                <div className="header-logo-box sf-logo-box">
                  <img src={sflogo} alt="sourcefuse" />
                </div>
              </div>
            </div>
            <div className="trans-report-detail-box-wrapper">
              <h5>Order# : ORD8</h5>
              <div className="trans-report-detail-box-container">
                <div className="trans-report-detail-box">
                  <label>Order Date</label>
                  <p>30/05/2024</p>
                </div>
                <div className="trans-report-detail-box">
                  <label>Order Status</label>
                  <p>Active</p>
                </div>
                <div className="trans-report-detail-box">
                  <label>Vendor Name</label>
                  <p>AmericansourceBergen</p>
                </div>
              </div>
              <div className="trans-report-table-wrapper">
                <Table bordered={false} hover={true}>
                  <thead>
                    <tr>
                      <th>Product No</th>
                      <th>Description</th>
                      <th>Location</th>
                      <th>Quantity</th>
                      <th>Unit</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0006-4827-01</td>
                      <td>Varivax</td>
                      <td>1234 Maple Street Springfield, Anytown, USA</td>
                      <td>100.00</td>
                      <td>ml</td>
                      <td>
                        <span className="status-tag active">Active</span>
                      </td>
                    </tr>
                    <tr>
                      <td>0006-4827-01</td>
                      <td>Rivax</td>
                      <td>Maple Street Springfield, Anytown, USA</td>
                      <td>30.00</td>
                      <td>ml</td>
                      <td>
                        <span className="status-tag active">Active</span>
                      </td>
                    </tr>
                    <tr>
                      <td>0006-4827-01</td>
                      <td>Arivax</td>
                      <td>Springfield Maple Street, Anytown, USA</td>
                      <td>20.00</td>
                      <td>ml</td>
                      <td>
                        <span className="status-tag active">Active</span>
                      </td>
                    </tr>
                    <tr>
                      <td>0006-4827-01</td>
                      <td>Varivax</td>
                      <td>Anytown, 1234 Maple Street Springfield, USA</td>
                      <td>80.00</td>
                      <td>ml</td>
                      <td>
                        <span className="status-tag inactive">Inactive</span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InventoryOrderList;
