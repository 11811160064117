import { Link } from "react-router-dom";
import profilepic from "../../assets/images/profile-pic.png";
import notificationicon from "../../assets/images/notification-bell.svg";
import Image from "react-bootstrap/Image";
import { ReactSVG } from "react-svg";
import useAuth from "../../Hooks/useAuth";
const RightNavbar = () => {
  const { logout } = useAuth();
  const logoutAction = async () => {
    await logout();
  };
  return (
    <div className="rightnavbar">
      <div id="notification-drop" className="dropdown">
        <span
          className="notification-box dropdown-toggle"
          data-bs-toggle="dropdown"
        >
          <ReactSVG className="svg-box" wrapper="span" src={notificationicon} />
        </span>
        <ul className="dropdown-menu">
          {/* <li>
            <Link className="dropdown-item" to="/">
              <Image src={profilepic} roundedCircle />
              <div className="notif-content">
                <h5>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit
                </h5>
                <p>dolor sit amet consectetur adipisicing</p>
              </div>
            </Link>
          </li> */}
          <li>
            <Link className="dropdown-item" to="/">
              notification 1
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/">
              notification 2
            </Link>
          </li>
        </ul>
      </div>
      <div id="profile-drop" className="dropdown">
        <div
          className="login-user-profile dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <Image src={profilepic} roundedCircle />
          <div className="user-info-box">
            <h4>{`${localStorage.getItem("firstName") ?? ""} ${localStorage.getItem("lastName") ?? ""} (${localStorage.getItem("username") ?? ""})`}</h4>
            <h6>{`${localStorage.getItem("roleName") ?? ""} (${localStorage.getItem("profileClientId")?.toUpperCase() ?? ""})`}</h6>
          </div>
          <i className="bi bi-chevron-down"></i>
        </div>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to="/user-profile">
              <i className="bi bi-person"></i> My Profile
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/" onClick={logoutAction}>
              <i className="bi bi-box-arrow-right"></i> Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RightNavbar;
