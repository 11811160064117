import RecordUsage from "./RecordUsage/RecordUsage";

const Records = () => {
  return (
    <div className="record-usage-wrapper">
      <RecordUsage />
    </div>
  );
};

export default Records;
