import { getErrorMessage } from "../utils/commonUtils";
import {
  useCreateItemWarningMutation,
  useDeleteItemWarningMutation,
  useDuplicateItemWarningMutation,
  useEditItemWarningMutation,
  useGetItemWarningByIdMutation,
  useGetItemWarningMutation,
} from "../redux/itemWarning/itemWarningApiSlice";
import { toast } from "react-toastify";

export default function useItemWarning() {
  const [getItemWarning, { isLoading: getItemWarningLoading }] =
    useGetItemWarningMutation();
  const [createItemWarning, { isLoading: createItemWarningLoading }] =
    useCreateItemWarningMutation();
  const [editItemWarning, { isLoading: editItemWarningLoading }] =
    useEditItemWarningMutation();
  const [deleteItemWarning, { isLoading: deleteItemWarningLoading }] =
    useDeleteItemWarningMutation();
  const [getItemWarningById, { isLoading: getItemWarningByIdLoading }] =
    useGetItemWarningByIdMutation();
  const [duplicateItemWarning, { isLoading: duplicateItemWarningLoading }] =
    useDuplicateItemWarningMutation();
  const getItemWarningAPI = async (data: any) => {
    try {
      const response: any = await getItemWarning(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getItemWarningByIdAPI = async (data: any) => {
    try {
      const response: any = await getItemWarningById(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const createItemWarningAPI = async (data: any) => {
    try {
      const response: any = await createItemWarning(data).unwrap();
      toast.success("Success! Item Warning has been Created.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const editItemWarningAPI = async (data: any) => {
    try {
      const response: any = await editItemWarning(data).unwrap();
      toast.success("Success! Item Warning has been Updated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const deleteItemWarningAPI = async (data: any) => {
    try {
      const response: any = await deleteItemWarning(data).unwrap();
      toast.success("Success! Item Warning has been Deleted.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const duplicateItemWarningAPI = async (data: any) => {
    try {
      const response: any = await duplicateItemWarning(data).unwrap();
      toast.success("Success! Item Warning has been Duplicated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    getItemWarningAPI,
    getItemWarningLoading,
    createItemWarningLoading,
    createItemWarningAPI,
    editItemWarningAPI,
    editItemWarningLoading,
    deleteItemWarningAPI,
    deleteItemWarningLoading,
    getItemWarningByIdAPI,
    getItemWarningByIdLoading,
    duplicateItemWarningAPI,
    duplicateItemWarningLoading,
  };
}
