import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "./../commonBaseApiSlice";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AuthData } from "./authSlice";

export type Credentials = {
  clientid: string;
  username: string;
  password: string;
};

export type GenrateToken = {
  code: string;
  clientId: string;
};

export const authApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthData, Credentials>({
      query: (credentials: Credentials) => ({
        url: `/authentication/authenticate?tenantId=${credentials.clientid}`,
        method: "POST",
        body: {
          username: credentials.username,
          password: credentials.password,
        },
      }),
    }),

    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `/user/forgotpassword?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    verifyResetPass: builder.mutation({
      query: (data) => ({
        url: `/user/verifyresetpasswordlink?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    resetPassword: builder.mutation<AuthData, Credentials>({
      query: (data) => ({
        url: `/user/resetpassword?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    changePassword: builder.mutation<AuthData, Credentials>({
      query: (data) => ({
        url: `/user/changepassword?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    logout: builder.mutation({
      query: (refreshToken: string | null) => ({
        url: "/authentication/revoke",
        method: "POST",
        body: { refreshToken },
      }),
    }),
    guideUrlApiSlice: builder.mutation({
      query: () => ({
        url: "/user/generateugcode",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useChangePasswordMutation,
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useVerifyResetPassMutation,
  useResetPasswordMutation,
  useGuideUrlApiSliceMutation,
} = authApiSlice;

// const apiSlice = commonBaseApiSlice.injectEndpoints({
//   endpoints: (builder) => ({
//     guideApiSlice: builder.mutation({
//       query: () => ({
//         url: "/user/generateugcode",
//         method: "GET",
//       }),
//     }),
//   }),
// });
// export const { useGuideApiSliceMutation } = apiSlice;
// export default apiSlice.reducer;
